export default {
  data () {
    return {
      fileSuffix: {
        audio: ['.mp3', '.wav', '.ape', '.flac', '.aac', '.wma', '.ra', '.mod', '.m4a'],
        video: ['.avi', '.mp4', '.m4v', '.vob', '.mts', '.264', '.asf', '.mkv', '.3gp', '.rmvb', '.mov', '.wmv', '.flv', '.mpg', '.f4v'],
        image: ['.jpg', '.jpeg', '.png', '.gif'],
        doc: ['.docx', '.doc', '.wps'],
        pdf: ['.pdf'],
        txt: ['.txt'],
        ppt: ['.pptx', '.ppt', '.dpt', '.dps'],
        zip: ['.btp', '.zip', '.iso'],
        xls: ['.wpt', '.xlsx', '.xls', '.ett', '.et'],
        dwg: ['.dwg']
      },
      fileMimeType: {
        audio: ['audio/mp3', 'audio/wav', 'audio/ape', 'audio/flac', 'audio/aac', 'audio/wma', 'audio/ra', 'audio/mod', 'audio/m4a'],
        video: ['video/mp4', 'application/vnd.rn-realmedia-vbr', 'video/avi', 'video/mts', 'video/264', 'video/x-ms-asf', 'video/mkv', 'video/3gp', 'video/mov', 'video/x-ms-wmv', 'video/flv', 'video/mpg', 'video/f4v'],
        image: ['image/jpeg', 'image/gif', 'image/png'],
        doc: ['.docx', '.doc', '.wps'],
        pdf: ['.pdf'],
        txt: ['.txt'],
        ppt: ['.pptx', '.ppt', '.dpt', '.dps'],
        zip: ['.btp', '.zip', '.iso'],
        xls: ['.wpt', '.xlsx', '.xls', '.ett', '.et'],
        dwg: ['.dwg']
      }
    }
  }
}
