export default {
    methods: {
        hasLocalDic (key) {
            return Array.isArray(this.getLocalStorage(key))
        },
        getLocalStorage (key) {
            return JSON.parse(sessionStorage.getItem(`JDICTIONARY_${key}`))
        },
        setLocalStorage (array, key) {
            if (!Array.isArray(array) || array.length < 1) return
            sessionStorage.setItem(`JDICTIONARY_${key}`, JSON.stringify(array))
        },
        dicMatchByArray (array, key, props = { key: 'key', value: 'value' }) {
            let target = {}
            array.some(item => {
                if (item[props.key] == key) {
                    target = item
                    return true
                }
            })
            return target[props.value] || key
        }
    }
}
