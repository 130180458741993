/*
 * @Description: 
 * @Author: tengmingxin
 * @Date: 2022-04-19 19:29:26
 * @LastEditors: tengmingxin
 * @LastEditTime: 2022-04-19 19:34:18
 */

import Vue from "vue"

/**
 * @msg: 分页查询个人小结
 * @param {Object} params
 */
export const getSummaryList = (params) => {
    return Vue.http({ url: '/api/personalsummary/getList', method: 'GET', params })
}

/**
 * @msg: 处理个人总结
 * @param {Object} data
 */
export const editSummary = (data) => {
    return Vue.http({ url: '/api/personalsummary', method: 'PUT', data })
}

/**
 * @msg: 处理个人总结
 * @param {Number} id
 */
export const getSummaryDetail = (id) => {
    return Vue.http({ url: `/api/personalsummary/${id}`, method: 'GET' })
}
