<template>
    <div :class="['j-menu-wrap', mode]">
        <!--        压缩菜单-->
        <div v-if="mode === 'vertical'" class="j-menu-collapse">
            <j-svg-icon class="j-menu-collapse-btn" v-if="isCollapse" name="collapse-left" @click="collapseEvent(false)"></j-svg-icon>
            <j-svg-icon class="j-menu-collapse-btn" v-else name="collapse-right"  @click="collapseEvent(true)"></j-svg-icon>
        </div>
        <div :class="['j-menu-sroll', mode]">
            <el-menu :default-active="activeMenu" :collapse="isCollapse" :mode="mode">
                <template v-for="item in $store.state.JMenu.menuList">
                    <!--包含二级菜单-->
                    <el-submenu v-if="item.children && item.children.length > 0" :key="item.menuId" :index="String(item.menuId)">
                        <template slot="title">
                            <j-svg-icon v-if="item.icon" class="j-menu-icon" :name="item.icon"/>
                            <span slot="title">{{item.name}}</span>
                        </template>
                        <el-menu-item @click="menuSelectEvent(sitem.address)" v-for="(sitem, si) in item.children" :index="`${item.menuId}-${sitem.menuId}`" :key="si">
                            <span slot="title">{{sitem.name}}</span>
                        </el-menu-item>
                    </el-submenu>
                    <!--仅有一级菜单-->
                    <el-menu-item v-else @click="menuSelectEvent(item.address)" :key="item.menuId" :index="`${item.parentId}-${item.menuId}`">
                        <j-svg-icon v-if="item.icon" class="j-menu-icon" :name="item.icon"/>
                        <span slot="title">{{item.name}}</span>
                    </el-menu-item>
                </template>
            </el-menu>
        </div>
    </div>
</template>

<script>
import JSvgIcon from "../JSvgIcon/JSvgIcon";
export default {
  name: 'JMenu',
  props: {
    mode: { // horizontal / vertical
      type: String,
      default: 'vertical'
    },
    collapseWidth: {
      type: [String, Number],
      default: 1280
    }
  },
  components: {
    JSvgIcon
  },
  data() {
    return {
      isCollapse: false,
      debounceResize: null
    }
  },
  created () {
    this.debounceResize = this.debounce(this.autoCollapse, 300)
    this.autoCollapse()
    window.addEventListener('resize', this.debounceResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.debounceResize)
  },
  methods: {
    debounce (fn, wait) {
      let timer = null
      return () => {
        timer !== null && clearTimeout(timer)
        timer = setTimeout(fn, wait)
      }
    },
    collapseEvent (flag) {
      this.isCollapse = flag
    },
    menuSelectEvent(component) {
      this.$store.commit('JMenu/openMenu', component)
    },
    autoCollapse () {
      console.log(document.documentElement.clientWidth, this)
      if (document.documentElement.clientWidth < (isNaN(Number(this.collapseWidth)) ? 1024 : Number(this.collapseWidth))) {
        this.isCollapse = true
      } else {
        this.isCollapse = false
      }
    }
  },
  computed: {
    activeMenu () {
      const activeItem = this.$store.state.JMenu.menuMap[this.$store.state.JMenu.menuActive] || {}
      return `${activeItem.parentId}-${activeItem.menuId}`
    }
  }
}
</script>

<style scoped lang="less">
    .j-menu-wrap {
        width: 100%;
        height: 100%;
        background-color: #FFF;
        box-sizing: border-box;
        .el-menu:not(.el-menu--collapse) {
            width: 200px;
        }
        &.vertical {
            border-right: 1px solid #e6e6e6;
        }
        .el-menu {
            border-right: 0;
        }
        .j-menu-collapse {
            padding: 10px 20px;
            font-size: 20px;
            text-align: center;
            height: 50px;
            box-sizing: border-box;
            color: @themeColor;
            .j-menu-collapse-btn {
                cursor: pointer;
            }
        }
        .j-menu-sroll.vertical {
            height: calc(100% - 50px);
            overflow-y: auto;
            overflow-x: hidden;
        }
        .j-menu-sroll.horizontal {
            height: 100%;
            width: 100%;
            overflow-x: auto;
            overflow-y: hidden;
            box-sizing: border-box;
            .el-menu--horizontal.el-menu {
                display: flex;
            }
        }
        /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
        ::-webkit-scrollbar
        {
            width: 3px;
            height: 3px;
            background-color: transparent ;
        }

        /*定义滚动条轨道 内阴影+圆角*/
        ::-webkit-scrollbar-track
        {
            background-color: transparent;
        }

        /*定义滑块 内阴影+圆角*/
        ::-webkit-scrollbar-thumb
        {
            border-radius: 10px;
            background-color: rgb(220,223,230);
        }
        .j-menu-icon {
            vertical-align: middle;
            margin-right: 5px;
            width: 24px;
            height: 100%;
            text-align: center;
            font-size: 23px;
            visibility: visible!important;
        }
        .el-submenu .j-menu-icon {
            vertical-align: middle;
            margin-right: 5px;
            width: 24px;
            height: 100%;
            text-align: center;
            font-size: 23px;
            visibility: visible!important;
        }
        .el-menu--collapse > .el-menu-item.j-menu-icon, .el-menu--collapse > .el-submenu > .el-submenu__title.j-menu-icon {
            margin: 0;
            vertical-align: middle;
            width: 24px;
            text-align: center;
        }
    }
</style>

<style lang="less">
    .el-submenu.is-active {
        .el-submenu__title {
            color: @themeColor;
        }
    }
</style>
