import Vue from 'vue'
import { Upload } from 'element-ui'
export default {
  name: 'ElUpload',
  extends: Upload,
  props: {
    onError: {
      type: Function,
      default: function (res) {
        if (res && res.message) {
          const data = JSON.parse(res.message)
          Vue.prototype.$message.info({ message: data.message || data.msg || '上传文件异常,请检查是否授权' })
        }
      }
    }
  },
  computed: {
    flsToken () {
      return this.$store.state.flsToken
    }
  },
  async created () {
    if (this.flsToken) {
      this.$set(this.headers, 'Authorization', this.flsToken)
    } else {
      this.$set(this.headers, 'Authorization', this.$store.state.user.token)
    }
  }
}
