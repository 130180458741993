/*
 * @Description:
 * @Author: tengmingxin
 * @Date: 2022-04-14 14:23:04
 * @LastEditors: tengmingxin
 * @LastEditTime: 2022-04-22 10:08:58
 */
import Vue from "vue";
import validate from "./validate";

let tool = {
  /**
   * @msg: 文件导出
   * @param {string} url api地址
   * @param {object} params 参数
   * @param {string} name 文件名
   */
  async exportEvent(url, name = "导出文件", params = {}) {
    console.log(params);
    const { data } = await Vue.http.request({
      method: "get",
      url,
      params,
      responseType: "arraybuffer",
    });
    const href = window.URL.createObjectURL(
      new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    let link = document.createElement("a");
    link.style.display = "none";
    link.href = href;
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // 下载完成移除元素
    window.URL.revokeObjectURL(href); // 释放掉blob对象
  },
  ...validate,
};
export default {
  install(Vue) {
    Vue.tool = Vue.prototype.$tool = tool;
  },
};
