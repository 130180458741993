import Vue from 'vue'
import Vuex from 'vuex'

import { user } from './user'

import { organ } from "./organ";

import { role } from './role';

import { JMenu } from '../components/JComponents/JMenu/store'

import { JDictionary } from "../components/JComponents/JDictionary/store";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ...user.state,
    ...organ.state,
    ...role.state,
  },
  mutations: {
    ...user.mutations,
    ...organ.mutations,
    ...role.mutations,
  },
  actions: {
    ...user.actions,
    ...organ.actions,
    ...role.actions
  },
  modules: {
    JMenu,
    JDictionary
  }
})
