import axios from 'axios'

const state = {
    flsToken: '', // 文件服务token
    user: {},
    rolesAction: []
}

const mutations = {
    user (state, user) {
        sessionStorage.setItem('biz_admin', JSON.stringify(user))
        this.dispatch('getFlsToken')
        state.user = user
    },
    flsToken (state, flsToken) {
        state.flsToken = flsToken
    },
    rolesAction (state, rolesAction) {
        state.rolesAction = rolesAction
    }
}

const actions = {
    async getFlsToken (content) {
        const { data } = await axios.get(`/api/files/token`)
        if (data.status != 'yes') return console.error(`获取文件服务token异常`)
        content.commit('flsToken', data.data || '')
    },
    async getRolesAction (content) {
        if (!content.state.user.roleId) return console.error(`获取roleId异常,获取权限失败`)
        const { data } = await axios.get(`/api/roles/${content.state.user.roleId}/buttons`)
        if (data.status != 'yes') return console.error(`获取权限按钮异常`)
        const action = data.data || []
        const rolesAction = []
        action.forEach(item => { if (item.roleId) rolesAction.push(item.code) })
        content.commit('rolesAction', rolesAction)
    }
}

export const user = {
    state,
    mutations,
    actions
}
