import axios from 'axios'
import mixin from '../mixins'

const props = { key:'key', value: 'value' }

const state = {
    Map: {},
    Dic: {}
}

const mutations = {
    Map (state, { key, map }) {
        state.Map[key] = map
    },
    Dic (state, { key, dic }) {
        state.Dic[key] = dic
    }
}

const actions = {
    async getDictionaryByKey (content, key) {
        content.commit('Dic', { key, dic: [] })
        content.commit('Map', { key, map: {} })
        let map = {}
        let dic = []
        if (mixin.methods.hasLocalDic(key)) {
            dic = mixin.methods.getLocalStorage(key) || []
        } else {
            const { data } = await axios.get(`/api/dictionaryItems/${key}/items-parent-key`)
            if (data.status != 'yes') return console.error(`字典ID：${key}，字典获取异常`)
            mixin.methods.setLocalStorage(data.data, key)
            dic = data.data
        }
        dic.forEach(item => { map[item[props.key]] = item[props.value] })
        content.commit('Dic', { key, dic })
        content.commit('Map', { key, map })
        return { map, dic }
    }
}

// 模块方式注册
export const JDictionary = {
    namespaced: true,
    state,
    mutations,
    actions
}
