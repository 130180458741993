<template>
    <div @click="$emit('click')" :class="['j-colour-wrap', size]" :style="`background-${type}: ${bgShade || bgColor};`">
        <div class="j-colour-wrap-icon">
            <slot name="icon"></slot>
        </div>
        <div v-if="desc || value" class="j-colour-wrap-desc">
            <div v-if="desc" class="j-colour-wrap-text" :title="desc">
                {{desc}}
            </div>
            <div v-if="value || value === 0" class="j-colour-wrap-count">
                {{number}}
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'JColour',
  props: {
    value: {
      type: [String, Number]
    },
    size: {
      type: String,
      default: 'small'
    },
    desc: {
      type: String,
      default: ''
    },
    icon: { // svg组件的name名称对应，优先级比图片插槽大,待完善
      type: String,
      default: ''
    },
    color: {
      type: [Array, String],
      default: ''
    },
    shade: { // 根据提供色值随机生成相近渐变色显示,shade优先级高于color
      type: String,
      default: ''
    }
  },
  computed: {
    bgColor () {
      if (Array.isArray(this.color)) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.type = 'image'
        if (this.color.length > 1) return `linear-gradient(47deg, ${this.color[0]} 0%, ${this.color[1]} 100%)`
        return `linear-gradient(47deg, ${this.color[0]} 0%, ${this.color[0]} 100%)`
      }
      return this.color || ''
    }
  },
  watch: {
    value (nv) {
      this.number = nv || 0
    }
  },
  data () {
    return {
      RGB: [],
      bgShade: '',
      type: 'color',
      number: ''
    }
  },
  created () {
    this.number = this.value
    if (this.shade) {
      this.type = 'image'
        if (this.shade.includes(',')) {
            this.shade.match(/\((.+?)\)/g)
            this.RGB = RegExp.$1.split(',')
        }
      else this.RGB = this.foramgeRGB(this.shade).split(',')
      const R = Number(this.RGB[0])
      const G = Number(this.RGB[1])
      const B = Number(this.RGB[2])
      // 生成最大色值
      const maxColorObject = this.findMaxRGB(R, G, B)
      // 生成相近色
      const shallowColorArray = this.getShallowRGB(maxColorObject, R, G, B)
      const C0 = `rgb(${shallowColorArray[0].toString()})`
      const C1 = `rgb(${shallowColorArray[1].toString()})`
      const C2 = `rgb(${shallowColorArray[2].toString()})`
      this.bgShade = `linear-gradient(47deg, ${C0} 0%, ${C2} 100%)`
    }
  },
  methods: {
    getShallowRGB (maxColorObject, R, G, B) {
      let threshold = 40// 色阶偏差值
      let offset = 10// 色深偏差值
      let result = []
      for (var d in maxColorObject) {
        if (d == 'R') {
          result = [
            [R - offset, G + threshold, B + threshold],
            [R - offset, G + threshold + 20, B + threshold + 20],
            [R - offset, G + threshold + 40, B + threshold + 40]
          ]
        } else if (d == 'G') {
          result = [
            [R + threshold, G - offset, B + threshold],
            [R + threshold + 20, G - offset, B + threshold + 20],
            [R + 60, G - offset, B + 60]
          ]
        } else if (d == 'B') {
          result = [
            [R + threshold, G + threshold, B - offset],
            [R + threshold + 20, G + threshold + 20, B - offset],
            [R + threshold + 40, G + threshold + 40, B - offset]
          ]
        }
      }
      return result
    },
    // 找到最大的RGB
    findMaxRGB (R, G, B) {
      let max
      let index
      if (R >= G && R >= B) {
        max = R
        index = 'R'
      }
      if (G >= R && G >= B) {
        max = G
        index = 'G'
      }
      if (B >= R && B >= G) {
        max = B
        index = 'B'
      }
      return {
        [index]: max
      }
    },
    // 16进制转RGB
    foramgeRGB (color) {
      // 16进制颜色值的正则
      let reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
      // 把颜色值变成小写
      color = color.toLowerCase()
      if (reg.test(color)) {
        // 如果只有三位的值，需变成六位，如：#fff => #ffffff
        if (color.length === 4) {
          let colorNew = '#'
          for (let i = 1; i < 4; i += 1) {
            colorNew += color.slice(i, i + 1).concat(color.slice(i, i + 1))
          }
          color = colorNew
        }
        // 处理六位的颜色值，转为RGB
        let colorChange = []
        for (let i = 1; i < 7; i += 2) {
          colorChange.push(parseInt('0x' + color.slice(i, i + 2)))
        }
        return colorChange.join(',')
      } else {
        return color
      }
    }
  }
}
</script>

<style scoped lang="less">
    .j-colour-wrap {
        display: inline-flex;
        align-items: center;
        vertical-align: middle;
        margin-top: 10px;
        margin-right: 10px;
        cursor: pointer;
        padding: 10px 5px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        width: 192px;
        color: #fff;
        &.mini {
            zoom: .8;
        }
        &.small {
            zoom: 1;
        }
        &.middle {
            zoom: 1.2;
        }
        .j-colour-wrap-icon {
            display: inline-flex;
            align-items: center;
            font-size: 30px;
            padding: 5px;
            img {
                height: 30px;
                object-fit: fill;
            }
        }
        .j-colour-wrap-desc {
            flex: 1;
            padding: 5px;
            font-size: 14px;
            color: #fff;
            min-height: 40px;
            min-width: 50px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            /*cursor: text;*/

            .j-colour-wrap-text {
                width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: keep-all;
                white-space: nowrap;
            }

            .j-colour-wrap-count {
                font-size: 16px;
                user-select: none;
            }
        }
    }
</style>
