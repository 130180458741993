<template>
    <div class="j-quick-tab-wrap">
        <div class="j-quick-tab-wrap-scroll">
            <j-quick-item :id="`j-quick-tab-${menu.menuId}`" v-for="(menu, index) in $store.state.JMenu.menuQuick" :key="index" :index="index" :meta="menu"/>
        </div>
    </div>
</template>

<script>
import JQuickItem from "./JQuickItem";
export default {
  name: 'JQuickTab',
  components: {
    JQuickItem
  }
}
</script>

<style scoped lang="less">
    .j-quick-tab-wrap {
        height: 100%;
        width: 100%;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        .j-quick-tab-wrap-scroll {
            display: flex;
            height: 100%;
            width: 100%;
            padding: 5px;
            box-sizing: border-box;
            overflow-y: hidden;
            overflow-x: auto;
        }
        /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
        ::-webkit-scrollbar
        {
            width: 3px;
            height: 3px;
            background-color: transparent ;
        }

        /*定义滚动条轨道 内阴影+圆角*/
        ::-webkit-scrollbar-track
        {
            background-color: transparent;
        }

        /*定义滑块 内阴影+圆角*/
        ::-webkit-scrollbar-thumb
        {
            border-radius: 10px;
            background-color: rgb(220,223,230);
        }
    }
</style>
