<template>
  <div class="j-drawer-wrap">
    <el-drawer :title="title" v-bind="$attrs" :destroy-on-close="true" :visible.sync="showDrawer" :modal="false" :wrapperClosable="false" direction="rtl" @closed="closeDrawer">
      <div class="view-drawer-header">
        <slot name="header"></slot>
      </div>
      <div class="view-drawer-wrap">
        <slot></slot>
      </div>
      <div class="view-drawer-footer">
        <slot name="footer"></slot>
      </div>
    </el-drawer>
  </div>
</template>

<script>
// <j-drawer title="新增" :close.sync="showAdd"></j-drawer>

export default {
  name: 'JDrawer',
  inheritAttrs: false, // $attrs 继承的属性会在组件根容器节点上展示
  props: {
    title: {
      type: String,
      default: ''
    },
    close: { type: Boolean, require: true }
  },
  data () {
    return {
      showDrawer: false
    }
  },
  watch: {
    close () {
      this.showDrawer = this.close
    }
  },
  methods: {
    closeDrawer () {
      this.$emit('hide')
      this.$emit('update:close', false)
    }
  }
}
</script>

<style lang="less">
  .j-drawer-wrap {
    // el-drawer 抽屉样式
    .el-drawer__wrapper {
      position: absolute;
      .el-drawer {
        width: 450px!important;
        .el-drawer__body {
          display: flex;
          flex-direction: column;
          min-height: 100px;
        }
        .el-drawer__header {
          margin-bottom: 20px;
        }
      }
    }

    // 抽屉内容容器
    .view-drawer-wrap {
      overflow-y: auto;
      overflow-x: hidden;
      min-height: 100px;
      flex: 1;
    }
    // 抽屉底部按钮区域
    .view-drawer-footer {
      max-height: 60px;
      background-color: #999999;
      display: flex;
      box-sizing: border-box;
      /*border-top: 1px solid #EBEEF5;*/
      box-shadow: 0 4px 10px 0 #DCDCDC;
      background-color: #fff;
      /*> div {*/
      /*  width: 100%;*/
      /*  display: flex;*/
      /*  padding: 10px 16px;*/
      /*}*/
    }
  }
</style>

<style lang="less">
  .j-drawer-wrap {
    ::-webkit-scrollbar { width: 4px; height: 4px; background-color: transparent;}
    ::-webkit-scrollbar-track { background-color: transparent; }
    ::-webkit-scrollbar-thumb { border-radius: 0px;background-color: rgba(51,51,51, .1); }
    .view-drawer-footer {
      .el-button {
        flex: 1;
        border: none;
        background: transparent;
        /*background: #2D76D5;*/
        /*color: #fff;*/
        font-size: 14px!important;
        /*border-radius: 10px!important;*/
        min-height: 48px;
        /*height: 40px;*/
      }
      .el-button + .el-button {
        border-left: 1px solid #EBEEF5;
      }
    }
  }
</style>
