<template>
    <div class="role-menu-tree-wrap">
        <div class="role-menu-tree-header"><span>模块</span><span>功能点</span></div>
        <div class="role-menu-tree-scroll" v-loading="loading">
            <el-tree
                    class="role-menu-tree"
                    ref="tree"
                    :data="tree"
                    show-checkbox
                    node-key="menuId"
                    empty-text=""
                    @check="nodeCheck"
                    :check-strictly="checkStrictly"
                    :props="treeProps">
                <div class="role-menu-tree-node" slot-scope="{ node, data }">
                    <span class="role-menu-tree-text" >{{ data.name }}</span>
                    <span class="role-menu-tree-content">
                        <div :class="['role-menu-tree-all', buttonMap[data.menuId] && buttonMap[data.menuId].length > 1 ? '' : 'hide-style']">
                            <el-checkbox @change="allEvent($event, data.menuId)" :value="buttonMap[data.menuId] && checkButtonMap[data.menuId] && buttonMap[data.menuId].length === checkButtonMap[data.menuId].length"/>
                        </div>
                        <el-checkbox-group v-model="checkButtonMap[data.menuId]" @change="changeCheckboxGroup($event, data.menuId)">
                            <el-checkbox  v-for="button in buttonMap[data.menuId]" :label="button.buttonId" :key="button.buttonId">{{button.name}}</el-checkbox>
                        </el-checkbox-group>
                    </span>
                </div>
            </el-tree>
        </div>
    </div>
</template>

<script>
    export default {
        name: "RoleMenuTree",
        props: {
            meta: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                loading: false,
                checkStrictly: true,
                roleMenus: [],
                roleButtons: [],
                checkButtonMap: [],
                buttonMap: {},
                menuMap: {},
                tree: [],
                treeProps: {
                    children: 'children',
                    label: 'name'
                }
            };
        },
        created () {
            this.initTree()
        },
        methods: {
            // 功能授权关联菜单选中状态，不允许出现单独功能点授权的情况
            changeCheckboxGroup (keys, menuId) {
                if (keys.length > 0) this.$refs.tree.setChecked(menuId, true, true)
            },
            // 勾选菜单触发全选/取消勾选逻辑
            nodeCheck ({ menuId, children },{ checkedKeys }) {
                if (checkedKeys.includes(menuId)) {
                    this.allEvent(true, menuId)
                } else {
                    if (children && children.length > 0) children.map(item => this.allEvent(false, item.menuId))
                    this.allEvent(false, menuId)
                }
            },
            // 全选/取消勾选逻辑
            allEvent (e, v) {
                if (!this.buttonMap[v]) return
                if (e) {
                    this.checkButtonMap[v] = this.buttonMap[v].map(item => item.buttonId)
                } else {
                    this.checkButtonMap[v] = []
                }
            },
            // 暴露组件数据
            getTreeData () {
                const menusNode = this.$refs.tree.getCheckedNodes(false, true) || []
                const buttons = []
                const menus = menusNode.map(item => item.menuId)
                for(let id in this.checkButtonMap) {
                    buttons.push(...this.checkButtonMap[id])
                }
                return { menus, buttons }
            },
            // 获取角色对应已授权数据
            async roleTree () {
                const menus = await this.getMenuByRoleId()
                const buttons = await this.getButtonByRoleId()
                menus.forEach(item => {
                    if (item.roleId) this.roleMenus.push(item.menuId)
                })
                buttons.forEach(item => {
                    if (item.roleId) this.roleButtons.push(item.buttonId)
                })
            },
            // 初始化菜单权限树
            async initTree () {
                this.loading = true
                await this.roleTree() // 获取当前角色已授权信息
                this.menus = await this.getMenuList()
                this.buttons = await this.getButtonList()
                const menuMap = {}
                const buttonMap = {}
                const checkButtonMap = {}
                // 根据id收集同组菜单
                this.buttons.forEach(item => {
                    if (!buttonMap[item.menuId]) buttonMap[item.menuId] = []
                    if (!checkButtonMap[item.menuId]) checkButtonMap[item.menuId] = []
                    let checked = this.roleButtons.includes(item.buttonId)
                    buttonMap[item.menuId].push({ ...item, checked })
                    checked && checkButtonMap[item.menuId].push(item.buttonId)
                })
                this.buttonMap = buttonMap
                this.checkButtonMap = checkButtonMap
                this.menus.forEach(item => {
                    if (!menuMap[item.menuId]) menuMap[item.menuId] = []
                    if (!menuMap[item.parentId]) menuMap[item.parentId] = []
                    menuMap[item.parentId].push({ ...item })
                })
                this.menuMap = menuMap
                this.bindMenu()
                this.loading = false
            },
            bindMenu () {
                const root = this.menuMap[0] || []
                root.forEach(item => item[this.treeProps.children] = this.menuMap[item.menuId])
                this.tree = root
                this.$nextTick(() => {
                    this.$refs.tree.setCheckedKeys(this.roleMenus)
                    this.checkStrictly = false
                })
            },
            async getMenuByRoleId () {
                const { data } = await this.$http.get(`/api/roles/${this.meta.roleId}/menus`)
                if (data.status !== 'yes') return this.$message?.error(data.msg || '获取角色菜单异常')
                return data.data || []
            },
            async getButtonByRoleId  () {
                const { data } = await this.$http.get(`/api/roles/${this.meta.roleId}/buttons`)
                if (data.status !== 'yes') return this.$message?.error(data.msg || '获取角色按钮异常')
                return data.data || []
            },
            // 获取按钮列表
            async getMenuList () {
                const { data } = await this.$http.get(`/api/menus`)
                if (data.status !== 'yes') return this.$message?.error(data.msg || '获取菜单异常')
                return data.data || []
            },
            // 获取按钮列表
            async getButtonList () {
                const { data } = await this.$http.get(`/api/buttons`)
                if (data.status !== 'yes') return this.$message?.error(data.msg || '获取按钮异常')
                return data.data || []
            }
        }
    }
</script>

<style lang="less">
    .role-menu-tree-wrap {
        border: 1px solid #e4e7ed;
        .role-menu-tree-header {
            display: flex;
            padding: 20px 30px;
            background-color: #f0f0f0;
            color: #333;
            font-weight: bold;
            > span {
                flex: 1;
            }
        }
        .role-menu-tree-scroll {
            min-height: 300px;
            max-height: 500px;
            overflow: auto;
        }
        .el-tree-node__content {
            min-height: 45px;
            height: auto;
        }
        .role-menu-tree-node {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding-right: 20px;
        }
        .role-menu-tree-text {
            display: flex;
            flex: 1;
            align-items: center;
        }
        .role-menu-tree-content {
            display: flex;
            align-items: center;
            width: 300px;
            padding: 10px 0;
            .role-menu-tree-all {
                padding: 0 20px;
                &.hide-style {
                    visibility: hidden;
                }
            }
            .el-checkbox-group {
                display: flex;
                flex-wrap: wrap;
                .el-checkbox {
                    padding: 8px 0;
                }
            }
        }
    }
</style>
