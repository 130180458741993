<!--
 * @Description: 本人小结
 * @Author: tengmingxin
 * @Date: 2022-04-15 09:35:39
 * @LastEditors: tengmingxin
 * @LastEditTime: 2022-05-05 09:56:02
-->
<template>
  <j-page class="my-summary">
    <!-- 顶部搜索 -->
    <div slot="search" class="search-bar" v-if="!formbacklog">
      <j-page-search title="所属机构">
        <el-cascader
          v-model="query.orgId"
          :disabled="isDisabled"
          :options="$store.state.orgTree"
          :props="{
            children: 'childrens',
            label: 'name',
            value: 'id',
            emitPath: false,
            checkStrictly: true,
          }"
          placeholder="请选择"
          clearable
        />
      </j-page-search>
      <j-page-search title="姓名">
        <el-input
          v-model="query.userName"
          :disabled="isDisabled"
          placeholder="请输入"
          clearable
        />
      </j-page-search>
      <j-page-search title="联系电话">
        <el-input
          v-model="query.userMobile"
          :disabled="isDisabled"
          placeholder="请输入"
          clearable
        />
      </j-page-search>
      <j-page-search title="身份证号">
        <el-input
          v-model="query.cardCode"
          :disabled="isDisabled"
          placeholder="请输入"
          clearable
        />
      </j-page-search>
      <j-page-search title="角色">
        <el-select
          v-model="query.roleId"
          :disabled="isDisabled"
          placeholder="请选择"
          clearable
        >
          <el-option
            v-for="item in $store.state.roles"
            :key="item.roleId"
            :label="item.name"
            :value="item.roleId"
          />
        </el-select>
      </j-page-search>
      <j-page-search title="状态">
        <j-dic-select v-model="query.status" code="STATUS_TYPE" />
      </j-page-search>
      <j-page-search title="考核月份">
        <el-date-picker
          v-model="query.yearMonth"
          type="month"
          placeholder="请选择"
          value-format="yyyy/MM"
          clearable
        />
      </j-page-search>
      <j-page-search title="进度">
        <j-dic-select v-model="query.finishStatus" code="FINISH_STATUS" />
      </j-page-search>
      <el-button type="primary" plain @click="searchEvent">搜索</el-button>
      <el-button @click="resetEvent" style="margin-right: 50px">重置</el-button>
      <el-checkbox
        class="checkbox"
        :disabled="isDisabled"
        v-model="bMyself"
        @change="checkboxChange"
        label="仅展示本人"
      ></el-checkbox>
    </div>

    <!-- 表格 -->
    <div slot="table">
      <t-table
        :table="tableData"
        :columns="columns"
        :handle-btns="handleBtns"
        @onHandle="onHandle"
        @pageChange="(e) => load({ page: e })"
      >
        <template #status="{row}">
         <span :class="`color-status-style color-status${row.status}`" />
        </template>
        <template #finishStatusName="{row}">
          <span :style="row.finishStatus?'rgba(29, 31, 34, 0.5)':'#FE693D'">{{row.finishStatusName}}</span>
        </template>
      </t-table>
    </div>

    <!-- 查看详情弹窗 -->
    <j-drawer title="查看" :close.sync="drawerShow" class="ledger-drawer">
      <div v-for="(item, index) in detailData" :key="index">
        <j-drawer-label type="title" :title="item.label" align="right" />
        <j-drawer-label
          v-for="(it, i) in item.children"
          :key="i"
          :title="it.label"
          align="left"
          v-show="index === 'userBasicInfo' || it.value"
        >
          <j-sensitive
            v-if="it.type"
            :type="item.type"
            v-model="it.value"
            :eye="showEye"
          />
          <span v-else>{{ it.value }}</span>
        </j-drawer-label>
      </div>
    </j-drawer>

    <!-- 处理弹窗 -->
    <el-dialog
      title="测评进度"
      :visible.sync="dealDialogShow"
      width="600px"
      :close-on-click-modal="false"
      @close="dealDialogClose"
    >
      <el-form
        ref="formRef"
        :model="form"
        label-width="120px"
        :rules="formRules"
      >
        <el-form-item label="自评分" prop="score">
          <el-input
            v-model="form.score"
            placeholder="请输入"
            type="number"
            min="0"
            clearable
          />
        </el-form-item>
        <el-form-item label="月度工作情况" prop="monthlyWorkSummary">
          <el-input
            v-model.trim="form.monthlyWorkSummary"
            type="textarea"
            :rows="5"
            placeholder="请输入"
            maxlength="1000"
            show-word-limit
            clearable
          />
        </el-form-item>
        <el-form-item
          label="未完成或完成质效不高的工作"
          prop="monthlyIncompleteLowefficiency"
        >
          <el-input
            v-model.trim="form.monthlyIncompleteLowefficiency"
            type="textarea"
            :rows="5"
            placeholder="请输入"
            maxlength="1000"
            show-word-limit
            clearable
          />
        </el-form-item>
      </el-form>
      <template slot="footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="dealDialogShow = false">取 消</el-button>
      </template>
    </el-dialog>
  </j-page>
</template>

<script>
import TTable from "../../components/TTable.vue";
import PageMixins from "@/components/JComponents/JPage/mixins/PageMixins";
import DrawerMixins from "@/components/JComponents/JPage/mixins/DrawerMixins.js";

import { editSummary, getSummaryDetail, getSummaryList } from "@/api/summary";

export default {
  name: "MySummary",
  components: { TTable },
  mixins: [PageMixins, DrawerMixins],
  props: {
    // 待办引用
    formbacklog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableData: {
        currentPage: 1,
        limit: 10,
        total: 0,
        data: [],
        loading: false,
      },
      columns: [
        { prop: "status", label: "状态" },
        { prop: "finishStatusName", label: "进度" },
        { prop: "yearMonth", label: "考核月份" },
        { prop: "orgName", label: "所属机构" },
        { prop: "roleName", label: "角色" },
        { prop: "userName", label: "姓名" },
        { prop: "score", label: "自评分" },
        { prop: "updateTime", label: "更新日期" },
      ],
      handleBtns: [
        { key: "view", name: "查看" },
        { key: "edit", name: "处理", validator: this.dealEditBtnShow },
        {
          key: "edit",
          name: "修改",
          validator: (row) => {
            return (
              [12, 21].includes(this.userInfo.roleId) &&
              !this.dealEditBtnShow(row)
            );
          },
        },
      ],
      query: {
        limit: 10,
        page: 1,
        cardCode: "",
        orgId: "",
        roleId: "",
        status: "",
        userMobile: "",
        userName: "",
        yearMonth: "",
        finishStatus: "",
      },
      bMyself: false,
      isDisabled: false,
      type: "view",
      showEye: true,
      drawerShow: false,
      dealDialogShow: false,
      detailData: {
        userBasicInfo: {
          label: "基本信息",
          children: {
            orgName: { label: "所属机构", value: "" },
            roleName: { label: "角色", value: "" },
            name: { label: "姓名", value: "" },
            cardCode: { label: "身份证号", type: "id", value: "" },
            sexName: { label: "性别", value: "" },
            mobile: { label: "联系电话", type: "phone", value: "" },
            statusName: { label: "状态", value: "" },
          },
        },
        personalSummaryInfo: {
          label: "个人总结",
          children: {
            yearMonth: { label: "考核月份", value: "" },
            score: { label: "自评分", value: "" },
            monthlyWorkSummary: { label: "月度工作情况", value: "" },
            monthlyIncompleteLowefficiency: {
              label: "未完成或完成质效不高的工作",
              value: "",
            },
            updateTime: { label: "更新日期", value: "" },
          },
        },
      },
      form: {
        id: "",
        score: "",
        monthlyWorkSummary: "",
        monthlyIncompleteLowefficiency: "",
      },
      formRules: {
        score: { required: true, message: "自评分不能为空", trigger: "blur" },
        monthlyWorkSummary: {
          required: true,
          message: "月度工作情况不能为空",
          trigger: "blur",
        },
        monthlyIncompleteLowefficiency: {
          required: true,
          message: "未完成或完成质效不高的工作不能为空",
          trigger: "blur",
        },
      },
      userInfo: {},
    };
  },
  created() {
    this.userInfo = this.$store.state.user;
    this.dealPermissions(this.userInfo.roleId);
    // 待办只查看未完成的本人数据
    if (this.formbacklog) {
      this.query.status = 0;
      this.query.roleId = this.userInfo.adminId;
      const date = new Date();
      this.query.yearMonth = `${date.getFullYear()}/${String(
        date.getMonth() + 1
      ).padStart(2, "0")}`;
    }
    this.load();
  },
  watch: {
    query: {
      handler() {
        if (!this.isDisabled) this.bMyself = false;
      },
      deep: true,
    },
  },
  methods: {
    async load(params = {}) {
      this.tableData.loading = true;
      const { data } = await getSummaryList({ ...this.query, ...params });
      this.tableData.data = data.data.data || [];
      this.tableData.loading = false;
      this.tableData.total = data.data.total;
      this.query.page = data.data.page;
    },
    onHandle(data) {
      this.type = data.type;
      if (data.type === "view") {
        this.getSummaryDetail(data.scope.row.id);
      } else if (data.type === "edit") {
        const {
          id,
          score,
          monthlyWorkSummary,
          monthlyIncompleteLowefficiency,
        } = data.scope.row;
        this.form = {
          id,
          score,
          monthlyWorkSummary,
          monthlyIncompleteLowefficiency,
        };
        this.dealDialogShow = true;
      }
    },
    async getSummaryDetail(id) {
      let { data } = await getSummaryDetail(id);
      this.drawerShow = true;
      for (const key in this.detailData) {
        for (const subKey in this.detailData[key].children) {
          this.detailData[key].children[subKey].value =
            data.data[key][subKey] || "";
        }
      }
    },
    submitForm() {
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) return;
        let { data } = await editSummary(this.form);
        this.dealDialogShow = false;
        this.$message.success("处理完成");
        this.load();
      });
    },
    checkboxChange(flag) {
      if (flag) {
        const { name, orgId, mobile, cardCode, roleId } = this.userInfo;
        this.query.userName = name;
        this.query.orgId = orgId;
        this.query.userMobile = mobile;
        this.query.cardCode = cardCode;
        this.query.roleId = roleId;
        this.$nextTick(() => {
          this.bMyself = true;
        });
      } else this.resetEvent();
      this.load();
    },
    dealDialogClose() {
      this.$refs.formRef.resetFields();
    },

    /** 重置query对象 */
    resetEvent() {
      if (this.isDisabled) {
        this.query.finishStatus = "";
        this.query.yearMonth = "";
        this.query.status = "";
      } else {
        this.query = { ...this.$options.data().query };
        this.bMyself = false;
      }
      this.load && this.load();
    },

    /** 处理权限 */
    dealPermissions(roleId) {
      // 科员、单位人员只能查看本人
      if ([22, 23].includes(roleId)) {
        this.isDisabled = true;
        this.checkboxChange(true);
        this.bMyself = true;
      }
      // 管理员、主管领导、考评办只可以查看
      if ([1, 11, 13].includes(roleId)) {
        this.handleBtns.splice(1, 1);
        // 主管领导、考评办查看时脱敏
        if (roleId === 11 || roleId === 13) this.showEye = false;
      }
    },

    /**
     * @msg: 控制处理按钮显示
     * @param {Object} row 当前行数据
     * @return {Boolean} False: 不显示，True: 显示
     */
    dealEditBtnShow(row) {
      // 分管领导、科室负责人仅可以处理本人
      if ([12, 21].includes(this.userInfo.roleId)) {
        return row.userId === this.userInfo.adminId && row.status !== 3;
      }
      // 手动结束后（红灯），不支持处理操作
      return row.status !== 3 && !row.updateTime;
    },
  },
};
</script>

<style lang="less">
.my-summary {
  .search-bar {
    position: relative;
    .checkbox {
      position: absolute;
      right: 0;
      bottom: 15px;
    }
  }
  .ledger-drawer .view-drawer-section > label {
    width: 100px !important;
    text-align: right !important;
  }
}
</style>
