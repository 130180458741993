<template>
  <div class="org-info">
    <j-drawer-label type="title" title="机构信息" />
    <div class="label-container">
      <j-label v-if="orgInfo.parentId" title="上级">
        <div class="content">
          {{ getOrgLabel($store.state.orgTree, orgInfo.parentId) }}
        </div>
      </j-label>
      <j-label v-if="orgInfo.orgTypeId" title="类别">
        <div class="content">
          {{ getOrgTypeLabel(orgTypeOptions, orgInfo.orgTypeId) }}
        </div>
      </j-label>
      <j-label v-if="orgInfo.name" title="名称">
        <div class="content">{{ orgInfo.name }}</div>
      </j-label>
      <j-label v-if="orgInfo.directOrgs" title="直属单位">
        <div class="content">
          <el-popover placement="left" trigger="hover">
            <div class="direct-orgs">
              <span v-for="item in orgInfo.directOrgs" :key="item.orgId">
                {{ item.orgName }}
              </span>
            </div>
            <el-button class="detail-btn" type="text" slot="reference">
              {{ orgInfo.directOrgs[0].orgName }}
            </el-button>
          </el-popover>
        </div>
      </j-label>
      <j-label v-if="orgInfo.branchLeaderUserName" title="分管领导">
        <div class="content">{{ orgInfo.branchLeaderUserName }}</div>
      </j-label>
      <j-label v-if="orgInfo.vestInOrgName" title="归属科室">
        <div class="content">{{ orgInfo.vestInOrgName }}</div>
      </j-label>
      <j-label v-if="orgInfo.principalUserName" title="负责人">
        <div class="content">{{ orgInfo.principalUserName }}</div>
      </j-label>
      <j-label v-if="orgInfo.calibration" title="校准值">
        <div class="content">{{ orgInfo.calibration }}</div>
      </j-label>
    </div>
  </div>
</template>

<script>
import JLabel from '@/components/JComponents/JLabel/JLabel'
import JDrawerLabel from '@/components/JComponents/JDrawer/JDrawerLabel'
import { getListDeptTypeTree } from '@/api/dailyInspection'
export default {
  name: 'OrgInfo',
  components: {
    JLabel,
    JDrawerLabel
  },
  props: {
    orgInfo: {
      type: Object
    }
  },
  data() {
    return {
      orgTypeOptions: [],
      orgOptions: []
    }
  },
  created() {
    this.onGetListDeptTypeTree()
  },
  methods: {
    // 获取机构类别
    async onGetListDeptTypeTree() {
      try {
        const { data } = await getListDeptTypeTree()
        this.orgTypeOptions = data.data
      } catch (error) {
        console.error(error)
      }
    },
    // 获取label值
    getOrgLabel(options, value) {
      let flatObj = []
      function query(options) {
        options.forEach(item => {
          flatObj[item.id] = item.name
          if (item.childrens) {
            query(item.childrens)
          }
        })
      }
      query(options)
      return flatObj[value]
    },
    // 获取label值
    getOrgTypeLabel(options, value) {
      let flatObj = []
      function query(options) {
        options.forEach(item => {
          flatObj[item.deptTypeId] = item.deptTypeName
          if (item.deptTypeChilds) {
            query(item.deptTypeChilds)
          }
        })
      }
      query(options)
      return flatObj[value]
    }
  }
}
</script>

<style lang="less" scoped>
.org-info {
  .label-container {
    padding: 0 15px;
    margin-bottom: 15px;
    .content {
      display: flex;
    }
  }
}
.direct-orgs {
  // width: 80px;
  display: flex;
  flex-direction: column;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.detail-btn {
  padding: 0px;
}
</style>
