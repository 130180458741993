<template>
  <div>
    <j-drawer-label type="title" title="任务信息" />
    <div class="label-container">
      <j-label title="考核月份">
        <div class="content">{{ taskInfo.yearMonths }}</div></j-label
      >
      <j-label title="考核项目">
        <div class="content">{{ getItemName(taskInfo.itemId) }}</div></j-label
      >
      <j-label title="任务事项">
        <div class="content">{{ taskInfo.task }}</div></j-label
      >
      <j-label title="责任领导">
        <div class="content">{{ taskInfo.leaderName }}</div></j-label
      >
      <j-label title="牵头单位">
        <div class="content">{{ taskInfo.leadUnitName }}</div></j-label
      >
      <j-label title="责任单位">
        <div class="content">{{ taskInfo.dutyUnitName }}</div></j-label
      >
      <j-label title="完成时限">
        <div class="content">{{ taskInfo.finishDate }}</div></j-label
      >
      <j-label title="进度">
        <div class="content">
          {{ taskInfo.status ? "已考核" : "未考核" }}
        </div></j-label
      >
    </div>
  </div>
</template>

<script>
import JLabel from "@/components/JComponents/JLabel/JLabel";
import JDrawerLabel from "@/components/JComponents/JDrawer/JDrawerLabel";

export default {
  name: "TaskInfo",
  components: {
    JLabel,
    JDrawerLabel,
  },
  props: {
    taskInfo: {
      type: Object,
    },
  },
  methods: {
    getItemName(id) {
      if (!id) return;
      const nameList = {
        1: "基础工作",
        2: "重点工作",
        9: "其他",
      };
      return nameList[id];
    },
  },
};
</script>

<style lang="less" scoped>
.label-container {
  padding: 0 15px;
  margin-bottom: 15px;
  .content {
    display: flex;
  }
}
</style>