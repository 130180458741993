<template>
    <div class="j-editor-wrap" v-loading="loading" :style="`min-height: ${height + 70}px`">
        <editor v-on="$listeners" v-bind="$attrs" :init="config" v-model="editorHtml" />
        <j-upload ref="JeditorJuploadRef" @upload-progress="uploadProgress" @upload-complete="uploadComplete" @upload-error="uploadError" v-show="false"/>
    </div>
</template>

<script>
import tinymce from 'tinymce/tinymce'
import 'tinymce/themes/modern' // 主题
// 插件按需引入，对应配置
import 'tinymce/plugins/image'
import 'tinymce/plugins/table'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/link'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/code'
import 'tinymce/plugins/contextmenu'
import 'tinymce/plugins/textcolor'
import 'tinymce/plugins/colorpicker'
import Editor from '@tinymce/tinymce-vue'
import JUpload from "../JUpload/components/JUpload"
export default {
  name: 'JEditor',
  components: { Editor, JUpload },
  props: {
    value: {
      type: String,
      default: ''
    },
    height: {
      type: [String, Number],
      default: 400
    },
    toolbar: {
      type: String,
      default: 'bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent blockquote | undo redo | uploadImage link table hr | removeformat | clear'
    }
  },
  data () {
    return {
      loading: true,
      editor: { iframeElement: null },
      editorHtml: this.value,
      config: {
        height: Number(this.height),
        language: 'zh_CN',
        language_url: '/static/tinymce/langs/zh_CN.js',
        skin_url: '/static/tinymce/skins/lightgray',
        plugins: 'link lists image code table colorpicker textcolor wordcount contextmenu', // 和引入插件对应
        toolbar: this.toolbar,
        menubar: false,
        statusbar: false,
        setup: editor => {
          this.loading = false
          this.editor = editor
          // 添加上传图片按钮并绑定事件
          // editor.addButton('name', {}) name必须和toolbar的字符串配置对应
          editor.addButton('uploadImage', { title: '上传图片', icon: 'image', tooltip: '上传图片', onclick: this.$refs.JeditorJuploadRef.UploadTrigger })
          editor.addButton('clear', { title: '重置', icon: 'reload', tooltip: '重置文档', onclick: () => { this.editorHtml = '' } })
        }
      }
    }
  },
  methods: {
    uploadComplete (data) {
      const imgNode = "<img src='" + data.fully + "' style='max-width:100%'>"
      this.editor.insertContent(imgNode)
      this.loading = false
    },
    uploadProgress () {
      this.loading = true
    },
    uploadError () {
      this.loading = false
    }
  },
  watch: {
    value (nv) {
      this.editorHtml = nv
    },
    editorHtml (nv) {
      this.$emit('input', nv)
    }
  },
  mounted() {
    tinymce.init({})
  }
}
</script>

<style lang="less">
    .j-editor-wrap {
        position: relative;
        box-sizing: border-box;
        border: 1px solid rgba(0,0,0,0.2);
        .mce-container, .mce-container-body {
            box-sizing: border-box;
            overflow: hidden;
        }
        .mce-panel {
            border-width: 0!important;
        }
    }
    .mce-notification {
        display: none!important;
    }
</style>
