<template>
    <div class="common-page-layout organ-manage-layout">
        <div class="organ-manage-layout-left">
            <div class="organ-manage-layout-left-search">
                <el-input placeholder="查询机构" style="width: 224px;" suffix-icon="el-icon-search" v-model="filterText" />
<!--                <div class="organ-manage-layout-left-search-btn-img" @click="addDgmxEvent('root')"></div>-->
            </div>
            <div class="organ-manage-layout-left-tree">
                <el-tree ref="orgTreeDom" highlight-current :data="orgTree" :indent="32" node-key="id" class="organ-manage-tree" :props="treeProps" :filter-node-method="filterNode" @node-click="orgFilter" default-expand-all :expand-on-click-node="false">
                    <div class="organ-manage-tree-item" slot-scope="{ node, data }">
                        <span :class="['organ-manage-tree-item-left', node.level === 1 ? '' : 'sub-tree']">
                            <j-svg-icon v-if="node.level === 1" name="org-tree-all"/>
                            <j-svg-icon v-else name="org-tree-sub"/>
                            {{ data.name }}
                        </span>
                        <span v-if="!data.tag" class="organ-manage-tree-item-right">
                            <span v-if="node.level === 1" @click.stop="addDgmxEvent('tree', data)">
                                <j-svg-icon name="org-add"/>
                            </span>
                            <span @click.stop="editDgmxEvent(data, node)" style="margin: 0 8px;">
                                <j-svg-icon name="org-edit"/>
                            </span>
                            <span @click.stop="delEvent(data)">
                                <j-svg-icon name="org-del"/>
                            </span>
                        </span>
                    </div>
                </el-tree>
            </div>
        </div>
        <div class="organ-manage-layout-right">
            <div class="organ-manage-layout-right-content">
                <user-manage ref="userListDom" :tree="orgTreeMeta"/>
            </div>
        </div>

        <el-dialog v-if="showDgmx" :title="dgmxTypeMap[dgmxType]" :visible.sync="showDgmx" width="400px" :close-on-click-modal="false">
            <el-form ref="dgmxDom" :model="dgmxForm" :rules="dgmxRule" :label-width="dgmxFormLabelWidth">
                <el-form-item v-if="dgmxForm.parentName" label="上级机构">
                    <el-input v-model="dgmxForm.parentName" placeholder="上级机构" readonly />
                </el-form-item>
                <el-form-item v-if="dgmxForm.parentName" label="类别" prop="orgTypeId">
                    <el-cascader style="width: 100%;" v-model="dgmxForm.orgTypeId" :options="typeTree" :props="typeProps" placeholder="请选择类别" />
                </el-form-item>
                <el-form-item label="名称" prop="orgName">
                    <el-input v-model="dgmxForm.orgName" placeholder="请输入名称" clearable />
                </el-form-item>
                <template v-if="dgmxForm.orgTypeId != 1 &&  dgmxForm.orgTypeId != '' && dgmxForm.parentName">
                    <el-form-item v-if="dgmxForm.orgTypeId == 3" label="归属科室" prop="vestInOrgIds">
                        <el-select style="width: 100%;"  v-model="dgmxForm.vestInOrgIds" placeholder="请选择归属科室" clearable filterable multiple>
                            <el-option v-for="item in ssks" :key="item.id" :label="item.name" :value="item.id" />
                        </el-select>
                    </el-form-item>
                    <el-form-item v-if="dgmxForm.orgTypeId == 11" label="直属单位" prop="directOrgIds">
                        <el-select style="width: 100%;"  v-model="dgmxForm.directOrgIds" placeholder="请选择直属单位" clearable filterable multiple>
                            <el-option v-for="item in zsdw" :key="item.id" :label="item.name" :value="item.id" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="分管领导" prop="branchLeaderUserId">
                        <el-select style="width: 100%;"  v-model="dgmxForm.branchLeaderUserId" placeholder="请选择分管领导" clearable filterable>
                            <el-option v-for="item in fgld" :key="item.adminId" :label="item.name" :value="item.adminId" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="负责人" prop="principalUserId">
                        <el-select style="width: 100%;" v-model="dgmxForm.principalUserId" placeholder="请选择负责人" clearable filterable>
                            <el-option v-for="item in fzr" :key="item.adminId" :label="item.name" :value="item.adminId" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label=校准值 prop="calibration">
                        <el-input v-model="dgmxForm.calibration" placeholder="请输入校准值" clearable />
                    </el-form-item>
                </template>
            </el-form>
            <template slot="footer">
                <el-button type="primary" @click="checkDgmxEvent('saveDgmxData')" :loading="submitLoad">确 定</el-button>
                <el-button @click="showDgmx = false">取 消</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    import PageMixins from "../../components/JComponents/JPage/mixins/PageMixins";
    import DialogMixins from "../../components/JComponents/JPage/mixins/DialogMixins";
    import UserManage from "./UserManage";
    const checkCalibration = (rule, value, callback) => {
        if (rule.required && (!value && value !== 0)) {
            return callback(new Error('校准值不能为空'))
        }
        if ((value || value === 0) && (value <= 0 || value >= 2)) {
            return callback(new Error('校准值范围0-2'))
        }
        callback()
    }
    export default {
        name: "OrganManage",
        mixins: [PageMixins, DialogMixins],
        components: {
            UserManage
        },
        data () {
            return {
                treeProps: { children: 'childrens', label: 'name' },
                typeProps: { children: 'deptTypeChilds', label: 'deptTypeName', value: 'deptTypeId', emitPath: false, checkStrictly: false },
                filterText: '',
                orgTree: [],
                orgTreeMeta: [],
                typeTree: [],
                dgmxForm: {
                    orgName: "",
                    parentId: '',
                    calibration: '',
                    parentName: '',
                    orgTypeId: '',
                    directOrgIds: "",
                    branchLeaderUserId: "",
                    principalUserId: "",
                    vestInOrgIds: ''
                },
                dgmxRule: {
                    orgName: [{ required: true, message: '请输入名称', trigger: 'blur' }],
                    calibration: [{ required: true, validator: checkCalibration, trigger: 'blur' }],
                    orgTypeId: [{ required: true, message: '请选择类别', trigger: 'change' }],
                },
                zsdw: [],
                fgld: [],
                ssks: [],
                fzr: []
            }
        },
        created () {
            this.load()
            this.loadTypeTree()
            this.getZsdw()
            this.getSsks()
            this.getFzr()
            this.getFgld()
        },
        watch: {
            filterText (val) {
                this.$refs.orgTreeDom.filter(val)
            }
        },
        methods: {
            orgFilter (data) {
               console.log(data)
               if (data.childrens) return // 一级选中无效
               this.$refs.userListDom.load(data, 1)
            },
            filterNode (value, data, node) {
                if (!value) {
                    return true
                }
                // return data.name.indexOf(value) !== -1;
                return this.checkBelongToChooseNode(value, data, node)
            },
            checkBelongToChooseNode (value, data, node) {
                if (data.name.indexOf(value) !== -1) {
                    return true
                }
                const level = node.level
                // 如果传入的节点本身就是一级节点就不用校验了
                if (level === 1) {
                    return false
                }
                // 先取当前节点的父节点
                let parentData = node.parent
                // 遍历当前节点的父节点
                let index = 0
                while (index < level - 1) {
                    // 如果匹配到直接返回
                    if (parentData.data.name.indexOf(value) != -1) {
                        return true
                    }
                    // 否则的话再往上一层做匹配
                    parentData = parentData.parent
                    index++
                }
                // 没匹配到返回false
                return false
            },
            addDgmxEvent (type, data) {
                this.dgmxType = 'ADD'
                if (type === 'root') {
                    this.dgmxForm.parentId = ''
                    this.dgmxForm.parentName = ''
                } else {
                    console.log(data)
                    this.dgmxForm.parentName = data.name
                    this.dgmxForm.parentId = data.id
                }
                this.showDgmx = true
            },
            async editDgmxEvent (data, node) {
                this.dgmxType = 'EDIT'
                this.curItem = { ...data }
                let directOrgIds = []
                let vestInOrgIds = []
                if (data.deptType == 3) vestInOrgIds = await this.getSsksByDirectOrgId(data.id)
                if (data.deptType == 11) directOrgIds = await this.getDeptType(data)
                this.dgmxForm = this.proxyDgmxData({
                    orgId: data.id,
                    orgName: data.name,
                    parentId: data.parentId || '',
                    calibration: data.calibration,
                    orgTypeId: data.deptType,
                    directOrgIds,
                    branchLeaderUserId: data.branchLeaderUserId,
                    principalUserId: data.principalUserId,
                    vestInOrgIds,
                    parentName: data.parentId ? node.parent.data.name : ''
                })
                console.log(data, node)
                this.showDgmx = true
            },
            async getDeptType (row) {
                if (!row.parentId) return
                const { data } = await this.$http.get(`/api/orgs/listDirectOrgsByParentId/${row.id}`)
                const list = data.data || []
                return list.map(item => item.id)
            },
            delEvent (row) {
                this.$confirm('您确认要删除当前节点吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http
                        .request({
                            method: 'delete',
                            url: `/api/orgs?id=${row.id}`
                        })
                        .then((res) => {
                            if (res.data.status !== 'yes') return this.$message.error(res.data.msg)
                            this.pageUpdate(this.query)
                            this.$message.success('删除成功')
                            this.load && this.load()
                            this.$store.dispatch('getOrgTree')
                        })
                })
            },
            async load () {
                this.tableLoading = true
                const { data } = await this.$http.get(`/api/orgs`)
                this.orgTreeMeta = data.data
                this.orgTree = [
                    { name: '全部数据', tag: 'ALL' },
                    ...data.data
                ]
                this.orgFilter(data.data[0] || {})
                this.tableLoading = false
            },
            async loadTypeTree () {
                this.tableLoading = true
                const { data } = await this.$http.get(`/api/orgs/listDeptTypeTree`)
                this.typeTree = data.data
                this.tableLoading = false
            },
            async getZsdw () {
                const { data } = await this.$http.get(`/api/orgs/listDirectOrgs`)
                this.zsdw = data.data
            },
            async getFgld () {
                const { data } = await this.$http.get(`/api/admins?roleId=12&page=1&limit=1000`)
                this.fgld = data.data.data
            },
            async getFzr () {
                const { data } = await this.$http.get(`/api/admins?roleId=21&page=1&limit=1000`)
                this.fzr = data.data.data
            },
            async getSsks () {
                const { data } = await this.$http.get(`/api/orgs/listBusinessOrgs`)
                this.ssks = data.data
            },
            async getSsksByDirectOrgId (directOrgId) {
                const { data } = await this.$http.get(`/api/orgs/listSuperBusinessOrgsByDirectOrgId/${directOrgId}`)
                return data.data.map(item => item.id)
            },
            async saveDgmxData () {
                if (this.submitLoad) return
                this.submitLoad = true
                let method = 'post'
                let url = `/api/orgs`
                if (this.dgmxType === 'EDIT') {
                    method = 'put'
                }
                const { data } = await this.$http[method](url, { ...this.dgmxForm, directOrgIds: Array.isArray(this.dgmxForm.directOrgIds) && this.dgmxForm.directOrgIds.join(','), vestInOrgIds: Array.isArray(this.dgmxForm.vestInOrgIds) && this.dgmxForm.vestInOrgIds.join(',') })
                    .catch(({response}) => { this.$message.error(response.data.msg) })
                    .finally(() => { this.submitLoad = false })
                if (data.status !== 'yes') return this.$message.error(data.msg)
                this.showDgmx = false
                this.$message.success('提交成功')
                this.load()
                this.$store.dispatch('getOrgTree')
            }
        }
    }
</script>

<style lang="less">
    .organ-manage-layout {
        display: flex;
        position: relative;
        background: #F6F8FB!important;
        .organ-manage-layout-left {
            min-width: 200px;
            margin-right: 5px;
            box-sizing: border-box;
            overflow-y: auto;
            padding: 16px;
            background-color: #fff;
            border-radius: 8px 0 0 8px;
            .organ-manage-layout-left-search {
                display: flex;
                margin-bottom: 17px;
                .el-input__inner {
                    border: none;
                    background-color: rgba(0,0,0,.04);
                    border-radius: 8px;
                }
                .organ-manage-layout-left-search-btn {
                    text-align: center;
                    line-height: 32px;
                    width: 32px;
                    height: 32px;
                    margin-left: 5px;
                    background-color: #7ACAFF;
                    cursor: pointer;
                }
            }
            .organ-manage-layout-left-search-btn-img {
                height: 32px;
                width: 32px;
                background-image: url("../../assets/org-search-add.png");
                background-size: cover;
                margin-left: 8px;
            }
            .organ-manage-layout-left-tree {
                .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
                    background: rgba(44, 162, 242, 0.05);
                    border-radius: 4px;
                }
                .el-tree-node__content:hover {
                    background: rgba(44, 162, 242, 0.05);
                    border-radius: 4px;
                    .organ-manage-tree-item-right {
                        display: block;
                    }
                    .organ-manage-tree-item-left {
                        color: #2CA2F2;
                    }
                }
            }
        }
        .organ-manage-layout-right {
            flex: 1;
            display: flex;
            flex-direction: column;
            width: ~'calc(100% - 500px)';
            overflow-x: auto;
            height: 100%;
            padding: 0 14px;
            box-sizing: border-box;
            background-color: #fff;
            border-radius: 0 8px 8px 0;
            .j-page-layout {
                position: initial;
            }
            .j-page-layout_wrap {
                position: initial;
                box-shadow: none;
            }
            .j-page-layout_wrap__table {
                padding: 0;
            }

            .organ-manage-layout-right-title {
                font-size: 16px;
                font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
                font-weight: normal;
                color: #2D363F;
                line-height: 26px;
                padding: 14px 0 0 16px;
            }
            .organ-manage-layout-right-content {
                flex: 1;
                min-height: 100px;
            }
        }

        .organ-manage-tree {
            .el-tree-node__content {
                height: auto;
                .el-tree-node__expand-icon {
                    position: relative;
                    z-index: 10;
                }
            }
            .el-tree-node__children {
                .el-tree-node:last-child {
                    .sub-tree {
                        position: relative;
                        &::before {
                            line-height: 90px;
                            top: -31px;
                        }
                    }
                }
            }
            .organ-manage-tree-item {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 14px;
                padding-right: 10px;
                height: 40px;
                box-sizing: border-box;
                color: #727a83;
                /*background-color: #7ACAFF;*/
                > span {
                    display: inline-block;
                }
                .sub-tree {
                    position: relative;
                    &::before {
                        position: absolute;
                        content: '￣';
                        height: 40px;
                        line-height: 50px;
                        width: 10px;
                        top: -12px;
                        left: -25px;
                        color: #eee;
                        border-left: 1px solid #eee;
                    }
                }
                .organ-manage-tree-item-left {

                }
                .organ-manage-tree-item-right {
                    display: none;
                }
            }
        }
    }
</style>
