import { api } from '../config'

export default {
  install (Vue, callback) {
    let parentSsoSystemUrl
    Vue.http.interceptors.response.handlers[0].rejected = function (error) {
      let data = (error && error.response && error.response.data) || {}
      if (error.config.url.includes('/api/admins/login')) { // 系统登录接口不进行错误统一处理
        return Promise.reject(error)
      }
      if (data.code === 401) {
        if (parentSsoSystemUrl) {
          window.parent.postMessage('401', parentSsoSystemUrl)
        } else {
          sessionStorage.clear()
          Vue.prototype.$message.info('登录过期，系统即将自动刷新登录')
          setTimeout(function () {
            location.href = location.origin || api
          }, 2000)
        }
      } else if (data.msg) {
        Vue.prototype.$message.error(data.msg)
      }
      return Promise.reject(error)
    }

    // 获取url中的查询参数
    function getQueryParam (queryName) {
      let reg = new RegExp('(^|&)' + queryName + '=([^&]*)(&|$)')
      let r = window.location.search.substr(1).match(reg)
      return r ? unescape(r[2]) : null
    }

    function bindSingleEvent () {
      addHandler(window, 'message', function (e) {
        if (e.data) {
          parentSsoSystemUrl = e.data
        }
        if (e.data.parentURL) {
          parentSsoSystemUrl = e.data.parentURL
        }
      })
    }

    /* 接收单点登录parent消息 */
    function addHandler (element, type, handler) {
      if (element.addEventListener) {
        element.addEventListener(type, handler, false)
      } else if (element.attachEvent) {
        element.attachEvent('on' + type, handler)
      } else {
        element['on' + type] = handler
      }
    }
    let url = location.href
    if (url.indexOf('data') !== -1 && url.indexOf('sign') !== -1) {
      bindSingleEvent()

      let payload = { 'data': getQueryParam('data'), 'sign': getQueryParam('sign') }
      Vue.http.request({
        method: 'post',
        url: '/api/admins/single-login',
        data: JSON.stringify(payload)
      }).then((res) => {
        if (!res || !res.data || !res.data.data) return
        Vue.store.commit('user', res.data.data)
        Vue.router.replace('/')
        // eslint-disable-next-line
        callback(true)
      })
    } else {
      // eslint-disable-next-line
      callback(false)
    }
  }
}
