<template>
    <j-page>
        <div slot="search">
            <j-page-search title="考核月份">
                <el-date-picker v-model="query.yearMonths" type="month"  placeholder="选择考核月份" value-format="yyyy/MM" />
            </j-page-search>
            <j-page-search title="所属机构">
                <el-cascader v-model="query.orgId" :options="$store.state.orgTree" :props="{ children: 'childrens', label: 'name', value: 'id', emitPath: false, checkStrictly: true }" placeholder="请选择所属机构" clearable />
            </j-page-search>
            <j-page-search title="姓名">
                <el-input v-model="query.userName" placeholder="请输入姓名" clearable />
            </j-page-search>
            <j-page-search title="联系电话">
                <el-input v-model="query.mobile" placeholder="请输入联系电话" clearable />
            </j-page-search>
            <j-page-search title="总分">
                <j-link-input style="width: 240px;" :start.sync="query.scoreFinalStart" :end.sync="query.scoreFinalEnd" />
            </j-page-search>
            <el-button type="primary" @click="searchEvent">查询</el-button>
            <el-button @click="resetEvent">重置</el-button>
            <el-button type="primary" plain @click="exportEvent">导出</el-button>
        </div>
        <div slot="table" v-loading="tableLoading">
            <section-item type="C"
                          v-for="(item, i) in tableData"
                          :key="i"
                          :meta="item"
                          :daily="dailyEvent"
                          :leadership="leadershipEvent"
                          :democracy="democracyEvent"
                          :individual="individualEvent"
            />
            <div v-if="tableData.length < 1"  class="common-empty-style" />
        </div>
        <div slot="pagination" v-show="query.total > query.limit">
            <el-pagination
                    :current-page="query.page"
                    :page-size="query.limit"
                    :total="query.total"
                    :background="pmxBackground"
                    :pager-count="pmxCount"
                    :layout="pmxLayout"
                    :pageSizes="pmxSizes"
                    @current-change="changePage"
                    @size-change="sizeChange"
            />
        </div>

        <el-dialog v-if="showDaily" :visible.sync="showDaily" width="1100px" top="10%" :close-on-click-modal="false">
            <span slot="title" class="common-title-style">科室工作人员日常考评<span>({{curItem && curItem.scoreDailyFinal}}分)</span></span>
            <div class="common-tip-style">
<!--                <div>科员日常考评评分 = 科室日常考评评分 × 60% + 领导评鉴 × 40%</div>-->
              <div>综合科室工作人员日常考核评分 = 科室日常考核评分 × 50% + 所有直属单位日常考核均值 × 50%</div>
              <div>业务科室工作人员日常考核评分 = 科室日常考核评分 × 50% + 直属单位日常考核均值 × 50%</div>
            </div>
            <el-table :data="dailyData" height="400px">
                <el-table-column type="index" label="序号" align="center" show-overflow-tooltip width="50px" />
                <el-table-column prop="yearMonths" label="考核月份" align="center" show-overflow-tooltip />
                <el-table-column sortable prop="orgName" label="所属机构" align="center" show-overflow-tooltip />
                <el-table-column prop="orgTypeName" label="类别" align="center" show-overflow-tooltip />
                <el-table-column prop="calibration" label="校准值" align="center" show-overflow-tooltip />
                <el-table-column sortable prop="countDaily" label="日常任务数量" align="center" show-overflow-tooltip />
                <el-table-column sortable prop="scoreTotalEmphasiswork" label="累计得分重点工作" align="center" show-overflow-tooltip />
                <el-table-column sortable prop="scoreTotalBasework" label="累计得分基础工作" align="center" show-overflow-tooltip />
                <el-table-column sortable prop="scoreTotalOthwork" label="累计得分其他" align="center" show-overflow-tooltip />
                <el-table-column sortable prop="scoreTotalFinal" label="总分" align="center" show-overflow-tooltip>
                    <span class="common-pointer-style" slot-scope="{row}">{{row.scoreTotalFinal}}</span>
                </el-table-column>
            </el-table>
            <div slot="pagination" v-show="dailyQuery.total > dailyQuery.limit">
                <el-pagination
                        :current-page="dailyQuery.page"
                        :page-size="dailyQuery.limit"
                        :total="dailyQuery.total"
                        :pager-count="pmxCount"
                        :layout="pmxLayout"
                        @current-change="changeDailyPage"
                />
            </div>
        </el-dialog>

        <el-dialog v-if="showLeader" :visible.sync="showLeader" width="800px" top="10%" :close-on-click-modal="false">
            <span slot="title" class="common-title-style">领导评鉴<span>({{curItem && curItem.scoreLeaderEvalueFinal}}分)</span></span>
            <div class="common-tip-style">
<!--                <div>科员日常考评评分 = 科室日常考评评分 × 60% + 领导评鉴 × 40%</div>-->
            </div>
            <el-table :data="leaderData" style="width: 100%" height="300px">
                <el-table-column type="expand" width="20">
                    <div class="common-expand-style" slot-scope="{row}">
                        <j-label title="评鉴说明" inline>{{row.appraiseDiscripotion}}</j-label>
                    </div>
                </el-table-column>
                <el-table-column prop="orgName" label="所属机构" show-overflow-tooltip />
                <el-table-column sortable prop="roleName" label="角色" align="center" show-overflow-tooltip />
                <el-table-column prop="userName" label="姓名" align="center" show-overflow-tooltip />
                <el-table-column sortable prop="appraiseStatusName" label="状态" align="center" show-overflow-tooltip />
                <el-table-column sortable prop="appraiseLevelName" label="评鉴等级" align="center" show-overflow-tooltip />
                <el-table-column sortable prop="appraiseScore" label="评鉴分数" align="center" show-overflow-tooltip>
                    <span class="common-pointer-style" slot-scope="{ row }">{{row.appraiseScore}}</span>
                </el-table-column>
                <el-table-column sortable prop="appraiseTime" label="评鉴日期" align="center" show-overflow-tooltip />
            </el-table>
            <div slot="pagination" v-show="leaderQuery.total > leaderQuery.limit">
                <el-pagination
                        :current-page="leaderQuery.page"
                        :page-size="leaderQuery.limit"
                        :total="leaderQuery.total"
                        :pager-count="pmxCount"
                        :layout="pmxLayout"
                        @current-change="changeLeadershipPage"
                />
            </div>
        </el-dialog>

        <el-dialog v-if="showDemocracy" :visible.sync="showDemocracy" width="800px" top="10%" :close-on-click-modal="false">
<!--            <span slot="title" class="common-title-style">民主测评<span>({{curItem && curItem.scoreAssessFinal}}分)</span></span>-->
<!--            <div class="common-tip-style">-->
<!--                <div>民主测评评分 =（好 × 100 + 较好 × 80 + 一般 × 60 + 较差 × 40）/ 有效评价数；“有效评价数”参与测评的评价数量</div>-->
<!--            </div>-->
            <el-table :data="democracyData" style="width: 100%" height="300px">
                <el-table-column type="expand" width="20">
                    <div class="common-expand-style" slot-scope="{row}">
                        <j-label title="测评说明" inline>{{row.evaluateDiscripotion}}</j-label>
                    </div>
                </el-table-column>
                <el-table-column prop="orgName" label="所属机构" show-overflow-tooltip />
                <el-table-column sortable prop="roleName" label="角色" align="center" show-overflow-tooltip />
                <el-table-column prop="userName" label="姓名" align="center" show-overflow-tooltip />
                <el-table-column sortable prop="evaluateStatusName" label="状态" align="center" show-overflow-tooltip />
                <el-table-column sortable prop="evaluateLevelName" label="测评等级" align="center" show-overflow-tooltip />
                <el-table-column sortable prop="evaluateTime" label="测评日期" align="center" show-overflow-tooltip />
            </el-table>
            <div slot="pagination" v-show="democracyQuery.total > democracyQuery.limit">
                <el-pagination
                        :current-page="democracyQuery.page"
                        :page-size="democracyQuery.limit"
                        :total="democracyQuery.total"
                        :pager-count="pmxCount"
                        :layout="pmxLayout"
                        @current-change="changeDemocracyPage"
                />
            </div>
        </el-dialog>

        <el-dialog v-if="showIndividual" :visible.sync="showIndividual" width="800px" top="10%" :close-on-click-modal="false">
            <span slot="title" class="common-title-style">个人奖惩<span>({{curItem && curItem.scorePersonFinal}}分)</span></span>
            <div class="common-tip-style">
                <div>个人奖惩分 = 奖励分数 - 惩罚分数</div>
            </div>
            <el-table :data="individualData" style="width: 100%">
                <el-table-column type="expand" width="20">
                    <div class="common-expand-style" slot-scope="{row}">
                        <j-label title="计分类型" inline>{{row.scoreTypeName}}</j-label>
                        <j-label title="计分分数" inline>{{row.score}}</j-label>
                        <j-label title="计分说明" inline>{{row.scoreDiscripotion}}</j-label>
                        <j-label title="附件" inline>
                            <j-upload :value="row.scoreFile" list-type="picture-card" read-only />
                        </j-label>
                        <j-label title="考核人" inline>{{row.userName}}</j-label>
                        <j-label title="考核时间" inline>{{row.scoreTime}}</j-label>
                    </div>
                </el-table-column>
                <el-table-column prop="scoreTime" label="日期" align="center" show-overflow-tooltip />
                <el-table-column sortable label="考核月份" align="center" show-overflow-tooltip>
                    <template slot-scope="{ row }">{{`${row.year}/${row.month}`}}</template>
                </el-table-column>
                <el-table-column prop="userName" label="考核人" align="center" show-overflow-tooltip />
                <el-table-column sortable prop="scoreTypeName" label="计分类型" align="center" show-overflow-tooltip />
                <el-table-column sortable prop="score" label="计分分数" align="center" show-overflow-tooltip />
            </el-table>
            <div slot="pagination" v-show="individualQuery.total > individualQuery.limit">
                <el-pagination
                        :current-page="individualQuery.page"
                        :page-size="individualQuery.limit"
                        :total="individualQuery.total"
                        :pager-count="pmxCount"
                        :layout="pmxLayout"
                        @current-change="changeIndividualPage"
                />
            </div>
        </el-dialog>
    </j-page>
</template>

<script>
    /**
     * @Author: fengjunjie <fengjunjie@btzh.cn>
     * @Description: jobrating_pc
     * @Date: 2022/04/15 10:02
     */
    import PageMixins from "../../components/JComponents/JPage/mixins/PageMixins";
    import PaginationMixins from "../../components/JComponents/JPage/mixins/PaginationMixins";
    import sectionMixins from "./sectionMixins";
    import moment from "moment/moment";
    export default {
        name: "SectionClerk",
        mixins: [PageMixins, PaginationMixins, sectionMixins],
        data () {
            return {}
        },
        created () {
            this.query.yearMonths = moment().format('YYYY/MM')
            this.load()
        },
        methods: {
            async exportEvent () {
                const { data } = await this.$http.request({
                    method: 'get',
                    url: `/api/bisMassEffectEvalue22/export`,
                    params: { ...this.query },
                    responseType: 'arraybuffer'
                })
                let url = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }))
                let link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                link.setAttribute('download', '科员导出')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link) // 下载完成移除元素
                window.URL.revokeObjectURL(url) // 释放掉blob对象
            },
            async load () {
                this.tableLoading = true
                const { data } = await this.$http.get(`/api/bisMassEffectEvalue22`, {
                    params: this.query
                })
                this.tableData = data.data.data
                this.query.total = data.data.total
                this.tableLoading = false
            }
        }
    }
</script>
