/*
 * @Description:待办API
 * @Author: tengmingxin
 * @Date: 2022-04-21 17:35:21
 * @LastEditors: tengmingxin
 * @LastEditTime: 2022-04-21 18:01:18
 */
import Vue from "vue";

/**
 * 本月考评统计-日常任务
 * @param {Number} orgId
 */
export const getTaskByOrgId = (orgId) => {
  return Vue.http({
    url: "/api/waitHandles/curMonthStatis",
    method: "GET",
    params: { orgId },
  });
};

/**
 * 本月考评统计-民主测评
 * @param {Number} orgId
 */
export const getEvaluationByOrgId = (orgId) => {
  return Vue.http({
    url: "/api/waitHandles/curMonthStatis/detail2",
    method: "GET",
    params: { orgId },
  });
};

/**
 * 本月考评统计-领导评鉴
 * @param {Number} orgId
 */
export const getCriticismByOrgId = (orgId) => {
  return Vue.http({
    url: "/api/waitHandles/curMonthStatis/detail3",
    method: "GET",
    params: { orgId },
  });
};

/**
 * 本月考评统计-个人奖惩
 * @param {Number} orgId
 */
export const getRewardByOrgId = (orgId) => {
  return Vue.http({
    url: "/api/waitHandles/curMonthStatis/detail4",
    method: "GET",
    params: { orgId },
  });
};

/**
 * 本月考评统计-个人小结
 * @param {Number} orgId
 */
export const getSummaryByOrgId = (orgId) => {
  return Vue.http({
    url: "/api/waitHandles/curMonthStatis/detail5",
    method: "GET",
    params: { orgId },
  });
};
