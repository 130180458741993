<!--
 * @Description: 待办-通知公告
 * @Author: tengmingxin
 * @Date: 2022-04-14 18:38:59
 * @LastEditors: tengmingxin
 * @LastEditTime: 2022-04-27 15:08:52
-->
<template>
  <div class="backlog-task-notice">
    <p class="card-header-title">
      <span>通知公告</span>
    </p>
    <div class="list">
      <p
        v-for="(item, index) in noticeList"
        :key="index"
        @click="showDialog(item.id)"
      >
        <span>{{ index + 1 }}</span>
        <span>{{ item.title }}</span>
        <span>{{ moment(item.createTime).format("MM-DD HH:mm") }}</span>
      </p>
    </div>
    <el-pagination
      v-if="query.total > 10"
      :current-page="query.page"
      :page-size="query.limit"
      :total="query.total"
      layout="total, prev, pager, next"
      @current-change="handleCurrentChange"
      background
    />

    <!-- 查看弹窗 -->
    <el-dialog
      title="查看"
      :visible.sync="viewDialogShow"
      width="1000px"
      :close-on-click-modal="false"
      class="notice-dialog"
    >
      <h2>{{ viewData.title }}</h2>
      <p>
        <span>{{ viewData.createUserName }}</span>
        <span>{{ viewData.createTime }}</span>
      </p>
      <div v-html="viewData.content"></div>
    </el-dialog>
  </div>
</template>

<script>
import { getNoticeList, getNoticeDetail } from "@/api/system";
import moment from "moment";

export default {
  data() {
    return {
      moment,
      viewDialogShow: false,
      query: {
        total: 0,
        page: 1,
        limit: 8,
      },
      noticeList: [],
      viewData: {},
    };
  },
  created() {
    this.getNoticeList();
  },
  methods: {
    getNoticeList() {
      getNoticeList(this.query).then((res) => {
        this.query = res.data.data;
        this.noticeList = res.data.data.data;
      });
    },
    handleCurrentChange(currentPage) {
      this.query.page = currentPage;
      this.getNoticeList();
    },
    showDialog(id) {
      getNoticeDetail(id).then((res) => {
        this.viewData = res.data.data;
        this.viewDialogShow = true;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.backlog-task-notice {
  height: 45%;
  .list {
    height: calc(100% - 70px);
    overflow-y: auto;
    > p {
      display: flex;
      align-items: center;
      margin-bottom: 14px;
      cursor: pointer;
      span {
        &:first-child {
          background: rgba(250, 143, 49, 0.1);
          display: inline-block;
          min-width: 16px;
          padding: 2px 0;
          text-align: center;
          margin-right: 6px;
          font-size: 12px;
          font-weight: bold;
          color: #fa8f31;
          line-height: 12px;
        }
        &:nth-child(2) {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          color: #1d1f22;
          line-height: 20px;
        }
        &:last-child {
          width: 70px;
          font-size: 12px;
          font-weight: 400;
          color: rgba(29, 31, 34, 0.75);
          line-height: 18px;
        }
      }
    }
  }
  .el-pagination {
    position: absolute;
    bottom: 12px;
    right: 0;
  }
  .notice-dialog .el-dialog__body {
    > p {
      padding: 16px 0;
      border-bottom: 1px solid #ebeef5;
      > span {
        background: #eef0f2;
        margin-right: 10px;
        padding: 4px 7px;
      }
    }
    > div {
      padding: 16px;
      height: 420px;
      overflow-y: auto;
    }
  }
}
</style>
