import Vue from 'vue'

const state = {
    roles: []
}

const mutations = {
    roles(state, payload) {
        state.roles = payload
    }
}

const actions = {
    async getRoleList(content) {
        const { data } = await Vue.http.get(`/api/roles`)
        if (data.status != 'yes') return Vue.prototype.$message.error(`获取角色列表异常`)
        content.commit('roles', data.data)
    }
}

// 模块方式注册
export const role = {
    namespaced: true,
    state,
    mutations,
    actions
}
