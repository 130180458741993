<template>
  <j-page>
    <div slot="search">
      <el-form
        :model="formInline"
        class="account-detail-form"
        label-width="70px"
      >
        <el-row :gutter="20">
          <el-col :span="4">
            <el-form-item label="考核月份">
              <el-date-picker
                v-model="searchForm.yearMonths"
                type="month"
                format="yyyy 年 MM 月"
                value-format="yyyy/MM"
                placeholder="请选择"
              />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="考核项目">
              <el-select v-model="searchForm.itemId" placeholder="请选择">
                <el-option
                  v-for="item in assessmentsOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="任务事项">
              <el-input
                v-model.trim="searchForm.task"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="责任领导">
              <el-input
                v-model.trim="searchForm.leaderName"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="牵头单位">
              <el-input
                v-model.trim="searchForm.leadUnitName"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="责任单位">
              <el-input
                v-model.trim="searchForm.dutyUnitName"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="状态">
              <el-select v-model="searchForm.status" placeholder="请选择">
                <el-option
                  v-for="item in progressStatus"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="完成时限">
              <el-date-picker
                v-model="searchForm.finishDate"
                type="date"
                format="yyyy 年 MM 月 DD 日"
                value-format="yyyy-MM-DD"
                placeholder="请选择"
              />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label=" ">
              <el-button type="primary" @click="onGetAccountDetail(orgstsId)"
                >搜索</el-button
              >
              <el-button type="primary" plain @click="resetSearchForm"
                >重置</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div slot="table">
      <el-table
        :data="taskDetail"
        border
        style="width: 100%"
        v-loading="tableLoading"
      >
        <el-table-column label="序号" type="index" width="50">
        </el-table-column>
        <el-table-column prop="yearMonths" label="考核月份"> </el-table-column>
        <el-table-column prop="task" label="任务事项"> </el-table-column>
        <el-table-column prop="itemId" label="考核项目">
          <template slot-scope="scope">
            {{ getLabel(assessmentsOptions, scope.row.itemId) }}
          </template>
        </el-table-column>
        <el-table-column prop="leaderName" label="责任领导"> </el-table-column>
        <el-table-column prop="leadUnitName" label="牵头单位">
        </el-table-column>
        <el-table-column prop="dutyUnitName" label="责任单位">
        </el-table-column>
        <el-table-column prop="finishDate" label="完成时限"> </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            {{ scope.row.status ? "已考核" : "未考核" }}
          </template>
        </el-table-column>
        <el-table-column prop="scoreReduceTotal" label="累计扣分">
        </el-table-column>
        <el-table-column prop="scoreAddTotal" label="累计加分">
        </el-table-column>
        <el-table-column prop="scoreFinal" label="最终得分"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              @click="showCheckedDetail(scope.row)"
              type="text"
              size="small"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div slot="pagination" v-show="pageQuery.total > pageQuery.limit">
      <el-pagination
        :current-page="pageQuery.page"
        :page-size="pageQuery.limit"
        :total="pageQuery.total"
        :background="pmxBackground"
        :pager-count="pmxCount"
        :layout="pmxLayout"
        :pageSizes="pmxSizes"
        @current-change="changePage"
      />
    </div>
    <!-- 考核明细 弹框 -->
    <el-dialog
      custom-class="dialog-style"
      v-if="isShowCheckedDetail"
      title="考核明细"
      :visible.sync="isShowCheckedDetail"
      width="870px"
      top="5%"
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <checked-detail ref="checkedDetail" :showSelector="false" />
    </el-dialog>
  </j-page>
</template>

<script>

import CheckedDetail from "./CheckedDetail.vue";
import { getAccountDetail } from "@/api/dailyInspection";
export default {
  name: "AccountDetail",
  components: {
    CheckedDetail,
  },
  props: {},
  data() {
    return {
      // 搜索表单控件
      formInline: {
        user: "",
        region: "",
        month: "",
      },
      isShowCheckedDetail: false,
      // 搜索表单控件
      searchForm: {
        dutyUnitName: "", //	责任单位
        finishDate: "", //完成时限日期
        itemId: "", //考核项目id
        leadUnitName: "", //	牵头单位
        leaderName: "", //	责任领导
        limit: "", // limit
        page: "", //  page
        status: "", // 进度是否考核
        task: "", //	任务事项
        yearMonths: "", //	考核年月
      },
      taskDetail: [],
      tableLoading: false,
      pageQuery: {
        page: 1,
        limit: 10,
        total: 0,
      },
      // 考核项目
      assessmentsOptions: [
        {
          value: 1,
          label: "基础工作",
        },
        {
          value: 2,
          label: "重点工作",
        },
        {
          value: 9,
          label: "其他",
        },
      ],
      //  进度下拉配置项
      progressStatus: [
        {
          value: true,
          label: "已考核",
        },
        {
          value: false,
          label: "未考核",
        },
      ],
    };
  },
  methods: {
    showCheckedDetail(row) {
      this.isShowCheckedDetail = !this.isShowCheckedDetail;
      this.$nextTick(() => {
        this.$refs.checkedDetail.onGetDailysDetail({
          dailyId: row.dailyId,
          orgId: row.orgId,
        });
      });
    },
    // 重置查询参数
    resetSearchForm() {
      for (let key in this.searchForm) {
        this.searchForm[key] = "";
      }
    },
    // 任务明细
    async onGetAccountDetail(id, itemId) {
      this.tableLoading = true;
      if (!this.orgstsId) {
        this.orgstsId = id;
      }
      // 有该参数 则直接设置考核项目类型
      if (itemId) this.searchForm.itemId = itemId;
      try {
        const { data } = await getAccountDetail({
          orgstsId: id,
          ...this.searchForm,
        });
        this.taskDetail = data.data.data;
      } catch (error) {
        console.error(error);
      }
      this.tableLoading = false;
    },
    changePage(page) {
      this.pageQuery.page = page;
      this.onGetAccountDetail(this.orgstsId);
    },
    // 获取label值 针对与 el-select
    getLabel(options, value) {
      if (value === undefined) return;
      for (let key in options) {
        if (options[key].value === value) return options[key].label;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.account-detail-form {
  /deep/input {
    width: 180px;
  }
}
/deep/.dialog-style {
  .el-dialog__body {
    height: 600px !important;
    .nad-tree {
      margin-left: 20px;
      flex: 1;
      > p {
        font-size: 16px;
        margin-bottom: 10px;
      }
      .el-tree-node__content {
        height: 32px;
      }
    }
    .input-tags {
      border: 1px solid #dcdfe6;
      padding: 5px 10px;
      min-height: 21px;
      border-radius: 4px;
      .el-tag {
        margin-right: 5px;
      }
    }
  }
  .j-page-layout_wrap {
    border-radius: 0px;
    box-shadow: none;
    padding: 0px;
    box-sizing: content-box;
  }
}
</style>