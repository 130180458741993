import Vue from "vue"

/**
 * 分页查询日常考核
 * @param {Object} params 
 * @returns 
 */
export const getBisDailysList = (params) => {
    return Vue.http({ url: '/api/bisDailys', method: 'GET', params })
}

/**
 * 新增日常考核
 * @param {Object} data 
 * @returns 
 */
export const addBisDailys = (data) => {
    return Vue.http({ url: '/api/bisDailys', method: 'POST', data })
}

/**
 * 编辑日常考核
 * @param {Object} data 
 * @returns 
 */
export const editBisDailys = (data) => {
    return Vue.http({ url: '/api/bisDailys', method: 'PUT', data })
}

/**
 * 下载导入模版
 * @returns 
 */
export const downlaodImportExcelTemplate = () => {
    return Vue.http({ url: '/api/bisDailys/downlaodImportExcelTemplate', method: 'GET', })
}

/**
 * 导入_日常考核
 * @returns 
 */
export const importFromExcel = (data) => {
    return Vue.http({ url: '/api/bisDailys/importFromExcel', method: 'POST', data })
}

/**
 * 新增考核机构
 * @param {Object} data 
 * @returns 
 */
export const addBisDailysOrg = (data) => {
    return Vue.http({ url: '/api/bisDailys/org', method: 'POST', data })
}

/**
 * 删除考核机构
 * @param {Number} orgId 
 * @returns 
 */
export const deleteBisDailysOrg = (id) => {
    return Vue.http({ url: `/api/bisDailys/org/${id}`, method: 'DELETE' })
}

/**
 * 列出日常任务详情-考核明细dailyOrg-fj
 * @param {Number} dailyOrgId 
 * @returns 
 */
export const getDailysDetailOfFile = (dailyOrgId) => {
    return Vue.http({ url: `/api/bisDailys/orgs/detail/${dailyOrgId}`, method: 'GET' })
}

/**
 * 列出日常任务详情-考核明细dailyOrg
 * @param {Object} params
 * @returns 
 */
export const getDailysDetail = (params) => {
    return Vue.http({ url: `/api/bisDailys/orgs/${params.dailyId}`, method: 'GET', params })
}

/**
 * 查看日常考核明细
 * @param {Number} dailyId 
 * @returns 
 */
export const getBisDailysDetail = (dailyId) => {
    return Vue.http({ url: `/api/bisDailys/${dailyId}`, method: 'GET' })
}

/**
 * 删除日常考核
 * @param {Number} dailyId 
 * @returns 
 */
export const deleteBisDailys = (dailyId) => {
    return Vue.http({ url: `/api/bisDailys/${dailyId}`, method: 'DELETE' })
}

/**
 * 分页日常考核台账
 * @param {Object} param 
 * @returns 
 */
export const getBisDailyLedgers = (params) => {
    return Vue.http({ url: `/api/bisDailyLedgers`, method: 'GET', params })
}

/**
 * 考核台账-1org任务明细
 * @param {*} params 
 * @returns 
 */
export const getAccountDetail = (params) => {
    return Vue.http({ url: `/api/bisDailyLedgers/detail`, method: 'GET', params })
}

/**
 * 考核台账--1org任务明细-1org考核明细dailyOrg-fj
 * @param {Number} dailyOrgId 
 * @returns 
 */
export const getAccountCheckedDetailByOrg = (dailyOrgId) => {
    return Vue.http({ url: `/api/bisDailyLedgers/detail/detail/detail/${dailyOrgId}`, method: 'GET' })
}

/**
 * 考核台账--1org任务明细-1org考核明细dailyOrg
 * @param {Object} params
 * @returns 
 */
export const getAccountCheckedDetailByDaily = ({ dailyId, page, limit }) => {
    return Vue.http({ url: `/api/bisDailyLedgers/detail/detail/${dailyId}`, method: 'GET', params: { page, limit } })
}

/**
 * 导出日常考核台账
 * @param {param} params
 * @returns 
 */
export const exportAccount = (params) => {
    return Vue.http({ url: `/api/bisDailyLedgers/export`, method: 'GET', params })
}

/**
 * 获取机构列表
 * @returns 
 */
 export const getListDeptTree = () => {
    return Vue.http({ url: `/api/orgs`, method: 'GET' })
}


/**
 * 获取机构详细信息
 * @param {*} orgId 
 * @returns 
 */
export const getOrgDetail = (orgId) => {
    return Vue.http({ url: `/api/orgs/orgDetail/${orgId}`, method: 'GET' })
}

/**
 * 获取机构类别
 * @returns 
 */
export const getListDeptTypeTree = () => {
    return Vue.http({ url: `/api/orgs/listDeptTypeTree`, method: 'GET' })
}

/**
 * 检查某月考核是否结束
 * @param { year, month } params 
 * @returns 
 */
export const checkSysEvalueIsEnd = (params) => {
    return Vue.http({ url: `/api/evalues/checkSysEvalueIsEnd`, method: 'GET', params })
}