/*
 * @Description: 
 * @Author: tengmingxin
 * @Date: 2022-04-17 13:06:13
 * @LastEditors: tengmingxin
 * @LastEditTime: 2022-04-17 17:33:47
 */

import Vue from "vue"

/**
 * @msg: 分页获取公告
 * @param {Number} page
 * @param {Number} limit
 * @param {String} title
 */
export const getNoticeList = ({ page, limit, title }) => {
    return Vue.http({ url: '/api/notices', method: 'get', params: { page, limit, title } })
}

/**
 * @msg: 获取公告明细
 * @param {Number} noticeId
 */
export const getNoticeDetail = noticeId => {
    return Vue.http({ url: `/api/notices/${noticeId}`, method: 'get' })
}

/**
 * @msg: 添加公告
 * @param {Object} data
 */
export const addNotice = data => {
    return Vue.http({ url: '/api/notices', method: 'POST', data })
}

/**
 * @msg: 更新公告
 * @param {Object} data
 */
export const editNotice = data => {
    return Vue.http({ url: `/api/notices/${data.id}`, method: 'PUT', data })
}

/**
 * @msg: 删除公告
 * @param {Number} noticeId
 */
export const deleteNotice = noticeId => {
    return Vue.http({ url: `/api/notices/${noticeId}`, method: 'DELETE' })
}


/**
 * @msg: 获取组织机构树
 * @param {String} name
 */
export const getOrgTree = name => {
    return Vue.http({ url: `/api/orgs`, method: 'get', params: { name } })
}