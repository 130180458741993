<template>
    <div class="j-ip-input-wrap">
        <el-input ref="input1" @input="inputEvent($event, 'input2')" onkeyup="this.value = this.value.replace(/[^\d.]/g,'');" class="j-ip-input-style" :maxlength="3" :minlength="1" v-model="input1" :readOnly="this.readOnly"/>
        <span>.</span>
        <el-input ref="input2" @input="inputEvent($event, 'input3')" onkeyup="this.value = this.value.replace(/[^\d.]/g,'');" class="j-ip-input-style" :maxlength="3" :minlength="1"  v-model="input2" :readOnly="this.readOnly"/>
        <span>.</span>
        <el-input ref="input3" @input="inputEvent($event, 'input4')" onkeyup="this.value = this.value.replace(/[^\d.]/g,'');" class="j-ip-input-style" :maxlength="3" :minlength="1"  v-model="input3" :readOnly="this.readOnly"/>
        <span>.</span>
        <el-input ref="input4" @input="inputEvent($event)" onkeyup="this.value = this.value.replace(/[^\d.]/g,'');" class="j-ip-input-style" :maxlength="3" :minlength="1"  v-model="input4" :readOnly="this.readOnly"/>
        <el-button v-if="!readOnly" style="margin-left: 5px" icon="el-icon-delete" @click="resetEvent"></el-button>
    </div>
</template>

<script>
export default {
  name: 'JIpInput',
  props: {
    value: {
      type: String
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      input1: '',
      input2: '',
      input3: '',
      input4: ''
    }
  },
  created () {
    console.log(this.value)
    if (this.value) {
      const arr = this.value.split('.')
      this.input1 = arr[0]
      this.input2 = arr[1]
      this.input3 = arr[2]
      this.input4 = arr[3]
    } else {
      this.input1 = ''
      this.input2 = ''
      this.input3 = ''
      this.input4 = ''
    }
  },
  methods: {
    resetEvent () {
      this.input1 = ''
      this.input2 = ''
      this.input3 = ''
      this.input4 = ''
      this.$emit('input', '')
    },
    inputEvent (v, t) {
      if (v.length >= 3 && t) {
        this.$refs[t].focus()
      }
      this.updateData()
    },
    updateData () {
      if (this.input1 == '' || this.input2 == '' || this.input3 == '' || this.input4 == '') return this.$emit('input', '')
      this.$emit('input', `${this.input1}.${this.input2}.${this.input3}.${this.input4}`)
    }
  }
}
</script>

<style lang="less">
.j-ip-input-wrap {
    display: flex;
    align-items: flex-start;
    >span {
        padding: 0 5px;
        display: flex;
        align-items: flex-end;
    }
    .j-ip-input-style {
        min-width: 50px;
        flex: 1;
        .el-input__inner {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}
</style>
