<template>
    <div class="j-page-layout">
        <div class="j-page-layout_wrap">
            <div class="j-page-layout_wrap__header">
                <slot name="header"></slot>
            </div>
            <div class="j-page-layout_wrap__search">
                <slot name="search"></slot>
            </div>
            <div class="j-page-layout_wrap__table">
                <slot name="table"></slot>
            </div>
            <div class="j-page-layout_wrap__pagination">
                <slot name="pagination"></slot>
            </div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
  name: 'JPage'
}
</script>

<style scoped lang="less">
    .j-page-layout {
        position: relative;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        /*padding: 14px!important;*/
        .j-page-layout_wrap {
            position: relative;
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            background: #FFF;
            border-radius: 3px;
            box-shadow: 0 0 3px #dadada;
            padding: 15px;
            box-sizing: border-box;
        }
        .j-page-layout_wrap__header {
            > * {
                padding-bottom: 10px;
            }
        }
        .j-page-layout_wrap__search {
            > * {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                padding-bottom: 10px;
            }
        }

        .j-page-layout_wrap__table {
            min-height: 100px;
            flex: 1;
            overflow-x: hidden;
            overflow-y: auto;
            box-sizing: border-box;
            padding-bottom: 10px;
            > * {
                height: 100%;
            }
        }

        .j-page-layout_wrap__pagination {
            > * {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding-bottom: 10px;
            }
        }
    }
</style>

<style lang="less">
    .j-page-layout {
        .el-table {
            margin-top: 0;
            border: 1px solid #e4e7ed;
            border-bottom: 0;
            thead {
                font-size: 14px;
                color: #333;
                font-weight: 500;
                th {
                    background-color: #f0f0f0;
                }
            }
        }
    }
</style>
