<template>
    <div class="section-principal-item">
        <div class="section-principal-item-header">
            <div>
                <span class="name">{{meta.userName}}</span>
                <span class="tag">{{meta.roleName}}</span>
                {{meta.orgName}}
            </div>
            <div>总分<span class="scope">{{meta.scoreFinal}}</span></div>
        </div>
        <div v-if="type === 'P'" class="section-principal-item-content">
            <div>
                <div class="section-principal-item-heaer">日常考核（{{meta.scoreDailyFinal}}）</div>
                <div class="section-principal-item-box">
                    <div>
                        科室日常考评<span @click="dailyEvaluationClick({ orgIds: meta.orgId, yearMonths: meta.yearMonths })">{{meta.scoreDaily}}</span>
                    </div>
<!--                    <div>-->
<!--                        直属单位均值<span @click="dailyEvaluationClick({ orgIds: meta.orgId, yearMonths: meta.yearMonths, massDirect: true })">{{meta.scoreDailyAvgdirect}}</span>-->
<!--                    </div>-->
                </div>
            </div>
            <div>
                <div class="section-principal-item-heaer">领导评鉴（{{meta.scoreLeaderEvalueFinal}}）</div>
                <div class="section-principal-item-box" style="width: 420px;">
<!--                    <div>-->
<!--                        主管领导<span @click="leadershipClick({ userId: meta.userId, yearMonth: meta.yearMonths, queryType: 1 })">{{meta.scoreLeaderEvalueMaster}}</span>-->
<!--                    </div>-->
<!--                    <div>-->
<!--                        其他科室分管领导<span @click="leadershipClick({ userId: meta.userId, yearMonth: meta.yearMonths, queryType: 3 })">{{meta.scoreLeaderEvalueBran0}}</span>-->
<!--                    </div>-->
<!--                    <div>-->
<!--                        本科室分管领导<span @click="leadershipClick({ userId: meta.userId, yearMonth: meta.yearMonths, queryType: 2 })">{{meta.scoreLeaderEvalueBran1}}</span>-->
<!--                    </div>-->
                  <div>
                    领导评鉴<span>{{meta.scoreLeaderEvalueFinal}}</span>
                  </div>
                </div>
            </div>
<!--            <div>-->
<!--                <div class="section-principal-item-heaer">民主测评（{{meta.scoreAssessFinal}}）</div>-->
<!--                <div class="section-principal-item-box">-->
<!--                    <div>-->
<!--                        有效评价数<span @click="democracyClick({ userId: meta.userId, yearMonth: meta.yearMonths })">{{meta.countAssessEvalue}}</span>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
            <div>
                <div class="section-principal-item-heaer">科室成员质效均值（{{meta.scoreDepartAvg}}）</div>
                <div class="section-principal-item-box">
                    <div>
                        科室成员数<span @click="memberClick({ orgId: meta.orgId })">{{meta.countDepartMem}}</span>
                    </div>
                </div>
            </div>
            <div>
                <div class="section-principal-item-heaer">个人奖惩（{{meta.scorePersonFinal}}）</div>
                <div class="section-principal-item-box">
                    <div>
                        奖励分数<span @click="individualClick({ userId: meta.userId, yearMonth: meta.yearMonths, queryType: 1 })">{{meta.scorePersonRewards}}</span>
                    </div>
                    <div>
                        惩罚分数<span @click="individualClick({ userId: meta.userId, yearMonth: meta.yearMonths, queryType: 2 })">{{meta.scorePersonPunish}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="type === 'C'" class="section-principal-item-content">
            <div>
                <div class="section-principal-item-heaer">日常考核（{{meta.scoreDailyFinal}}）</div>
                <div class="section-principal-item-box">
                    <div>
                        科室日常考评<span @click="dailyEvaluationClick({ orgIds: meta.orgId, yearMonths: meta.yearMonths })">{{meta.scoreDaily}}</span>
                    </div>
                </div>
            </div>
            <div>
                <div class="section-principal-item-heaer">领导评鉴（{{meta.scoreLeaderEvalueFinal}}）</div>
                <div class="section-principal-item-box">
                  <div>
                    领导评鉴<span>{{meta.scoreLeaderEvalueFinal}}</span>
                  </div>
<!--                    <div>-->
<!--                        本科室分管领导<span  @click="leadershipClick({ userId: meta.userId, yearMonth: meta.yearMonths, queryType: 2 })">{{meta.scoreLeaderEvalueBran1}}</span>-->
<!--                    </div>-->
<!--                    <div>-->
<!--                        其他科室分管领导<span  @click="leadershipClick({ userId: meta.userId, yearMonth: meta.yearMonths, queryType: 3 })">{{meta.scoreLeaderEvalueBran0}}</span>-->
<!--                    </div>-->
                </div>
            </div>
<!--            <div>-->
<!--                <div class="section-principal-item-heaer">民主测评（{{meta.scoreAssessFinal}}）</div>-->
<!--                <div class="section-principal-item-box">-->
<!--                    <div>-->
<!--                        有效评价数<span @click="democracyClick({ userId: meta.userId, yearMonth: meta.yearMonths })">{{meta.countAssessEvalue}}</span>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
            <div>
                <div class="section-principal-item-heaer">个人奖惩（{{meta.scorePersonFinal}}）</div>
                <div class="section-principal-item-box">
                    <div>
                        奖励分数<span @click="individualClick({ userId: meta.userId, yearMonth: meta.yearMonths, queryType: 1 })">{{meta.scorePersonRewards}}</span>
                    </div>
                    <div>
                        惩罚分数<span @click="individualClick({ userId: meta.userId, yearMonth: meta.yearMonths, queryType: 2 })">{{meta.scorePersonPunish}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    /**
     * @Author: fengjunjie <fengjunjie@btzh.cn>
     * @Description: jobrating_pc
     * @Date: 2022/04/15 14:09
     */
    export default {
        name: "sectionItem",
        props: {
            type: {
              type: String,
              default: 'P'
            },
            meta: {
                type: Object,
                default: () => { return {} }
            },
            daily: Function,
            leadership: Function,
            democracy: Function,
            member: Function,
            individual: Function
        },
        methods: {
            // 科室日常考评
            dailyEvaluationClick (query) {
                this.daily && this.daily(this.meta, query)
            },
            // 领导评鉴
            leadershipClick (query) {
                this.leadership && this.leadership(this.meta, query)
            },
            // 民主测评
            // democracyClick (query) {
            //     this.democracy && this.democracy(this.meta, query)
            // },
            // 科室成员质效均值
            memberClick (query) {
                this.member && this.member(this.meta, query)
            },
            // 个人奖惩
            individualClick (query) {
                this.individual && this.individual(this.meta, query)
            }
        }
    }
</script>

<style scoped lang="less">
    .section-principal-item {
        margin-bottom: 12px;
        .section-principal-item-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px 0 16px;
            height: 44px;
            background: #F8FAFA;
            border-radius: 6px;
            margin-bottom: 4px;
            > div {
                font-size: 12px;
                font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
                font-weight: normal;
                color: rgba(29, 31, 34, 0.49);
            }
            .name {
                font-size: 16px;
                font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
                font-weight: normal;
                color: #1D1F22;
                margin-right: 8px;
            }
            .tag {
                border-radius: 4px;
                background: rgba(250, 143, 49, .1);
                color: #FA8F31;
                padding: 2px 4px;
                margin-right: 8px;
            }
            .scope {
                font-size: 18px;
                font-family: TeXGyreAdventor-Bold, TeXGyreAdventor;
                font-weight: bold;
                color: #FA8F31;
                margin-left: 6px;
            }
        }

        .section-principal-item-content {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 0 16px 10px;
            background: rgba(0, 185, 220, 0.04);
            border-radius: 6px;
            > div {
                margin-top: 8px;
            }

            .section-principal-item-heaer {
                font-size: 12px;
                font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
                font-weight: normal;
                color: #00B9DC;
                margin-top: 0;
            }
            .section-principal-item-box {
                display: flex;
                flex-wrap: wrap;
                width: 200px;
                > div {
                    display: flex;
                    justify-content: space-between;
                    width: 160px;
                    margin-top: 8px;
                    font-size: 14px;
                    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
                    font-weight: normal;
                    color: rgba(29, 31, 34, 0.75);
                    line-height: 20px;
                    margin-right: 40px;
                    > span {
                        font-size: 14px;
                        font-family: TeXGyreAdventor-Bold, TeXGyreAdventor;
                        font-weight: bold;
                        color: #1D1F22;
                        line-height: 18px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
</style>
