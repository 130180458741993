<template>
    <el-date-picker
            type="daterange"
            v-model="timeRange"
            v-bind="$attrs"
            v-on="$listeners"
            :clearable="clearable"
            :value-format="valueFormat"
            :range-separator="rangeSeparator"
            :start-placeholder="startPlaceholder"
            :end-placeholder="endPlaceholder"
            @change="dateChange"
            :picker-options="pickerOptions"
    />
</template>

<script>
export default {
  name: 'JDateRange',
  props: {
    beforeShortcuts: { // [7,30,90]
      type: Array,
      default: () => []
    },
    afterShortcuts: { // [7,30,90]
      type: Array,
      default: () => []
    },
    disabledRange: { // ['', 'before', 'after']
      type: String,
      default: ''
    },
    start: {
      type: String,
      default: ''
    },
    end: {
      type: String,
      default: ''
    },
    valueFormat: { // 'yyyy-MM-dd HH:mm:ss'
      type: String,
      default: 'yyyy-MM-dd'
    },
    rangeSeparator: {
      type: String,
      default: '至'
    },
    startPlaceholder: {
      type: String,
      default: '开始日期'
    },
    endPlaceholder: {
      type: String,
      default: '结束日期'
    },
    clearable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      timeRange: [],
      pickerOptions: {
        shortcuts: null
      }
    }
  },
  created () {
    if (this.start && this.end) {
      this.timeRange = [this.start, this.end]
    }
    // 时间段限制
    if (this.disabledRange === 'before') this.pickerOptions['disabledDate'] = this.disableBefore
    else if (this.disabledRange === 'after') this.pickerOptions['disabledDate'] = this.disableAfter
    // 快捷时间
    this.shortcutBeforeTime()
    this.shortcutAfterTime()
  },
  methods: {
    shortcutBeforeTime () {
      if (this.beforeShortcuts.length < 1) return
      this.beforeShortcuts.forEach(day => {
        if (!this.pickerOptions.shortcuts) this.pickerOptions.shortcuts = []
        this.pickerOptions.shortcuts.push({
          text: `最近${day}天`,
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * Number(day || 1))
            picker.$emit('pick', [start, end])
          }
        })
      })
    },
    shortcutAfterTime () {
      if (this.afterShortcuts.length < 1) return
      this.afterShortcuts.forEach(day => {
        if (!this.pickerOptions.shortcuts) this.pickerOptions.shortcuts = []
        this.pickerOptions.shortcuts.push({
          text: `未来${day}天`,
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            end.setTime(start.getTime() + 3600 * 1000 * 24 * Number(day || 1))
            picker.$emit('pick', [start, end])
          }
        })
      })
    },
    disableBefore (time) {
      return time.getTime() < Date.now() - 8.64e7 // -8.64e7 可以选择今天的时间，没有则不能选
    },
    disableAfter (time) {
      return time.getTime() > Date.now() - 8.64e6 // -8.64e6 可以选择今天的时间，没有则不能选
    },
    dateChange (v) {
      if (!v) {
        this.$emit('update:start', '')
        this.$emit('update:end', '')
        return
      }
      this.$emit('update:start', v[0])
      if (v[1].includes('00:00:00')) {
        this.$emit('update:end', v[1].substr(0, 10) + ' 23:59:59')
      } else {
        this.$emit('update:end', v[1])
      }
    }
  },
  watch: {
    start (nv) {
      this.timeRange = [nv || '', this.end || '']
    },
    end (nv) {
      this.timeRange = [this.start || '', nv || '']
    }
  }
}
</script>
