<template>
    <j-page>
        <div slot="search">
            <j-page-search title="所属机构">
                <el-cascader v-model="query.orgId" :options="$store.state.orgTree" :props="{ children: 'childrens', label: 'name', value: 'id', emitPath: false, checkStrictly: true }" placeholder="请选择所属机构" clearable />
            </j-page-search>
            <j-page-search title="姓名">
                <el-input v-model.trim="query.userName" placeholder="请输入姓名" clearable />
            </j-page-search>
            <j-page-search title="联系电话">
                <el-input type="number" v-model.trim="query.userMobile" placeholder="请输入联系电话" clearable />
            </j-page-search>
            <j-page-search title="身份证号">
                <el-input type="text" v-model.trim="query.cardCode" placeholder="请输入身份证号" clearable />
            </j-page-search>
            <j-page-search title="角色">
                <el-select v-model="query.roleId" placeholder="请选择角色" clearable filterable>
                    <el-option v-for="item in roleList" :key="item.roleId" :label="item.name" :value="item.roleId" />
                </el-select>
            </j-page-search>
            <j-page-search title="状态">
                <j-dic-select code="STATUS_TYPE" v-model="query.status" placeholder="请选择状态" />
            </j-page-search>
            <j-page-search title="考核月份">
                <el-date-picker v-model="query.yearMonth" type="month"  placeholder="请选择考核月份" value-format="yyyy/MM" />
            </j-page-search>
            <j-page-search title="个人小结">
                <j-dic-select code="PERSONAL_SUMMARY_STATUS" v-model="query.personalSummaryStatus" placeholder="请选择个人小结" />
            </j-page-search>
            <j-page-search title="领导评鉴总分">
                <j-link-input style="width: 280px;" :start.sync="query.appraiseTotalScoreGreaterThan" :end.sync="query.appraiseTotalScoreLessThan"/>
            </j-page-search>
            <j-page-search title="评鉴进度">
                <j-dic-select code="COMPLETE_STATUS" v-model="query.completeProportionId" placeholder="请选择评鉴进度" />
            </j-page-search>
            <el-button type="primary" @click="searchEvent">查询</el-button>
            <el-button @click="resetEvent">重置</el-button>
            <el-button type="primary" @click="exportEvent">导出</el-button>
        </div>
        <div slot="table">
            <el-table v-loading="tableLoading" :data="tableData" @selection-change="tableSelectionChange" height="100%">
                <el-table-column prop="status" label="状态" align="center" width="50">
                    <template slot-scope="{ row }">
                        <span :class="`color-status-style color-status${row.status}`" />
                    </template>
                </el-table-column>
                <el-table-column prop="title" label="考核月份" align="center" show-overflow-tooltip>
                    <template slot-scope="{ row }">{{`${row.year}/${row.month}`}}</template>
                </el-table-column>
                <el-table-column prop="orgName" label="所属机构" align="center" show-overflow-tooltip />
                <el-table-column prop="roleName" label="角色" align="center" show-overflow-tooltip />
                <el-table-column prop="userName" label="姓名" align="center" show-overflow-tooltip />
                <!--                <el-table-column prop="cardCode" label="身份证号" align="center" show-overflow-tooltip>-->
                <!--                    <j-sensitive slot-scope="{ row }" type="id" v-model="row.cardCode"/>-->
                <!--                </el-table-column>-->
                <el-table-column prop="userMobile" label="联系电话" align="center" show-overflow-tooltip />
                <el-table-column prop="completeProportion" label="评鉴进度" align="center" show-overflow-tooltip>
                    <span v-if="[1,11,13].includes(Number($store.state.user.roleId))" class="complete-pointer-style" slot-scope="{row}" @click="leaderEvent(row)">{{row.completeProportion}}</span>
                    <span v-else>{{row.completeProportion}}</span>
                </el-table-column>
                <el-table-column prop="personalSummaryStatusName" label="个人小结" align="center" show-overflow-tooltip>
                    <span v-if="row.personalSummaryStatus == 0" class="complete-pointer-style" slot-scope="{row}">{{row.personalSummaryStatusName}}</span>
                    <span v-else slot-scope="{row}">{{row.personalSummaryStatusName}}</span>
                </el-table-column>
                <el-table-column prop="appraiseTotalScore" label="领导评鉴总分" align="center" show-overflow-tooltip />
                <el-table-column label="操作" width="100">
                    <template slot-scope="{row}">
                        <el-button @click.stop="evaluateEvent('DET', row)" type="primary" class="el-button el-button--text">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div slot="pagination" v-show="query.total > query.limit">
            <el-pagination
                    :current-page="query.page"
                    :page-size="query.limit"
                    :total="query.total"
                    :background="pmxBackground"
                    :pager-count="pmxCount"
                    :layout="pmxLayout"
                    :pageSizes="pmxSizes"
                    @current-change="changePage"
                    @size-change="sizeChange"
            />
        </div>
        <j-drawer :title="drmxTypeMap[drmxType]" :close.sync="showDrmx">
            <div>
                <j-drawer-label type="title" title="基本信息" />
                <div style="padding-right: 30px;">
                    <j-drawer-label align="right" title="所属机构">{{ userBasicInfo.orgName }}</j-drawer-label>
                    <j-drawer-label align="right" title="角色">{{ userBasicInfo.roleName }}</j-drawer-label>
                    <j-drawer-label align="right" title="姓名">{{ userBasicInfo.name || curItem.userName }}</j-drawer-label>
                    <j-drawer-label align="right" title="身份证号">
                        <j-sensitive type="id" v-model="userBasicInfo.cardCode" eye/>
                    </j-drawer-label>
                    <j-drawer-label align="right" title="性别">
                        <j-dictionary code="GENDER" :value="userBasicInfo.sex" />
                    </j-drawer-label>
                    <j-drawer-label align="right" title="联系电话">{{ userBasicInfo.mobile || curItem.userMobile }}</j-drawer-label>
                    <j-drawer-label align="right" title="状态">{{ userBasicInfo.statusName }}</j-drawer-label>
                </div>
            </div>
            <div>
                <j-drawer-label type="title" title="个人总结" />
                <div v-if="personalSummaryInfo" style="padding-right: 30px;">
                    <j-drawer-label align="right" title="考核月份">{{ personalSummaryInfo.yearMonth }}</j-drawer-label>
                    <j-drawer-label align="right" title="自评分">{{ personalSummaryInfo.score }}</j-drawer-label>
                    <j-drawer-label align="right" title="月度工作情况">{{ personalSummaryInfo.monthlyWorkSummary }}</j-drawer-label>
                    <j-drawer-label align="right" title="未完成或完成质效不高的工作">{{ personalSummaryInfo.monthlyIncompleteLowefficiency }}</j-drawer-label>
                    <j-drawer-label align="right" title="更新时间">{{ personalSummaryInfo.updateTime }}</j-drawer-label>
                </div>
                <div v-else class="common-empty-wrap" />
            </div>
            <div>
                <j-drawer-label type="title" title="领导评鉴" />
                <div style="padding-right: 30px;">
                    <j-drawer-label align="right" title="考核月份">{{ leaderAppraise.yearMonth }}</j-drawer-label>
                    <j-drawer-label align="right" title="评鉴进度">
                        <span v-if="[1,11,13].includes(Number($store.state.user.roleId))" class="complete-pointer-style" @click="loadLeaderData">{{leaderAppraise.completeProportion}}</span>
                        <span v-else>{{leaderAppraise.completeProportion}}</span>
                    </j-drawer-label>
                    <j-drawer-label align="right" title="状态">{{ leaderAppraise.personalSummaryStatusName }}</j-drawer-label>
                </div>
            </div>
        </j-drawer>
        <el-dialog v-if="showLeader" title="评鉴进度" :visible.sync="showLeader" width="800px" top="10%" :close-on-click-modal="false">
            <el-table :data="leaderData" style="width: 100%" height="340px">
                <el-table-column type="expand" width="20">
                    <div class="common-expand-style" slot-scope="{row}">
                        <j-label title="评鉴说明" inline>{{row.appraiseDiscripotion}}</j-label>
                    </div>
                </el-table-column>
                <el-table-column prop="appraiseOrgName" label="所属机构" show-overflow-tooltip />
                <el-table-column sortable prop="appraiseRoleName" label="角色" align="center" show-overflow-tooltip />
                <el-table-column prop="appraiseUserName" label="姓名" align="center" show-overflow-tooltip />
                <el-table-column sortable prop="appraiseStatusName" label="状态" align="center" show-overflow-tooltip />
                <el-table-column sortable prop="appraiseLevelName" label="评鉴等级" align="center" show-overflow-tooltip />
                <el-table-column sortable prop="appraiseScore" label="评鉴分数" align="center" show-overflow-tooltip>
                    <span class="common-pointer-style" slot-scope="{ row }">{{row.appraiseScore}}</span>
                </el-table-column>
                <el-table-column sortable prop="appraiseTime" label="评鉴日期" align="center" show-overflow-tooltip />
            </el-table>
            <div style="display: flex; justify-content: flex-end">
                <el-pagination
                        :current-page="leaderQuery.page"
                        :page-size="leaderQuery.limit"
                        :total="leaderQuery.total"
                        :pager-count="pmxCount"
                        :layout="pmxLayout"
                        @current-change="changeLeaderPage"
                />
            </div>
        </el-dialog>
    </j-page>
</template>

<script>
    /**
     * @Author: fengjunjie <fengjunjie@btzh.cn>
     * @Description: jobrating_pc
     * @Date: 2022/04/18 16:26
     */
    import PageMixins from "../../components/JComponents/JPage/mixins/PageMixins";
    import PaginationMixins from "../../components/JComponents/JPage/mixins/PaginationMixins";
    import DrawerMixins from "../../components/JComponents/JPage/mixins/DrawerMixins";
    export default {
        name: "CriticismLedger",
        mixins: [PageMixins, PaginationMixins, DrawerMixins],
        data () {
            return {
                query: {
                    appraiseLevel: '',
                    appraiseTotalScoreGreaterThan: '',
                    appraiseTotalScoreLessThan: '',
                    cardCode: '',
                    completeProportionId: '',
                    orgId: '',
                    personalSummaryStatus: '',
                    roleId: '',
                    status: '',
                    userMobile: '',
                    userName: '',
                    yearMonth: ''
                },
                roleList: [],
                showLeader: false,
                leaderData: [{title: ''}],
                leaderQuery: {
                    page: 1,
                    limit: 10,
                    total: 0,
                    userId: '',
                    yearMonth: ''
                },
                drmxTypeMap: {
                    'ADD': '评鉴',
                    'DET': '详情'
                },
                userBasicInfo: {},
                personalSummaryInfo: null,
                leaderAppraise: {}
            }
        },
        created () {
            this.getRoleList()
            this.load()
        },
        methods: {
            async exportEvent () {
                const { data } = await this.$http.request({
                    method: 'get',
                    url: `/api/leaderAppraise/export`,
                    params: { ...this.query },
                    responseType: 'arraybuffer'
                })
                let url = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }))
                let link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                link.setAttribute('download', '领导评鉴台账导出')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link) // 下载完成移除元素
                window.URL.revokeObjectURL(url) // 释放掉blob对象
            },
            leaderEvent (row) {
                  this.curItem = row
                  this.loadLeaderData()
            },
            changeLeaderPage (page) {
                this.leaderQuery.page = page
                this.loadLeaderData()
            },
            async loadLeaderData () {
                const { data } = await this.$http.get(`/api/leaderAppraise/getCompleteProportion`, {
                    params:  { ...this.leaderQuery, userId: this.curItem.userId, yearMonth: `${this.curItem.year}/${this.curItem.month}` }
                })
                this.leaderData = data.data.data
                this.leaderQuery.total = data.data.total
                this.showLeader = true
            },
            async load () {
                this.tableLoading = true
                const { data } = await this.$http.get(`/api/leaderAppraise/getStandingBookList`, {
                    params: this.query
                })
                this.tableData = data.data.data
                this.query.total = data.data.total
                this.tableLoading = false
            },
            async getRoleList () {
                const { data } = await this.$http.get(`/api/roles`)
                this.roleList = data.data
            },
            async getDetailData (row) {
                const {userId, year, month } = row
                const { data } = await this.$http.get(`/api/leaderAppraise/getStandingBookDetailInfo`, {
                    params: { userId, yearMonth: `${year}/${month}` }
                })
                this.leaderAppraise = data.data.leaderAppraise || {}
                this.personalSummaryInfo = data.data.personalSummaryInfo || null
                this.userBasicInfo = data.data.userBasicInfo || {}
            },
            async evaluateEvent (type, row) {
                this.drmxType = type
                this.curItem = { ...row }
                await this.getDetailData(row)
                this.showDrmx = true
            }
        },
        watch: {
            showLeader (nv) {
                if (!nv) {
                    this.leaderQuery = { ...this.$options.data().leaderQuery }
                }
            }
        }
    }
</script>

<style lang="less">
    .color-status-style {
        display: inline-flex;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        &.color-status1 {
            background: #C0C4CC;
        }
        &.color-status2 {
            background: #E6A23C;
        }
        &.color-status3 {
            background: #F56C6C;
        }
    }
</style>
