<template>
    <div class="index-layout-wrap">
        <!--        布局头部-->
        <div class="index-layout-wrap-header">
            <!--        头部LOGO-->
            <div class="index-layout-wrap-header_logo"/>
            <!--            横向菜单区域-->
            <div class="index-layout-wrap-header_menu"></div>
            <!--        用户信息-->
            <div class="index-layout-wrap-header_user">
                <j-avatar />
            </div>
        </div>
        <!--        布局内容区域-->
        <div class="index-layout-wrap-content">
            <!--            左侧菜单区域-->
            <div class="index-layout-wrap-content_menu">
                <j-menu />
            </div>
            <!--            右侧切换区域-->
            <div class="index-layout-wrap-content_page">
                <!--                快速访问栏-->
                <div class="index-layout-wrap-content_page__tab">
                    <j-quick-tab/>
                </div>
                <!--                动态组件加载区-->
                <div class="index-layout-wrap-content_page__component">
                    <transition name="index-transition-page">
                        <keep-alive :include="$store.getters['JMenu/menuInclude']">
                            <component :is="$store.state.JMenu.menuActive" />
                        </keep-alive>
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import JAvatar from "../../components/JComponents/JAvatar/JAvatar";
    import JMenu from "../../components/JComponents/JMenu/JMenu";
    import JQuickTab from "../../components/JComponents/JQuickTab/components/JQuickTab";
    export default {
        name: "Home",
        components: {
            JAvatar,
            JMenu,
            JQuickTab,
        },
        created() {
            this.$store.dispatch('getRolesAction')
            this.$store.dispatch('getOrgTree') // 获取全局机构树数据
            this.$store.dispatch("getRoleList") // 获取全局角色树数据
            this.$store.dispatch('JMenu/getMenuList')
        }
    }
</script>

<style scoped lang="less">
    .index-layout-wrap {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .index-layout-wrap-header {
            height: 60px;
            box-sizing: border-box;
            display: flex;
            background-color: #F6F8FB;
            background-image: url("../../assets/header/layout-header.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            border-bottom: 3px solid #00B9DC;

            .index-layout-wrap-header_logo {
                font-size: 20px;
                font-weight: bolder;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 336px;
                height: 38px;
                margin-left: 20px;
                margin-top: 8px;
                background-image: url("../../assets/header/header-title.png");
                background-size: cover;
            }

            .index-layout-wrap-header_menu {
                flex: 1;
                min-width: 100px;
            }

            .index-layout-wrap-header_user {
                width: 180px;
                padding-right: 30px;
                box-sizing: border-box;
            }
        }

        .index-layout-wrap-content {
            flex: 1;
            min-height: 200px;
            background-color: beige;
            display: flex;

            .index-layout-wrap-content_menu {
                max-width: 200px;
                background-color: #00ff80;
            }

            .index-layout-wrap-content_page {
                flex: 1;
                min-width: 100px;
                background-color: #F1F5F6;
                display: flex;
                flex-direction: column;

                .index-layout-wrap-content_page__tab {
                    height: 40px;
                }

                .index-layout-wrap-content_page__component {
                    flex: 1;
                    min-height: 100px;
                    box-sizing: border-box;
                    margin: 0px 16px 16px;
                    background-color: #fff;
                    overflow: hidden;
                }
            }
        }
    }
</style>

<style>
    html, body {
        height: 100%;
    }
    .index-transition-page-leave-active,
    .index-transition-page-enter-active {
        transition: all 500ms ease;
        width: 100%;
        height: 100%;
        box-shadow: 0 7px 21px rgba(0, 0, 0, .25);
    }

    .index-transition-page-enter {
        transform: translateX(20px);
        opacity: 0;
    }

    .index-transition-page-leave-to {
        transform: translateX(20px);
        opacity: 0;
    }
</style>
