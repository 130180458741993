<template>
    <j-page>
        <div slot="search"  v-if="search">
            <j-page-search title="姓名">
                <el-input v-model="query.name" placeholder="请输入姓名" clearable />
            </j-page-search>
            <j-page-search title="联系电话">
                <el-input v-model.trim="query.mobile" placeholder="请输入联系电话" clearable />
            </j-page-search>
            <j-page-search title="身份证号">
                <el-input v-model.trim="query.cardCode" placeholder="请输入身份证号" clearable />
            </j-page-search>
            <j-page-search title="角色">
                <el-select v-model="query.roleId" placeholder="请选择角色" clearable filterable>
                    <el-option v-for="item in roleList" :key="item.roleId" :label="item.name" :value="item.roleId" />
                </el-select>
            </j-page-search>
            <j-page-search title="状态">
                <j-dic-select code="ORG_STATUS" v-model="query.status" placeholder="请选择状态" />
            </j-page-search>
            <j-page-search title="小程序">
                <el-select v-model="query.wx" placeholder="请选择小程序状态" clearable filterable>
                    <el-option label="已绑定" :value="true" />
                    <el-option label="未绑定" :value="false" />
                </el-select>
            </j-page-search>
            <el-button type="primary" @click="searchEvent">查询</el-button>
            <el-button @click="resetEvent">重置</el-button>
            <el-button type="warning" @click="addDrmxEvent">新增</el-button>
        </div>
        <div slot="table">
            <el-table stripe v-loading="tableLoading" :data="tableData" height="100%">
                <el-table-column prop="orgName" label="所属机构" align="center" show-overflow-tooltip />
                <el-table-column prop="roleName" label="角色" align="center" show-overflow-tooltip />
                <el-table-column prop="name" label="姓名" align="center" show-overflow-tooltip />
                <el-table-column prop="sex" label="性别" align="center" show-overflow-tooltip>
                    <j-dictionary slot-scope="{row}" code="GENDER" :value="row.sex" />
                </el-table-column>
                <el-table-column prop="mobile" label="联系电话" align="center" show-overflow-tooltip />
                <el-table-column prop="cardCode" label="身份证号" align="center" show-overflow-tooltip>
                    <j-sensitive slot-scope="{row}" type="id" v-model="row.cardCode" />
                </el-table-column>
                <el-table-column prop="status" label="状态" align="center" show-overflow-tooltip>
                    <j-dictionary slot-scope="{row}" code="ORG_STATUS" :value="row.status" />
                </el-table-column>
                <el-table-column prop="wxOpenId" label="小程序" align="center" show-overflow-tooltip >
<!--                    <j-dictionary slot-scope="{row}" code="ORG_WX_STATUS" :value="row.wxOpenId" />-->
                    <span slot-scope="{row}">{{row.wxOpenId ? '已绑定' : '未绑定'}}</span>
                </el-table-column>
                <el-table-column label="操作" width="240">
                    <template slot-scope="scope">
                        <el-button @click.stop="detailDrmxEvent(scope.row)" type="primary" class="el-button el-button--text">查看</el-button>
                        <el-button @click.stop="editDrmxEvent(scope.row)" type="primary" class="el-button el-button--text">编辑</el-button>
                        <el-button @click.stop="delEvent(scope.row)" class="el-button el-button--text common-btn-warn-style" >删除</el-button>
                        <el-button @click.stop="resetPassword(scope.row)" type="primary" class="el-button el-button--text">重置密码</el-button>
                        <el-button v-if="scope.row.status == 0" @click.stop="changeUserStatus(scope.row, 1)" class="el-button el-button--text common-btn-stop-style">启用</el-button>
                        <el-button v-else @click.stop="changeUserStatus(scope.row, 0)" class="el-button el-button--text common-btn-stop-style">禁用</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div slot="pagination" v-show="query.total > 0">
            <el-pagination
                    :current-page="query.page"
                    :page-size="query.limit"
                    :total="query.total"
                    :background="pmxBackground"
                    :pager-count="pmxCount"
                    :layout="pmxLayout"
                    :pageSizes="pmxSizes"
                    @current-change="changePage"
                    @size-change="sizeChange"
            />
        </div>

        <el-dialog v-if="showDgmx" title="重置密码" :visible.sync="showDgmx" width="400px" :close-on-click-modal="false">
            <el-form ref="dgmxDom" :model="dgmxForm" :rules="dgmxRule">
                <el-form-item label="新密码" prop="newPwd">
                    <el-input type="password" v-model.trim="dgmxForm.newPwd" placeholder="请输入新密码" clearable />
                </el-form-item>
                <el-form-item label="重复密码" prop="newPwdConfim">
                    <el-input type="password" v-model.trim="dgmxForm.newPwdConfim" placeholder="请重复密码" clearable />
                </el-form-item>
            </el-form>
            <template slot="footer">
                <el-button type="primary" @click="checkDgmxEvent('saveDgmxData')" :loading="submitLoad">保 存</el-button>
                <el-button @click="showDgmx = false">取 消</el-button>
            </template>
        </el-dialog>

        <j-drawer :title="drmxTypeMap[drmxType]" :close.sync="showDrmx">
            <div>
                <el-form ref="drmxDom" :model="drmxForm" :rules="drmxRule" :label-width="drmxFormLabelWidth">
                    <j-drawer-label type="title" title="用户信息" />
                    <div style="padding-right: 30px;">
                        <el-form-item label="所属机构" prop="orgId">
                            <el-cascader ref="org_cascader" style="width: 100%;" v-model="drmxForm.orgId" :options="tree" :props="treeProps" placeholder="请选择所属机构" @change="changeNode" />
                        </el-form-item>
                        <el-form-item label="角色" prop="roleId">
                            <el-select v-model="drmxForm.roleId" :disabled="!search" placeholder="请选择角色" style="width: 100%" clearable filterable>
                                <el-option v-for="item in roleList" :key="item.roleId" :label="item.name" :value="item.roleId" />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="用户名" prop="username">
                            <el-input v-model.trim="drmxForm.username" placeholder="请输入用户名" clearable maxlength="20" />
                        </el-form-item>
                        <el-form-item label="姓名" prop="name">
                            <el-input v-model.trim="drmxForm.name" placeholder="请输入姓名" maxlength="20" clearable />
                        </el-form-item>
                        <el-form-item label="性别" prop="sex">
                            <j-dic-select code="GENDER" v-model="drmxForm.sex"  placeholder="请选择性别"/>
                        </el-form-item>
                        <el-form-item label="身份证号" prop="cardCode">
                            <el-input v-model.trim="drmxForm.cardCode" placeholder="请输入身份证号" clearable />
                        </el-form-item>
                        <el-form-item label="联系电话" prop="mobile">
                            <el-input v-model.trim="drmxForm.mobile" placeholder="请输入联系电话" clearable />
                        </el-form-item>
                        <el-form-item label="状态" prop="status">
                            <j-dic-select code="ORG_STATUS" v-model="drmxForm.status"  placeholder="请选择状态"/>
                        </el-form-item>
                        <el-form-item label="照片" prop="avataUrl">
                            <j-upload v-model="drmxForm.avataUrl" type="String" list-type="picture-card" :mimeTypeLimit="['.gif', '.png', '.jpeg', '.icon']" :countLimit="1">
                                <i class="el-icon-plus" style="font-size: 18px;" />
                            </j-upload>
                        </el-form-item>
                    </div>
                </el-form>
            </div>
            <div slot="footer" style="width: 100%;">
                <el-button style="width: 100%;" @click="checkDrmxEvent('saveDrmxData')" :loading="submitLoad">保存</el-button>
            </div>
        </j-drawer>

        <!--查看详情-->
        <j-drawer :title="drmxTypeMap[drmxType]" :close.sync="showDrmxDetail">
            <div>
                <j-drawer-label type="title" title="基本信息" />
                <div style="padding-right: 30px;">
                    <j-drawer-label v-if="curItem.avataUrl" align="right" title="照片">
                        <j-upload :value="curItem.avataUrl" list-type="picture-card" read-only />
                    </j-drawer-label>
                    <j-drawer-label align="right" title="所属机构">{{ curItem.orgName }}</j-drawer-label>
                    <j-drawer-label align="right" title="角色">{{ curItem.roleName }}</j-drawer-label>
                    <j-drawer-label align="right" title="用户名">{{ curItem.username }}</j-drawer-label>
                    <j-drawer-label align="right" title="姓名">{{ curItem.name }}</j-drawer-label>
                    <j-drawer-label align="right" title="身份证号">
                        <j-sensitive type="id" v-model="curItem.cardCode" eye/>
                    </j-drawer-label>
                    <j-drawer-label align="right" title="性别">
                        <j-dictionary code="GENDER" :value="curItem.sex" />
                    </j-drawer-label>
                    <j-drawer-label align="right" title="联系电话">{{ curItem.mobile }}</j-drawer-label>
                    <j-drawer-label align="right" title="状态">
                        <j-dictionary code="ORG_STATUS" :value="curItem.status" />
                    </j-drawer-label>
                    <j-drawer-label align="right" title="小程序">{{ curItem.wxOpenId ? '已绑定' : '未绑定' }}</j-drawer-label>
                    <j-drawer-label v-if="curItem.wxOpenId" align="right" title="OpenID">{{ curItem.wxOpenId }}</j-drawer-label>
                </div>
            </div>
        </j-drawer>
    </j-page>
</template>

<script>
    import md5 from 'js-md5'
    import PageMixins from "../../components/JComponents/JPage/mixins/PageMixins";
    import DialogMixins from "../../components/JComponents/JPage/mixins/DialogMixins";
    import DrawerMixins from "../../components/JComponents/JPage/mixins/DrawerMixins";
    import PaginationMixins from "../../components/JComponents/JPage/mixins/PaginationMixins";
    import validator from "../../components/JComponents/JPage/mixins/validator";
    export default {
        name: "UserManage",
        mixins: [PageMixins, DialogMixins, DrawerMixins, PaginationMixins, validator],
        props: {
          search: {
              type: Boolean,
              default: true
          },
          tree: {
              type: Array
          }
        },
        data () {
            return {
                treeProps: { children: 'childrens', label: 'name', value: 'id', emitPath: false },
                roleList: [],
                statusMap: {
                    0: '禁用',
                    1: '启用'
                },
                query: {
                    cardCode: '',
                    mobile: '',
                    name: '',
                    orgId: '',
                    roleId: '',
                    status: '',
                    wx: ''
                },
                drmxForm: {
                    "avataUrl": undefined,
                    "mobile": undefined,
                    "name": undefined,
                    "orgId": undefined,
                    "orgName": undefined,
                    "roleId": undefined,
                    "status": undefined,
                    "sex": undefined,
                    "cardCode": undefined,
                    "username": undefined
                },
                drmxRule: {
                    username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
                    name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
                    orgId: [{ required: true, message: '请选择所属机构', trigger: 'change' }],
                    roleId: [{ required: true, message: '请选择角色', trigger: 'change' }],
                    status: [{ required: true, message: '请选择状态', trigger: 'change' }],
                    cardCode: [{ required: false, validator: this.cardNoValidator, trigger: 'blur' }],
                    mobile: [{ required: false, validator: this.phoneValidator, trigger: 'blur' }]
                },
                dgmxForm: {
                    "adminId": '',
                    "newPwd": "",
                    "newPwdConfim": ""
                },
                dgmxRule: {
                    newPwd: [{ required: true, message: '请输入密码', trigger: 'blur' }],
                    newPwdConfim: [{ required: true, validator: this.passValidator, trigger: 'blur' }]
                },
                cascaderProps: { children: 'childrens', label: 'name', value: 'id', emitPath: false },
                org: {}
            }
        },
        created () {
            this.getRoleList()
            if (this.search) this.load(this.org)
        },
        methods: {
          changeNode () {
            this.$nextTick(() => {
              const node = this.$refs.org_cascader.getCheckedNodes()[0]
              if (node) {
                this.drmxForm.orgName = node.label
              } else {
                this.drmxForm.orgName = ''
              }
            })
          },
            resetEvent () {
                // 重置query对象
                this.query = { ...this.$options.data().query }
                this.load && this.load(this.org)
            },
            optionChecked (item) {
              this.$refs['votDom'] && this.$refs['votDom'].blur()
                if (this.drmxForm.roleId != 5) return;
              if (!item.principalName) return
                this.$confirm('所选服务队已有队长，是否替换？', '提示', {
                    confirmButtonText: '替换队长',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    console.log('替换队长')
                }, () => {
                    this.drmxForm.votTeamId = ''
                })
            },
            addDrmxEvent () {
                if (!this.search) this.drmxForm.roleId = this.org.parentId ? 4 : 3
                this.drmxType = 'ADD'
                this.drmxForm.orgId = this.org.id
                this.drmxForm.orgName = this.org.name
                this.showDrmx = true
            },
            editDrmxEvent (row) {
                // this.$store.dispatch('getServeTeam')
                if (!this.search) this.drmxForm.roleId = this.org.parentId ? 4 : 3
                this.drmxType = 'EDIT'
                this.curItem = { ...row }
                this.drmxForm = this.proxyDrmxData({ ...this.drmxForm, ...row })
                this.showDrmx = true
            },
            passValidator (rule, value, callback) {
                if (!value && rule.required) {
                    callback(new Error('请确认密码'));
                } else if (value != this.dgmxForm.newPwd) {
                    callback(new Error('两次输入密码不一致!'));
                } else {
                    callback();
                }
            },
            async load (org = null, page) {
                this.org = org || this.org
                this.query.page = page || this.query.page
                this.tableLoading = true
                const { data } = await this.$http.get(`/api/admins`, {
                    params:  { ...this.query, orgId: this.org.id }
                })
                this.tableData = data.data.data
                this.query.total = data.data.total
                this.tableLoading = false
            },
            async getRoleList () {
                const { data } = await this.$http.get(`/api/roles`)
                this.roleList = data.data
            },
            delEvent (row) {
                this.$confirm('您确认要删除当前用户吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http
                        .request({
                            method: 'delete',
                            url: `/api/admins/${row.adminId}`
                        })
                        .then((res) => {
                            if (res.data.status !== 'yes') return this.$message.error(res.data.msg)
                            this.pageUpdate(this.query)
                            this.$message.success('删除成功')
                            this.load && this.load(this.org)
                        })
                })
            },
            resetPassword (row) {
                this.$confirm('您确认要重置密码吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http
                        .request({
                            method: 'PUT',
                            url: `/api/admins/resetPassword/${row.adminId}`
                        })
                        .then((res) => {
                            if (res.data.status !== 'yes') return this.$message.error(res.data.msg)
                            this.$message.success('重置成功')
                            this.load && this.load(this.org)
                        })
                })
            },
            async changeUserStatus (row, status) {
                if (this.submitLoad) return
                this.submitLoad = true
                const {data} = await this.$http.put(`/api/admins/setUserStatus`, { ...row, status })
                  .catch(({response}) => { this.$message.error(response.data.msg) })
                  .finally(() => { this.submitLoad = false })
                if (data.status !== 'yes') return this.$message.error(data.msg)
                this.$message.success('更新成功')
                this.load(this.org)
            },
            async saveDgmxData () {
                if (this.submitLoad) return
                this.submitLoad = true
                let method = 'put'
                let url = `/api/admins/resetPassword`
                const { data } = await this.$http[method](url, { ...this.dgmxForm,
                        newPwd: md5(this.dgmxForm.newPwd),
                        newPwdConfim: md5(this.dgmxForm.newPwdConfim)
                })
                    .catch(({response}) => { this.$message.error(response.data.msg) })
                    .finally(() => { this.submitLoad = false })
                if (data.status !== 'yes') return this.$message.error(data.msg)
                this.showDgmx = false
                this.$message.success('提交成功')
                this.load(this.org)
            },
            async saveDrmxData () {
                if (this.submitLoad) return
                this.submitLoad = true
                let method = 'post'
                let url = `/api/admins`
                if (this.drmxType === 'EDIT') {
                    url = `/api/admins`
                    method = 'put'
                }
                const { data } = await this.$http[method](url, this.drmxForm)
                    .finally(() => { this.submitLoad = false })
                if (data.status !== 'yes') return this.$message.error(data.msg)
                this.showDrmx = false
                this.$message.success('提交成功')
                this.load(this.org)
            }
        }
    }
</script>

<style scoped>

</style>
