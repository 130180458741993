<template>
    <div v-on="$listeners" @click="quickToMenu(meta)" :class="['j-quick-item-wrap', meta.address === $store.state.JMenu.menuActive ? 'j-quick-item-active' : '']">
        <j-svg-icon v-if="icon && meta.icon" class="j-quick-item-close" :name="meta.icon"/>
        <el-tooltip v-if="breviary" effect="dark" :content="meta.name" placement="top-start">
            <span class="j-quick-item-name">{{meta.name}}</span>
        </el-tooltip>
        <span v-else class="j-quick-item-name">{{meta.name}}</span>
        <el-dropdown class="j-quick-item-dropdown" v-if="meta.address === $store.state.JMenu.menuActive && $store.state.JMenu.menuQuick.length > 1" size="mini">
            <j-svg-icon class="j-quick-item-close" name="del-icon"/>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="close">关闭</el-dropdown-item>
                <el-dropdown-item @click.native="closeOther" divided>关闭其他</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>

<script>
import JSvgIcon from "../../JSvgIcon/JSvgIcon";
export default {
  name: 'JQuickItem',
  components: {
    JSvgIcon
  },
  props: {
    meta: {
      type: Object,
      required: true
    },
    icon: { // 显示菜单对应svg icon
      type: Boolean,
      default: false
    },
    breviary: { // 针对菜单名称过长 使用缩略模式
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      required: true
    }
  },
  methods: {
    quickToMenu (menu) {
      this.$store.commit('JMenu/openMenu', menu.address)
    },
    close () {
      if (this.index > 0) {
        this.quickToMenu(this.$store.state.JMenu.menuQuick[this.index - 1])
      } else {
        this.quickToMenu(this.$store.state.JMenu.menuQuick[this.index + 1])
      }
      this.$store.commit('JMenu/decreaseQuick', this.index)
    },
    closeOther () {
      this.$store.commit('JMenu/menuQuick', [{ ...this.meta }])
    }
  }
}
</script>

<style scoped lang="less">
    .j-quick-item-wrap {
        display: flex;
        padding: 0 5px;
        align-items: center;
        background-color: #fff;
        border-radius: 3px;
        cursor: pointer;
        color: #606266;
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
        + .j-quick-item-wrap {
            margin-left: 6px;
        }

        &.j-quick-item-active {
            background-color: @themeColor;
            color: #fff;
            .j-quick-item-dropdown {
                color: rgba(255,255,255,.7);
            }
        }

        .j-quick-item-name {
            display: inline-block;
            padding: 0 6px;
            max-width: 120px;
            font-size: 12px;
            text-overflow: ellipsis;
            white-space: pre;
            overflow: hidden;
        }
        .j-quick-item-close {
            cursor: pointer;
            font-size: 13px;
        }
    }
</style>
