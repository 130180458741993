import Vue from 'vue'
import { home } from '@/config'

const api = `/api/admins/menus`

let userAddress = home // 读取配置文件指定首次加载
let defaultAddress = ''
const menuResolve = (arr) => {
  let menuMap = {}
  arr.forEach((item, index) => {
    menuMap[item.address] = item
    if (index === 0) defaultAddress = item.address
    if (item.children) {
      item.children.forEach((v, i) => {
        menuMap[v.address] = v
        if (index === 0 && i === 0) defaultAddress = v.address
      })
    }
  })
  return menuMap
}

const scrollView = (state) => {
  const id = `j-quick-tab-${state.menuMap[state.menuActive] && state.menuMap[state.menuActive].menuId}`
  document.getElementById(id) && document.getElementById(id).scrollIntoView({ behavior: 'smooth', inline: 'end' })
}

const state = {
  menuMap: {},
  menuList: [],
  menuQuick: [],
  menuActive: ''
}

const mutations = {
  menuMap (state, payload) {
    state.menuMap = payload
  },
  menuList (state, payload) {
    state.menuList = payload
  },
  menuActive (state, payload) {
    state.menuActive = payload
    sessionStorage.setItem('menuActive', payload)
    Vue.nextTick(() => scrollView(state))
  },
  menuQuick (state, payload) {
    state.menuQuick = payload
    sessionStorage.setItem('menuQuick', JSON.stringify(state.menuQuick))
  },
  decreaseQuick (state, index) {
    state.menuQuick.splice(index, 1)
    sessionStorage.setItem('menuQuick', JSON.stringify(state.menuQuick))
  },
  additionQuick (state, quick) {
    state.menuQuick.push(quick)
    sessionStorage.setItem('menuQuick', JSON.stringify(state.menuQuick))
    Vue.nextTick(() => scrollView(state))
  },
  openMenu (state, menuName) {
    scrollView(state)
    if (state.menuActive === menuName) return
    let isExist = false
    state.menuQuick.some(item => {
      if (item.address === menuName) {
        this.commit('JMenu/menuActive', menuName)
        isExist = true
        return true
      }
    })
    if (!isExist) {
      this.commit('JMenu/additionQuick', state.menuMap[menuName] || {})
      this.commit('JMenu/menuActive', menuName)
    }
  }
}

const actions = {
  async getMenuList (content) {
    const { data } = await Vue.http.get(api)
    if (data.status != 'yes') return Vue.prototype.$message.error(`${api}接口，菜单信息获取异常`)
    let map = menuResolve(data.data)
    let menuQuick = JSON.parse(sessionStorage.getItem('menuQuick') || '[]')
    let initActive = sessionStorage.getItem('menuActive') || (userAddress && map[userAddress] && map[userAddress].address) || defaultAddress
    content.commit('menuList', data.data)
    content.commit('menuMap', map)
    if (menuQuick.length > 0) {
      content.commit('menuQuick', menuQuick)
    }
    content.commit('openMenu', initActive)
  }
}

const getters = {
  menuInclude (state) {
    return state.menuQuick.map(item => item.address)
  }
}

// 模块方式注册
export const JMenu = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
