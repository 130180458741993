<template>
    <j-page>
        <div slot="search">
            <j-page-search title="所属机构">
                <el-cascader v-model="query.orgId" :options="$store.state.orgTree" :props="{ children: 'childrens', label: 'name', value: 'id', emitPath: false, checkStrictly: true }" placeholder="请选择所属机构" clearable />
            </j-page-search>
            <j-page-search title="姓名">
                <el-input v-model.trim="query.userName" placeholder="请输入姓名" clearable />
            </j-page-search>
            <j-page-search title="联系电话">
                <el-input type="number" v-model.trim="query.userMobile" placeholder="请输入联系电话" clearable />
            </j-page-search>
            <j-page-search title="身份证号">
                <el-input type="text" v-model.trim="query.cardCode" placeholder="请输入身份证号" clearable />
            </j-page-search>
            <j-page-search title="角色">
                <el-select v-model="query.roleId" placeholder="请选择角色" clearable filterable>
                    <el-option v-for="item in roleList" :key="item.roleId" :label="item.name" :value="item.roleId" />
                </el-select>
            </j-page-search>
            <j-page-search title="考核月份">
                <el-date-picker v-model="query.yearMonth" type="month"  placeholder="请选择考核月份" value-format="yyyy/MM" />
            </j-page-search>
            <j-page-search title="计分类型">
                <j-dic-select code="SCORE_TYPE" v-model="query.scoreType" placeholder="请选择计分类型" />
            </j-page-search>
            <j-page-search title="计分分数">
                <j-link-input style="width: 280px;" :start.sync="query.scoreGreaterThan" :end.sync="query.scoreLessThan"/>
            </j-page-search>
            <el-button type="primary" @click="searchEvent">查询</el-button>
            <el-button @click="resetEvent">重置</el-button>
            <el-button type="warning" @click="addDgmxEvent">新增</el-button>
            <el-button type="primary" plain @click="exportEvent">导出</el-button>
        </div>
        <div slot="table">
            <el-table v-loading="tableLoading" :data="tableData" @selection-change="tableSelectionChange" height="100%">
                <el-table-column prop="title" label="考核月份" align="center" show-overflow-tooltip>
                    <template slot-scope="{ row }">{{`${row.year}/${row.month}`}}</template>
                </el-table-column>
                <el-table-column prop="orgName" label="所属机构" align="center" show-overflow-tooltip />
                <el-table-column prop="roleName" label="角色" align="center" show-overflow-tooltip />
                <el-table-column prop="userName" label="姓名" align="center" show-overflow-tooltip />
<!--                <el-table-column prop="userMobile" label="联系电话" align="center" show-overflow-tooltip />-->
                <el-table-column prop="scoreTypeName" label="计分类型" align="center" show-overflow-tooltip>
                    <span class="complete-pointer-style" slot-scope="{row}">{{row.scoreTypeName}}</span>
                </el-table-column>
                <el-table-column prop="score" label="计分分数" align="center" show-overflow-tooltip >
                    <span class="complete-pointer-style" slot-scope="{row}">{{row.score}}</span>
                </el-table-column>
                <el-table-column label="操作" width="100">
                    <template slot-scope="{row}">
                        <el-button @click.stop="evaluateEvent('DET', row)" type="primary" class="el-button el-button--text">详情</el-button>
                        <el-button v-if="row.status != 3" @click.stop="delEvent(row)" type="primary" class="el-button el-button--text">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div slot="pagination" v-show="query.total > query.limit">
            <el-pagination
                    :current-page="query.page"
                    :page-size="query.limit"
                    :total="query.total"
                    :background="pmxBackground"
                    :pager-count="pmxCount"
                    :layout="pmxLayout"
                    :pageSizes="pmxSizes"
                    @current-change="changePage"
                    @size-change="sizeChange"
            />
        </div>
        <j-drawer :title="drmxTypeMap[drmxType]" :close.sync="showDrmx">
            <div>
                <j-drawer-label type="title" title="基本信息" />
                <div style="padding-right: 30px;">
                    <j-drawer-label align="right" title="所属机构">{{ userBasicInfo.orgName }}</j-drawer-label>
                    <j-drawer-label align="right" title="角色">{{ userBasicInfo.roleName }}</j-drawer-label>
                    <j-drawer-label align="right" title="姓名">{{ userBasicInfo.name || curItem.userName }}</j-drawer-label>
                    <j-drawer-label align="right" title="身份证号">
                        <j-sensitive type="id" v-model="userBasicInfo.cardCode" eye/>
                    </j-drawer-label>
                    <j-drawer-label align="right" title="性别">
                        <j-dictionary code="GENDER" :value="userBasicInfo.sex" />
                    </j-drawer-label>
                    <j-drawer-label align="right" title="联系电话">{{ userBasicInfo.mobile || curItem.userMobile }}</j-drawer-label>
                    <j-drawer-label align="right" title="状态">{{ userBasicInfo.statusName }}</j-drawer-label>
                </div>
            </div>
            <div>
                <j-drawer-label type="title" title="个人奖惩" />
                <div style="padding-right: 30px;">
                    <j-drawer-label align="right" title="考核月份">{{ personalRewardPunishment.yearMonth }}</j-drawer-label>
                    <j-drawer-label align="right" title="操作用户">{{personalRewardPunishment.userName}}</j-drawer-label>
                    <j-drawer-label align="right" title="计分日期">{{ personalRewardPunishment.scoreTime }}</j-drawer-label>
                    <j-drawer-label align="right" title="计分类型">{{ personalRewardPunishment.scoreTypeName }}</j-drawer-label>
                    <j-drawer-label align="right" title="计分分数">{{ personalRewardPunishment.score }}</j-drawer-label>
                    <j-drawer-label align="right" title="计分说明">{{ personalRewardPunishment.scoreDiscripotion }}</j-drawer-label>
                    <j-drawer-label align="right" title="附件">
                        <j-upload :value="personalRewardPunishment.scoreFile" list-type="picture-card" read-only />
                    </j-drawer-label>
                </div>
            </div>
        </j-drawer>
        <el-dialog v-if="showDgmx" :title="dgmxTypeMap[dgmxType]" :visible.sync="showDgmx" width="450px" top="5%" :close-on-click-modal="false">
            <el-form ref="dgmxDom" :model="dgmxForm" :rules="dgmxRule" :label-width="dgmxFormLabelWidth">
                <el-form-item label="所属机构" prop="orgId">
                    <el-cascader ref="org_cascader" style="width: 100%;" v-model="dgmxForm.orgId" :options="$store.state.orgTree" :props="{ children: 'childrens', label: 'name', value: 'id', emitPath: false, checkStrictly: false }" placeholder="请选择所属机构" clearable @change="changeNode" />
                </el-form-item>
                <el-form-item label="考核月份" prop="year">
                    <el-date-picker style="width: 100%;" v-model="dgmxForm.year" type="month"  placeholder="请选择考核月份" value-format="yyyy/MM" />
                </el-form-item>
                <el-form-item label="角色" prop="roleId">
                    <el-select style="width: 100%;" v-model="dgmxForm.roleId" placeholder="请选择角色" clearable filterable @change="roleChange">
                        <el-option v-for="item in roleList" :key="item.roleId" :label="item.name" :value="item.roleId" />
                    </el-select>
                </el-form-item>
                <el-form-item label="姓名" prop="userName">
<!--                    <el-select style="width: 100%;" v-model="dgmxForm.userId" placeholder="请选择用户" clearable filterable>-->
<!--                        <el-option v-for="item in userList" :key="item.adminId" :label="item.name" :value="item.adminId" />-->
<!--                    </el-select>-->
                  <el-input v-model.trim="dgmxForm.userName" type="text" placeholder="选择所属机构和角色后，姓名自动填充" readonly />
                </el-form-item>
                <el-form-item label="计分类型" prop="scoreType">
                    <j-dic-select code="SCORE_TYPE" v-model="dgmxForm.scoreType" placeholder="请选择计分类型" />
                </el-form-item>
                <el-form-item label="计分分数" prop="score">
                    <el-input type="number" v-model.trim="dgmxForm.score" placeholder="请输入计分分数" maxlength="200" clearable />
                </el-form-item>
                <el-form-item label="计分说明" prop="scoreDiscripotion">
                    <el-input v-model.trim="dgmxForm.scoreDiscripotion" type="textarea" :rows="3" placeholder="请输入计分说明" maxlength="200" show-word-limit clearable />
                </el-form-item>
                <el-form-item label="附件" prop="scoreFile">
                    <j-upload v-model="dgmxForm.scoreFile">
                        <span class="common-pointer-style">上传附件</span>
                    </j-upload>
                </el-form-item>
            </el-form>
            <template slot="footer">
                <el-button type="primary" @click="checkDgmxEvent('saveDgmxData')" :loading="submitLoad">保 存</el-button>
                <el-button @click="showDgmx = false">取 消</el-button>
            </template>
        </el-dialog>
    </j-page>
</template>

<script>
    /**
     * @Author: fengjunjie <fengjunjie@btzh.cn>
     * @Description: jobrating_pc
     * @Date: 2022/04/18 16:26
     */
    import PageMixins from "../../components/JComponents/JPage/mixins/PageMixins";
    import PaginationMixins from "../../components/JComponents/JPage/mixins/PaginationMixins";
    import DrawerMixins from "../../components/JComponents/JPage/mixins/DrawerMixins";
    import DialogMixins from "../../components/JComponents/JPage/mixins/DialogMixins";
    export default {
        name: "IndividualReward",
        mixins: [PageMixins, PaginationMixins, DrawerMixins, DialogMixins],
        data () {
            return {
                query: {
                    scoreType: '',
                    scoreGreaterThan: '',
                    scoreLessThan: '',
                    cardCode: '',
                    orgId: '',
                    roleId: '',
                    status: '',
                    userMobile: '',
                    userName: '',
                    yearMonth: ''
                },
                roleList: [],
                roleMap: {},
                userList: [],
                showLeader: false,
                leaderData: [{title: ''}],
                leaderQuery: {
                    page: 1,
                    limit: 10,
                    total: 0,
                    userId: '',
                    yearMonth: ''
                },
                drmxTypeMap: {
                    'ADD': '评鉴',
                    'DET': '详情'
                },
                dgmxForm: {
                    "orgName": '',
                    "roleName": '',
                    "userName": '',
                    "month": '',
                    "orgId": '',
                    "roleId": '',
                    "score": '',
                    "scoreDiscripotion": '',
                    'userId': '',
                    "scoreType": '',
                    "scoreFile": '',
                    "year": ''
                },
                dgmxRule: {
                    score: [{ required: true, message: '请输入计分分数', trigger: 'blur' }],
                    userName: [{ required: true, message: '姓名不能为空', trigger: 'blur' }],
                    scoreType: [{ required: true, message: '请选择计分类型', trigger: 'change' }],
                    year: [{ required: true, message: '请选择考核月份', trigger: 'change' }],
                    roleId: [{ required: true, message: '请选择角色', trigger: 'change' }],
                    orgId: [{ required: true, message: '请选择所属机构', trigger: 'change' }],
                    userId: [{ required: true, message: '请选择用户', trigger: 'change' }]
                },
                personalRewardPunishment: {},
                userBasicInfo: {}
            }
        },
        created () {
            this.getRoleList()
            this.getUserList()
            this.load()
        },
        methods: {
          async getUserName () {
            if (!this.dgmxForm.orgName || !this.dgmxForm.roleName) return (this.dgmxForm.userName = '')
            const { data } = await this.$http.get(`/api/admins?orgId=${this.dgmxForm.orgId}&roleId=${this.dgmxForm.roleId}`)
            if (data.status !== 'yes') return this.$message.error(data.msg || data.message || '接口异常')
            const user = data.data.data[0] || {}
            this.dgmxForm.userName = user.name || user.userName
            this.dgmxForm.userId = user.adminId || user.userId
          },
          roleChange (id) {
            this.dgmxForm.roleName = this.roleMap[id] || ''
            this.getUserName()
          },
          changeNode () {
            this.$nextTick(() => {
              const node = this.$refs.org_cascader.getCheckedNodes()[0]
              if (node) {
                this.dgmxForm.orgName = node.label
              } else {
                this.dgmxForm.orgName = ''
              }
              this.getUserName()
            })
          },
            delEvent (row) {
                this.$confirm('您确认要删除当前记录吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http
                        .request({
                            method: 'delete',
                            url: `/api/rewardpunishment/${row.id}`
                        })
                        .then((res) => {
                            if (res.data.status !== 'yes') return this.$message.error(res.data.msg)
                            this.pageUpdate(this.query)
                            this.$message.success('删除成功')
                            this.load && this.load()
                        })
                })
            },
            async exportEvent () {
                const { data } = await this.$http.request({
                    method: 'get',
                    url: `/api/rewardpunishment/export`,
                    params: { ...this.query },
                    responseType: 'arraybuffer'
                })
                let url = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }))
                let link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                link.setAttribute('download', '个人奖惩导出')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link) // 下载完成移除元素
                window.URL.revokeObjectURL(url) // 释放掉blob对象
            },
            async load () {
                this.tableLoading = true
                const { data } = await this.$http.get(`/api/rewardpunishment/getList`, {
                    params: this.query
                })
                this.tableData = data.data.data
                this.query.total = data.data.total
                this.tableLoading = false
            },
            async getRoleList () {
                const { data } = await this.$http.get(`/api/roles`)
                // this.roleList = data.data.filter(item => {
                //     this.roleMap[item.roleId] = item.name
                //     if (item.name == '分管领导' || item.name == '科室负责人' || item.name == '科员') return true
                // })
              this.roleList = data.data || []
              this.roleList.forEach(item => {
                this.roleMap[item.roleId] = item.name
                // if (item.name == '分管领导' || item.name == '科室负责人' || item.name == '科员') return true
              })
            },
            async getUserList () {
                const { data } = await this.$http.get(`/api/admins`, {
                    params:  { page: 1, limit: 1000 }
                })
                this.userList = data.data.data
            },
            async getDetailData (row) {
                const { data } = await this.$http.get(`/api/rewardpunishment/${row.id}`)
                this.personalRewardPunishment = data.data.personalRewardPunishment || {}
                this.userBasicInfo = data.data.userBasicInfo || {}
            },
            async evaluateEvent (type, row) {
                this.drmxType = type
                this.curItem = { ...row }
                await this.getDetailData(row)
                this.showDrmx = true
            },
            async saveDgmxData () {
                if (this.submitLoad) return
                this.submitLoad = true
                let method = 'post'
                let url = `/api/rewardpunishment`
                const ym = this.dgmxForm.year.split('/')
                let year = ym[0]
                let month = ym[1]
                const { data } = await this.$http[method](url,  { ...this.dgmxForm, year, month })
                    .catch(({response}) => { this.$message.error(response.data.msg) })
                    .finally(() => { this.submitLoad = false })
                if (data.status !== 'yes') return this.$message.error(data.msg)
                this.showDgmx = false
                this.$message.success('提交成功')
                this.load()
            }
        },
        watch: {
            showLeader (nv) {
                if (!nv) {
                    this.leaderQuery = { ...this.$options.data().leaderQuery }
                }
            }
        }
    }
</script>

<style lang="less">
    .color-status-style {
        display: inline-flex;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        &.color-status1 {
            background: #C0C4CC;
        }
        &.color-status2 {
            background: #E6A23C;
        }
        &.color-status3 {
            background: #F56C6C;
        }
    }
</style>
