<template>
    <el-select style="width: 100%;" v-model="select" clearable filterable v-bind="$attrs" @change="selectEvent">
        <el-option v-for="option in options" :key="option[props['key']]" :label="option[props['value']]" :value="String(option[props['key']])" />
    </el-select>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import mixin from '../JDictionary/mixins'
export default {
  name: 'JDicSelect',
  mixins: [mixin],
  props: {
    code: {
      type: String,
      required: true
    },
    value: [String, Number, Array],
    props: {
      type: Object,
      default() { return { key: 'key', value: 'value' } }
    }
  },
  data () {
    return {
      select: '',
      options: []
    }
  },
  created() {
    this.select = String(this.value || '')
    if (this.hasDicStore()) return this.injectStore()
    this.applyLocalStorage()
  },
  methods: {
    selectEvent (v) {
      this.$emit('input', v)
    },
    async injectStore () {
      if (Vue.store.state.JDictionary.Dic[this.code]) {
        this.options = Vue.store.state.JDictionary.Dic[this.code] || []
      } else {
        const { dic } = await Vue.store.dispatch('JDictionary/getDictionaryByKey', this.code)
        this.options = dic || []
      }
    },
    async applyLocalStorage () {
      let dic = []
      if (this.hasLocalDic(this.code)) dic = this.getLocalStorage(this.code) || []
      else dic = await this.getDictionaryByKey(this.code)
      this.dic = dic
    },
    async getDictionaryByKey (key) {
      const { data } = await axios.get(`/api/dictionaryItems/${key}/items-parent-key`)
      if (data.status != 'yes') return console.error(`字典ID：${key}，字典获取异常`)
      this.setLocalStorage(data.data, key)
      return data.data || []
    },
    hasDicStore () {
      return Vue.store && Vue.store.state.JDictionary
    }
  },
  watch: { value (nv) { this.select = String(nv) } }
}
</script>
