<template>
    <j-page class="menu-manage-layout">
        <!--        按钮区域-->
        <div slot="search">
            <el-button type="warning" icon="el-icon-plus" @click="addEvent">新增一级菜单</el-button>
        </div>
        <!--        菜单树列表-->
        <div slot="table" class="menu-manage-tree" v-loading="loading">
            <el-tree :data="tree" :props="treeProps" node-key="treeId" ref="menuTreeDom" :default-expanded-keys="expanded">
                <span class="menu-tree-node" slot-scope="{ node, data }">
                    <span class="menu-tree-node-text">{{ data.name }}</span>
                    <!--  菜单地址或菜单代码描述（根节点无需显示）-->
                    <span v-if="data.parentId != 0" class="menu-tree-node-text">{{ data.address || data.code }}</span>
                    <span class="menu-tree-node-ctrl">
                        <template v-if="data.buttonId">
                            <el-button type="text" size="mini" @click.stop="() => editButton(node, data)">编辑</el-button>
                            <el-button type="text" class="common-btn-warn-style" size="mini" @click.stop="() => delButton(data)">删除</el-button>
                        </template>
                        <template v-else>
                            <el-button v-if="data.parentId == 0" type="text" size="mini" @click.stop="() => appendSubMenu(data, node)">添加二级菜单</el-button>
                            <el-button type="text" size="mini" @click.stop="() => addButton(data)">添加权限</el-button>
                            <el-button type="text" size="mini" @click.stop="() => editMenu(node, data)">编辑</el-button>
                            <el-button type="text" class="common-btn-warn-style" size="mini" @click.stop="() => delMenu(data)">删除</el-button>
                        </template>
                    </span>
                </span>
            </el-tree>
        </div>

        <!--        菜单弹窗-->
        <el-dialog :title="ctrlTitle[ctrlType]" :visible.sync="showAdd" width="400px" :close-on-click-modal="false" @keydown.native.enter="checkEvent('AddFormDom', saveMenu)">
            <el-form :model="formData" :rules="formRule" ref="AddFormDom" :label-width="formLabelWidth">
                <el-form-item v-if="ctrlType === 'SADD' || ctrlType === 'SEDIT'" label="一级菜单" prop="parentName">
                    <el-input v-model="formData.parentName" placeholder="一级菜单" readonly />
                </el-form-item>
                <el-form-item label="菜单名称" prop="name">
                    <el-input v-model="formData.name" placeholder="请输入菜单名称" :maxlength="40"/>
                </el-form-item>
                <el-form-item label="菜单地址" prop="address">
                    <el-input v-model="formData.address" placeholder="请输入菜单地址" :maxlength="100"/>
                </el-form-item>
                <!--                <el-form-item label="所属系统" prop="system">-->
                <!--                    <el-select v-model="formData.system" placeholder="请选择所属系统" clearable filterable style="width:100%">-->
                <!--                        <el-option v-for="(label,value) in systemType" :key="value" :label="label" :value="value"/>-->
                <!--                    </el-select>-->
                <!--                </el-form-item>-->
                <el-form-item label="菜单序号" prop="orderNo">
                    <el-input v-model="formData.orderNo" type="number" placeholder="请输入菜单序号" :maxlength="4"/>
                </el-form-item>
                <el-form-item label="菜单图标">
                    <el-input v-model="formData.icon" placeholder="菜单图标名称"/>
                </el-form-item>
            </el-form>
            <span slot="footer">
                <el-button type="primary" @click="checkEvent('AddFormDom', saveMenu)" :loading="submitLoad">保 存</el-button>
                <el-button @click="showAdd = false">取 消</el-button>
            </span>
        </el-dialog>

        <!--        权限弹窗-->
        <el-dialog :title="ctrlTitle[ctrlType]" :visible.sync="showButton" width="350px" :close-on-click-modal="false" @keydown.native.enter="checkEvent('AddButtonDom', saveButton)">
            <el-form :model="formDataButton" :rules="formRuleButton" ref="AddButtonDom" :inline="false">
                <el-form-item label="所属菜单">
                    <el-input :value="formDataButton.parentName" placeholder="所属菜单" readonly />
                </el-form-item>
                <el-form-item label="权限描述" prop="name">
                    <el-input v-model="formDataButton.name" placeholder="请输入名称" :maxlength="100"/>
                </el-form-item>
                <el-form-item label="权限代码（键）" prop="code">
                    <el-input v-model="formDataButton.code" placeholder="请输入权限代码（键）" :maxlength="30"/>
                </el-form-item>
                <el-form-item label="权限图标">
                    <el-input v-model="formDataButton.icon" placeholder="权限图标名称"/>
                </el-form-item>
            </el-form>
            <span slot="footer">
                <el-button type="primary" @click="checkEvent('AddButtonDom', saveButton)" :loading="submitLoad">保 存</el-button>
                <el-button @click="showButton = false">取 消</el-button>
            </span>
        </el-dialog>
    </j-page>
</template>

<script>
    import JPage from "../../../components/JComponents/JPage/JPage";
    export default {
        name: 'MenuManage',
        components: {JPage},
        data() {
            const validateAddress = (rule, value, callback) => {
                if (value === '' && rule.required) {
                    callback(new Error('请输入菜单地址'))
                // eslint-disable-next-line no-useless-escape
                } else if (value && !(/^(?![\._\-\:\d])[\.\-\:\w]+$/g.test(value))) {
                    callback(new Error('菜单地址以 - 分割,且不能以数字或特殊字符开头'))
                } else {
                    callback()
                }
            };
            return {
                loading: false,
                expanded: [], // 默认展开树层级
                menuMap: {},
                buttonMap: {},
                tree: [],
                treeProps: { children: 'children', label: 'name' },
                showAdd: false,
                formData: {
                    parentId: '',
                    parentName: '',
                    system: '',
                    name: '',
                    address: '',
                    orderNo: ''
                },
                formRule: {
                    name: [{ required: true, message: '请输入菜单名称', trigger: 'blur' }],
                    system: [{ required: true, message: '请选择所属系统', trigger: 'change' }],
                    address: [{ required: true, validator: validateAddress, trigger: ['blur', 'change'] }],
                    orderNo: [{ required: true, message: '请输入菜单序号', trigger: 'blur' }]
                },
                changeProxy: {},
                submitLoad: false,
                curItem: {}, // 当前选中项
                monitorChange: ['EDIT', 'SEDIT', 'BEDIT'], // 需要监听编辑改动变化的状态值
                ctrlType: 'ADD',
                formLabelWidth: '80px',
                ctrlTitle: { // 状态Map
                    'ADD': '新增一级菜单',
                    'EDIT': '编辑一级菜单',
                    'BADD': '新增权限',
                    'BEDIT': '编辑权限',
                    'SADD': '新增二级菜单',
                    'SEDIT': '编辑二级菜单'
                },
                showButton: false,
                formDataButton: {
                    parentName: '',
                    name: '',
                    menuId: '',
                    icon: '',
                    code: ''
                },
                formRuleButton: {
                    name: [{ required: true, message: '请输入权限名称', trigger: 'blur' }],
                    code: [{ required: true, message: '请输入权限代码（键）', trigger: 'blur' }]
                },
                systemType: {
                    COMMON: '通用',
                    WGGL: '网格管理',
                    JXKH: '绩效考核',
                }
            };
        },
        created () {
          this.buildTree()
        },
        methods: {
            // 获取数据初始化相关方法
            async buildTree () {
                this.loading = true
                this.menus = await this.getMenuList()
                this.buttons = await this.getButtonList()
                const menuMap = {}
                const buttonMap = {}
                // 根据id收集同组菜单
                this.buttons.forEach(item => {
                    if (!buttonMap[item.menuId]) buttonMap[item.menuId] = []
                    buttonMap[item.menuId].push({...item, treeId: `${item.menuId}${item.buttonId}` })
                })
                this.buttonMap = buttonMap
                this.menus.forEach(item => {
                    if (!menuMap[item.menuId]) menuMap[item.menuId] = []
                    menuMap[item.menuId].push(...(buttonMap[item.menuId] || []))
                    if (!menuMap[item.parentId]) menuMap[item.parentId] = []
                    menuMap[item.parentId].push({ ...item, [this.treeProps.children]: buttonMap[item.menuId], treeId: item.menuId })
                })
                this.menuMap = menuMap
                this.bindMenu()
                this.loading = false
            },
            // 绑定菜单子父级关系
            bindMenu () {
                const root = this.menuMap[0] || []
                root.forEach(item => item[this.treeProps.children] = this.menuMap[item.menuId])
                this.tree = root
            },
            // 获取按钮列表
            async getMenuList () {
                const { data } = await this.$http.get(`/api/menus`)
                if (data.status !== 'yes') return this.$message?.error(data.msg || '获取菜单异常')
                return data.data || []
            },
            // 获取按钮列表
            async getButtonList () {
                const { data } = await this.$http.get(`/api/buttons`)
                if (data.status !== 'yes') return this.$message?.error(data.msg || '获取按钮异常')
                return data.data || []
            },

            // 新增菜单相关方法
            addEvent () {
                this.ctrlType = 'ADD'
                this.formRule['address'][0].required = false
                this.formData.parentId = 0
                this.formData.orderNo = this.tree.length
                this.showAdd = true
            },
            appendSubMenu(data, node) {
                this.ctrlType = 'SADD'
                this.formRule['address'][0].required = true
                this.formData.parentName = data.name
                this.formData.system = data.system
                this.formData.parentId = data.menuId
                this.formData.orderNo = node.childNodes.length
                this.showAdd = true
            },
            editMenu (node, data) {
                this.curItem = { ...data }
                let row = { ...data }
                if (data.parentId == 0) {
                    this.ctrlType = 'EDIT'
                    this.formRule['address'][0].required = false
                } else {
                    this.ctrlType = 'SEDIT'
                    row.parentName = node.parent.data.name
                    this.formRule['address'][0].required = true
                }
                this.formData = this.proxyData({ ...this.formData, ...row })
                this.showAdd = true
            },
            delMenu (data) {
                this.$confirm('您确认要删除当前菜单吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http.delete(`/api/menus/${data.menuId}`).then(res => {
                        if (res.data.status !== 'yes') return this.$message.error(res.data.msg || '删除菜单异常')
                        this.$message.success('删除成功')
                        this.setCurrentKey(data.parentId)
                        this.buildTree()
                    })
                })
            },
            async saveMenu () {
                if (this.submitLoad) return
                this.submitLoad = true
                let method = 'post'
                let url = `/api/menus`
                if (this.ctrlType === 'EDIT' || this.ctrlType === 'SEDIT') {
                    url = `/api/menus/${this.curItem.menuId}`
                    method = 'put'
                }
                const { data } = await this.$http[method](url, this.formData).finally(() => {
                    this.submitLoad = false
                })
                if (data.status !== 'yes') return this.$message.error(data.msg || '菜单保存异常')
                this.showAdd = false
                this.$message.success('提交成功')
                this.buildTree()
                if (this.ctrlType === 'SADD' || this.ctrlType === 'SEDIT') {
                    this.setCurrentKey(this.formData.parentId)
                }
            },

            // 新增按钮权限相关方法
            addButton (data) {
                this.ctrlType = 'BADD'
                this.formDataButton.menuId = data.menuId
                this.formDataButton.parentName = data.name
                this.showButton = true
            },
            editButton (node, row) {
                this.ctrlType = 'BEDIT'
                this.curItem = { ...row }
                this.formDataButton = this.proxyData({ ...this.formDataButton, ...row, parentName: node.parent.data.name })
                this.showButton = true
            },
            delButton (data) {
                this.$confirm('您确认要删除当前权限吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http.delete(`/api/buttons/${data.buttonId}`).then(() => {
                        this.$message.success('删除成功')
                        this.buildTree()
                        this.setCurrentKey(data.menuId)
                    })
                })
            },
            async saveButton () {
                if (this.submitLoad) return
                this.submitLoad = true
                let method = 'post'
                let url = `/api/buttons`
                if (this.ctrlType === 'BEDIT') {
                    url = `/api/buttons/${this.curItem.buttonId}`
                    method = 'put'
                }
                const { data } = await this.$http[method](url, this.formDataButton).finally(() => {
                    this.submitLoad = false
                })
                if (data.status !== 'yes') return this.$message.error(data.msg || '按钮保存异常')
                this.showButton = false
                this.$message.success('提交成功')
                this.buildTree()
                this.setCurrentKey(this.formDataButton.menuId)
            },

            // 交互校验相关方法
            checkProxy () {
                if (!this.monitorChange.includes(this.ctrlType)) return true
                let flag = false
                for (let key in this.changeProxy) {
                    if (this.curItem[key] !== this.changeProxy[key]) {
                        flag = true
                        break
                    }
                }
                return flag
            },
            proxyData (obj) {
                this.changeProxy = {}
                const that = this
                if (!obj) return {}
                return new Proxy(obj, {
                    set (target, p, value, receiver) {
                        that.changeProxy[p] = value
                        return Reflect.set(target, p, value, receiver)
                    }
                })
            },
            checkEvent (target, funName) {
                if (!this.checkProxy()) return this.$message.info('数据未变动，无需重复保存')
                this.$refs[target].validate((valid) => {
                    if (valid) {
                        funName()
                    }
                })
            },
            setCurrentKey (key) {
                this.expanded = []
                this.expanded.push(key)
            }
        },
        watch: {
            showAdd (nv) {
                if (!nv) {
                    this.$refs['AddFormDom'] && this.$refs['AddFormDom'].resetFields()
                    this.formData = { ...this.$options.data().formData }
                }
            },
            showButton (nv) {
                if (!nv) {
                    this.$refs['AddButtonDom'] && this.$refs['AddButtonDom'].resetFields()
                    this.formDataButton = { ...this.$options.data().formDataButton }
                }
            }
        }
    };
</script>

<style lang="less">
    .menu-manage-layout {
        .el-tree-node__content {
            padding: 9px 0;
            background: #F1F5F6;
            border-radius: 6px;
        }
        .el-tree-node__children {
            position: relative;
            &:after {
                position: absolute;
                top: 4px;
                left: 12px;
                content: '';
                width: 3px;
                height: 100%;
                border-left: 1px solid #F1F5F6;
            }
            .el-tree-node__content {
                background: transparent;
                padding: 0;
                border-radius: 0;
                height: auto;
                .menu-tree-node {
                    display: inline-flex;
                    border-bottom: 1px solid #F1F5F6;
                    padding: 10px 0;
                    box-sizing: border-box;
                }
            }
            .el-tree-node__children {
                position: relative;
                &:after {
                    position: absolute;
                    top: 4px;
                    left: 32px;
                    content: '';
                    width: 3px;
                    height: 100%;
                    border-left: 1px solid #F1F5F6;
                }
                :first-child {
                    .menu-tree-node {
                        border-radius: 5px;
                    }
                }
                .el-tree-node__content {
                    .menu-tree-node {
                        padding-left: 20px;
                        border-bottom: 1px solid #F1F5F6;
                        background: rgba(0, 185, 220, 0.08);
                    }
                }
                .el-tree-node {
                    padding: 0;
                }
            }
        }
        .el-tree-node {
            padding-top: 6px;
        }
        .menu-manage-tree {
            height: 100%;
        }
        .menu-tree-node {
            display: flex;
            width: 100%;
            justify-content: space-between;
            > span {
                flex: 1;
                font-size: 14px;
                color: #909399;
            }
            .menu-tree-node-text {
                display: flex;
                align-items: center;
                min-width: 110px;
                word-break: keep-all;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            .menu-tree-node-ctrl {
                display: flex;
                width: 200px;
                justify-content: flex-end;
                padding-right: 10px;
            }
        }
    }
</style>
