<template>
    <j-page>
        <div slot="search">
            <j-page-search title="字典名称">
                <el-input v-model="query.name" @keydown.native.enter="searchEvent"  placeholder="请输入字典名称" clearable></el-input>
            </j-page-search>
            <el-button type="primary" @click="searchEvent">查询</el-button>
            <el-button type="warning" @click="addEvent">添加字典</el-button>
        </div>
        <div slot="table">
            <el-table :data="ListData" v-loading="tabLoading" height="100%">
                <el-table-column prop="value" label="字典名称" show-overflow-tooltip />
                <el-table-column prop="key" label="字典代码" show-overflow-tooltip />
                <el-table-column prop="description" label="字典说明" width="400" show-overflow-tooltip />
                <el-table-column label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button @click.stop="editEvent(scope.row)" type="primary"  class="el-button el-button--text">编辑</el-button>
                        <el-button @click.stop="delEvent(scope.row)" type="primary"  class="el-button el-button--text font-red" >删除</el-button>
                        <el-button @click.stop="configEvent(scope.row)" type="primary"  class="el-button el-button--text">字典项配置</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div slot="pagination" v-show="query.total > 0">
            <el-pagination
                    :current-page="query.page"
                    :page-size="query.limit"
                    :total="query.total"
                    :background="pmxBackground"
                    :pager-count="pmxCount"
                    :layout="pmxLayout"
                    :pageSizes="pmxSizes"
                    @current-change="changePage"
                    @size-change="sizeChange"
            />
        </div>

        <!--        新增修改弹窗-->
        <j-drawer :title="type === 'ADD' ? '新建字典' : '编辑字典'" :close.sync="showAdd">
            <j-drawer-label type="title" title="字典信息" />
            <div style="padding: 20px 20px 0 0;">
                <el-form ref="formDom" :model="AddForm" :rules="RuleAddForm" :label-width="formLabelWidth" class="cust-class">
                    <el-form-item  label="字典名称" prop="value">
                        <el-input v-model="AddForm.value" placeholder="请输入字典名称" :maxlength="100" clearable></el-input>
                    </el-form-item>
                    <el-form-item  label="字典代码" prop="key">
                        <el-input v-model="AddForm.key" placeholder="请输入字典代码" :maxlength="100" clearable></el-input>
                    </el-form-item>
                    <el-form-item  label="字典描述" prop="description">
                        <el-input v-model="AddForm.description" type="textarea" :rows="3" placeholder="请输入字典描述" maxlength="200" show-word-limit clearable />
                    </el-form-item>
                </el-form>
            </div>
            <el-button slot="footer" @click="submitEvent">确定</el-button>
        </j-drawer>
        <!--        字典项配置弹窗-->
        <j-drawer :title="curItem.value || '字典项配置'" :close.sync="showConfig">
            <div style="padding: 10px 20px;">
                <el-button type="primary" plain icon="el-icon-plus" @click="addConfig">添加字典项</el-button>
                <el-table style="margin-top: 10px;" :data="configList" >
                    <el-table-column prop="value" label="字典项名称" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="key" label="字典项代码" show-overflow-tooltip></el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button @click.stop="editConfig(scope.row)" type="primary"  class="el-button el-button--text">编辑</el-button>
                            <el-button @click.stop="delConfig(scope.row)" type="primary"  class="el-button el-button--text font-red" >删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </j-drawer>

        <el-dialog custom-class="custom-dialog" :close-on-click-modal="false" width="400px" :title="subType === 'ADD' ? '新建字典项' : '编辑字典项'" :visible.sync="showDialog" @close="resetForm">
            <el-form ref="dialogForm" :model="form" :rules="rules">
                <el-form-item  label="字典项名称" prop="value">
                    <el-input v-model="form.value" placeholder="请输入字典项名称" :maxlength="20" clearable/>
                </el-form-item>
                <el-form-item  label="字典项代码" prop="key">
                    <el-input v-model="form.key" placeholder="请输入字典项代码" :maxlength="100" clearable />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="saveEvent" :loading="submitLoad">确 定</el-button>
                <el-button type="warning" @click="closeEvent">关闭</el-button>
            </div>
        </el-dialog>
    </j-page>
</template>

<script>
import PageMixins from "@/components/JComponents/JPage/mixins/PageMixins";
import DrawerMixins from "@/components/JComponents/JPage/mixins/DrawerMixins";
import PaginationMixins from "@/components/JComponents/JPage/mixins/PaginationMixins";
export default {
  name: 'DictionaryManage',
  mixins: [ PageMixins, DrawerMixins, PaginationMixins  ],
  data () {
    return {
      type: 'ADD',
      subType: 'ADD',
      showConfig: false,
      showDialog: false,
      tabLoading: false,
      showAdd: false,
      formLabelWidth: '100px',
      query: {
        name: '',
        page: 1,
        limit: 10,
        total: 0
      },
      ListData: [],
      configList: [],
      subItem: null,
      size: 'mini',
      AddForm: {
        key: '',
        value: '',
        description: ''
      },
      RuleAddForm: {
        value: [{ required: true, message: '请输入字典名称', trigger: 'blur' }],
        key: [{ required: true, message: '请输入字典代码', trigger: 'blur' }]
      },
      rules: {
        value: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        key: [{ required: true, message: '请输入代码', trigger: 'blur' }]
      },
      form: {
        parentId: '',
        parentKey: '',
        'key': '',
        'value': ''
      }
    }
  },
  created () {
    this.load()
  },
  methods: {
    // 更新缓存字典
    updateStoreDir (key) {
        this.$store.state.JDictionary && this.$store.state.JDictionary.Dir && this.$store.state.JDictionary.Dir[key] && this.$store.dispatch('JDictionary/getDictionaryByKey', key)
    },
    async loadDirList () {
      const { data } = await this.$http.get(`/api/dictionaryItems/${this.curItem.dictionaryId}/items`)
      this.configList = data.data
      this.showConfig = true
    },
    async configEvent (row) {
      this.curItem = row
      this.loadDirList()
    },
    editConfig (row) {
      this.subType = 'EDIT'
      this.subItem = row
      this.form = { ...row }
      this.showDialog = true
    },
    delConfig (row) {
      this.$confirm('您确认要删除当前项吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http
          .request({
            method: 'delete',
            url: `/api/dictionaryItems/${row.dictionaryItemId}`
          })
          .then(() => {
            this.$message.success('删除成功')
            this.loadDirList()
            this.updateStoreDir(this.curItem.key)
          })
      })
    },
    addConfig () {
      this.subType = 'ADD'
      this.form.parentId = this.curItem.dictionaryId
      this.form.parentKey = this.curItem.key
      this.showDialog = true
    },
    closeEvent () {
      this.showDialog = false
      this.$refs['dialogForm'] && this.$refs['dialogForm'].resetFields()
    },
    resetForm () {
      this.$refs['dialogForm'] && this.$refs['dialogForm'].resetFields()
    },
    async load () {
      this.tabLoading = true
      const { data } = await this.$http.get(`/api/dictionaries`, {
        params: this.query
      })
      this.ListData = data.data.data
      this.query.total = data.data.total
      this.tabLoading = false
    },
    addEvent () {
      this.type = 'ADD'
      this.showAdd = true
    },
    saveEvent () {
      this.$refs['dialogForm'].validate((valid) => {
        if (valid) {
          this.saveSubData()
        }
      })
    },
    editEvent (row) {
      this.type = 'EDIT'
      this.curItem = row
      this.AddForm = { ...row }
      this.showAdd = true
    },
    delEvent (row) {
      this.$confirm('您确认要删除当前记录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http
          .request({
            method: 'delete',
            url: `/api/dictionaries/${row.dictionaryId}`
          })
          .then(() => {
            let delPage = Math.ceil((this.total - 1) / this.limit)
            if (this.query.page > delPage) this.query.page = delPage
            this.$message.success('删除成功')
            this.load()
            this.updateStoreDir(row.key)
          })
      })
    },
    submitEvent () {
      this.$refs.formDom.validate((valid) => {
        if (valid) {
          this.saveData()
        }
      })
    },
    async saveData () {
      if (this.submitLoad) return
      this.submitLoad = true
      let method = 'post'
      let url = `/api/dictionaries`
      if (this.type === 'EDIT') {
        url = `/api/dictionaries/${this.curItem.dictionaryId}`
        method = 'put'
      }
      const { data } = await this.$http[method](url, this.AddForm).finally(() => {
        this.submitLoad = false
      })
      if (data.status !== 'yes') return this.$message.error(data.msg)
      this.showAdd = false
      this.$message.success('提交成功')
      this.load()
      this.updateStoreDir(this.AddForm.key)
    },
    async saveSubData () {
      if (this.submitLoad) return
      this.submitLoad = true
      let method = 'post'
      let url = `/api/dictionaryItems`
      if (this.subType === 'EDIT') {
        url = `/api/dictionaryItems/${this.subItem.dictionaryItemId}`
        method = 'put'
      }
      const { data } = await this.$http[method](url, this.form).finally(() => {
        this.submitLoad = false
      })
      if (data.status !== 'yes') return this.$message.error(data.msg)
      this.showDialog = false
      this.$message.success('提交成功')
      this.loadDirList()
      this.updateStoreDir(this.curItem.key)
    }
  },
  watch: {
    showAdd (nv) {
      if (!nv) {
        this.$refs['formDom'] && this.$refs['formDom'].resetFields()
        this.AddForm = { ...this.$options.data().AddForm }
      }
    },
    showDialog (nv) {
      if (!nv) {
        this.$refs['dialogForm'] && this.$refs['dialogForm'].resetFields()
        this.form = { ...this.$options.data().form }
      }
    }
  }
}
</script>
