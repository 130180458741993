/*
 * @Description: 
 * @Author: tengmingxin
 * @Date: 2022-04-19 09:53:17
 * @LastEditors: tengmingxin
 * @LastEditTime: 2022-04-19 18:24:46
 */

import Vue from "vue"

/**
 * @msg: 分页查询(民主测评-待测评)
 * @param {Object} params
 */
export const getIncompleteTaskList = (params) => {
    return Vue.http({ url: '/api/democraticevaluation/getIncompleteTaskList', method: 'get', params })
}

/**
 * @msg: 分页查询(民主测评-已测评)
 * @param {Object} params
 */
export const getCompletedTaskList = (params) => {
    return Vue.http({ url: '/api/democraticevaluation/getCompletedTaskList', method: 'get', params })
}

/**
 * @msg: 民主测评-标签页数字提示
 */
export const getLabelNumber = () => {
    return Vue.http({ url: '/api/democraticevaluation/getLabelNumber', method: 'get' })
}

/**
 * @msg: 测评任务-查看明细
 * @param {Number} id
 */
export const getTaskDetail = (id) => {
    return Vue.http({ url: `/api/democraticevaluation/${id}`, method: 'get' })
}

/**
 * @msg: 测评台账-查看明细
 * @param {Number} userId 用户id
 * @param {String} yearMonth 年月
 */
export const getLedgerDetail = ({ userId, yearMonth }) => {
    return Vue.http({ url: '/api/democraticevaluation/getStandingBookDetailInfo', method: 'get', params: { userId, yearMonth } })
}
/**
 * @msg: 测评台账-分页查询
 * @param {Object} params
 */
export const getLedgerList = (params) => {
    return Vue.http({ url: '/api/democraticevaluation/getStandingBookList', method: 'get', params })
}

/**
 * @msg: 测评台账-分页查询
 * @param {String} evaluateDiscripotion
 * @param {Number} evaluateLevel
 * @param {Number} id
 */
export const editEvaluate = ({ evaluateDiscripotion, evaluateLevel, id }) => {
    return Vue.http({ url: '/api/democraticevaluation', method: 'put', data: { evaluateDiscripotion, evaluateLevel, id } })
}

/**
 * @msg: 民主测评进度-分页查询
 * @param {Object} params
 */
export const getProgressList = (params) => {
    return Vue.http({ url: '/api/democraticevaluation/getCompleteProportion', method: 'get', params })
}