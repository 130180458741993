import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 引入配置
import { api, title } from './config'

// 引入工具
import tool from './utils'

// 引入ui组件依赖
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// sso单点登录
import singleLogin from "./btzh/singleLogin"

// 动态菜单页面组件引入
import register from "./views/register";

// 引入插件拓展
import http from "./extend/Axios/http";
import ElUploadExtend from "./extend/ElUpload/ElUploadExtend";

// 全局引入svg组件
import '@/svg';
import JSvgIcon from "./components/JComponents/JSvgIcon/JSvgIcon";

// 引入样式集合
import '@/style/index.less'

document.title = title

Vue.component('JSvgIcon', JSvgIcon);

Vue.use(ElementUI, { size: 'middle', zIndex: 3000 });
Vue.use(http, { api })
Vue.use(tool)
Vue.use(register)
Vue.component(ElUploadExtend.name, ElUploadExtend)

Vue.config.productionTip = false

Vue.store = store
Vue.router = router

Vue.use(singleLogin, async isSingleLogin => {
  let adminJson = sessionStorage.getItem('biz_admin')
  if (adminJson) {
    store.commit('user', JSON.parse(adminJson))
  } else if (!isSingleLogin) {
    router.replace('/login')
  }

  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})
