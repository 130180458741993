<template>
  <j-page>
    <div slot="search">
      <el-form v-if="showSelector" :inline="true" :model="form">
        <el-form-item label="考核机构">
          <el-cascader
            v-model="form.id"
            :options="$store.state.orgTree"
            :props="{
              children: 'childrens',
              label: 'name',
              value: 'id',
              emitPath: false,
            }"
            placeholder="请选择"
            clearable
            @change="changeCascader"
          />
        </el-form-item>
        <!-- <el-form-item>
        <el-button type="primary">查询</el-button>
        <el-button>重置</el-button>
      </el-form-item> -->
      </el-form>
    </div>
    <div slot="table">
      <el-table
        :data="tableData"
        style="width: 100%"
        @expand-change="getFileInfo"
        :row-key="(row) => row.id"
        :expand-row-keys="expands"
        v-loading="tableLoading"
      >
        <el-table-column align="center" type="expand">
          <template slot-scope="props">
            <el-row :gutter="20" class="content-container">
              <el-col :span="12" class="border-line">
                <el-row :gutter="20">
                  <el-col :span="24">
                    <span class="label">考核机构</span>
                    <span class="content">{{ props.row.orgName }}</span>
                  </el-col>
                  <el-col :span="12">
                    <span class="label">计分类型</span>
                    <span class="text-status">{{ props.row.score > 0 ? "加分" : "减分" }}</span>
                  </el-col>
                  <el-col :span="12">
                    <span class="label">计分分数</span>
                    <span class="text-status">{{ props.row.score }}</span>
                  </el-col>
                  <el-col :span="24">
                    <span class="label">计分说明</span>
                    <span class="long-content">{{ props.row.scoreNote }}</span>
                  </el-col>
                  <el-col :span="24" v-if="props.row.imgList" class="file-col">
                    <span class="label">图片</span>
                    <span class="fileList-container">
                      <j-upload
                        v-model="props.row.imgList"
                        type="String"
                        list-type="picture-card"
                        need-fully
                        :disabled="true"
                      >
                        <i class="el-icon-plus" style="font-size: 18px"></i>
                      </j-upload>
                    </span>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="12">
                <el-row :gutter="20">
                  <el-col :span="24">
                    <span class="label">考核人</span>
                    <span>{{ props.row.createUserName }}</span>
                    <el-button
                      type="warning"
                      size="mini"
                      class="btn-del"
                      @click="deleteRow(props.row)"
                      icon="el-icon-delete"
                    ></el-button>
                  </el-col>
                  <!-- :disabled="!isExpired(props.row.createTime)" -->
                  <el-col :span="24">
                    <span class="label">考核时间</span>
                    <span>{{ props.row.createTime }}</span>
                  </el-col>
                  <el-col
                    :span="24"
                    v-if="props.row.fileList"
                    class="file-col-right"
                  >
                    <span class="label">附件</span>
                    <span class="fileList-container">
                      <j-upload
                        v-model="props.row.fileList"
                        type="String"
                        list-type="text"
                        need-fully
                        :disabled="true"
                      >
                      </j-upload
                    ></span>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column align="center" label="日期" prop="createTime">
        </el-table-column>
        <el-table-column align="center" label="考核人" prop="createUserName">
        </el-table-column>
        <el-table-column align="center" label="考核机构" prop="orgName">
        </el-table-column>
        <el-table-column align="center" label="计分类型">
          <template slot-scope="scope">
            <span>{{ scope.row.score > 0 ? "加分" : "减分" }}</span>
          </template></el-table-column
        >
        <el-table-column align="center" label="计分分数" prop="score">
        </el-table-column>
      </el-table>
    </div>
    <div slot="pagination" v-show="pageQuery.total > pageQuery.limit">
      <el-pagination
        :current-page="pageQuery.page"
        :page-size="pageQuery.limit"
        :total="pageQuery.total"
        :background="pmxBackground"
        :pager-count="pmxCount"
        :layout="pmxLayout"
        :pageSizes="pmxSizes"
        @current-change="changePage"
      />
    </div>
  </j-page>
</template>

<script>
import moment from "moment";

import PageMixins from "@/components/JComponents/JPage/mixins/PageMixins";
import PaginationMixins from "@/components/JComponents/JPage/mixins/PaginationMixins";

import JUpload from "@/components/JComponents/JUpload/components/JUpload.vue";
import { getDailysDetailOfFile } from "@/api/dailyInspection";

import { getDailysDetail, deleteBisDailysOrg } from "@/api/dailyInspection";

export default {
  name: "CheckedDetail",
  mixins: [PageMixins, PaginationMixins],
  components: {
    JUpload,
  },
  props: {
    showSelector: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dailyId: "",
      tableLoading: false,
      form: {
        id: "",
      },
      tableData: [],
      expands: [],
      pageQuery: {
        page: 1,
        limit: 10,
        total: 0,
      },
    };
  },
  methods: {
    // 获取考核明细数据
    async onGetDailysDetail({ dailyId, orgId }) {
      this.tableLoading = true;
      // 存储任务id
      if (!this.dailyId) {
        this.dailyId = dailyId;
      }
      try {
        const { data } = await getDailysDetail({
          dailyId,
          orgId,
          ...this.pageQuery,
        });
        this.tableData = data.data.data;
        this.pageQuery.total = data.data.total;
      } catch (error) {
        console.error(error);
      }
      this.tableLoading = false;
    },
    changePage(page) {
      this.pageQuery.page = page;
      this.onGetDailysDetail({ dailyId: this.dailyId });
    },
    changeCascader(id) {
      if (!id) this.pageQuery.page = 1;
      this.onGetDailysDetail({ dailyId: this.dailyId, orgId: id });
    },
    // 删除考核机构
    async onDeleteBisDailysOrg(id) {
      try {
        const { data } = await deleteBisDailysOrg(id);
        if (data.code === 200) {
          this.$message.success("删除成功");
          this.onGetDailysDetail({ dailyId: this.dailyId });
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 删除任务
    deleteRow(row) {
      this.$confirm("确定删除该任务及其绑定机构的考核明细?", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.onDeleteBisDailysOrg(row.id);
        })
        .catch(() => {});
    },
    // 获取添加的文件信息
    async getFileInfo(row) {
      try {
        const {
          data: { data },
        } = await getDailysDetailOfFile(row.id);
        const { files } = data;
        const { tableData } = this;

        let imgList = [];
        let fileList = [];
        for (let key in files) {
          files[key].fileType === 1
            ? imgList.push(files[key].fileUrl)
            : fileList.push(files[key].fileUrl);
        }
        data.imgList = imgList.join();
        data.fileList = fileList.join();
        const newTableData = tableData.map((item) => {
          if (item.id === data.id) return { ...data, ...item };
          return item;
        });
        this.tableData = newTableData;
      } catch (error) {
        console.error(error);
      }
    },
    // 判断考核是否过期 当前时间月份在考核时间月份后 算作过期
    isExpired(time) {
      const yearMonths = moment(time).startOf("month").valueOf();
      const yearMonthsNow = moment(new Date()).startOf("month").valueOf();
      return yearMonthsNow > yearMonths ? false : true;
    },
  },
};
</script>

<style lang="less" scoped>
.text-status {
  font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
  font-weight: bold;
  color: #fa8f31;
}
.content-container {
  padding: 12px;
  min-height: 120px;
  border-radius: 4px;
  background: rgba(0, 185, 220, 0.08);
  .file-col {
    display: flex;
    .fileList-container {
      width: 330px;
      position: relative;
      /deep/.el-upload {
        display: none;
      }
    }
  }
  .file-col-right {
    display: flex;
    .fileList-container {
      width: 250px;
      position: relative;
      /deep/.el-upload-list__item {
        margin-top: 3px;
      }
      /deep/.el-upload {
        display: none;
      }
    }
  }
  span {
    min-height: 30px;
    margin-right: 10px;
    line-height: 30px;
    display: inline-block;
  }
  .label {
    width: 70px;
    text-align: right;
  }
  .long-content {
    width: 280px;
    min-height: 30px;
    vertical-align: top;
  }
  .btn-del {
    float: right;
    background: rgb(254, 105, 61);
    padding: 7px;
  }
}
</style>