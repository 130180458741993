<template>
  <j-page>
    <!-- 搜索表单控件 -->
    <div slot="search">
      <j-page-search title="考核月份">
        <el-date-picker
          v-model="searchForm.yearMonths"
          type="month"
          format="yyyy 年 MM 月"
          value-format="yyyy/MM"
          placeholder="选择考核月份"
        />
      </j-page-search>
      <j-page-search title="所属机构">
        <el-cascader
          v-model="searchForm.orgIds"
          :options="$store.state.orgTree"
          :props="{
            children: 'childrens',
            label: 'name',
            value: 'id',
            emitPath: false,
            multiple: true,
          }"
          placeholder="请选择所属机构"
          clearable
        />
      </j-page-search>
      <j-page-search title="类别">
        <el-cascader
          v-model="searchForm.orgTypeIds"
          :options="orgTypeOptions"
          :props="{
            children: 'deptTypeChilds',
            label: 'deptTypeName',
            value: 'deptTypeId',
            emitPath: false,
            multiple: true,
          }"
          placeholder="请选择类别"
          clearable
        ></el-cascader>
      </j-page-search>
      <j-page-search title="总分">
        <j-link-input
          style="width: 240px"
          :start.sync="searchForm.scoreTotalFinalStart"
          :end.sync="searchForm.scoreTotalFinalEnd"
        />
      </j-page-search>
      <el-button type="primary" @click="onGetBisDailyLedgers">搜索</el-button>
      <el-button type="primary" plain @click="resetSearchForm">重置</el-button>
      <el-button type="primary" plain @click="onExportAccount">导出</el-button>
    </div>
    <!-- 日常任务表格信息 -->
    <div slot="table">
      <el-table v-loading="tableLoading" :data="tableData" height="100%">
        <el-table-column label="序号" type="index" width="50">
        </el-table-column>
        <el-table-column
          prop="yearMonths"
          label="考核月份"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="orgName"
          label="所属机构"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="orgTypeId"
          label="类别"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ getLabel(orgTypeOptions, scope.row.orgTypeId) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="calibration"
          label="校准值"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="countDaily"
          label="日常任务数量"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="scoreTotalEmphasiswork"
          label="累计得分重点工作"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="scoreTotalBasework"
          label="累计得分基础工作"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="scoreTotalOthwork"
          label="累计得分其他"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="scoreTotalFinal2"
          label="总分"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span class="text-status">{{ scope.row.scoreTotalFinal2 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="title"
          label="操作"
          align="center"
          width="120px"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-button
              @click.native.prevent="showDetail(scope.row)"
              type="text"
              size="small"
            >
              查看
            </el-button>
            <el-button
              @click.native.prevent="showTaskDetail(scope.row)"
              type="text"
              size="small"
            >
              任务明细
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页器 -->
    <div slot="pagination" v-show="pageQuery.total > pageQuery.limit">
      <el-pagination
        :current-page="pageQuery.page"
        :page-size="pageQuery.limit"
        :total="pageQuery.total"
        :background="pmxBackground"
        :pager-count="pmxCount"
        :layout="pmxLayout"
        :pageSizes="pmxSizes"
        @current-change="changePage"
      />
    </div>
    <!-- 查看 弹框 -->
    <j-drawer title="查看" :close.sync="isShowDetail">
      <div>
        <div style="padding-right: 30px">
          <org-info :orgInfo="orgDetailData" />
          <checked-info :cd="showTaskDetail" :checkedInfo="checkedInfo" />
        </div>
      </div>
      <!-- <div slot="footer" style="display: flex; width: 100%">
        <el-button type="primary">保存</el-button>
      </div> -->
    </j-drawer>
    <!-- 任务明细 弹框 -->
    <el-dialog
      custom-class="dialog-style"
      v-if="isShowTaskDetail"
      title="任务明细"
      :visible.sync="isShowTaskDetail"
      width="1600px"
      top="5%"
      :close-on-click-modal="false"
    >
      <account-detail ref="accountDetail" />
    </el-dialog>
  </j-page>
</template>

<script>
import PageMixins from "../../components/JComponents/JPage/mixins/PageMixins";
import PaginationMixins from "../../components/JComponents/JPage/mixins/PaginationMixins";
import AccountDetail from "../dailyInspection/components/AccountDetail.vue";
import OrgInfo from "../dailyInspection/components/OrgInfo.vue";
import CheckedInfo from "../dailyInspection/components/CheckedInfo.vue";

import JDrawer from "@/components/JComponents/JDrawer/JDrawer";

import {
  getBisDailyLedgers,
  exportAccount,
  getAccountDetail,
  getOrgDetail,
  getListDeptTypeTree,
} from "@/api/dailyInspection";

export default {
  name: "CheckingAccount",
  mixins: [PageMixins, PaginationMixins],
  components: {
    AccountDetail,
    JDrawer,
    OrgInfo,
    CheckedInfo,
  },
  data() {
    return {
      isShowNewTask: false,
      isShowImport: false,
      isShowDetail: false,
      isShowTaskDetail: false,
      tableData: [],
      pageQuery: {
        page: 1,
        limit: 10,
        total: 0,
      },
      searchForm: {
        yearMonths: "",
      },
      taskDetail: [],
      orgTypeOptions: [],
      orgDetailData: {},
      checkedInfo: {},
    };
  },
  created() {
    // this.load()
    this.onGetListDeptTypeTree();
    this.onGetBisDailyLedgers();
  },
  methods: {
    // 获取台账数据列表
    async onGetBisDailyLedgers() {
      try {
        const { searchForm, pageQuery } = this;
        const params = { ...searchForm, ...pageQuery };
        if (searchForm.orgIds) params.orgIds = searchForm.orgIds.join();
        if (searchForm.orgTypeIds)
          params.orgTypeIds = searchForm.orgTypeIds.join();
        const { data } = await getBisDailyLedgers(params);
        if (data.code === 200) {
          this.tableData = data.data.data;
          this.pageQuery.total = data.data.total;
        }
      } catch (error) {
        console.error(error);
      }
    },
    changePage(page) {
      this.pageQuery.page = page;
      this.onGetBisDailyLedgers();
    },
    // 重置查询参数
    resetSearchForm() {
      for (let key in this.searchForm) {
        this.searchForm[key] = "";
      }
      this.onGetBisDailyLedgers();
    },
    // 导出文件
    async onExportAccount() {
      const { data } = await this.$http.request({
        method: "get",
        url: `/api/bisDailyLedgers/export`,
        params: {},
        responseType: "arraybuffer",
      });
      let url = window.URL.createObjectURL(
        new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", "考核台账数据");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // 下载完成移除元素
      window.URL.revokeObjectURL(url); // 释放掉blob对象
    },
    async getDetail(row) {
      try {
        const { data } = await getOrgDetail(row.orgId);
        this.orgDetailData = data.data;
        this.checkedInfo = row;
      } catch (error) {
        console.error(error);
      }
    },
    // 考核-按钮回调
    showDetail(row) {
      this.getDetail(row);
      this.isShowDetail = !this.isShowDetail;
    },
    // 任务明细-按钮回调 itemId
    showTaskDetail(row, itemId) {
      this.isShowTaskDetail = !this.isShowTaskDetail;
      this.$nextTick(() => {
        this.$refs.accountDetail.onGetAccountDetail(row.id, itemId);
      });
    },
    // 获取机构类别
    async onGetListDeptTypeTree() {
      try {
        const { data } = await getListDeptTypeTree();
        this.orgTypeOptions = data.data;
      } catch (error) {
        console.error(error);
      }
    },
    // 获取label值
    getLabel(options, value) {
      let flatObj = [];
      function query(options) {
        options.forEach((item) => {
          flatObj[item.deptTypeId] = item.deptTypeName;
          if (item.deptTypeChilds) {
            query(item.deptTypeChilds);
          }
        });
      }
      query(options);
      return flatObj[value];
    },
  },
};
</script>
<style lang="less" scoped>
.text-status {
  font-family: TeXGyreAdventor-Bold, TeXGyreAdventor;
  font-weight: bold;
  color: #fa8f31;
}
.daily-task-form {
  /deep/input {
    width: 200px;
  }
}
.new-task-form,
.import-form {
  /deep/input {
    width: 300px;
  }
}
/deep/.dialog-style {
  border-radius: 24px;
  .el-dialog__body {
    height: 600px !important;
    .nad-tree {
      margin-left: 20px;
      flex: 1;
      > p {
        font-size: 16px;
        margin-bottom: 10px;
      }
      .el-tree-node__content {
        height: 32px;
      }
    }
    .input-tags {
      border: 1px solid #dcdfe6;
      padding: 5px 10px;
      min-height: 21px;
      border-radius: 4px;
      .el-tag {
        margin-right: 5px;
      }
    }
  }
}
</style>