<template>
  <div class="check-status-container">
    <j-drawer-label type="title" title="考核情况" />
    <el-table :data="orgsStatis" style="width: 100%" @row-click="onRowClick">
      <el-table-column prop="orgName" label="考核机构"> </el-table-column>
      <el-table-column prop="scoreAddTotal" label="累计加分"> </el-table-column>
      <el-table-column prop="scoreReduceTotal" label="累计减分">
      </el-table-column>
      <el-table-column prop="scoreFinal" label="总分"> </el-table-column>
    </el-table>
  </div>
</template>

<script>
import JDrawerLabel from "@/components/JComponents/JDrawer/JDrawerLabel";

export default {
  name: "CheckStatusList",
  components: {
    JDrawerLabel,
  },
  props: {
    orgsStatis: {
      type: Array,
    },
    cb: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {
    onRowClick(row) {
      this.cb(row);
    },
  },
};
</script>

<style lang="less" scoped>
.check-status-container {
  .check-title {
    margin-bottom: 20px;
  }
}
</style>