<template>
    <div class="j-label-wrap" :style="`display: ${ inline ? 'inline-flex' : 'flex' };`">
        <label v-if="title" class="j-label-wrap_label" :style="`width: ${labelWidth}px;text-align: ${align}`" :title="title">{{title}}</label>
        <div class="j-label-wrap_content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
  name: 'JLabel',
  props: {
    title: {
      type: [String, Number],
      default: ''
    },
    inline: { // 行内模式，按多个label宽度平均分多余部分自动换行
      type: Boolean,
      default: false
    },
    labelWidth: { // 文本字符占用宽度，超出部分有省略号
      type: Number,
      default: 70
    },
    align: { // [left, center, right ]
      type: String,
      default: 'left'
    }
  }
}
</script>

<style lang="less">
    .j-label-wrap {
        align-items: flex-start;
        min-width: 120px;
        vertical-align: bottom;
        padding: 5px 10px 5px 0;
        .j-label-wrap_label {
            font-size: 14px;
            color: #909399;
            padding-right: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            box-sizing: border-box;
        }
        .j-label-wrap_content {
            flex: 1;
            min-width: 10px;
            word-break: break-all;
            font-size: 14px;
            color: #303133;
            &:empty {
                display: flex;
                height: 100%;
                width: 100%;
                &::before {
                    content: '暂无数据';
                    font-weight: lighter;
                    color: #C0C4CC;
                }
            }
        }
    }
</style>
