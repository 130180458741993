<!--
 * @Description: 待办
 * @Author: tengmingxin
 * @Date: 2022-04-14 14:28:34
 * @LastEditors: tengmingxin
 * @LastEditTime: 2022-04-28 11:39:34
-->
<template>
  <div class="common-page-layout organ-manage-layout backlog-task">
    <div class="organ-manage-layout-left">
      <div class="common-tab-style">
        <div
          v-for="(item, index) in tabs"
          :key="index"
          :class="[
            'common-tab-item-style',
            activeKey === index ? 'active' : '',
          ]"
          @click="activeKey = index"
        >
          <div>{{ item.value || 0 }}</div>
          <div>{{ item.name }}</div>
        </div>
      </div>
      <div class="components-container">
        <keep-alive>
          <democracy-task
            formbacklog
            v-if="tabs.democracy && activeKey === 'democracy'"
            @setTotal="(e) => (tabs.democracy.value = e)"
          />
          <wait-list
            formbacklog
            v-if="tabs.criticism && activeKey === 'criticism'"
            @setTotal="(e) => (tabs.criticism.value = e)"
          />
          <daily-task
            formbacklog
            v-if="tabs.daily && activeKey === 'daily'"
            @setTotal="(e) => (tabs.daily.value = e)"
          />
          <my-summary formbacklog v-if="tabs.my && activeKey === 'my'" />
        </keep-alive>
      </div>
    </div>
    <div class="organ-manage-layout-right">
      <backlog-task-notice />
      <backlog-task-statistical />
    </div>
  </div>
</template>

<script>
import PageMixins from "@/components/JComponents/JPage/mixins/PageMixins";
import BacklogTaskNotice from "./components/BacklogTaskNotice.vue";
import BacklogTaskStatistical from "./components/BacklogTaskStatistical.vue";
import WaitList from "../criticism/waitList.vue";
export default {
  name: "BacklogTask",
  components: {
    BacklogTaskNotice,
    BacklogTaskStatistical,
    WaitList,
  },
  mixins: [PageMixins],
  data() {
    return {
      roleId: this.$store.state.user.roleId,
      // 1: 管理员 11: 主管领导 12: 分管领导 13: 考评办 21: 科室负责人 22: 科员 23: 单位人员
      tabs: {
        // democracy: {
        //   name: "民主测评(待测评)",
        //   value: "",
        //   show: [21, 22, 23],
        //   background: "#facd91",
        // },
        criticism: {
          name: "领导评鉴(待评鉴)",
          value: "",
          show: [11, 12],
          background: "#facd91",
        },
        daily: {
          name: "日常任务(未考核)",
          value: "",
          show: [1, 13],
          background: "#facd91",
        },
        my: {
          name: "个人小结(未完成)",
          key: "2",
          value: "未完成",
          show: [12, 21, 22, 23],
          background: "#facd91",
        },
      },
      activeKey: "",
    };
  },
  computed: {},
  created() {
    this.dealTabs();
  },
  methods: {
    dealTabs() {
      for (const key in this.tabs) {
        if (!this.tabs[key].show.includes(this.roleId)) {
          delete this.tabs[key];
        }
      }
      this.activeKey = Object.keys(this.tabs)[0];
    },
  },
};
</script>

<style lang="less">
.backlog-task {
  .j-page-layout_wrap {
    padding: 0 !important;
    box-shadow: none !important;
  }
  .organ-manage-layout-left {
    margin-right: 16px;
    flex: 2;
    .components-container {
      margin-top: 12px;
      height: calc(100% - 96px);
      > div {
        height: 100%;
      }
    }
  }
  .organ-manage-layout-right {
    flex: 0 0 400px;
    padding: 0;
    height: 100%;
    overflow: hidden;
    background-color: transparent;
    > div {
      position: relative;
      background: #ffffff;
      border-radius: 8px;
      padding: 12px 16px;
    }
  }
  .card-header-title {
    margin-bottom: 25px;
    > span {
      font-size: 16px;
      font-weight: bold;
      color: #1d1f22;
      line-height: 20px;
    }
    &::after {
      content: "";
      top: 40px;
      position: absolute;
      display: block;
      width: 28px;
      height: 2px;
      background: #fa8f31;
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 40px;
      display: block;
      width: 100%;
      height: 2px;
      background: #f1f5f6;
    }
  }
}
</style>
