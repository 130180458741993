<template>
    <div class="j-upload-wrap">
        <el-upload ref="JUploadRef" :class="['j-upload-wrap-style', readOnly ? 'read-only' : '']" v-bind="$attrs" :limit="countLimit" :action="action" :data="postData" :file-list="fileList"
                   :on-exceed="handleExceed"
                   :before-upload="handleBefore"
                   :on-progress="handleProgress"
                   :on-success="handleSuccess"
                   :on-preview="handlePreview"
                   :on-remove="handleRemove"
                   :on-error="handleError"
                   :multiple="countLimit > 1"
                   >
            <slot></slot>
        </el-upload>
        <j-image-preview v-if="showPreview" :close.sync="showPreview" :src="previewUrl" />
    </div>
</template>

<script>
import { fls, bucketName, system, flsPathSufix } from '@/config'
import fileTypeMixins from '../mixins/fileTypeMixins'
import utilMixins from '../mixins/utilMixins'
import JImagePreview from './JImagePreview'
export default {
  name: 'JUpload',
  inheritAttrs: false, // $attrs 继承的属性会在组件根容器节点上展示
  mixins: [fileTypeMixins, utilMixins],
  components: {
    JImagePreview
  },
  props: {
    countLimit: { // 默认上传30个文件
      type: Number,
      default: 30
    },
    mimeTypeLimit: { // mimeType数组限制
      type: Array,
      default: () => []
    },
    sizeLimit: { // 上传文件大小限制
      type: Number,
      default: 0
    },
    readOnly: { // 只读模式下 不可以删除、上传可以文件下载和预览
      type: Boolean,
      default: false
    },
    type: { // 决定了双向绑定时组件返回的数据类型，包含String 和 Array两种模式，存的时候用什么模式，回显的时候必须用相同的模式和数据格式
      type: String,
      default: 'String'
    },
    action: { // 可配置的文件上传接口地址
      type: String,
      default: `${fls}/api/files`
    },
    module: { // 可配置的模块名称，决定了文件上传的path路径模块名，推荐每个模块使用不同的名称，文件服务中存储文件夹更加清晰
      type: String,
      default: 'JUpload'
    },
    value: { // 双向绑定值，包含两种类型
      type: [String, Array]
    },
    needFully: { // 返回全路径
      type: Boolean,
      default: false
    },
    Separator: { // 返回文本时使用分隔符，只有String模式有效
      type: String,
      default: ','
    }
  },
  data () {
    return {
      showPreview: false, // 预览弹窗标志位
      previewUrl: '', // 预览文件时的临时变量
      postData: { // 上传时附带的信息
        path: '',
        bucketName
      },
      fileList: [], // 文件列表数组
      url: '', // 文件名截取后的拼接
      fully: '' // 全路径地址的拼接
    }
  },
  created () {
    this.value && this.handleFile()
    // 规范化path命名
    this.postData.path = `${system || 'grid'}_${bucketName}_${this.dateFormatByPattern(null, 'YYYY-MM-DD-HH-mm-ss')}_${this.module}`
  },
  methods: {
    // 代码触发上传选择框
    UploadTrigger () {
      this.$refs.JUploadRef.$el.getElementsByClassName('el-upload__input')[0].click()
    },
    handleFile () { // 编辑回显时文件列表生成
      if (this.type === 'Array' && Array.isArray(this.value)) {
        if (this.needFully) {
          return this.value.map(item => {
            const fully = item // 获取绝对路径
            const { suffix, name } = this.resolveFullSuffix(item) // 获取后缀
            this.fileList.push({
              url: this.fileSuffix.image.includes(suffix) ? fully : require('../assets/icon/link.png'),
              name,
              meta: fully,
              suffix,
              fully
            })
          })
        }
        this.value.map(item => this.handleAddFile(item, item))
      } else {
        if (typeof this.value !== 'string') return
        const arr = this.value.split(this.Separator)
        arr.map(item => this.handleAddFile(item, item))
      }
    },
    handleAddFile (name, meta) {
      const fully = this.pathHandle(meta) // 获取绝对路径
      const suffix = this.resolveSuffix(meta) // 获取后缀
      const data = {
        url: this.fileSuffix.image.includes(suffix) ? fully : require('../assets/icon/link.png'),
        name,
        meta,
        suffix,
        fully
      }
      this.fileList.push(data)
      this.$emit('upload-complete', data)
    },
    // 更新方法
    updateData () {
      if (this.type === 'Array') {
        let arr = []
        if (this.needFully) {
          arr = this.fileList.map(item => item['fully'])
        } else {
          arr = this.fileList.map(item => item['meta'])
        }
        this.$emit('input', [...arr])
      } else {
        this.url = ''
        this.fully = ''
        this.fileList.forEach((item, i) => {
          if (i === 0) {
            this.url = item.meta
            this.fully = item.fully
          } else {
            this.url = this.url + this.Separator + item.meta
            this.fully = this.fully + this.Separator + item.fully
          }
        })
        if (this.needFully) return this.$emit('input', this.fully)
        this.$emit('input', this.url)
      }
    },
    handleExceed (files, fileLis) {
      this.$message.error(`最多上传${this.countLimit}个文件`)
    },
    handleBefore (file) {
      let mimeType = true
      let size = true
      let fileType = file.name.substring(file.name.lastIndexOf("."));
      if (this.mimeTypeLimit.length > 0 && !this.mimeTypeLimit.includes(fileType)) mimeType = false
      if (this.sizeLimit > 0 && (file.size / 1024 / 1024) > this.sizeLimit) size = false
      if (!mimeType) {
        this.$message.error(`上传文件允许格式:${this.mimeTypeLimit.join(',')}`)
        this.$emit('upload-error', {
          status: 'no',
          msg: '文件类型不允许',
          mimeType: file.type,
          mimeTypeLimit: this.mimeTypeLimit
        }, file, this.fileList)
      }
      if (!size) {
        this.$message.error(`上传文件大小不能超过 ${this.sizeLimit}MB`)
        this.$emit('upload-error', {
          status: 'no',
          msg: '文件大小超出限定',
          size: (file.size / 1024 / 1024),
          sizeLimit: this.sizeLimit
        }, file, this.fileList)
      }
      return mimeType && size
    },
    handleProgress (event, file, fileList) {
      this.$emit('upload-progress', event, file, fileList)
    },
    handleSuccess (res, file,fileList) {
      if (res.status !== "yes") {
        this.$message.error(res.msg || "文件上传异常");
        fileList.splice(0,1)
        this.$emit("upload-error", res);
        return;
      }
      this.handleAddFile(file.name, res.data.generateName)
      this.updateData()
    },
    handleError (err, file, fileList) {
      const { msg, message } = err
      this.$message && this.$message.error(msg || message || '文件上传异常')
      this.$emit('upload-error', err, file, fileList)
    },
    handleRemove (file, fileList) {
      this.fileList = [...fileList]
      this.updateData()
      this.$emit('upload-remove',file)
    },
    handlePreview (file) {
      if (this.fileSuffix.image.includes(file.suffix)) {
        this.previewUrl = file.url
        this.showPreview = true
      } else { // 非图片走下载逻辑
        this.fileDownloadByUrl(file.fully, file.name)
      }
    },
    /**
       * 文件服务路径处理
       * @param {string} filePath ("vux-img/name.jpg&bucketName=grid")
       * @return {string} 返回资源的绝对路径 (https://oss-manage.btzh.net:4443/grid/static-file/vux-img/name.jpg)
       */
    pathHandle (filePath) {
      if (!filePath) return
      const splitArr = filePath.split('&bucketName=')
      let name = splitArr[0]
      let bucket = splitArr[1] || bucketName || 'grid'
      let url = `${fls}/${bucket}/` // `${fls}/${bucketName}/${filePath}`
      flsPathSufix && (url += flsPathSufix + '/') // `${fls}/${bucketName}/${flsPathSufix}/${filePath}`
      return (url + name)
    },
    /**
       * 提取文件后缀
       * @param {string} filePath ("vux-img/name.jpg&bucketName=grid")
       * @return {string} 返回后缀 (.jpg) 未匹配到后缀返回 (.unknown)
       */
    resolveSuffix (filePath) {
      if (!filePath) return '.unknown'
      const name = filePath.split('&')[0]
      return `.${name.split('.')[1] || 'unknown'}`
    },
    resolveFullSuffix (filePath) {
      if (!filePath) return '.unknown'
      const arr = filePath.split('/')
      const name = arr[arr.length - 1]
      return {
        suffix: `.${name.split('.')[1] || 'unknown'}`,
        name: name.split('.')[0]
      }
    }
  }
}
</script>

<style lang="less">
    .j-upload-wrap {
        .read-only {
            .el-upload-list__item-actions {
                .el-upload-list__item-delete {
                    display: none;
                }
            }
            .el-upload.el-upload--picture, .el-upload.el-upload--text {
                display: none;
            }
            .el-upload-list__item:first-child {
                margin-top: 0px;
            }
            .el-upload--picture-card {
                display: none;
            }
            .el-upload-list__item.is-success .el-upload-list__item-status-label {
                display: none;
            }
            .el-icon-close {
                display: none!important;
            }
        }
        .el-progress-circle{
            width: 100px!important;
            height: 100px!important;
        }
        .el-upload-list--picture-card .el-progress {
            width: 100px;
        }
        .el-upload--picture-card {
            width: 100px;
            height: 100px;
            line-height: 100px;
            background-color: transparent;
        }
        .el-upload-list--picture-card .el-upload-list__item {
            width: 100px;
            height: 100px;
        }
    }
</style>
