<template>
    <div class="j-link-input-wrap">
        <el-input v-if="type === 'number'" class="j-link-input" type="text" v-model="startVal" :placeholder="placeholderStrat" :clearable="clearable" @input="updateEvent($event, 'start')" oninput="value=value.replace(/^\.+|[^\d.]/g,'')">
            <span v-if="unit" class="j-link-input-unit" slot="suffix">{{unit}}</span>
        </el-input>
        <el-input v-else class="j-link-input" :type="type" v-model="startVal" :placeholder="placeholderStrat" :clearable="clearable" @input="updateEvent($event, 'start')">
            <span v-if="unit" class="j-link-input-unit" slot="suffix">{{unit}}</span>
        </el-input>
        <span>{{rangeSeparator}}</span>
        <el-input v-if="type === 'number'" class="j-link-input" type="text" v-model="endVal" :placeholder="placeholderEnd" :clearable="clearable" @input="updateEvent($event, 'end')" oninput="value=value.replace(/^\.+|[^\d.]/g,'')">
            <span v-if="unit" class="j-link-input-unit" slot="suffix">{{unit}}</span>
        </el-input>
        <el-input v-else class="j-link-input" :type="type" v-model="endVal" :placeholder="placeholderEnd" :clearable="clearable" @input="updateEvent($event, 'end')">
            <span v-if="unit" class="j-link-input-unit" slot="suffix">{{unit}}</span>
        </el-input>
    </div>
</template>

<script>
    export default {
        name: "JLinkInput",
        props: {
            clearable: {
                type: Boolean,
                default: false
            },
            unit: {
                type: String,
                default: ''
            },
            rangeSeparator: {
                type: String,
                default: '-'
            },
            placeholderStrat: {
                type: String,
                default: '开始值'
            },
            placeholderEnd: {
                type: String,
                default: '结束值'
            },
            type: {
                type: String,
                default: 'number'
            },
            start: {
                type: [String, Number],
                default: ''
            },
            end: {
                type: [String, Number],
                default: ''
            }
        },
        data() {
            return {
                startVal: '',
                endVal: ''
            }
        },
        created() {
            this.startVal = this.start
            this.endVal = this.end
        },
        methods: {
            updateEvent(v, type) {
                this.$emit(`update:${type}`, v)
            }
        },
        watch: {
            start(nv) {
                this.startVal = nv
            },
            end(nv) {
                this.endVal = nv
            }
        }
    }
</script>

<style lang="less">
    .j-link-input-wrap {
        display: inline-flex;
        align-items: center;

        .j-link-input {
            flex: 1;
        }

        .j-link-input-unit {
            display: inline-flex;
            height: 100%;
            align-items: center;
            font-size: 12px;
        }

        > span {
            display: inline-block;
            padding: 0 5px;
            color: #666;
            opacity: .8;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none !important;
            margin: 0;
        }

        input[type="number"] {
            -moz-appearance: textfield;
        }
    }
</style>
