<template>
    <span class="j-svg-icon-wrap" v-on="$listeners">
        <svg :class="['j-svg-icon', customClass]">
            <use :xlink:href="`#icon-${name}`"></use>
        </svg>
    </span>
</template>

<script>
// <j-svg-icon name="gis1" />

// 注意使用组件预先配置
// 假设svg文件放在src/icons下

// 存放svg文件夹下，新建index.js,利用webpack提供的api进行自动引入
// const req = require.context('@/icons':[svg存放路径], false, /\.svg$/)
// req.keys().map(req)

// main.js引入存储路径，执行index.js 触发自动引入
// import "./icons";

// vue.config.js 重新配置svg相关规则
// const resolve = dir => require('path').join(__dirname, dir)
// module.exports = {
//     chainWebpack (config) {
//         // 排除项目中默认的svg规则
//         config.module.rule('svg').exclude.add(resolve('src/icons':[svg存放路径]))
//         // 配置svg-loader
//         config.module.rule('svg-icons')
//             .test(/\.svg$/)
//             .include.add(resolve('src/icons':[svg存放路径])).end()
//             .use('svg-sprite-loader')
//             .loader('svg-sprite-loader')
//             .options({symbolId: 'svg-icon-[name]'})
//     }
// }

export default {
  name: 'JSvgIcon',
  props: {
    name: {
      type: String,
      required: true
    },
    customClass: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
    .j-svg-icon {
        /*将icon大小设置和字体大小一致，后续在通过svg-icon 使用icon的时候，可直接设置图标的font-size即可控制图标大小 */
        width: 1em;
        height: 1em;
        vertical-align: -0.20em; /* 因icon大小被设置为和字体大小一致，而span等标签的下边缘会和字体的基线对齐，故需设置一个往下的偏移比例，来纠正视觉上的未对齐效果 */
        fill: currentColor; /* 定义元素的颜色，currentColor是一个变量，这个变量的值就表示当前元素的color值，如果当前元素未设置color值，则从父元素继承 */
        overflow: hidden;
    }
</style>
