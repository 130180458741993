<template>
    <div class="j-image-preview__wrapper">
        <div class="j-image-preview__mask"></div>
        <span class="j-image-preview__title">{{ title }}</span>
        <!-- CLOSE -->
        <span class="j-image-preview__btn j-image-preview__close" @click="close">
        <i class="el-icon-circle-close"></i>
    </span>
        <!--    <span class="j-image-preview__btn j-image-preview__prev" @click="prev"><i class="el-icon-arrow-left"/></span>-->
        <!--    <span class="j-image-preview__btn j-image-preview__next" @click="next"><i class="el-icon-arrow-right"/></span>-->
        <div class="j-image-preview__btn j-image-preview__actions">
            <div class="el-image-viewer__actions__inner">
                <i class="el-icon-zoom-out" @click="scaleMin(-0.1)"></i>
                <i class="el-icon-zoom-in" @click="scaleMax(0.1)"></i>
                <i class="el-icon-full-screen" v-show="!fullscreen" @click="toFullScreen"></i>
                <i class="el-icon-c-scale-to-original" v-show="fullscreen" @click="toOriginal"></i>
                <i class="el-icon-refresh-left" @click="rotate(-90)"></i>
                <i class="el-icon-refresh-right" @click="rotate(90)"></i>
            </div>
        </div>
        <div class="j-image-preview__canvas"
             v-loading="loading"
             element-loading-text="图片加载中..."
             element-loading-background="transparent"
        >
            <img ref="img" class="j-image-preview__img" :style="imgStyle" :src="src" alt="...">
        </div>
    </div>
</template>

<script>
export default {
  name: 'JImagePreview',
  props: {
    src: {
      type: String,
      default: ''
    }
    // srcList: {
    //   type: Array,
    //   default: () => []
    // }
  },
  computed: {
    imgStyle () {
      return {
        transform: `scale(${this.scale}) rotate(${this.deg}deg)`,
        transition: 'transform .3s',
        maxWidth: this.fullscreen ? '' : '100%',
        maxHeight: this.fullscreen ? '' : '100%'
      }
    }
  },
  data () {
    return {
      title: '图片预览',
      fullscreen: false,
      scale: 1,
      deg: 0,
      // curIndex: 0,
      // previewImg: '',
      loading: false
    }
  },
  mounted () {
    this.$refs.img.onmousewheel = (e) => {
      if (e.wheelDeltaY > 0) {
        this.scaleMin(-0.1)
      } else {
        this.scaleMax(0.1)
      }
    }
    window.addEventListener('keydown', this.escEvent, true)
  },
  destroyed () {
    window.onmousewheel = null
    window.removeEventListener('keydown', this.escEvent)
  },
  methods: {
    toFullScreen () {
      this.fullscreen = true
      this.scale = 1
    },
    toOriginal () {
      this.fullscreen = false
      this.scale = 1
    },
    // loadImg (item) {
    //   this.loading = true
    //   this.title = item.name
    //   if (item.filePath) {
    //     this.previewImg = item.filePath
    //     this.loading = false
    //   }
    // },
    // prev () {
    //   if (this.curIndex === this.srcList.length - 1) {
    //     this.curIndex = 0
    //   } else {
    //     this.curIndex += 1
    //   }
    //   this.loadImg(this.srcList[this.curIndex])
    // },
    // next () {
    //   if (this.curIndex === 0) {
    //     this.curIndex = this.srcList.length - 1
    //   } else {
    //     this.curIndex -= 1
    //   }
    //   this.loadImg(this.srcList[this.curIndex])
    // },
    rotate (deg) {
      this.deg += deg
    },
    scaleMin (value) {
      if (this.scale < 0.4) return
      this.scale += value
    },
    scaleMax (value) {
      if (this.scale >= 2) return
      this.scale += value
    },
    close () {
      this.$emit('update:close', false)
    },
    escEvent (e) {
      if (e.which === 27) {
        this.close()
      }
    }
  }
}
</script>

<style lang="less">
    .j-image-preview__wrapper {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 9999;
        .j-image-preview__mask {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: .8;
            background: #000;
        }
        .j-image-preview__canvas {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .j-image-preview__img {
            transform: scale(1) rotate(0deg);
            margin-left: 0px;
            margin-top: 0px;
        }
        .j-image-preview__btn {
            position: absolute;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            opacity: .8;
            cursor: pointer;
            box-sizing: border-box;
            user-select: none;
        }
        .j-image-preview__close{
            top: 40px;
            right: 40px;
            width: 40px;
            height: 40px;
            font-size: 40px;
            color: #fff;
            z-index: 9999;
        }
        .j-image-preview__title{
            position: absolute;
            top: 40px;
            left: 40px;
            color: #fff;
        }
        .j-image-preview__prev {
            top: 50%;
            width: 44px;
            height: 44px;
            font-size: 24px;
            color: #fff;
            background-color: #606266;
            border-color: #fff;
            transform: translateY(-50%);
            left: 40px;
        }
        .j-image-preview__next {
            top: 50%;
            width: 44px;
            height: 44px;
            font-size: 24px;
            color: #fff;
            background-color: #606266;
            border-color: #fff;
            transform: translateY(-50%);
            right: 40px;
            text-indent: 2px;
        }
        .j-image-preview__actions {
            left: 50%;
            bottom: 30px;
            transform: translateX(-50%);
            width: 282px;
            height: 44px;
            padding: 0 23px;
            background-color: #606266;
            border-color: #fff;
            border-radius: 22px;
        }
        .el-image-viewer__actions__inner {
            i {
                cursor: pointer;
            }
        }
        .el-loading-spinner .path {
            stroke: #dadada;
        }
        .el-loading-spinner .el-loading-text{
            color: #dadada;
        }
    }
</style>
