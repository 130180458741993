<!--
 * @Description:
 * @Author: tengmingxin
 * @Date: 2022-04-14 14:35:20
 * @LastEditors: tengmingxin
 * @LastEditTime: 2022-04-28 14:02:33
-->
<template>
  <j-page>
    <div slot="search">
      <j-page-search title="文件名称">
        <el-input
          v-model="query.title"
          @input="load"
          placeholder="请输入"
          clearable
        />
      </j-page-search>
      <el-button type="primary" @click="onHandle({ type: 'add' })"
        >新增通知</el-button
      >
    </div>
    <!-- 表格 -->
    <div slot="table">
      <t-table
        :table="tableData"
        :columns="columns"
        :handle-btns="handleBtns"
        @onHandle="onHandle"
        @pageChange="(e) => load({ page: e })"
      />
    </div>

    <!-- 新增/编辑弹窗 -->
    <el-dialog
      :title="type === 'add' ? '新增' : '编辑'"
      :visible.sync="addDialogShow"
      width="1000px"
      :close-on-click-modal="false"
      class="notice-add-dialog"
      @close="closeAddDialog"
    >
      <el-form
        ref="formRef"
        :model="form"
        label-width="80px"
        :rules="formRules"
      >
        <el-form-item label="名称" prop="title">
          <el-input
            v-model="form.title"
            placeholder="请输入"
            maxlength="100"
            show-word-limit
            clearable
          />
        </el-form-item>
        <el-form-item label="公告内容" prop="content">
          <j-editor :size-limit="2" v-model="form.content" />
        </el-form-item>
        <el-form-item label="附件">
          <j-upload
            v-if="addDialogShow"
            v-model="fileList"
            ref="uploadRef"
            type="Array"
            list-type="text"
            @upload-complete="setFileList"
            @upload-remove="removeFile"
          >
            <el-button icon="el-icon-plus">上传</el-button>
          </j-upload>
        </el-form-item>
        <el-form-item label="已选择" prop="name">
          <div class="input-tags">
            <el-tag
              v-for="tag in treeSelectedNodes"
              :key="tag.orgId"
              closable
              @close="closeTag(tag.id)"
            >
              {{ tag.name }}
            </el-tag>
          </div>
        </el-form-item>
      </el-form>
      <div class="nad-tree">
        <p>已选择</p>
        <el-tree
          show-checkbox
          ref="treeRef"
          node-key="id"
          :props="treeProps"
          :data="treeData"
          :default-checked-keys="form.checkedKeys"
          @check="treeCheckChange"
        />
      </div>
      <template slot="footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="addDialogShow = false">取 消</el-button>
      </template>
    </el-dialog>

    <!-- 查看弹窗 -->
    <el-dialog
      title="详细信息"
      :visible.sync="viewDialogShow"
      width="1000px"
      :close-on-click-modal="false"
      class="notice-view-dialog"
    >
      <h2>{{ viewData.title }}</h2>
      <p>
        <span>{{ viewData.createUserName }}</span>
        <span>{{ viewData.createTime }}</span>
        <el-popover
          v-if="viewData.countNoticeUser"
          placement="right-start"
          trigger="hover"
        >
          <div>
            <p v-for="item in viewData.noticeUsers" :key="item.adminId">
              {{ item.name }}
            </p>
            <p v-if="!viewData.countNoticeUser">无通知人员</p>
          </div>
          <span slot="reference">
            <i class="el-icon-s-custom"></i>{{ viewData.countNoticeUser }}
          </span>
        </el-popover>
        <span v-else>
          <i class="el-icon-s-custom"></i>{{ viewData.countNoticeUser }}
        </span>
      </p>
      <div v-html="viewData.content"></div>
    </el-dialog>
  </j-page>
</template>

<script>
import TTable from "../../components/TTable.vue";
import PageMixins from "@/components/JComponents/JPage/mixins/PageMixins";
import JEditor from "../../components/JComponents/JEditor/JEditor.vue";
import JUpload from "../../components/JComponents/JUpload/components/JUpload.vue";

import {
  getNoticeList,
  addNotice,
  editNotice,
  deleteNotice,
  getNoticeDetail,
} from "@/api/system";

export default {
  name: "NoticeManage",
  components: { TTable, JEditor, JUpload },
  mixins: [PageMixins],
  data() {
    const checkContent = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("公告内容不能为空"));
      } else {
        if (
          value.replace(/<[^>]+>/g, "").replace(/&{1}[a-z]{1,8};{1}/g, "")
            .length > 1000
        ) {
          callback(new Error("公告内容不能超过1000字"));
        } else {
          callback();
        }
      }
    };
    return {
      fileList: [],
      tableData: {
        currentPage: 1,
        limit: 10,
        total: 0,
        data: [],
        loading: false,
      },
      columns: [
        { prop: "title", label: "名称" },
        { prop: "createTime", label: "创建时间", width: "200" },
        { prop: "createUserName", label: "创建人", width: "200" },
      ],
      handleBtns: [
        { key: "view", name: "查看" },
        { key: "edit", name: "编辑" },
        { key: "delete", name: "删除" },
      ],
      query: {
        title: "",
        page: 1,
        limit: 10,
      },
      type: "add",
      addDialogShow: false,
      viewDialogShow: false,
      form: {
        title: "",
        content: "",
        files: [],
        noticeOrgIds: "",
      },
      viewData: {},
      formRules: {
        title: { required: true, message: "名称不能为空", trigger: "blur" },
        content: [
          {
            required: true,
            validator: checkContent,
            trigger: "blur",
          },
        ],
      },
      treeData: [],
      treeProps: { children: "childrens", label: "name" },
      treeSelectedNodes: [],
    };
  },
  // watch: {
  //   fileList: function (val,oldVal) {
  //   if (oldVal.length < val.length)return
  //    {

  //       this.form.files.splice(this.form.files.findIndex(e=>e.fileUrl),1)
  //   }
  //   },
  // },
  created() {
    this.load();
    this.treeData = [
      { name: "全部成员", childrens: this.$store.state.orgTree },
    ];
  },
  methods: {
    removeFile(file) {
      this.form.files.splice(
        this.form.files.findIndex((e) => e.fileUrl === file.meta),
        1
      );
    },
    load(params = {}) {
      this.tableData.loading = true;
      getNoticeList({ ...this.query, ...params }).then((res) => {
        this.tableData.data = res.data.data.data || [];
        this.tableData.loading = false;
        if (this.tableData.data.length === 0 && this.query.page > 1) {
          this.query.page--;
          this.load();
        }
        this.tableData.total = res.data.data.total;
        this.query.page = res.data.data.page;
      });
    },
    onHandle(data) {
      switch (data.type) {
        case "add":
          this.form = {
            title: "",
            content: "",
            files: [],
            noticeOrgIds: "",
          };
          this.addDialogShow = true;
          break;
        case "edit":
          getNoticeDetail(data.scope.row.id).then((res) => {
            this.form = res.data.data;
            this.form.noticeOrgIds = "";
            this.treeSelectedNodes = this.form.noticeOrgs.map((item) => {
              return { id: item.orgId, name: item.orgName };
            });
            this.treeSelectedNodes.forEach((item) => {
              this.form.noticeOrgIds += item.id + ",";
            });
            this.addDialogShow = true;
            this.fileList = this.form.files.map((e) => e.fileUrl);
            this.$nextTick(() => {
              this.$refs.treeRef.setCheckedKeys(
                this.form.noticeOrgs.map((e) => e.orgId)
              );
            });
          });
          break;
        case "view":
          getNoticeDetail(data.scope.row.id).then((res) => {
            this.viewData = res.data.data;
            this.viewDialogShow = true;
          });
          break;
        case "delete":
          deleteNotice(data.scope.row.id).then((res) => {
            this.load();
          });
          break;
        default:
          break;
      }
      this.type = data.type;
    },
    setFileList(data) {
      let { name, suffix, meta } = data;
      if (this.form.files.findIndex((e) => e.fileUrl === meta) !== -1) return;
      this.form.files.push({
        fileName: name,
        fileType: [".jpg", ".jpeg", ".png", ".gif"].includes(suffix) ? 1 : 2,
        fileUrl: meta,
      });
    },
    closeAddDialog() {
      this.$refs.formRef.resetFields();
      this.$refs.treeRef.setCheckedKeys([]);
      this.treeSelectedNodes = [];
      this.fileList.splice(0, this.fileList.length);
    },
    submitForm() {
      this.$refs.formRef.validate((valid) => {
        if (!valid) return;
        if (this.type === "add") {
          addNotice(this.form).then((res) => {
            this.load({ page: 1 });
            this.addDialogShow = false;
            this.$message.success("添加成功");
          });
        } else {
          const { files, id, noticeOrgIds, title, content } = this.form;
          editNotice({ files, id, noticeOrgIds, title, content }).then(
            (res) => {
              this.addDialogShow = false;
              this.$message.success("编辑成功");
            }
          );
        }
      });
    },
    treeCheckChange() {
      this.form.noticeOrgIds = "";
      this.treeSelectedNodes = this.$refs.treeRef.getCheckedNodes(true) || [];
      this.treeSelectedNodes.forEach((item) => {
        this.form.noticeOrgIds += item.id + ",";
      });
    },
    closeTag(orgId) {
      this.form.noticeOrgIds = this.form.noticeOrgIds.replace(orgId + ",", "");
      this.$refs.treeRef.setCheckedKeys(this.form.noticeOrgIds.split(","));
      this.treeCheckChange();
    },
  },
};
</script>

<style lang="less">
.notice-add-dialog {
  .el-dialog__body {
    display: flex;
    .el-form {
      width: 70%;
    }
    .nad-tree {
      margin-left: 20px;
      flex: 1;
      > p {
        font-size: 16px;
        margin-bottom: 10px;
      }
      .el-tree-node__content {
        height: 32px;
      }
    }
    .input-tags {
      border: 1px solid #dcdfe6;
      padding: 5px 10px;
      min-height: 21px;
      border-radius: 4px;
      .el-tag {
        margin-right: 5px;
        background: #f7f9fa;
        border-color: #fcfdfd;
        color: rgba(29, 31, 34, 0.75);
        .el-tag__close:hover {
          background-color: #c9d1de;
        }
      }
    }
  }
}
.notice-view-dialog {
  .el-dialog__body {
    > p {
      padding: 16px 0;
      border-bottom: 1px solid #ebeef5;
      > span {
        &:not(:last-child) {
          background: #eef0f2;
          margin-right: 10px;
          padding: 4px 7px;
        }
        &:last-child {
          float: right;
          i {
            font-size: 22px;
            margin-right: 8px;
          }
        }
      }
    }
    > div {
      padding: 16px;
      height: 420px;
      overflow-y: auto;
    }
  }
}
</style>
