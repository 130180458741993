<template>
    <el-dialog :title="title" :visible.sync="showResetPwd" v-bind="$attrs" width="350px" :close-on-click-modal="false" @close="closeDialog">
        <el-form :model="pwdForm" :rules="pwdRule" ref="PWDDOM" :status-icon="true">
            <el-form-item label="原密码" prop="password">
                <el-input v-model="pwdForm.password" placeholder="请输入原密码" type="password" />
            </el-form-item>
            <el-form-item label="新密码" prop="newPassword">
                <el-input v-model="pwdForm.newPassword" placeholder="请输入新密码" type="password" :mixlength="6" :maxlength="40" />
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="checkEvent" size="small" :loading="submitLoad">保 存</el-button>
            <el-button @click="closeDialog" size="small">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import md5 from 'js-md5'
    export default {
        name: 'JResetPwd',
        inheritAttrs: false, // $attrs 继承的属性会在组件根容器节点上展示
        props: {
            title: {
                type: String,
                default: '重置密码'
            },
            close: { type: Boolean, require: true }
        },
        data () {
            return {
                submitLoad: false,
                showResetPwd: false,
                pwdForm: {
                    newPassword: '',
                    password: ''
                },
                pwdRule: {
                    newPassword: [
                        { required: true, message: '请输入新密码', trigger: 'blur' },
                        { min: 6, message: '新密码不可少于6位数', trigger: 'blur' }
                    ],
                    password: [
                        { required: true, message: '请输入原密码', trigger: 'blur' }
                    ]
                }
            }
        },
        watch: {
            close () { this.showResetPwd = this.close }
        },
        methods: {
            closeDialog () {
                this.$refs['PWDDOM'] && this.$refs['PWDDOM'].resetFields()
                this.pwdForm = { ...this.$options.data().pwdForm }
                this.$emit('update:close', false)
            },
            checkEvent () {
                this.$refs.PWDDOM.validate((pass) => {
                    if (pass) {
                        this.savePwdEvent()
                    }
                })
            },
            async savePwdEvent () {
                const { data } = await this.$http.put(`/api/admins/password`, {
                    newPassword: md5(this.pwdForm.newPassword),
                    password: md5(this.pwdForm.password)
                }).finally(() => {
                    this.submitLoad = false
                })
                if (data.status !== 'yes') return this.$message.error(data.msg)
                this.$message.success('重置成功')
                this.closeDialog()
            }
        }
    }
</script>
