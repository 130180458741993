<template>
    <div class="j-page-search-wrap">
        <label v-if="title" class="j-page-search-wrap_label">{{title}}</label>
        <div class="j-page-search-wrap_content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
  name: 'JPageSearch',
  props: {
    title: {
      type: [String, Number],
      default: ''
    }
  }
}
</script>

<style lang="less">
    .j-page-search-wrap {
        display: inline-flex;
        align-items: center;
        min-width: 120px;
        vertical-align: bottom;
        padding: 5px 10px 5px 5px;
        .j-page-search-wrap_content {
            max-width: 360px;
            overflow: hidden;
        }
        .j-page-search-wrap_label {
            font-size: 14px;
            color: #606266;
            padding-right: 10px;
            white-space: nowrap;
            /*max-width: 100px;*/
            /*overflow: hidden;*/
            /*text-overflow: ellipsis;*/
            /*box-sizing: border-box;*/
        }
        .el-radio {
            margin-right: 10px!important;
        }
    }
</style>
