export default {
    checkPhone (value) {
        const isPhone = /^([0-9]{3,4}-)?[0-9]{7,8}$/
        if (isPhone.test(value)) {
            return true
        } else {
            return false
        }
    },
    checkEmail (value) {
        // eslint-disable-next-line no-useless-escape
        const isMail = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
        return isMail.test(value)
    },
    checkTel (value) {
        let isPhone = /^([0-9]{3,4}-)?[0-9]{7,8}$/
        let isMob= /^((\+86)|(86))?(1)\d{10}$/
        let isTel = /^0[0-9-]{10,13}$/
        if (isMob.test(value) || isPhone.test(value) || isTel.test(value)) {
            return true
        } else {
            return false
        }
    }
}
