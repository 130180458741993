<template>
  <j-page>
    <!-- 搜索表单控件 -->
    <div slot="search">
      <el-form
        :model="searchForm"
        :inline="true"
        class="daily-task-form"
        label-width="70px"
      >
        <el-form-item>
          <j-page-search title="考核月份">
            <el-date-picker
              v-model="searchForm.yearMonths"
              type="month"
              format="yyyy 年 MM 月"
              value-format="yyyy/MM"
              placeholder="请选择"
            />
          </j-page-search>
        </el-form-item>
        <el-form-item>
          <j-page-search title="考核项目">
            <el-select v-model="searchForm.itemId" placeholder="请选择">
              <el-option
                v-for="item in assessmentsOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </j-page-search>
        </el-form-item>
        <el-form-item>
          <j-page-search title="任务事项">
            <el-input
              v-model.trim="searchForm.task"
              placeholder="请输入"
            ></el-input>
          </j-page-search>
        </el-form-item>
        <el-form-item>
          <j-page-search title="责任领导">
            <el-input
              v-model.trim="searchForm.leaderName"
              placeholder="请输入"
            ></el-input>
          </j-page-search>
        </el-form-item>
        <el-form-item>
          <j-page-search title="牵头单位">
            <el-input
              v-model.trim="searchForm.leadUnitName"
              placeholder="请输入"
            ></el-input>
          </j-page-search>
        </el-form-item>
        <el-form-item>
          <j-page-search title="责任单位">
            <el-input
              v-model.trim="searchForm.dutyUnitName"
              placeholder="请输入"
            ></el-input>
          </j-page-search>
        </el-form-item>
        <el-form-item>
          <j-page-search title="进度">
            <el-select
              :disabled="formbacklog"
              v-model="searchForm.status"
              placeholder="请选择"
            >
              <el-option
                v-for="item in progressStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </j-page-search>
        </el-form-item>
        <el-form-item>
          <j-page-search title="完成时限">
            <el-date-picker
              v-model="searchForm.finishDate"
              type="date"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              placeholder="请选择"
            />
          </j-page-search>
        </el-form-item>
        <el-form-item class="btn-container">
          <el-button type="primary" @click="onGetBisDailysList">搜索</el-button>
          <el-button @click="resetSearchForm">重置</el-button>
        </el-form-item>
        <el-form-item class="btn-container" style="float: right">
          <el-button type="warning" @click="showNewOrUpdateTask('新增任务', {})"
            >新增</el-button
          >
          <el-button type="primary" plain @click="showImport">导入</el-button>
          <el-button
            type="primary"
            v-if="formbacklog"
            @click="$store.commit('JMenu/openMenu', 'daily-task')"
            >更多</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <!-- 日常任务表格信息 -->
    <div slot="table">
      <el-table v-loading="tableLoading" :data="tableData" height="100%">
        <el-table-column label="序号" type="index" width="50">
        </el-table-column>
        <el-table-column
          prop="status"
          label="进度"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span :class="scope.row.status && 'pro-status'">{{
              getLabel(progressStatus, scope.row.status)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="month"
          label="考核月份"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ `${scope.row.year}/${scope.row.month}` }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="itemId"
          label="考核项目"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ getLabel(assessmentsOptions, scope.row.itemId) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="task"
          label="任务事项"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="leaderName"
          label="责任领导"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="leadUnitName"
          label="牵头单位"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="dutyUnitName"
          label="责任单位"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="finishDate"
          label="完成时限"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="title"
          label="操作"
          align="center"
          width="240px"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-button
              @click.native.prevent="showCheck(scope.row, true)"
              type="text"
              size="small"
            >
              查看
            </el-button>
            <el-button
              v-if="!formbacklog"
              @click.native.prevent="showNewOrUpdateTask('编辑任务', scope.row)"
              type="text"
              size="small"
            >
              编辑
            </el-button>
            <el-button
              @click.native.prevent="showCheck(scope.row)"
              type="text"
              size="small"
              :disabled="!scope.row.canEvalue"
            >
              考核
            </el-button>
            <el-button
              v-if="!formbacklog"
              @click.native.prevent="showCheckedDetail(scope.row)"
              type="text"
              size="small"
            >
              考核明细
            </el-button>
            <el-button
              v-if="!formbacklog"
              @click.native.prevent="deleteRow(scope.row)"
              type="text"
              size="small"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页器 -->
    <div slot="pagination" v-show="pageQuery.total > pageQuery.limit">
      <el-pagination
        :current-page="pageQuery.page"
        :page-size="pageQuery.limit"
        :total="pageQuery.total"
        :background="pmxBackground"
        :pager-count="pmxCount"
        :layout="pmxLayout"
        :pageSizes="pmxSizes"
        @current-change="changePage"
      />
    </div>
    <!-- 新增/编辑任务抽屉 -->
    <j-drawer :title="newOrUpdateTitle" :close.sync="isShowNewOrUpdateTask">
      <div>
        <el-form
          :model="newOrUpdateTaskForm"
          status-icon
          :rules="newOrUpdateRules"
          ref="newOrUpdateTaskForm"
          class="new-task-form"
        >
          <j-drawer-label type="title" title="基本信息" />
          <div class="form-container">
            <el-form-item prop="yearMonths">
              <j-page-search title="考核月份">
                <el-date-picker
                  v-model="newOrUpdateTaskForm.yearMonths"
                  type="month"
                  format="yyyy 年 MM 月"
                  value-format="yyyy/MM"
                  placeholder="请选择考核月份"
                />
              </j-page-search>
            </el-form-item>
            <el-form-item prop="itemId">
              <j-page-search title="考核项目">
                <el-select
                  v-model="newOrUpdateTaskForm.itemId"
                  placeholder="请选择考核项目"
                >
                  <el-option
                    v-for="item in assessmentsOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </j-page-search>
            </el-form-item>
            <el-form-item prop="task">
              <j-page-search title="任务事项" class="textarea-style">
                <el-input
                  v-model.trim="newOrUpdateTaskForm.task"
                  type="textarea"
                  placeholder="请输入任务事项"
                  maxlength="500"
                  show-word-limit
                ></el-input>
              </j-page-search>
            </el-form-item>
            <el-form-item prop="leaderName">
              <j-page-search title="责任领导">
                <el-input
                  v-model.trim="newOrUpdateTaskForm.leaderName"
                  placeholder="请输入责任领导"
                ></el-input>
              </j-page-search>
            </el-form-item>
            <el-form-item prop="leadUnitName">
              <j-page-search title="牵头单位">
                <el-input
                  v-model.trim="newOrUpdateTaskForm.leadUnitName"
                  placeholder="请输入牵头单位"
                ></el-input>
              </j-page-search>
            </el-form-item>
            <el-form-item prop="dutyUnitName">
              <j-page-search title="责任单位">
                <el-input
                  v-model.trim="newOrUpdateTaskForm.dutyUnitName"
                  placeholder="请输入责任单位"
                ></el-input>
              </j-page-search>
            </el-form-item>
            <!-- <el-form-item label="进度">
              <el-select v-model="newOrUpdateTaskForm.status" placeholder="活动区域">
                <el-option
                  v-for="item in progressStatus"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item prop="finishDate">
              <j-page-search title="完成时限">
                <el-date-picker
                  v-model="newOrUpdateTaskForm.finishDate"
                  type="date"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                  placeholder="请选择完成时限"
                />
              </j-page-search>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div slot="footer" style="display: flex; width: 100%">
        <el-button type="primary" @click="onNewOrUpdateOk">保存</el-button>
      </div>
    </j-drawer>
    <!-- 导入数据弹框 -->
    <el-dialog
      v-if="isShowImport"
      title="导入"
      :visible.sync="isShowImport"
      width="372px"
      top="10%"
      :close-on-click-modal="false"
    >
      <el-form
        :model="importForm"
        status-icon
        ref="importForm"
        :rules="importRules"
        label-width="80px"
        class="import-form"
      >
        <el-form-item label="考核月份" prop="yearMonths">
          <el-date-picker
            v-model="importForm.yearMonths"
            type="month"
            format="yyyy 年 MM 月"
            value-format="yyyy/MM"
            placeholder="请选择考核月份"
          />
        </el-form-item>
        <el-form-item label="考核项目" prop="fileList">
          <el-upload
            class="upload-file"
            :on-remove="handleRemove"
            :on-change="hanleChange"
            :file-list="importForm.fileList"
            :auto-upload="false"
          >
            <el-button
              icon="el-icon-plus"
              v-if="!importForm.fileList.length"
              slot="trigger"
              type="primary"
              plain
              >上传</el-button
            >
            <div slot="tip" class="el-upload__tip">
              <el-button type="text" @click="onDownlaodImportExcelTemplate">
                <div class="import-tem">
                  <img src="../../assets/import-template.png" alt="" />
                  <span>导入模板</span>
                </div>
              </el-button>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button plain type="primary" @click="hideImport">取消</el-button>
          <el-button type="primary" @click="onImportFromExcel">导入</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 考核弹框 -->
    <j-drawer title="考核" :close.sync="isShowCheck">
      <div>
        <div>
          <task-info :taskInfo="assessmentData" />
          <check-status-list
            v-if="isShowCheckedOrgList"
            :orgsStatis="assessmentData.orgsStatis"
            :cb="getCheckedDetail"
          />
          <edit-checked-org ref="checkedOrg" v-else />
        </div>
      </div>
      <div
        slot="footer"
        style="display: flex; width: 100%"
        v-if="!isShowCheckedOrgList"
      >
        <el-button type="primary" @click="saveCheckedOrg">保存</el-button>
      </div>
    </j-drawer>
    <!-- 考核明细弹框 -->
    <el-dialog
      custom-class="dialog-style"
      v-if="isShowCheckedDetail"
      title="考核明细"
      :visible.sync="isShowCheckedDetail"
      width="860px"
      top="5%"
      :close-on-click-modal="false"
    >
      <checked-detail :showSelector="showSelector" ref="checkedDetail" />
    </el-dialog>
  </j-page>
</template>

<script>
import moment from "moment";

import PageMixins from "../../components/JComponents/JPage/mixins/PageMixins";
import PaginationMixins from "../../components/JComponents/JPage/mixins/PaginationMixins";
import DrawerMixins from "../../components/JComponents/JPage/mixins/DrawerMixins";

import TaskInfo from "../dailyInspection/components/TaskInfo.vue";
import CheckStatusList from "../dailyInspection/components/CheckStatusList.vue";
import EditCheckedOrg from "../dailyInspection/components/EditCheckedOrg.vue";
import CheckedDetail from "../dailyInspection/components/CheckedDetail.vue";

import {
  getBisDailysList,
  addBisDailys,
  editBisDailys,
  deleteBisDailys,
  downlaodImportExcelTemplate,
  getBisDailysDetail,
  addBisDailysOrg,
  importFromExcel,
  checkSysEvalueIsEnd
} from "@/api/dailyInspection";

export default {
  name: "DailyTask",
  mixins: [PageMixins, PaginationMixins, DrawerMixins],
  props: {
    // 待办引用
    formbacklog: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TaskInfo,
    CheckStatusList,
    EditCheckedOrg,
    CheckedDetail,
  },
  data() {
    return {
      // 新增/编辑表单标题
      newOrUpdateTitle: "",
      isShowNewOrUpdateTask: false,
      isShowImport: false,
      isShowCheck: false,
      isShowCheckedDetail: false,
      tableData: [],
      pageQuery: {
        page: 1,
        limit: 10,
        total: 0,
      },
      // 搜索表单控件
      searchForm: {
        dutyUnitName: "", //	责任单位
        finishDate: "", //完成时限日期
        itemId: "", //考核项目id
        leadUnitName: "", //	牵头单位
        leaderName: "", //	责任领导
        limit: "", // limit
        page: "", //  page
        status: "", // 进度是否考核
        task: "", //	任务事项
        yearMonths: "", //	考核年月
      },
      // 考核项目
      assessmentsOptions: [
        {
          value: 1,
          label: "基础工作",
        },
        {
          value: 2,
          label: "重点工作",
        },
        {
          value: 9,
          label: "其他",
        },
      ],
      //  进度下拉配置项
      progressStatus: [
        {
          value: true,
          label: "已考核",
        },
        {
          value: false,
          label: "未考核",
        },
      ],
      // 新增/编辑任务表单
      newOrUpdateTaskForm: {},
      // 新增/编辑任务表单校验规则
      newOrUpdateRules: {
        yearMonths: [{ required: true, message: "请选择考核月份" }],
        itemId: [{ required: true, message: "请选择考核项目" }],
        task: [
          { required: true, message: "请输入任务事项" },
          { max: 500, message: "最多输入500个字符" },
        ],
        leaderName: [{ max: 15, message: "最多输入15个字符" }],
        leadUnitName: [{ max: 50, message: "最多输入50个字符" }],
        dutyUnitName: [{ max: 50, message: "最多输入50个字符" }],
      },
      // 导入表单
      importForm: {
        yearMonths: "",
        fileList: [],
      },
      importRules: {
        yearMonths: [{ required: true, message: "请选择考核月份" }],
        fileList: [{ required: true, message: "请选择文件" }],
      },
      assessmentData: {}, // 查看/考核详细数据
      isShowCheckedOrgList: false, // 控制查看或考核时 考核机构 显隐
      showSelector: true,
    };
  },
  async created() {
    if (this.formbacklog) this.searchForm.status = false;
    await this.onGetBisDailysList();
    this.$emit("setTotal", this.pageQuery.total);
  },
  methods: {
    // 获取日常考核数据列表
    async onGetBisDailysList() {
      this.tableLoading = true;
      try {
        const params = { ...this.searchForm, ...this.pageQuery };
        const { data } = await getBisDailysList(params);
        this.tableData = data.data.data;
        this.pageQuery.total = data.data.total;
      } catch (error) {
        console.error(error);
      }
      this.tableLoading = false;
    },
    changePage(page) {
      this.pageQuery.page = page;
      this.onGetBisDailysList();
    },
    // 重置搜索表单控件参数
    resetSearchForm() {
      for (let key in this.searchForm) {
        this.searchForm[key] = "";
      }
      if (this.formbacklog) this.searchForm.status = false;
      this.onGetBisDailysList();
    },
    // 导入文件
    onImportFromExcel() {
      this.$refs.importForm.validate(async (valid) => {
        if (!valid) return;
        try {
          const {
            importForm: { fileList, yearMonths },
          } = this;
          let formData = new FormData();
          formData.append("excel", fileList[0].raw);
          formData.append("yearMonths", yearMonths);
          const { data } = await importFromExcel(formData);
          if (data.code === 200) {
            this.onGetBisDailysList();
            this.isShowImport = false;
            this.$message.success("导入成功");
            this.resetImportForm();
          }
        } catch (error) {
          console.error(error);
        }
      });
    },
    resetImportForm() {
      this.importForm = {
        yearMonths: "",
        fileList: [],
      };
    },
    // 新增/编辑抽屉保存
    onNewOrUpdateOk() {
      this.$refs.newOrUpdateTaskForm.validate((vaild) => {
        if (!vaild) return;
        this.newOrUpdateTitle === "新增任务" && this.onAddBisDailys();
        this.newOrUpdateTitle === "编辑任务" && this.onEditBisDailys();
      });
    },
    // 新增任务
    async onAddBisDailys() {
      try {
        const [year, month] = this.newOrUpdateTaskForm.yearMonths.split("/");
        const params = { ...this.newOrUpdateTaskForm, year, month };
        const { data } = await addBisDailys(params);
        if (data.code === 200) {
          this.$message.success("新增成功");
          this.onGetBisDailysList();
          this.isShowNewOrUpdateTask = false;
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 编辑任务
    async onEditBisDailys() {
      try {
        const [year, month] = this.newOrUpdateTaskForm.yearMonths.split("/");
        const params = { ...this.newOrUpdateTaskForm, year, month };
        const { data } = await editBisDailys(params);
        if (data.code === 200) {
          this.$message.success("编辑成功");
          this.onGetBisDailysList();
          this.isShowNewOrUpdateTask = false;
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 删除任务
    async onDeleteBisDailys(row) {
      try {
        const { data } = await deleteBisDailys(row.id);
        if (data.code === 200) {
          this.$message.success("删除成功");
          this.onGetBisDailysList();
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 查看任务详情
    async onGetBisDailysDetail(row) {
      try {
        const { data } = await getBisDailysDetail(row.id);
        this.assessmentData = data.data;
      } catch (error) {
        console.error(error);
      }
    },
    changeLeaderPage(page) {
      this.leaderQuery.page = page;
      this.loadLeaderData();
    },
    async loadLeaderData() {
      const { data } = await this.$http.get(`/api/list`, {
        params: this.leaderQuery,
      });
      this.leaderData = data.data.data;
      this.leaderQuery.total = data.data.total;
      this.isShowNewOrUpdateTask = true;
    },
    // 新增任务-按钮回调
    showNewOrUpdateTask(title, row) {
      this.newOrUpdateTitle = title;
      this.echoUpdateTaskForm(row);
      this.isShowNewOrUpdateTask = !this.isShowNewOrUpdateTask;
    },
    // 编辑任务时 回显数据
    echoUpdateTaskForm(row) {
      if (row.year && row.month) {
        let yearMonths = `${row.year}/${row.month}`;
        row.yearMonths = yearMonths;
      }
      // 保持数据响应式
      if (row)
        this.newOrUpdateTaskForm = { ...JSON.parse(JSON.stringify(row)) };
      // 处理时间选择器 数据格式
    },
    // 下载导入文件模板
    async onDownlaodImportExcelTemplate() {
      try {
        const { data } = await downlaodImportExcelTemplate();
        if (data.code === 200) {
          window.open(data.data, "_self");
          this.importForm = {
            yearMonths: "",
            fileList: [],
          };
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 导入-按钮回调
    showImport() {
      this.isShowImport = true;
    },
    // 关闭导入弹框
    hideImport() {
      this.isShowImport = false;
      this.importForm = {
        yearMonths: "",
        fileList: [],
      };
    },
    async checkTaskIsEnd(row){
      const params = { year: row.year, month: row.month }
      const { data } = await checkSysEvalueIsEnd(params)
      return data.data
    },
    // 考核-按钮回调
    async showCheck(row, isShowCheckedOrgList = false) {
      const result = await this.checkTaskIsEnd(row)
      if (result) {
        return this.$message.warning('该任务考核已结束')
      }
      this.isShowCheck = !this.isShowCheck;
      this.isShowCheckedOrgList = isShowCheckedOrgList;
      this.onGetBisDailysDetail(row);
    },
    // 考核明细-按钮回调
    showCheckedDetail(row) {
      this.isShowCheckedDetail = true;
      this.showSelector = true;
      this.$nextTick(() => {
        this.$refs.checkedDetail.onGetDailysDetail({ dailyId: row.id });
      });
    },
    handleRemove(file, fileList) {
      this.importForm.fileList = [];
    },
    // 导入-上传删除 文件变更
    hanleChange(file) {
      this.importForm.fileList = [file];
    },
    // 删除任务
    deleteRow(row) {
      this.$confirm("确定删除该任务及其绑定机构的考核明细?", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.onDeleteBisDailys(row);
        })
        .catch(() => {});
    },
    // 获取label值 针对与 el-select
    getLabel(options, value) {
      if (value === undefined) return;
      for (let key in options) {
        if (options[key].value === value) return options[key].label;
      }
    },
    // 查看考核 - 添加机构 - 保存
    async saveCheckedOrg() {
      try {
        const { formList, $refs } = this.$refs.checkedOrg;
        const { assessmentData } = this;
        let resFlag = true;
        // 校验全部考核机构表单
        for (const key in formList) {
          $refs[`addOrgForm-${key}`][0].validate((valid) => {
            resFlag = valid;
          });
        }
        if (!resFlag) return;
        // 处理计分分数加为正数减为负数 提交请求
        for (const key in formList) {
          let score = parseFloat(formList[key].scoreType + formList[key].score);
          const params = {
            dailyId: assessmentData.id,
            ...formList[key],
            score,
          };
          const { data } = await addBisDailysOrg(params);
          if (data.code !== 200) {
            resFlag = false;
          }
        }
        if (resFlag) {
          this.$message.success("保存成功");
          this.isShowCheck = false;
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 在查看详情中 点击table列 查看考核明细
    getCheckedDetail(row) {
      this.showSelector = false;
      this.isShowCheckedDetail = true;
      this.$nextTick(() => {
        this.$refs.checkedDetail.onGetDailysDetail({
          dailyId: row.dailyId,
          orgId: row.orgId,
        });
      });
    },
    // 判断考核是否过期 当前时间月份在考核时间月份后 算作过期
    isExpired(time) {
      const yearMonths = moment(time).startOf("month").valueOf();
      const yearMonthsNow = moment(new Date()).startOf("month").valueOf();
      return yearMonthsNow > yearMonths ? false : true;
    },
  },
};
</script>
<style lang="less" scoped>
.pro-status {
  font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
  font-weight: bold;
  color: #00b9dc;
}
.daily-task-form,
.newOrUpdateTaskForm {
  /deep/.el-form-item {
    margin: 0px;
  }
  .btn-container {
    margin: 5px;
  }
}

/deep/.dialog-style {
  .el-dialog__body {
    height: 600px !important;
    .el-form {
      width: 70%;
    }
    .nad-tree {
      margin-left: 20px;
      flex: 1;
      > p {
        font-size: 16px;
        margin-bottom: 10px;
      }
      .el-tree-node__content {
        height: 32px;
      }
    }
    .input-tags {
      border: 1px solid #dcdfe6;
      padding: 5px 10px;
      min-height: 21px;
      border-radius: 4px;
      .el-tag {
        margin-right: 5px;
      }
    }
  }
  .j-page-layout_wrap {
    border-radius: 0px;
    box-shadow: none;
    padding: 0px;
    box-sizing: content-box;
  }
}
.upload-file {
  /deep/.el-upload-list {
    position: absolute;
    top: 0px;
    .el-upload-list__item {
      margin-top: 8px;
      .el-upload-list__item-name {
        width: 110px;
      }
    }
  }
  /deep/.el-upload__tip {
    float: right;
    margin: 0;
    button {
      padding: 13px 0;
    }
  }
}
.import-form {
  /deep/.el-date-editor {
    width: 100%;
  }
  .import-tem {
    display: flex;
    align-items: center;
    img {
      width: 16px;
      margin-right: 3px;
    }
    span {
      color: #fe693d;
    }
  }
}
.new-task-form {
  .form-container {
    margin: 0 16px;
    /deep/.el-form-item {
      margin-bottom: 0px;
      .el-form-item__content {
        line-height: 15px;
      }
      .el-form-item__error {
        margin: 0 5px;
        position: relative;
      }
    }
    .j-page-search-wrap {
      width: 92%;
      .j-page-search-wrap_content {
        width: 92%;
      }
      /deep/.el-input__inner,
      .el-date-editor {
        width: 320px;
      }
      /deep/.el-textarea__inner {
        width: 328px;
        border: none;
      }
      /deep/.el-form-item__error {
        width: 100%;
      }
    }
    .textarea-style {
      width: 94.25%;
      padding-right: 0;
      /deep/.el-input__count {
        height: 28px;
      }
    }
  }
}
</style>
