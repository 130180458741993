<template>
  <div class="org-container">
    <j-drawer-label type="title" title="考核明细" />
    <el-form
      v-for="(form, i) in formList"
      :key="i"
      :model="form"
      :rules="formRules"
      :ref="`addOrgForm-${i}`"
      class="org-form"
    >
      <el-button
        v-if="formList.length !== 1"
        class="add-org-card"
        icon="el-icon-close"
        circle
        size="mini"
        @click="deleteOrgCard(i)"
      ></el-button>

      <el-form-item prop="orgId">
        <j-page-search title="考核机构">
          <el-cascader
            v-model="form.orgId"
            :options="orgTreeData"
            :props="{
              children: 'childrens',
              label: 'name',
              value: 'id',
              emitPath: false,
            }"
            placeholder="请选择"
          />
        </j-page-search>
      </el-form-item>
      <el-form-item prop="scoreType">
        <j-page-search title="计分类型">
          <el-select v-model="form.scoreType">
            <el-option
              v-for="item in scoreOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </j-page-search>
      </el-form-item>
      <el-form-item prop="score">
        <j-page-search title="计分分数">
          <el-input-number
            v-model="form.score"
            controls-position="right"
            :precision="2"
            :step="0.1"
            :min="0"
            :max="100"
          ></el-input-number>
        </j-page-search>
        <!-- <el-input
              onkeyup="this.form.score = this.form.score.replace(/[^\d.]/g,'');"
              v-model="form.score"
              placeholder="请输入"
            ></el-input> -->
      </el-form-item>
      <el-form-item prop="scoreNote">
        <j-page-search title="计分说明" class="textarea-style">
          <el-input
            v-model="form.scoreNote"
            type="textarea"
            placeholder="请输入"
          ></el-input>
        </j-page-search>
      </el-form-item>
      <el-form-item label="图片" class="file-style">
        <j-upload
          class="upload-img"
          v-model="form.imgList"
          type="String"
          list-type="picture-card"
          @upload-complete="(data) => setFileList(data, i)"
          :sizeLimit="10"
          :mimeTypeLimit="imgTypeLimitList"
          need-fully
        >
          <!-- <i class="el-icon-plus" style="font-size: 18px"></i> -->
          <j-svg-icon class="upload-icon" name="upload-img" />
        </j-upload>
      </el-form-item>
      <el-form-item label="附件" class="file-style">
        <j-upload
          v-model="form.fileList"
          type="String"
          list-type="text"
          @upload-complete="(data) => setFileList(data, i)"
          :sizeLimit="10"
          :mimeTypeLimit="fileTypeLimitList"
          need-fully
        >
          <el-button icon="el-icon-plus" type="primary" plain
            >上传附件</el-button
          >
        </j-upload>
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="addCheckedOrg" class="add-btn"
      >新增考核机构</el-button
    >
  </div>
</template>

<script>
import PageMixins from "@/components/JComponents/JPage/mixins/PageMixins";

import JDrawerLabel from "@/components/JComponents/JDrawer/JDrawerLabel";
import JUpload from "@/components/JComponents/JUpload/components/JUpload.vue";

export default {
  name: "EditCheckedOrg",
  mixins: [PageMixins],
  components: {
    JDrawerLabel,
    JUpload,
  },
  data() {
    return {
      scoreOptions: [
        { label: "加分", value: "+" },
        { label: "减分", value: "-" },
      ],
      formList: [{}],
      formRules: {
        orgId: [{ required: true, message: "请选择考核机构" }],
        scoreType: [{ required: true, message: "请选择计分类型" }],
        score: [
          { required: true, message: "请输入计分分数", trigger: "change" },
        ],
        scoreNote: [{ max: 150, message: "最多输入150个字符" }],
      },
      imgTypeLimitList: [".jpg", ".jpeg", ".png", ".gif"],
      fileTypeLimitList: [".doc", ".docx", ".xls", ".xlsx", ".pdf"],
    };
  },
  computed: {
    // 城管委机关单位不参与考核 去除
    orgTreeData() {
      return this.$store.state.orgTree.filter(
        (item) => item.name !== "城管委机关单位"
      );
    },
  },
  methods: {
    addCheckedOrg() {
      const newForm = {};
      this.formList.push(newForm);
    },
    setFileList(data, i) {
      if (!this.formList[i].files) {
        this.formList[i].files = [];
      }
      let { name, suffix, meta } = data;
      // if (this.formList[i].files.findIndex((e) => e.fileUrl === meta) !== -1)
      //   return;
      this.formList[i].files.push({
        fileName: name,
        fileType: [".jpg", ".jpeg", ".png", ".gif"].includes(suffix) ? 1 : 2,
        fileUrl: meta,
      });
    },
    deleteOrgCard(i) {
      this.formList.splice(i, 1);
    },
  },
};
</script>

<style lang="less" scoped>
.org-container {
  .title {
    padding: 20px 0;
  }
  .org-form {
    border-bottom: solid 1px #ddd;
    padding: 5px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    margin-left: 5px;
    position: relative;
    .add-org-card {
      position: absolute;
      right: -14px;
      top: -10px;
      margin: 0 16px;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    /deep/.el-form-item {
      margin-bottom: 0px;
      .el-form-item__content {
        line-height: 15px;
      }
      .el-form-item__error {
        margin: 0 5px;
        position: relative;
      }
    }
    .j-page-search-wrap {
      width: 92%;
      padding-right: 0px;
      overflow: hidden;
      .j-page-search-wrap_content {
        width: 92%;
      }
      /deep/.el-input__inner,
      .el-date-editor,
      .el-input-number {
        width: 335px !important;
      }
      /deep/.el-textarea__inner {
        width: 331px;
        border: none;
      }
      /deep/.el-form-item__error {
        width: 100%;
      }
      /deep/.el-input-number {
        input {
          text-align: left;
        }
        span {
        }
      }
    }
    .textarea-style {
      width: 92.25%;
      padding-right: 0;
      /deep/.el-input__count {
        height: 28px;
      }
    }
    .file-style {
      padding: 0 17px;
      margin: 10px 0;
    }
    /deep/.el-input-number {
      width: 92px !important;
    }
    .upload-img {
      /deep/.el-upload {
        background: rgba(0, 185, 220, 0.2);
      }
    }
    .upload-icon {
      font-size: 20px;
    }
  }
  .add-btn {
    display: block;
    width: calc(100% - 5px);
    margin: 5px 5px 15px 5px;
  }
}
</style>
