<!--
 * @Description: 
 * @Author: tengmingxin
 * @Date: 2022-04-14 14:30:38
 * @LastEditors: tengmingxin
 * @LastEditTime: 2022-05-05 10:46:01
-->
<template>
  <div class="table-container">
    <div v-if="table.data.length===0" class="common-empty-wrap"></div>
    <el-table
      v-else
      ref="tableRef"
      v-loading="table.loading"
      style="width: 99.5%"
      highlight-current-row
      :header-cell-style="{ background: '#f4fafe'}"
      :data="table.data"
      size="medium"
      @selection-change="(e) => $emit('selection-change', e)"
    >
      <el-table-column v-if="table.showSelection" type="selection" width="55" />
      <el-table-column v-if="table.expand" type="expand" width="55">
        <template slot-scope="scope">
          <slot name="expand" :row="scope.row"></slot>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, index) in columns"
        :key="index"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        :min-width="item.minWidth"
        align="center"
        :sortable="item.sortable"
        :show-overflow-tooltip="!item.width"
      >
        <template slot-scope="scope">
          <slot :name="item.prop" :row="scope.row">
            <span>{{
              item.prop === "index"
                ? scope.$index + 1
                : getLable(scope.row[item.prop], item.options, item.prop)
            }}</span>
          </slot>
        </template>
      </el-table-column>
      <el-table-column
        v-if="handleBtns.length > 0"
        label="操作"
        fixed="right"
        align="center"
        :width="52 * handleBtns.length"
      >
        <template slot-scope="scope">
          <span
            v-for="(item, index) in handleBtns"
            v-show="!item.validator || getIsShow(item.validator, scope.row)"
            :key="index"
            class="tools"
          >
            <el-link
              :underline="false"
              :style="{ color: item.key === 'delete' ? '#FE693D' : '#0E90A8' }"
              @click.stop="onHandle(scope, item.key)"
            >
              <i v-if="item.icon" :class="item.icon" />{{ item.name }}
            </el-link>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="table.total > 10"
      :current-page="table.currentPage"
      :page-size="table.limit"
      :total="table.total"
      layout="total, prev, pager, next"
      background
      @current-change="handleCurrentChange"
    />
  </div>
</template>
<script>
export default {
  props: {
    table: {
      type: Object,
      default: () => ({
        loading: false,
        data: [],
        total: 0,
        currentPage: 1,
        limit: 10,
        showSelection: false,
      }),
    },
    columns: {
      type: Array,
      default: () => [],
    },
    handleBtns: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    "table.showSelection": function (val) {
      if (!val) this.$refs.tableRef.clearSelection();
    },
  },
  methods: {
    handleCurrentChange(currentPage) {
      this.$emit("pageChange", currentPage);
    },
    getIsShow(validator, row) {
      if (typeof validator === "function") {
        return validator(row);
      }
      const value = row[validator.key];
      const Fn = Function;
      return new Fn("value", `return ${validator.show}`)(value);
    },
    onHandle(scope, type) {
      if (type === "delete") {
        this.$confirm("确定要删除该条数据?", "删除", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$emit("onHandle", { scope, type });
        });
      } else {
        this.$emit("onHandle", { scope, type });
      }
    },
    getLable(value, options, prop) {
      return (!value && value !== 0) || !options
        ? value
        : options.find((e) => e.value === value).name;
    },
  },
};
</script>
<style lang="less">
.table-container {
  height: 100%;
  .common-empty-wrap{
    height: 100%;
    background-size:142px 114px;
    margin: 0;
    &::before{
      top: calc(50% + 70px);
    }
  }
  .tools {
    span {
      font-size: 14px;
    }
    & + .tools {
      margin-left: 20px;
    }
  }
  .el-table__header {
    height: 44px;
    .el-table__cell {
      padding: 0;
      margin: 0;
    }
    .cell {
      color: #1D1F22;
      line-height: 20px;
      font-size: 14px;
    }
  }
  .el-table__body {
    .el-table__cell {
      padding: 14px 0;
    }
    .cell {
      color: rgba(0, 0, 0, 0.8);
      line-height: 20px;
      font-size: 14px;
    }
  }
  .el-pagination {
    float: right;
    margin: 21px 0;
    span,
    button,
    li {
      font-size: 14px !important;
      line-height: 32px;
      height: 32px;
    }
  }
}
.el-tooltip__popper {
  max-width: 40%;
}
</style>
