<template>
    <j-page class="wait-list-layout">
        <div slot="search">
            <j-page-search title="所属机构">
                <el-cascader v-model="query.orgId" :options="$store.state.orgTree" :props="{ children: 'childrens', label: 'name', value: 'id', emitPath: false, checkStrictly: true }" placeholder="请选择所属机构" clearable />
            </j-page-search>
            <j-page-search title="姓名">
                <el-input v-model.trim="query.userName" placeholder="请输入姓名" clearable />
            </j-page-search>
            <j-page-search title="联系电话">
                <el-input type="number" v-model.trim="query.userMobile" placeholder="请输入联系电话" clearable />
            </j-page-search>
            <j-page-search title="身份证号">
                <el-input type="text" v-model.trim="query.idcard" placeholder="请输入身份证号" clearable />
            </j-page-search>
            <j-page-search title="角色">
                <el-select v-model="query.roleId" placeholder="请选择角色" clearable filterable>
                    <el-option v-for="item in roleList" :key="item.roleId" :label="item.name" :value="item.roleId" />
                </el-select>
            </j-page-search>
            <j-page-search title="状态">
                <j-dic-select code="STATUS_TYPE" v-model="query.status" placeholder="请选择状态" />
            </j-page-search>
            <j-page-search title="考核月份">
                <el-date-picker v-model="query.yearMonth" type="month"  placeholder="请选择考核月份" value-format="yyyy/MM" />
            </j-page-search>
            <j-page-search title="评鉴等级">
                <j-dic-select code="EVALUATION_LEVEL" v-model="query.appraiseLevel" placeholder="请选择评鉴等级" />
            </j-page-search>
            <j-page-search title="评鉴分数">
                <j-link-input style="width: 280px;" :start.sync="query.appraiseScoreGreaterThan" :end.sync="query.appraiseScoreLessThan"/>
            </j-page-search>
            <j-page-search title="个人小结">
                <j-dic-select code="PERSONAL_SUMMARY_STATUS" v-model="query.personalSummaryStatus" placeholder="请选择个人小结" />
            </j-page-search>
            <el-button type="primary" @click="searchEvent">查询</el-button>
            <el-button @click="resetEvent">重置</el-button>
        </div>
        <div slot="table">
            <el-table v-loading="tableLoading" :data="tableData" @selection-change="tableSelectionChange" height="100%">
                <el-table-column prop="status" label="状态" align="center" width="50">
                    <template slot-scope="{ row }">
                        <span :class="`color-status-style color-status${row.status}`" />
                    </template>
                </el-table-column>
                <el-table-column prop="title" label="考核月份" align="center" show-overflow-tooltip>
                    <template slot-scope="{ row }">{{`${row.year}/${row.month}`}}</template>
                </el-table-column>
                <el-table-column prop="orgName" label="所属机构" align="center" show-overflow-tooltip />
                <el-table-column prop="roleName" label="角色" align="center" show-overflow-tooltip />
                <el-table-column prop="userName" label="姓名" align="center" show-overflow-tooltip />
<!--                <el-table-column prop="cardCode" label="身份证号" align="center" show-overflow-tooltip>-->
<!--                    <j-sensitive slot-scope="{ row }" type="id" v-model="row.cardCode"/>-->
<!--                </el-table-column>-->
                <el-table-column prop="userMobile" label="联系电话" align="center" show-overflow-tooltip />
                <el-table-column prop="completeProportion" label="评鉴进度" align="center" show-overflow-tooltip />
                <el-table-column prop="appraiseTime" label="评鉴日期" align="center" show-overflow-tooltip />
                <el-table-column prop="appraiseLevelName" label="评鉴等级" align="center" show-overflow-tooltip />
                <el-table-column prop="appraiseScore" label="评鉴分数" align="center" show-overflow-tooltip />
                <el-table-column label="操作" width="100">
                    <template slot-scope="{row}">
                        <el-button @click.stop="evaluateEvent('DET', row)" type="primary" class="el-button el-button--text">详情</el-button>
                        <el-button v-if="curItem.status != 3" @click.stop="evaluateEvent('ADD', row)" type="primary" class="el-button el-button--text">修改</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div slot="pagination" v-show="query.total > query.limit">
            <el-pagination
                    :current-page="query.page"
                    :page-size="query.limit"
                    :total="query.total"
                    :background="pmxBackground"
                    :pager-count="pmxCount"
                    :layout="pmxLayout"
                    :pageSizes="pmxSizes"
                    @current-change="changePage"
                    @size-change="sizeChange"
            />
        </div>
        <j-drawer :title="drmxTypeMap[drmxType]" :close.sync="showDrmx">
            <div>
                <j-drawer-label type="title" title="基本信息" />
                <div style="padding-right: 30px;">
                    <j-drawer-label align="right" title="所属机构">{{ userBasicInfo.orgName }}</j-drawer-label>
                    <j-drawer-label align="right" title="角色">{{ userBasicInfo.roleName }}</j-drawer-label>
                    <j-drawer-label align="right" title="姓名">{{ userBasicInfo.name || curItem.userName }}</j-drawer-label>
                    <j-drawer-label align="right" title="身份证号">
                        <j-sensitive type="id" v-model="userBasicInfo.cardCode" eye/>
                    </j-drawer-label>
                    <j-drawer-label align="right" title="性别">
                        <j-dictionary code="GENDER" :value="userBasicInfo.sex" />
                    </j-drawer-label>
                    <j-drawer-label align="right" title="联系电话">{{ userBasicInfo.mobile || curItem.userMobile }}</j-drawer-label>
                    <j-drawer-label align="right" title="状态">{{ userBasicInfo.statusName }}</j-drawer-label>
                </div>
            </div>
            <div>
                <j-drawer-label type="title" title="个人总结" />
                <div v-if="personalSummaryInfo" style="padding-right: 30px;">
                    <j-drawer-label align="right" title="考核月份">{{ personalSummaryInfo.yearMonth }}</j-drawer-label>
                    <j-drawer-label align="right" title="自评分">{{ personalSummaryInfo.score }}</j-drawer-label>
                    <j-drawer-label align="right" title="月度工作情况">{{ personalSummaryInfo.monthlyWorkSummary }}</j-drawer-label>
                    <j-drawer-label align="right" title="未完成或完成质效不高的工作">{{ personalSummaryInfo.monthlyIncompleteLowefficiency }}</j-drawer-label>
                    <j-drawer-label align="right" title="更新时间">{{ personalSummaryInfo.updateTime }}</j-drawer-label>
                </div>
                <div v-else class="common-empty-wrap" />
            </div>
            <div>
                <j-drawer-label type="title" title="领导评鉴" />
                <div style="padding-right: 30px;">
                    <j-drawer-label align="right" title="评鉴进度">{{ leaderAppraise.completeProportion }}</j-drawer-label>
                    <j-drawer-label align="right" title="考核月份">{{ leaderAppraise.yearMonth }}</j-drawer-label>
                    <j-drawer-label align="right" title="状态">{{ leaderAppraise.personalSummaryStatusName }}</j-drawer-label>
                    <template v-if="drmxType === 'DET'">
                        <j-drawer-label align="right" title="评鉴人">{{ leaderAppraise.appraiseUserName }}</j-drawer-label>
                        <j-drawer-label align="right" title="评鉴日期">{{ leaderAppraise.appraiseTime }}</j-drawer-label>
                        <j-drawer-label align="right" title="评鉴等级">{{ leaderAppraise.appraiseLevelName }}</j-drawer-label>
                        <j-drawer-label align="right" title="评鉴分数">{{ leaderAppraise.appraiseScore }}</j-drawer-label>
                        <j-drawer-label align="right" title="评鉴说明">{{ leaderAppraise.appraiseDiscripotion }}</j-drawer-label>
                    </template>
                    <div v-if="drmxType === 'ADD'">
                        <el-form ref="drmxDom" :model="drmxForm" :rules="drmxRule" :label-width="drmxFormLabelWidth">
                            <div style="padding-right: 30px;">
                                <el-form-item label="评鉴等级" prop="appraiseLevel">
                                    <j-dic-select code="EVALUATION_LEVEL" v-model="drmxForm.appraiseLevel" placeholder="请选择评鉴等级" />
                                </el-form-item>
                                <el-form-item label="评鉴分数" prop="appraiseScore">
                                    <span v-if="drmxForm.appraiseLevel">{{`评鉴分数范围：${appraiseLevelMap[drmxForm.appraiseLevel].min}-${appraiseLevelMap[drmxForm.appraiseLevel].max}`}}</span>
                                    <el-input v-model.trim="drmxForm.appraiseScore" placeholder="请输入评鉴分数" type="number" clearable />
                                </el-form-item>
                                <el-form-item label="评鉴说明" prop="appraiseDiscripotion">
                                    <el-input v-model.trim="drmxForm.appraiseDiscripotion" type="textarea" :rows="3" placeholder="请输入评鉴说明" maxlength="150" show-word-limit clearable />
                                </el-form-item>
                            </div>
                        </el-form>
                    </div>
                </div>
            </div>
            <el-button v-if="drmxType === 'ADD'" slot="footer" @click="checkDrmxEvent('saveDrmxData')" :loading="submitLoad">修改</el-button>
        </j-drawer>
    </j-page>
</template>

<script>
    /**
     * @Author: fengjunjie <fengjunjie@btzh.cn>
     * @Description: jobrating_pc
     * @Date: 2022/04/18 16:26
     */
    import PageMixins from "../../components/JComponents/JPage/mixins/PageMixins";
    import PaginationMixins from "../../components/JComponents/JPage/mixins/PaginationMixins";
    import DrawerMixins from "../../components/JComponents/JPage/mixins/DrawerMixins";
    export default {
        name: "alreadyList",
        mixins: [PageMixins, PaginationMixins, DrawerMixins],
        data () {
            const checkAppraiseScore = (rule, value, callback) => {
                if (!this.drmxForm.appraiseLevel) {
                    return callback(new Error('请先选择评鉴等级'))
                }
                if (value === '' && rule.required) {
                    return callback(new Error('请输入评鉴分数'))
                }

                if (Number(value) < this.appraiseLevelMap[this.drmxForm.appraiseLevel].min || Number(value) > this.appraiseLevelMap[this.drmxForm.appraiseLevel].max) {
                    return callback(new Error('请根据评鉴分数范围输入'))
                }
                callback()
            };
            return {
                query: {
                    appraiseLevel: '',
                    appraiseScoreGreaterThan: '',
                    appraiseScoreLessThan: '',
                    idcard: '',
                    orgId: '',
                    personalSummaryStatus: '',
                    roleId: '',
                    status: '',
                    userMobile: '',
                    userName: '',
                    yearMonth: ''
                },
                roleList: [],
                drmxTypeMap: {
                    'ADD': '评鉴',
                    'DET': '详情'
                },
                drmxForm: {
                    "appraiseDiscripotion": "",
                    "appraiseLevel": "",
                    "appraiseScore": "",
                },
                drmxRule: {
                    appraiseScore: [{ required: true, validator: checkAppraiseScore, trigger: 'blur' }],
                    appraiseLevel: [{ required: true, message: '请选择评鉴等级', trigger: 'change' }]
                },
                appraiseLevelMap: {
                    1: { min: 90, max: 100 },
                    2: { min: 80, max: 89 },
                    3: { min: 61, max: 79 },
                    4: { min: 0, max: 60 }
                },
                userBasicInfo: {},
                personalSummaryInfo: null,
                leaderAppraise: {}
            }
        },
        created () {
            this.getRoleList()
            this.load()
        },
        methods: {
            async load () {
                this.tableLoading = true
                const { data } = await this.$http.get(`/api/leaderAppraise/getCompletedTaskList`, {
                    params: this.query
                })
                this.tableData = data.data.data
                this.query.total = data.data.total
                this.tableLoading = false
            },
            async getRoleList () {
                const { data } = await this.$http.get(`/api/roles`)
                this.roleList = data.data
            },
            async getDetailData (row) {
                const { data } = await this.$http.get(`/api/leaderAppraise/${row.id}`)
                this.leaderAppraise = data.data.leaderAppraise || {}
                this.personalSummaryInfo = data.data.personalSummaryInfo || null
                this.userBasicInfo = data.data.userBasicInfo || {}
                this.drmxForm.appraiseDiscripotion = this.leaderAppraise.appraiseDiscripotion || ''
                this.drmxForm.appraiseLevel = this.leaderAppraise.appraiseLevel || ''
                this.drmxForm.appraiseScore = this.leaderAppraise.appraiseScore || ''
            },
            async evaluateEvent (type, row) {
                this.drmxType = type
                this.drmxForm.id = row.id
                this.curItem = { ...row }
                await this.getDetailData(row)
                this.showDrmx = true
            },
            async saveDrmxData () {
                if (this.submitLoad) return
                this.submitLoad = true
                let method = 'put'
                let url = `/api/leaderAppraise`
                const { data } = await this.$http[method](url, this.drmxForm)
                    .catch(({response}) => { this.$message.error(response.data.msg) })
                    .finally(() => { this.submitLoad = false })
                if (data.status !== 'yes') return this.$message.error(data.msg)
                this.showDrmx = false
                this.$message.success('提交成功')
                this.load()
                this.$parent.$parent && this.$parent.$parent.getCount()
            }
        }
    }
</script>

<style lang="less">
    .color-status-style {
        display: inline-flex;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        &.color-status1 {
            background: #C0C4CC;
        }
        &.color-status2 {
            background: #E6A23C;
        }
        &.color-status3 {
            background: #F56C6C;
        }
    }
    .wait-list-layout {
        position: static!important;
        .j-page-layout_wrap {
            padding: 0!important;
            position: static!important;
        }
    }
</style>
