<template>
    <j-page class="role-manage-layout">
        <!--        按钮区域-->
        <div slot="search">
            <el-button type="warning" icon="el-icon-plus" @click="addEvent">新增角色</el-button>
        </div>
        <!--        数据列表-->
        <div slot="table" class="role-manage-table">
            <el-table stripe v-loading="loading" :data="roleList" height="100%">
                <el-table-column prop="roleId" label="ID" align="center" width="50" />
                <el-table-column prop="name" label="名称" align="center" show-overflow-tooltip />
                <el-table-column prop="description" label="描述" align="center" show-overflow-tooltip />
                <el-table-column label="操作" width="160">
                    <template slot-scope="scope">
                        <el-button @click.stop="editRole(scope.row)" type="primary" class="el-button el-button--text">编辑</el-button>
                        <el-button @click.stop="editRoleMenu(scope.row)" type="primary" class="el-button el-button--text">菜单授权</el-button>
                        <el-button @click.stop="delRole(scope.row)" type="primary" class="el-button el-button--text font-red" >删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!--        新增/编辑弹窗-->
        <el-dialog :title="ctrlTitle[ctrlType]" :visible.sync="showAdd" width="350px" :close-on-click-modal="false" @keydown.native.enter="checkEvent('AddRuleDom', saveRule)">
            <el-form :model="formData" :rules="formRule" ref="AddRuleDom" :inline="false">
                <el-form-item label="名称" prop="name">
                    <el-input v-model="formData.name" placeholder="请输入名称" maxlength="20" show-word-limit clearable />
                </el-form-item>
                <el-form-item label="描述" prop="description">
                    <el-input v-model="formData.description" type="textarea" :rows="3" placeholder="请输入描述" maxlength="200" show-word-limit clearable />
                </el-form-item>
            </el-form>
            <span slot="footer">
                <el-button type="primary" @click="checkEvent('AddRuleDom', saveRule)" :loading="submitLoad">保 存</el-button>
                <el-button @click="showAdd = false">取 消</el-button>
            </span>
        </el-dialog>

        <!--       授权弹窗-->
        <el-dialog title="菜单授权" :visible.sync="showAuth" width="650px" top="10%" :close-on-click-modal="false" @keydown.native.enter="saveAuth">
            <role-menu-tree v-if="showAuth" ref="authTree" :meta="curItem" />
            <span slot="footer">
                <el-button type="primary" @click="saveAuth" :loading="submitLoad">授 权</el-button>
                <el-button @click="showAuth = false">取 消</el-button>
            </span>
        </el-dialog>
    </j-page>
</template>

<script>
    import JPage from "../../../components/JComponents/JPage/JPage";
    import RoleMenuTree from "./RoleMenuTree";
    export default {
        name: "RoleManage",
        components: { JPage, RoleMenuTree },
        data () {
            return {
                loading: false,
                submitLoad: false,
                ctrlTitle: {
                  "ADD": "新增角色",
                  "EDIT": "编辑角色"
                },
                showAuth: false,
                showAdd: false,
                ctrlType: 'ADD',
                formData: {
                    name: '',
                    description: '',
                    menus: [],
                    buttons: []
                },
                formRule: {
                    name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
                    description: [{ required: false, message: '请输入描述', trigger: 'change' }]
                },
                curItem: {},
                roleList: [],
                monitorChange: ['EDIT'], // 需要监听编辑改动变化的状态值
            }
        },
        created () {
          this.getRoleList()
        },
        methods: {
            async getRoleList () {
                this.loading = true
                const { data } = await this.$http.get(`/api/roles`).finally(() => { this.loading = false })
                if (data.status !== 'yes') return this.$message.error(data.msg || '获取角色列表异常')
                this.roleList = data.data
            },
            editRoleMenu (row) {
                this.curItem = row
                this.showAuth = true
            },
            async saveAuth () {
                if (this.submitLoad) return
                this.submitLoad = true
                let method = 'put'
                let url = `/api/roles/${this.curItem.roleId}`
                const { data } = await this.$http[method](url, {
                    ...this.curItem,
                    ...this.$refs.authTree.getTreeData()
                }).finally(() => {
                    this.submitLoad = false
                })
                if (data.status !== 'yes') return this.$message.error(data.msg || '角色授权异常')
                this.showAuth = false
                this.$message.success('授权成功')
            },
            addEvent () {
                this.ctrlType = 'ADD'
                this.showAdd = true
            },
            editRole (row) {
                this.ctrlType = 'EDIT'
                this.curItem = { ...row }
                this.formData = this.proxyData({ ...row })
                this.showAdd = true
            },
            delRole (row) {
                this.$confirm('您确认要删除当前角色吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http
                        .request({
                            method: 'delete',
                            url: `/api/roles/${row.roleId}`
                        })
                        .then((res) => {
                            if (res.data.status !== 'yes') return this.$message.error(res.data.msg || '删除角色异常')
                            this.$message.success('删除成功')
                            this.getRoleList()
                            this.$store.dispatch('getRoleList')
                        })
                })
            },
            async saveRule () {
                if (this.submitLoad) return
                this.submitLoad = true
                let method = 'post'
                let url = `/api/roles`
                if (this.ctrlType === 'EDIT') {
                    url = `/api/roles/${this.curItem.roleId}`
                    method = 'put'
                }
                const { data } = await this.$http[method](url, this.formData).finally(() => {
                    this.submitLoad = false
                })
                if (data.status !== 'yes') return this.$message.error(data.msg || '提交角色异常')
                this.showAdd = false
                this.$message.success('提交成功')
                this.getRoleList()
                this.$store.dispatch('getRoleList')
            },

            // 交互校验相关方法
            checkProxy () {
                if (!this.monitorChange.includes(this.ctrlType)) return true
                let flag = false
                for (let key in this.changeProxy) {
                    if (this.curItem[key] !== this.changeProxy[key]) {
                        flag = true
                        break
                    }
                }
                return flag
            },
            proxyData (obj) {
                this.changeProxy = {}
                const that = this
                if (!obj) return {}
                return new Proxy(obj, {
                    set (target, p, value, receiver) {
                        that.changeProxy[p] = value
                        return Reflect.set(target, p, value, receiver)
                    }
                })
            },
            checkEvent (target, funName) {
                if (!this.checkProxy()) return this.$message.info('数据未变动，无需重复保存')
                this.$refs[target].validate((valid) => {
                    if (valid) {
                        funName()
                    }
                })
            }
        },
        watch: {
            showAdd (nv) {
                if (!nv) {
                    this.$refs['AddRuleDom'] && this.$refs['AddRuleDom'].resetFields()
                    this.formData = { ...this.$options.data().formData }
                }
            }
        }
    }
</script>

<style lang="less">
    .role-manage-layout {
        .role-manage-table {
            height: 100%;
        }
    }
</style>
