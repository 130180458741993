// 新增编辑
/* <j-drawer :title="drmxTypeMap[drmxType]" :close.sync="showDrmx">
    <div>
        <el-form ref="drmxDom" :model="drmxForm" :rules="drmxRule" :label-width="drmxFormLabelWidth">
            <j-drawer-label type="title" title="基本信息" />
            <div style="padding-right: 30px;">
                <el-form-item label="输入" prop="input">
                    <el-input v-model.trim="drmxForm.input" placeholder="请输入" maxlength="200" clearable />
                </el-form-item>
                <el-form-item label="选择" prop="select">
                    <el-select v-model="drmxForm.select" placeholder="请选择" style="width: 100%" clearable filterable>
                        <el-option v-for="(item, i) in ['选项1', '选项2']" :key="i" :label="item" :value="i" />
                    </el-select>
                </el-form-item>
            </div>
        </el-form>
    </div>
    <div slot="footer" style="display: flex; width: 100%;">
        <el-button @click="checkDrmxEvent('saveDrmxData')" :loading="submitLoad">保存</el-button>
        <el-button @click="showDrmx = false" :loading="submitLoad">支持多个按钮</el-button>
    </div>
 </j-drawer> */

// 详情查看
/* <j-drawer :title="drmxTypeMap[drmxType]" :close.sync="showDrmxDetail">
        <div>
            <j-drawer-label type="title" title="基本信息" />
            <div style="padding-right: 30px;">
            <j-drawer-label align="right" title="输入框">{{ curItem.name }}</j-drawer-label>
            <j-drawer-label align="right" title="选择框">{{ curItem.sex }}</j-drawer-label>
            </div>
        </div>
        <el-button slot="footer" @click="showDrmx = false" :loading="submitLoad">单按钮</el-button>
  </j-drawer> */

import JDrawer from "../../JDrawer/JDrawer";
import JDrawerLabel from "../../JDrawer/JDrawerLabel";
export default {
  components: {
    JDrawer,
    JDrawerLabel
  },
  data () {
    return {
      curItem: {}, // 当前选中项
      drmxChangeProxy: {},
      submitLoad: false,
      drmxFormLabelWidth: '100px',
      showDrmx: false,
      showDrmxDetail: false,
      drmxType: 'ADD',
      drmxTypeMap: {
        'ADD': '新增',
        'EDIT': '编辑',
        'DET': '详情'
      },
      drmxForm: {
        // input: '',
        // select: ''
      },
      drmxRule: {
        // input: [{ required: true, message: '请输入', trigger: 'blur' }],
        // select: [{ required: true, message: '请选择', trigger: 'change' }]
      }
    }
  },
  methods: {
    detailDrmxEvent (row) {
      this.drmxType = 'DET'
      this.curItem = { ...row }
      this.showDrmxDetail = true
    },
    editDrmxEvent (row) {
      this.drmxType = 'EDIT'
      this.curItem = { ...row }
      this.drmxForm = this.proxyDrmxData({ ...this.drmxForm, ...row })
      this.showDrmx = true
    },
    addDrmxEvent () {
      this.drmxType = 'ADD'
      this.showDrmx = true
    },
    checkDrmxProxy () {
      if (this.drmxType !== 'EDIT') return true
      let flag = false
      for (let key in this.drmxChangeProxy) {
        if (this.curItem[key] !== this.drmxChangeProxy[key]) {
          flag = true
          break
        }
      }
      return flag
    },
    proxyDrmxData (obj) {
      const that = this
      if (!obj) return {}
      return new Proxy(obj, {
        set (target, p, value, receiver) {
          that.drmxChangeProxy[p] = value
          return Reflect.set(target, p, value, receiver)
        }
      })
    },
    checkDrmxEvent (funName) {
      if (!this.checkDrmxProxy()) return this.$message.info('数据未变动，无需重复保存')
      this.$refs['drmxDom'].validate((valid) => {
        if (valid) {
          this[funName]()
        }
      })
    },
    async saveDrmxData () {
      if (this.submitLoad) return
      this.submitLoad = true
      let method = 'post'
      let url = `/api/personalDocs`
      if (this.drmxType === 'EDIT') {
        url = `/api/personalDocs/${this.curItem.id}`
        method = 'put'
      }
      const { data } = await this.$http[method](url, this.drmxForm)
            .catch(({response}) => { this.$message.error(response.data.msg) })
            .finally(() => { this.submitLoad = false })
      if (data.status !== 'yes') return this.$message.error(data.msg)
      this.showDrmx = false
      this.$message.success('提交成功')
      this.load()
    }
  },
  watch: {
    showDrmx (nv) {
      if (!nv) {
        this.drmxForm = { ...this.$options.data().drmxForm }
        this.drmxChangeProxy = { ...this.$options.data().drmxChangeProxy }
      }
    }
  }
}
