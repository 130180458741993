<template>
    <span v-if="ov" class="j-sensitive-wrap">
        {{ eyeStyle === 'close' ? nv : ov }}
        <i v-if="eye && ov" :class="`j-sensitive-eye-${eyeStyle}`" @click="eyeChange" />
    </span>
</template>

<script>
export default {
  name: 'JSensitive',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    rule: { // 第一位为正数表示正向保留多少位， 第二位为负数表示倒向保留多少位
      type: Array,
      default: () => [1]
    },
    type: { // [id, phone, name]
      type: String,
      default: ''
    },
    eye: { // 关闭、开启脱敏开关
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      eyeStyle: 'close',
      nv: '',
      ov: ''
    }
  },
  watch: {
    value (nv) {
      this.ov = String(nv)
      this.updateSensitive()
    }
  },
  created () {
    this.ov = String(this.value)
    this.updateSensitive()
  },
  methods: {
    eyeChange () {
      if (this.eyeStyle === 'close') this.eyeStyle = 'open'
      else if (this.eyeStyle === 'open') this.eyeStyle = 'close'
    },
    updateSensitive () {
      // 姓名 [1] 张**
      if (this.type === 'name') return this.handleSensitive([1])
      // 手机号 [3, -2] 131******91
      if (this.type === 'phone') return this.handleSensitive([3, -2])
      // 身份证号 [1, -1] 2**************7
      if (this.type === 'id') return this.handleSensitive([1, -1])
      this.handleSensitive(this.rule)
    },
    handleSensitive (rule) {
      let start = this.ov.substr(0, rule[0])
      let end = this.ov.substr(rule[1] || this.ov.length)
      let center = this.ov.substring(rule[0], this.ov.length + (rule[1] || 0))
      this.nv = `${start}${'*'.repeat(center.length)}${end}`
    }
  }
}
</script>

<style scoped lang="less">
    .j-sensitive-wrap {
        display: inline-flex;
        align-items: center;
        i {
            display: inline-block;
            width: 24px;
            height: 24px;
            background-size: contain;
            background-repeat: no-repeat;
            margin: 0 5px;
            cursor: pointer;
        }
        .j-sensitive-eye-open {
            background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAEdUlEQVRoQ+1YTWhcVRT+zn0ZSZYuQqtGtIUYBMVFFSIkM+++zEhcaBXtgGICsRoR7UKKuBBcioi4kFaoliozopiioiIiybw7M9EYsEIX/qFttVb8IajbZH7ukTu8F4Yw834ymUrgPRiY4Z3z3fOd33uGsMsf2uX2IyHwf0cwiUASgR49kKRQjw7sWT2JQM8u7BEgiYDvQKVUlpn3E9F+AJsfZmYi+gnAOWa+QEQXAVyUUi716PyWek8RWFhYsIaHh2cAzAKQMQ1SAApra2vFfD7fjKm7Kb4tAqurq3vW19dnmdkYflPLE0Q1Zv6MiM553v7Osqxva7WatixrlIhGAYwy8w3MPEFE13hWfENEhcHBwcL4+PhfcYnEJqCUmgBwEsCYd9glIirW6/W3crnc91EMqFarw1rrOa31w0TUwmHm8wCOOo7zYRQMXyYWgVKpdK8Q4n1P+Swzv5FKpYqTk5P/xjnUl11ZWRmq1WpzzPy4H0lmftZxnOej4kUmoJQ6CuAlD/j4xsbGc9PT0/9EPShIrlKp7NNavw5gypM7KaV8NAp2JAJKqU8BTHu5/qBt2+8EgbuuOy+EmGDmSU9nWWv9ueM4rwXplcvlt5n5AU9mSUqZCyMRSkAp9QyAFwxQo9G4LpfL/RoEqpQ6A+BAF5mvpZS3hpA4wsyvGBnLsu5Pp9PvBckHEqhUKqNa6x+9IptwHOeLEOMXABzyvJ5vNBpV831gYCDNzOadeU5LKfNBOKVSaU4IccrIDA0N7Q3qToEElFJlAJmIh84IIQrmUCllR1ylFJv3WuvZqampYkgampZ8B4A3pZRz3WS7EnBd92kiejHIoHZQpVTL+0KIg5lM5qNOB1Yqlbu11qZNhkbB6PuEATwppTzeCbMrAaWUmZQ2EeVt2z4dVkxKqUsARizLujqdTv/RSb5arV7VbDZ/B/CblPLaMMxyuXzIS72ylLLjpA8lIIS4L5PJ+L2/65lKqZ8BXN9sNvdms9mOE3VpaWmPZVl/AvhFSrkvjIBS6h4AHwCIT6AthdabzeZINpv9O6R7FJh5JihivkfN5LZt21xDAp+eUsjLwVYRE9Ep27YPhxTdPBGdiFLEzPxYhJlwgpnnt13ExpAtbfQJx3FeDWmjmzPAFDMRfWXkmfk2r3jNz9BZ4LruASIyWL21US8Km4NMCHE4k8m0+nO3x+9GXd6Hdh/XdW8horNGv+dB5hvRfpWIctkqlUpmJtwF4HYP40ut9ccRev/LRPSUp7MzV4k2Eu2XuWNSyiNhRRj1vVJqhJmPEdFBT2dnL3O+Ie3XaWb+xHQTKeW7UQ3dKre8vHxlvV43nesRADd79dKf63RbJLYuNGeYuRhnL1hcXLwxlUo9ZNougNZAuywLjU+i00oJwEziVQAXtNbnhRBmO/vBM85sXWNCiDFmHiOiO5n5Cg/v8q6U7Wmwa5f6Tjm/K/9W2W7x7qRe6Ea2k4f1Aysh0A+vxsFMIhDHW/2QTSLQD6/GwUwiEMdb/ZBNItAPr8bB/A/i+hZPUBUmJwAAAABJRU5ErkJggg==");
        }
        .j-sensitive-eye-close {
            background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAADEklEQVRoQ+2VT4hNYRjGn/e7o4wkNixYoCYaImt1z3nPdTczTFIW1CwITexEpDSTbEwpK0JiMU1iJdwFc893zslaxJAs/E1hpXDTdL5Xp86ta2LOueec0tS5y/ne5/e9z/N8cy9hnn9onu+P0sD/brBsoGwgZwLlE8oZYG552UDuCHMCygZyBphbXjaQO8KcgFQNaK1XMfPHnHellgdB0B+G4Xtm/p4kSjQQBMGmMAyfAphg5uEkYN7zRqOxpLe31wewgJk3JvESDUQArfUjAFsBnGbms0nQPOeu644T0XERGXcc50QSK5UB3/eHjDF3IphSaq1lWW+SwFnOPc+ri8gDIrpr2/ZQGkYqAxHIdd1rRLRfRD45jrMyDbzbGa21xJphZp5Io09tIDbxg4gWAXjVarU2DwwM/EpzSdKM7/uOMaYZzYnIFyJaDmCEmS8naVMbaDabY0qp0Q7gO2PMrlqt9jjpkrnOXdc9SkTn4+VPKKXeiMitWHOSmc/NpU9lQGsdLT4Wg9aIyDARnRGRn0qpYzMzM5P1ev1bN0Z83+8zxuzt4B5g5msRw/O83R0mtjDzk3+xEw10wpRSg5ZlNSJYs9k8qJS6EoM/A7iulJq0LOvZXEaCIKhFi4tItPxCEXkP4LDjOPc7dVrr7QBWtE1lNqC1tgGMEtFF27Zvd4KmpqY2VSqVGwC2dPz9JYAXxpjnlUplWkSqItICsIGI+gGsjmdfA+gDcI+Zd3TTXudsYgNzgbXWSwF8ALCYiE6JyCCAaMll/9C9BTAd/yjedF33HhENEtEh27avZjGR18AIgEsA7jDzzvYC0fsWkfXGmAtEtFZEjvT09NyuVqtfZz2TbQAeAnjHzO1muvKR14AGED2xPcx8c/bNWuv2OTOz97fNtNbXo2cVDXS1eTycy4DneXvCMFxXq9Xa31B/7JDGQJalC/sfSLo8/vqN0t2XNJv1PFcDWS8tUlcaKDLNLKyygSypFakpGygyzSyssoEsqRWpKRsoMs0srLKBLKkVqSkbKDLNLKyygSypFamZ9w38BqJCEEACfhEUAAAAAElFTkSuQmCC");
        }
    }
</style>
