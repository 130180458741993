<template>
    <span v-if="text || text === 0">{{text}}</span>
</template>

<script>
    import Vue from 'vue'
    import axios from 'axios'
    import mixin from './mixins'
    export default {
        name: "JDictionary",
        mixins: [mixin],
        props: {
          code: {
            type: String,
            required: true
          },
          value: [String, Number],
          props: {
              type: Object,
              default() { return { key: 'key', value: 'value' } }
          }
        },
        data () {
            return {
                text: ''
            }
        },
        created() {
            this.updateData()
        },
        methods: {
            updateData () {
                if (this.hasDicStore()) return this.injectStore()
                this.applyLocalStorage()
            },
            async injectStore () {
                if (Vue.store.state.JDictionary.Map[this.code]) {
                    this.text = Vue.store.state.JDictionary.Map[this.code][this.value] || this.value
                } else {
                    const { map } = await Vue.store.dispatch('JDictionary/getDictionaryByKey', this.code)
                    this.text = map[this.value] || this.value
                }
            },
            async applyLocalStorage () {
                let dic = []
                if (this.hasLocalDic(this.code)) dic = this.getLocalStorage(this.code) || []
                else dic = await this.getDictionaryByKey(this.code)
                this.text = this.dicMatchByArray(dic, this.value, this.props)
            },
            async getDictionaryByKey (key) {
                const { data } = await axios.get(`/api/dictionaryItems/${key}/items-parent-key`)
                if (data.status != 'yes') return console.error(`字典ID：${key}，字典获取异常`)
                this.setLocalStorage(data.data, key)
                return data.data || []
            },
            hasDicStore () {
                return Vue.store && Vue.store.state.JDictionary
            }
        },
        watch: { value () { this.updateData() } }
    }
</script>
