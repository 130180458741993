<template>
    <j-page>
        <div slot="search">
            <j-page-search title="考核月份">
                <el-date-picker v-model="query.yearMonths" type="month"  placeholder="选择考核月份" value-format="yyyy/MM" />
            </j-page-search>
            <j-page-search title="所属机构">
                <el-cascader v-model="query.orgId" :options="$store.state.orgTree" :props="{ children: 'childrens', label: 'name', value: 'id', emitPath: false, checkStrictly: true }" placeholder="请选择所属机构" clearable />
            </j-page-search>
            <j-page-search title="姓名">
                <el-input v-model="query.userName" placeholder="请输入姓名" clearable />
            </j-page-search>
            <j-page-search title="联系电话">
                <el-input v-model="query.mobile" placeholder="请输入联系电话" clearable />
            </j-page-search>
            <j-page-search title="总分">
                <j-link-input style="width: 240px;" :start.sync="query.scoreFinalStart" :end.sync="query.scoreFinalEnd" />
            </j-page-search>
            <el-button type="primary" @click="searchEvent">查询</el-button>
            <el-button @click="resetEvent">重置</el-button>
            <el-button type="primary" plain @click="exportEvent">导出</el-button>
        </div>
        <div slot="table">
            <el-table v-loading="tableLoading" :data="tableData" height="100%">
                <el-table-column prop="yearMonths" label="考核月份" align="center" show-overflow-tooltip />
                <el-table-column prop="userName" label="名称" align="center" show-overflow-tooltip />
<!--                <el-table-column prop="mobile" label="联系电话" align="center" show-overflow-tooltip />-->
                <el-table-column prop="orgName" label="所属机构" align="center" show-overflow-tooltip />
                <el-table-column prop="roleName" label="角色" align="center" show-overflow-tooltip />
                <el-table-column prop="personalSummary" label="个人小结" align="center" show-overflow-tooltip>
                    <span slot-scope="{row}">{{row.personalSummary ? '已提交' : '未提交'}}</span>
                </el-table-column>
                <el-table-column prop="scoreFinal" label="质效总分" align="center" show-overflow-tooltip>
                    <span class="common-pointer-style" slot-scope="{row}" @click.stop="leaderEvent(row)">{{row.scoreFinal}}</span>
                </el-table-column>
            </el-table>
        </div>
        <div slot="pagination" v-show="query.total > query.limit">
            <el-pagination
                    :current-page="query.page"
                    :page-size="query.limit"
                    :total="query.total"
                    :background="pmxBackground"
                    :pager-count="pmxCount"
                    :layout="pmxLayout"
                    :pageSizes="pmxSizes"
                    @current-change="changePage"
                    @size-change="sizeChange"
            />
        </div>
        <el-dialog v-if="showDgmxDetail" title="总分" :visible.sync="showDgmxDetail" width="800px" top="10%" :close-on-click-modal="false">
            <el-table :data="leaderData" style="width: 100%" height="500px">
                <el-table-column type="expand" width="20">
                    <div class="common-expand-style" slot-scope="{row}">
                        <j-label title="评鉴说明" inline>{{row.appraiseDiscripotion}}</j-label>
                    </div>
                </el-table-column>
                <el-table-column prop="orgName" label="所属机构" align="center" show-overflow-tooltip />
                <el-table-column sortable prop="roleName" label="角色" align="center" show-overflow-tooltip />
                <el-table-column prop="userName" label="姓名" align="center" show-overflow-tooltip />
                <el-table-column prop="status" label="状态" align="center" show-overflow-tooltip />
                <el-table-column prop="appraiseLevelName" label="评鉴等级" align="center" show-overflow-tooltip />
                <el-table-column sortable prop="appraiseScore" label="评鉴分数" align="center" show-overflow-tooltip>
                    <span class="common-pointer-style" slot-scope="{row}">{{row.appraiseScore}}</span>
                </el-table-column>
                <el-table-column prop="appraiseTime" label="评鉴日期" align="center" show-overflow-tooltip />
            </el-table>
            <div slot="pagination" v-show="leaderQuery.total > leaderQuery.limit">
                <el-pagination
                        :current-page="leaderQuery.page"
                        :page-size="leaderQuery.limit"
                        :total="leaderQuery.total"
                        :pager-count="pmxCount"
                        :layout="pmxLayout"
                        @current-change="changeLeaderPage"
                />
            </div>
        </el-dialog>
    </j-page>
</template>

<script>
    /**
     * @Author: fengjunjie <fengjunjie@btzh.cn>
     * @Description: jobrating_pc
     * @Date: 2022/04/15 10:02
     */
    import PageMixins from "../../components/JComponents/JPage/mixins/PageMixins";
    import PaginationMixins from "../../components/JComponents/JPage/mixins/PaginationMixins";
    import DialogMixins from "../../components/JComponents/JPage/mixins/DialogMixins";
    export default {
        name: "DirectlyUnit",
        mixins: [PageMixins, PaginationMixins, DialogMixins],
        data () {
            return {
                query: {
                    mobile: '',
                    orgId: '',
                    scoreFinalEnd: '',
                    scoreFinalStart: '',
                    userName: '',
                    yearMonths: ''
                },
                leaderQuery: {
                    page: 1,
                    limit: 10,
                    total: 0
                },
                leaderData: []
            }
        },
        created () {
            this.load()
        },
        methods: {
            async exportEvent () {
                const { data } = await this.$http.request({
                    method: 'get',
                    url: `/api/bisMassEffectEvalue23/export`,
                    params: { ...this.query },
                    responseType: 'arraybuffer'
                })
                let url = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }))
                let link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                link.setAttribute('download', '直属单位导出')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link) // 下载完成移除元素
                window.URL.revokeObjectURL(url) // 释放掉blob对象
            },
            async load () {
                this.tableLoading = true
                const { data } = await this.$http.get(`/api/bisMassEffectEvalue23`, {
                    params: this.query
                })
                this.tableData = data.data.data
                this.query.total = data.data.total
                this.tableLoading = false
            },
            leaderEvent (row) {
                this.curItem = row
                this.loadLeaderData()
            },
            changeLeaderPage (page) {
                this.leaderQuery.page = page
                this.loadLeaderData()
            },
            async loadLeaderData () {
                const { data } = await this.$http.get(`/api/leaderAppraise/getCompleteProportion`, {
                    params:  { ...this.leaderQuery, userId: this.curItem.userId, yearMonth: this.curItem.yearMonths }
                })
                this.leaderData = data.data.data
                this.leaderQuery.total = data.data.total
                this.showDgmxDetail = true
            }
        }
    }
</script>
