<template>
    <div class="j-user-ctrl-wrap">
        <el-dropdown v-if="isLogin" @command="commandCtrl" size="small">
            <div class="j-user-ctrl-box">
                <el-avatar :title="name" size="medium" :src="getUrlByPath(avatar)" @error="errorHandler">{{name}}</el-avatar>
                <span class="j-user-ctrl-box_name" :title="name">{{name}}</span>
                <i class="el-icon-arrow-down" />
            </div>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="reset" >重置密码</el-dropdown-item>
                <el-dropdown-item command="logout" divided>退出登录</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
        <div v-else class="j-user-ctrl-box">
            <span class="j-user-ctrl-box_name" @click="$router.push('/login')">登录</span>
        </div>
        <j-reset-pwd :close.sync="showPwd" />
    </div>
</template>

<script>
    import JResetPwd from "./JResetPwd";
    import utilMixins from "../JUpload/mixins/utilMixins";
    export default {
        name: "JAvatar",
        mixins: [utilMixins],
        components: {
            JResetPwd
        },
        computed: {
            isLogin () {
                return !!this.$store.state.user.adminId
            },
            name () {
                return this.$store.state.user.name
            },
            avatar () {
                return this.$store.state.user.avataUrl
            }
        },
        data () {
            return {
                showPwd: false
            }
        },
        methods: {
            errorHandler() {
                return true
            },
            commandCtrl (command) {
                this[command]()
            },
            reset () {
                this.showPwd = true
            },
            async logout () {
                const { data } = await this.$http.post(`/api/admins/logout`)
                if (data.status !== 'yes') return this.$message.error(data.msg)
                sessionStorage.clear()
                location.href = location.origin
            }
        }
    }
</script>

<style scoped lang="less">
    .j-user-ctrl-wrap {
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        .j-user-ctrl-box {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            /*color: #fff;*/
            .j-user-ctrl-box_name {
                font-size: 14px;
                font-weight: bold;
                max-width: 70px;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: keep-all;
                margin: 0 2px 0 6px;
                line-height: 20px;
                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
            >i {
                display: inline-block;
                height: 20px;
                line-height: 22px;
            }
        }
        .el-dropdown {
            height: 100%;
            width: 100%;
            display: block;
        }
    }
</style>
