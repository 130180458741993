<template>
  <div class="login-layout">
    <div class="login-content">
      <div class="login-box-background">
        <div class="login-box">
          <div class="login-box-title">欢迎登录</div>
          <el-form :model="loginForm" :rules="loginFormRule" ref="loginForm">
            <el-form-item label="用户名" prop="username">
              <el-input v-model="loginForm.username" @keydown.native.enter="checkLogin" placeholder="请输入用户名" ref="username" :maxlength="40"/>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input v-model="loginForm.password" @keydown.native.enter="checkLogin" placeholder="请输入密码" type="password" :maxlength="40"/>
            </el-form-item>
            <div class="error-tip" v-if="errorTip"><i class="el-icon-warning" />{{errorTip}}</div>
          </el-form>
          <el-button class="login-box-btn" type="primary" @click="checkLogin" :loading="loading">登 录</el-button>
        </div>
      </div>
      <div class="login-img"></div>
    </div>
  </div>
</template>

<script>
  import md5 from 'js-md5'
  import {title} from "../../config";

  export default {
    name: 'login',
    data () {
      return {
        title,
        loading: false,
        errorTip: '',
        loginForm: {
          username: '',
          password: ''
        },
        loginFormRule: {
          username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
          password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
        }
      }
    },
    methods: {
      async checkLogin () {
        const isSuccess = await this.$refs.loginForm.validate()
        isSuccess && this.login()
      },
      async login () {
          const { data } = await this.$http.post(`/api/admins/login`, {
            username: this.loginForm.username,
            password: md5(this.loginForm.password)
          })
          .catch(({response}) => { this.errorTip = response.data.msg || '登录异常' })
          .finally(() => { this.loading = false })
          if (data.status != 'yes') return this.errorTip = data.msg || '用户名/密码有误，请重新输入'
          this.$store.commit('user', data.data)
          this.$router.push('/')
      }
    }
  }
</script>

<style lang="less">
  .login-layout {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #263c5e;
    background-image: url("../../assets/login/login-backgground.png");
    background-size: auto 100%;

    .el-form-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: rgba(202, 239, 247, 0.5);
      border-radius: 8px;
      .el-form-item__label {
        width: 64px;
        text-align: left;
      }
      .el-input__inner {
        background-color: transparent;
        border: none;
      }
    }
    .login-content {
      margin-top: 100px;
      display: flex;
      align-items: flex-start;
    }
    .login-img {
      width: 460px;
      height: 510px;
      background-image: url("../../assets/login/login-img.png");
      background-size: contain;
      min-width: 26px;
    }

    .login-box-background{
      background: #e4f7fb;
      border: 4px solid #fff;
      padding: 10px;
      border-radius: 25px;
      margin-top: 26px;
    }
      .login-box {
        width: 300px;
        padding: 0 16px;
        background-color: #fff;
        border-radius: 10px;
        box-sizing: border-box;
        .error-tip {
          color: #FE693D;
          > i {
            display: inline-block;
            margin-bottom: 2px;
          }
        }
        .login-box-title {
          padding: 28px 0 40px;
          height: 30px;
          font-size: 20px;
          font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
          font-weight: normal;
          color: #1D1F22;
          line-height: 30px;
          font-weight: bold;
          background-image: url("../../assets/login/login-title.png");
          background-size: 24px 24px;
          background-position: 65px;
          background-repeat: no-repeat;
        }
        .login-box-btn {
          width: 100%;
          margin: 30px 0;
        }
      }
  }
</style>
