<!--
 * @Description: 民主测评任务
 * @Author: tengmingxin
 * @Date: 2022-04-15 09:35:39
 * @LastEditors: tengmingxin
 * @LastEditTime: 2022-05-05 09:35:52
-->
<template>
  <j-page class="democracy-task">
    <!-- 头部tab -->
    <div slot="header" class="common-tab-style" v-if="!formbacklog">
      <div
        v-for="item in tabs"
        :key="item.key"
        :class="[
          'common-tab-item-style',
          activeTab === item.key ? 'active' : '',
        ]"
        @click="changeTab(item.key)"
      >
        <div>{{ item.value || 0 }}</div>
        <div>{{ item.label }}</div>
      </div>
      <el-tooltip
        content="考核说明：“好“等级人员不得超过考核人员的20%"
        effect="light"
        placement="right-start"
      >
        <j-svg-icon class="common-tab-tip-style" name="tab-info" />
      </el-tooltip>
    </div>

    <!-- 搜索 -->
    <div slot="search" style="margin-top: 12px">
      <j-page-search title="所属机构">
        <el-cascader
          v-model="query.orgId"
          :options="$store.state.orgTree"
          :props="{
            children: 'childrens',
            label: 'name',
            value: 'id',
            emitPath: false,
            checkStrictly: true,
          }"
          placeholder="请选择"
          clearable
        />
      </j-page-search>
      <j-page-search title="姓名">
        <el-input v-model="query.userName" placeholder="请输入" clearable />
      </j-page-search>
      <j-page-search title="联系电话">
        <el-input v-model="query.userMobile" placeholder="请输入" clearable />
      </j-page-search>
      <j-page-search title="身份证号">
        <el-input v-model="query.cardCode" placeholder="请输入" clearable />
      </j-page-search>
      <j-page-search title="角色">
        <el-select v-model="query.roleId" placeholder="请选择" clearable>
          <el-option
            v-for="item in $store.state.roles"
            :key="item.roleId"
            :label="item.name"
            :value="item.roleId"
          />
        </el-select>
      </j-page-search>
      <j-page-search title="状态">
        <j-dic-select v-model="query.status" code="STATUS_TYPE" />
      </j-page-search>
      <j-page-search title="考核月份">
        <el-date-picker
          v-model="query.yearMonth"
          type="month"
          placeholder="请选择"
          value-format="yyyy/MM"
          clearable
        />
      </j-page-search>
      <j-page-search title="个人小结">
        <j-dic-select
          v-model="query.personalSummaryStatus"
          code="PERSONAL_SUMMARY_STATUS "
        />
      </j-page-search>
      <el-button type="primary" @click="searchEvent">搜索</el-button>
      <el-button @click="resetEvent">重置</el-button>
    </div>

    <!-- 表格 -->
    <div slot="table">
      <t-table
        :table="tableData"
        :columns="columns"
        :handle-btns="handleBtns"
        @onHandle="onHandle"
        @pageChange="(e) => load({ page: e })"
      >
        <template #status="{row}">
         <span :class="`color-status-style color-status${row.status}`" />
        </template>
      </t-table>
    </div>

    <!-- 弹窗 -->
    <j-drawer
      :title="type === 'view' ? '查看' : '测评'"
      :close.sync="drawerShow"
      class="ledger-drawer"
    >
      <div ref="drawerBodyRef" style="height: 100%; overflow-y: auto">
        <!-- 固定详情部分 -->
        <div v-for="(item, index) in detailData" :key="index">
          <j-drawer-label type="title" :title="item.label" align="right" />
          <el-empty
            v-if="item.isNull"
            :image-size="100"
            description="暂无数据"
          />
          <div v-else>
            <j-drawer-label
              v-for="(it, i) in item.children"
              :key="i"
              :title="it.label"
              align="left"
              v-show="
                (activeTab && type === 'view') ||
                !['evaluateLevelName', 'evaluateDiscripotion'].includes(i)
              "
            >
              <j-sensitive
                v-if="it.type"
                type="item.type"
                v-model="it.value"
                eye
              />
              <span v-else>{{ it.value }}</span>
            </j-drawer-label>
          </div>
        </div>

        <!-- 可提交数据 -->
        <el-form
          v-if="type !== 'view'"
          :model="evaluateForm"
          label-width="130px"
          inline
          :rules="evaluateRules"
          ref="evaluateRef"
        >
          <el-form-item label="等级" prop="evaluateLevel">
            <j-dic-select
              v-model="evaluateForm.evaluateLevel"
              code="EVALUATION_LEVEL"
            />
          </el-form-item>
          <el-form-item label="评价说明">
            <el-input
              v-model="evaluateForm.evaluateDiscripotion"
              type="textarea"
              :rows="3"
              placeholder="请输入"
              maxlength="300"
              show-word-limit
              clearable
            />
          </el-form-item>
        </el-form>
      </div>

      <!-- 按钮 -->
      <template slot="footer">
        <el-button @click="changeType" v-show="type === 'view' && !activeTab"
          >测评</el-button
        >
        <el-button @click="changeType" v-show="type === 'view' && activeTab"
          >修改</el-button
        >
        <el-button @click="saveEvaluate" v-show="type !== 'view'"
          >保存</el-button
        >
      </template>
    </j-drawer>
  </j-page>
</template>

<script>
import TTable from "../../components/TTable.vue";
import PageMixins from "@/components/JComponents/JPage/mixins/PageMixins";
import DrawerMixins from "@/components/JComponents/JPage/mixins/DrawerMixins.js";

import {
  getIncompleteTaskList,
  getCompletedTaskList,
  getLabelNumber,
  getTaskDetail,
  editEvaluate,
} from "@/api/democracy";

export default {
  name: "DemocracyTask",
  components: { TTable },
  mixins: [PageMixins, DrawerMixins],
  props: {
    // 待办引用
    formbacklog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableData: {
        currentPage: 1,
        limit: 10,
        total: 0,
        data: [],
        loading: false,
      },
      columns: [
        { prop: "status", label: "状态" },
        { prop: "yearMonth", label: "考核月份" },
        { prop: "orgName", label: "所属机构" },
        { prop: "roleName", label: "角色" },
        { prop: "userName", label: "姓名" },
        { prop: "userMobile", label: "联系电话" },
        { prop: "cardCode", label: "身份证号" },
        { prop: "personalSummaryStatusName", label: "个人小结" },
        { prop: "completeProportion", label: "评价进度" },
      ],
      handleBtns: [
        { key: "view", name: "查看" },
        { key: "evaluate", name: "测评" },
      ],
      query: {
        limit: 10,
        page: 1,
        cardCode: "",
        orgId: "",
        personalSummaryStatus: "",
        roleId: "",
        status: "",
        userMobile: "",
        userName: "",
        yearMonth: "",
      },
      type: "view",
      drawerShow: false,
      detailData: {
        userBasicInfo: {
          label: "基本信息",
          children: {
            orgName: { label: "所属机构", value: "" },
            roleName: { label: "角色", value: "" },
            name: { label: "姓名", value: "" },
            cardCode: { label: "身份证号", type: "id", value: "" },
            sexName: { label: "性别", value: "" },
            mobile: { label: "联系电话", value: "" },
            statusName: { label: "状态", value: "" },
          },
        },
        personalSummaryInfo: {
          label: "个人总结",
          children: {
            yearMonth: { label: "考核月份", value: "" },
            score: { label: "自评分", value: "" },
            monthlyWorkSummary: { label: "月度工作情况", value: "" },
            monthlyIncompleteLowefficiency: {
              label: "未完成或完成质效不高的工作",
              value: "",
            },
            updateTime: { label: "更新日期", value: "" },
          },
        },
        democraticEvaluation: {
          label: "民主评价",
          children: {
            completeProportion: { label: "评价进度", value: "" },
            yearMonth: { label: "考核月份", value: "" },
            evaluateStatusName: { label: "状态", value: "" },
            evaluateLevelName: { label: "等级", value: "" },
            evaluateDiscripotion: { label: "评价说明", value: "" },
          },
        },
      },
      activeTab: 0,
      tabs: [
        {
          label: "待测评",
          value: 0,
          key: 0,
        },
        {
          label: "已测评",
          value: 0,
          key: 1,
        },
      ],
      evaluateForm: {
        evaluateDiscripotion: "",
        evaluateLevel: "",
        id: "",
      },
      evaluateRules: {
        evaluateLevel: [
          { required: true, message: "请选择测评等级", trigger: "blur" },
        ],
      },
    };
  },
  async created() {
    this.load();
    await this.getLabelNumber();
    this.$emit("setTotal", this.tabs[0].value);
  },
  methods: {
    async load(params = {}) {
      this.tableData.loading = true;
      const { data } = this.activeTab
        ? await getCompletedTaskList({ ...this.query, ...params })
        : await getIncompleteTaskList({ ...this.query, ...params });
      this.tableData.data = data.data.data || [];
      this.tableData.loading = false;
      if (this.tableData.data.length === 0 && this.query.page > 1) {
        this.query.page--;
        this.load();
      }
      this.tableData.total = data.data.total;
      this.query.page = data.data.page;
    },
    async getLabelNumber() {
      const { data } = await getLabelNumber();
      this.tabs[0].value = data.data.incompleteCount;
      this.tabs[1].value = data.data.completedCount;
    },
    changeTab(key) {
      this.activeTab = key;
      this.handleBtns[1] = this.activeTab
        ? { key: "edit", name: "修改" }
        : { key: "evaluate", name: "测评" };
      this.load();
    },
    onHandle(data) {
      this.getTaskDetail(data.scope.row.id);
      this.drawerShow = true;
      this.type = data.type;
    },
    changeType() {
      this.type = "evaluate";
      const drawerDom = this.$refs.drawerBodyRef;
      this.$nextTick(() => {
        drawerDom.scrollTop = drawerDom.scrollHeight;
      });
    },
    async getTaskDetail(id) {
      let { data } = await getTaskDetail(id);
      for (const key in this.detailData) {
        if (!data.data[key]) {
          this.detailData[key].isNull = true;
        } else
          for (const subKey in this.detailData[key].children) {
            this.detailData[key].children[subKey].value =
              data.data[key][subKey] || "";
          }
      }
      const { evaluateDiscripotion = "", evaluateLevel = "" } =
        data.data.democraticEvaluation;
      this.evaluateForm = {
        evaluateDiscripotion,
        evaluateLevel,
        id,
      };
    },
    saveEvaluate() {
      this.$refs.evaluateRef.validate(async (valid) => {
        if (!valid) return;
        await editEvaluate(this.evaluateForm);
        this.drawerShow = false;
        this.$message.success(`测评成功`);
        await this.getLabelNumber();
        this.$emit("setTotal", this.tabs[0].value);
      });
    },
  },
};
</script>

<style lang="less">
// .democracy-task {
// }
</style>
