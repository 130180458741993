<template>
    <j-page>
        <div slot="header">
            <div class="common-tab-style">
                <div :class="['common-tab-item-style', active === 1 ? 'active' : '']" @click="active = 1">
                    <div>{{count.incompleteCount || 0}}</div>
                    <div>待评鉴</div>
                </div>
                <div :class="['common-tab-item-style', active === 2 ? 'active' : '']" @click="active = 2">
                <div>{{count.completedCount || 0}}</div>
                    <div>已评鉴</div>
                </div>
                <el-tooltip :content="tooltip" effect="light" placement="right-start">
                    <j-svg-icon class="common-tab-tip-style" name="tab-info" />
                </el-tooltip>
            </div>
        </div>
        <div slot="table">
            <wait-list v-if="active === 1" />
            <already-list v-if="active === 2" />
        </div>
    </j-page>
</template>

<script>
    /**
     * @Author: fengjunjie <fengjunjie@btzh.cn>
     * @Description: jobrating_pc
     * @Date: 2022/04/18 16:23
     */
    import PageMixins from "../../components/JComponents/JPage/mixins/PageMixins";
    import waitList from "./waitList";
    import alreadyList from "./alreadyList";
    export default {
        name: "CriticismTask",
        mixins: [PageMixins],
        components: {
            waitList,
            alreadyList
        },
        props: {
            // 待办引用
         formbacklog: {
           type: Boolean,
          default: false,
         },
        },
        data () {
            return {
                tooltip: '考核说明：90分以上人员不得超过考核人员的20%',
                count: {},
                active: 1
            }
        },
        created () {
          this.getCount()
        },
        methods: {
            async getCount () {
                const { data } = await this.$http.get(`/api/leaderAppraise/getLabelNumber`)
                this.count = data.data
            }
        }
    }
</script>
