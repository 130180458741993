import { fls, flsPathSufix, bucketName } from "@/config";

export default {
  methods: {
    /**
     * 文件服务路径处理
     * @param {string} filePath ("vux-img/name.jpg&bucketName=grid")
     * @return {string} 返回资源的绝对路径 (https://oss-manage.btzh.net:4443/grid/static-file/vux-img/name.jpg)
     */
    getUrlByPath (filePath) {
      if (!filePath) return
      const splitArr = filePath.split('&bucketName=')
      let name = splitArr[0]
      let bucket = splitArr[1] || bucketName || 'grid'
      let url = `${fls}/${bucket}/` // `${fls}/${bucketName}/${filePath}`
      flsPathSufix && (url += flsPathSufix + '/') // `${fls}/${bucketName}/${flsPathSufix}/${filePath}`
      return (url + name)
    },
    // 下载文件
    fileDownloadByUrl (url, name) {
      const link = document.createElement('a')
      const body = document.querySelector('body')
      link.href = url
      link.target = '_blank'
      if (name) link.download = name
      // fix Firefox
      link.style.display = 'none'
      body.appendChild(link)
      link.click()
      body.removeChild(link)
    },
    // 日期格式化
    dateFormatByPattern (time, formatStr = 'YYYY-MM-DD HH:mm:ss') {
      const date = time ? new Date(time) : new Date()
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      const week = date.getDay()
      const hour = date.getHours()
      const minute = date.getMinutes()
      const second = date.getSeconds()
      const weeks = ['日', '一', '二', '三', '四', '五', '六']
      return formatStr.replace(/Y{2,4}|M{1,2}|D{1,2}|d{1,4}|H{1,2}|m{1,2}|s{1,2}/g, (match) => {
        switch (match) {
          case 'YY':
            return String(year).slice(-2)
          case 'YYY':
          case 'YYYY':
            return String(year)
          case 'M':
            return String(month)
          case 'MM':
            return String(month).padStart(2, '0')
          case 'D':
            return String(day)
          case 'DD':
            return String(day).padStart(2, '0')
          case 'd':
            return String(week)
          case 'dd':
            return weeks[week]
          case 'ddd':
            return '周' + weeks[week]
          case 'dddd':
            return '星期' + weeks[week]
          case 'H':
            return String(hour)
          case 'HH':
            return String(hour).padStart(2, '0')
          case 'm':
            return String(minute)
          case 'mm':
            return String(minute).padStart(2, '0')
          case 's':
            return String(second)
          case 'ss':
            return String(second).padStart(2, '0')
          default:
            return match
        }
      })
    }
  }
}
