<template>
    <j-page>
        <div slot="header">
            <!--      无图标，背景渐变自动计算-->
            <j-colour shade="#75d7d5" desc="无图标，背景渐变自动计算" :value="88" />
            <!--      颜色范围，无数字,使用图片作为插槽内容-->
            <j-colour :color="['#9370DB', '#87CEFA']" desc="颜色范围，无数字11111112222222">
                <img slot="icon" src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" alt="test">
            </j-colour>
            <j-colour shade="#90EE90" desc="颜色范围，无数字" :value="88" >
                <img slot="icon" src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" alt="test">
            </j-colour>
            <j-colour shade="#90EE90" desc="svg图标" :value="88" >
                <j-svg-icon slot="icon" name="xtwh"/>
            </j-colour>
        </div>
        <div slot="search">
            <j-page-search title="时间">
                <j-date-range :start.sync="query.starttime" :end.sync="query.endtime"/>
            </j-page-search>
            <j-page-search title="标题">
                <el-input v-model="query.title" placeholder="请输入" clearable />
            </j-page-search>
            <j-page-search title="角色">
                <el-select v-model="query.xxx" placeholder="请选择" clearable filterable>
                    <el-option v-for="item in []" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
            </j-page-search>
            <j-page-search title="范围输入">
                <j-link-input style="width: 280px;" :start.sync="query.start" :end.sync="query.end" unit="单位" />
            </j-page-search>
            <el-button type="primary" plain @click="searchEvent">查询</el-button>
            <el-button type="primary" plain @click="addDrmxEvent">抽屉</el-button>
            <el-button type="primary" plain @click="addDgmxEvent">弹窗</el-button>
        </div>
        <div slot="table">
            <el-table v-loading="tableLoading" :data="tableData" height="100%">
                <el-table-column type="index" label="序号" align="center" width="50" />
                <el-table-column prop="name" label="名字脱敏" align="center" show-overflow-tooltip >
                    <j-sensitive slot-scope="{row}" type="name" :value="row.name" eye />
                </el-table-column>
                <el-table-column prop="phone" label="手机号脱敏" align="center" show-overflow-tooltip >
                    <j-sensitive slot-scope="{row}" type="phone" :value="row.phone" eye />
                </el-table-column>
                <el-table-column prop="card" label="身份证号脱敏不显示眼睛" align="center" show-overflow-tooltip>
                    <j-sensitive slot-scope="{row}" type="id" :value="row.card" />
                </el-table-column>
                <el-table-column label="操作" width="160">
                    <template slot-scope="scope">
                        <el-button @click.stop="detialEvent(scope.row)" type="primary" class="el-button el-button--text">详情</el-button>
                        <el-button @click.stop="editDgmxEvent(scope.row)" type="primary" class="el-button el-button--text">编辑</el-button>
                        <el-button @click.stop="delEvent(scope.row)" type="primary" class="el-button el-button--text font-red" >删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div slot="pagination" v-show="query.total > 0">
            <el-pagination
                    :current-page="query.page"
                    :page-size="query.limit"
                    :total="query.total"
                    :background="pmxBackground"
                    :pager-count="pmxCount"
                    :layout="pmxLayout"
                    :pageSizes="pmxSizes"
                    @current-change="changePage"
                    @size-change="sizeChange"
            />
        </div>

        <!--        抽屉-->
        <j-drawer :title="drmxTypeMap[drmxType]" :close.sync="showDrmx">
            <div>
                <el-form ref="drmxDom" :model="drmxForm" :rules="drmxRule" :label-width="drmxFormLabelWidth">
                    <j-drawer-label type="title" title="基本信息" />
                    <div style="padding-right: 30px;">
                        <el-form-item label="输入" prop="input">
                            <el-input v-model="drmxForm.input" placeholder="请输入" maxlength="200" clearable />
                        </el-form-item>
                        <el-form-item label="选择" prop="select">
                            <el-select v-model="drmxForm.select" placeholder="请选择" style="width: 100%" clearable filterable>
                                <el-option v-for="(item, i) in ['选项1', '选项2']" :key="i" :label="item" :value="i" />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="ip" prop="ip">
                            <j-ip-input v-model="drmxForm.ip" />
                        </el-form-item>
                        <el-form-item label="文件上传" prop="file">
                            <j-upload v-model="drmxForm.file" type="String" list-type="picture-card">
                                <i class="el-icon-plus" style="font-size: 18px;"></i>
                            </j-upload>
                        </el-form-item>
                    </div>
                </el-form>
            </div>
            <div slot="footer" style="display: flex; width: 100%;">
                <el-button @click="checkDrmxEvent('saveDrmxData')" :loading="submitLoad">保存</el-button>
                <el-button @click="showDrmx = false" :loading="submitLoad">取消</el-button>
            </div>
        </j-drawer>

        <!--        弹窗-->
        <el-dialog v-if="showDgmx" :title="dgmxTypeMap[dgmxType]" :visible.sync="showDgmx" width="800px" top="5%" :close-on-click-modal="false">
            <el-form ref="dgmxDom" :model="dgmxForm" :rules="dgmxRule" :label-width="dgmxFormLabelWidth">
                <el-form-item label="输入框" prop="name">
                    <el-input v-model="dgmxForm.name" placeholder="请输入" maxlength="10" show-word-limit clearable />
                </el-form-item>
                <el-form-item label="描述" prop="phone">
                    <el-input v-model="dgmxForm.phone" type="textarea" :rows="3" placeholder="请输入描述" maxlength="200" show-word-limit clearable />
                </el-form-item>
                <el-form-item label="选择" prop="select">
                    <el-select v-model="dgmxForm.select" placeholder="请选择" style="width: 100%" clearable filterable>
                        <el-option v-for="(item, i) in ['选项1', '选项2']" :key="i" :label="item" :value="i" />
                    </el-select>
                </el-form-item>
                <el-form-item label="富文本" prop="card">
                    <j-editor v-model="dgmxForm.card" />
                </el-form-item>
            </el-form>
            <template slot="footer">
                <el-button type="primary" @click="checkDgmxEvent('saveDgmxData')" :loading="submitLoad">保 存</el-button>
                <el-button @click="showDgmx = false">取 消</el-button>
            </template>
        </el-dialog>
    </j-page>
</template>

<script>
    import PageMixins from "../../components/JComponents/JPage/mixins/PageMixins";
    import PaginationMixins from "../../components/JComponents/JPage/mixins/PaginationMixins";
    import DrawerMixins from "../../components/JComponents/JPage/mixins/DrawerMixins";
    import DialogMixins from "../../components/JComponents/JPage/mixins/DialogMixins";
    import JColour from "../../components/JComponents/JColour/JColour";
    import JSensitive from "../../components/JComponents/JSensitive/JSensitive"; //  脱敏组件
    import JEditor from "../../components/JComponents/JEditor/JEditor"; // 富文本
    import JLinkInput from "../../components/JComponents/JLinkInput/JLinkInput"; // 范围输入
    import JIpInput from "../../components/JComponents/JIpInput/JIpInput";
    import JUpload from "../../components/JComponents/JUpload/components/JUpload";
    export default {
        name: "JDemo",
        mixins: [PageMixins, PaginationMixins, DrawerMixins, DialogMixins],
        components: {
            JColour,
            JSensitive,
            JEditor,
            JLinkInput,
            JIpInput,
            JUpload
        },
        data () {
            return {
                query: {
                    start: '',
                    end: '',
                    total: 10
                },
                tableData: [
                    { name: '张思睿', phone: 13109130865, card: '120110199802084616' },
                    { name: '张思睿', phone: 13109130865, card: '120110199802084616' },
                    { name: '张思睿', phone: 13109130865, card: '120110199802084616' },
                    { name: '张思睿', phone: 13109130865, card: '120110199802084616' },
                    { name: '张思睿', phone: 13109130865, card: '120110199802084616' },
                    { name: '张思睿', phone: 13109130865, card: '120110199802084616' },
                    { name: '张思睿', phone: 13109130865, card: '120110199802084616' },
                    { name: '张思睿', phone: 13109130865, card: '120110199802084616' },
                    { name: '张思睿', phone: 13109130865, card: '120110199802084616' }
                ],
                dgmxForm: {
                    name: '',
                    card: '',
                    phone: ''
                },
                drmxForm: {
                    ip: '',
                    editorVal: '',
                    select: '',
                    flie: ''
                }
            }
        },
        created () {

        },
        methods: {
            detialEvent () {

            }
        }
    }
</script>
