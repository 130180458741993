<!--
 * @Description: 待办-考评统计
 * @Author: tengmingxin
 * @Date: 2022-04-14 18:40:58
 * @LastEditors: tengmingxin
 * @LastEditTime: 2022-04-28 11:20:47
-->
<template>
  <div class="backlog-task-statistical">
    <div class="card-header-title">
      <span>
        本月考评统计(
        <span style="color: #f59a23">{{ new Date().getMonth() + 1 }}月</span>
        )
      </span>
      <div>
        <span>所属机构</span>
        <el-cascader
          size="mini"
          v-model="query.orgId"
          :options="$store.state.orgTree"
          :props="treeProps"
          :show-all-levels="false"
          @change="handleOrgChange"
          clearable
        />
      </div>
    </div>
    <div class="nav">
      <div
        :class="['sub-nav', { 'sub-nav-active': activeTabIndex === i }]"
        v-for="(item, i) in tabs"
        :key="item.name"
        @click="renderChars(item, i)"
      >
        <p>{{ item.data[item.sumKey] || 0 }}</p>
        <p>{{ item.name }}</p>
      </div>
    </div>
    <div class="chars" ref="charsRef" />
  </div>
</template>

<script>
const echarts = require("echarts/lib/echarts");
require("echarts/lib/component/title");
require("echarts/lib/component/tooltip");
require("echarts/lib/component/graphic");
require("echarts/lib/component/legend");
require("echarts/lib/component/grid");
require("echarts/lib/chart/pie");
require("echarts/lib/chart/bar");

import {
  getTaskByOrgId,
  getEvaluationByOrgId,
  getCriticismByOrgId,
  getRewardByOrgId,
  getSummaryByOrgId,
} from "@/api/backlog";

import delaData from "./optionOption";

export default {
  data() {
    return {
      treeProps: {
        children: "childrens",
        label: "name",
        value: "id",
        emitPath: false,
        checkStrictly: true,
      },
      activeTabIndex: 0,
      query: {
        orgId: "",
      },
      myChart: {},
      tabs: [
        {
          name: "日常任务",
          sumKey: "countDaily",
          show: [1, 11, 13],
          data: {},
          fuc: getTaskByOrgId,
          map: {
            countDaily0: "未考核",
            countDaily1: "已考核",
          },
        },
        // {
        //   name: "民主测评",
        //   sumKey: "countDemocraticEvalue",
        //   show: [1, 11, 12, 13, 21, 22, 23],
        //   data: {},
        //   fuc: getEvaluationByOrgId,
        //   map: {
        //     countDemocraticEvalue1: "&<0.2",
        //     countDemocraticEvalue2: "0.2≤&<0.4",
        //     countDemocraticEvalue3: "0.4≤&<0.6",
        //     countDemocraticEvalue4: "0.6≤&<0.8",
        //     countDemocraticEvalue5: "0.8≤&<1",
        //     countDemocraticEvalue6: "&=1",
        //   },
        // },
        {
          name: "领导评鉴",
          sumKey: "countLeaderAppraise",
          show: [1, 11, 12, 13, 21, 22, 23],
          data: {},
          fuc: getCriticismByOrgId,
          map: {
            countLeaderAppraise1: "&<0.2",
            countLeaderAppraise2: "0.2≤&<0.4",
            countLeaderAppraise3: "0.4≤&<0.6",
            countLeaderAppraise4: "0.6≤&<0.8",
            countLeaderAppraise5: "0.8≤&<1",
            countLeaderAppraise6: "&=1",
          },
        },
        {
          name: "个人小结",
          sumKey: "countPersonalSummary",
          show: [1, 11, 12, 13, 21, 22, 23],
          data: {},
          fuc: getSummaryByOrgId,
          map: {
            countPersonalSummary0: "未完成",
            countPersonalSummary1: "已完成",
          },
        },
        {
          name: "个人奖惩",
          sumKey: "countPersonalRP",
          show: [1, 11, 12, 13, 21, 22, 23],
          data: {},
          fuc: getRewardByOrgId,
          map: {
            countPersonalSummary0: "未完成",
            countPersonalSummary1: "已完成",
          },
        },
      ],
    };
  },
  mounted() {
    // 1: 管理员 11: 主管领导 12: 分管领导 13: 考评办 21: 科室负责人 22: 科员 23: 单位人员
    this.tabs = this.tabs.filter((e) =>
      e.show.includes(this.$store.state.user.roleId)
    );
    this.myChart = echarts.init(this.$refs.charsRef);
    this.handleOrgChange();
  },
  methods: {
    handleOrgChange(orgId = "") {
      Promise.allSettled(this.tabs.map((item) => item.fuc(orgId))).then(
        (res) => {
          this.tabs.forEach((item, i, arr) => {
            if (res[i].status === "fulfilled")
              arr[i].data = res[i].value.data.data || {};
            else this.$message.error(item.name + "数据获取失败");
          });
          this.renderChars(this.tabs[this.activeTabIndex], this.activeTabIndex);
        }
      );
    },
    renderChars(item, index) {
      this.activeTabIndex = index;
      this.myChart.clear();
      let option;
      if (item.name.startsWith("个人奖惩")) {
        if (!item.data.users.length) {
          this.$refs.charsRef.classList.add("common-empty-wrap");
          return;
        }
        option = delaData.countBar(
          item.data.users?.map((e) => e.userName),
          item.data.users?.map((e) => e.countPersonalRP),
          item.name
        );
      } else option = delaData.countPie(item.data, item.map, item.name);
      this.myChart.setOption(option);
      this.$refs.charsRef.classList.remove("common-empty-wrap");
    },
  },
};
</script>

<style lang="less">
.backlog-task-statistical {
  height: calc(55% - 16px);
  overflow-y: auto;
  margin-top: 16px;
  .card-header-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &::after,
    &::before {
      top: 52px !important;
    }
    > div {
      border: 1px solid #eef3f4;
      display: inline-block;
      width: 160px;
      padding-left: 7px;
      border-radius: 8px;

      > span {
        font-size: 14px;
        color: rgba(29, 31, 34, 0.75);
        line-height: 18px;
        padding-right: 8px;
        border-right: 1px solid #eef3f4;
      }
      .el-cascader {
        width: 80px;
        margin-left: 10px;
        input {
          padding-left: 0;
          border: none;
          height: 32px;
        }
      }
    }
  }
  .nav {
    .sub-nav {
      display: inline-block;
      background: #f8fafa;
      width: calc(20% - 4px);
      margin-bottom: 10px;
      padding: 5px 0;
      border-radius: 8px;
      & + .sub-nav {
        margin-left: 5px;
      }
      p {
        font-size: 18px;
        text-align: center;
        line-height: 24px;
        &:first-child {
          color: #1d1f22;
          font-family: TeXGyreAdventor-Bold, TeXGyreAdventor;
        }
        &:last-child {
          font-size: 16px;
          color: rgba(29, 31, 34, 0.49);
        }
      }
      &-active {
        background: rgba(0, 185, 220, 0.1);
        p {
          font-weight: bold;
          color: #00b9dc !important;
        }
      }
    }
  }
  .chars {
    height: calc(100% - 130px);
    min-height: 150px;
    margin: 0;
  }
}
</style>
