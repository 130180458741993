/*
 * @Description:
 * @Author: tengmingxin
 * @Date: 2022-04-14 14:23:04
 * @LastEditors: tengmingxin
 * @LastEditTime: 2022-04-15 15:49:42
 */
// 注册需要动态加载的页面组件

// demo演示
import JDemo from "./demo/JDemo";

import SvgViewer from "./demo/SvgViewer";

import JComponents from "./demo/JComponents";

// 菜单管理
import MenuManage from "./system/basic/MenuManage";
// 角色管理
import RoleManage from "./system/basic/RoleManage";

// 用户管理
import UserManage from "./system/UserManage";
// 机构管理
import OrganManage from "./system/OrganManage";
// 字典管理
import DictionaryManage from "./system/DictionaryManage";
// 通知公告
import NoticeManage from "./system/NoticeManage";
// 待办任务
import BacklogTask from "./backlog/BacklogTask";
// 民主测评-测评台账
import DemocracyLedger from "./democracy/DemocracyLedger";
// 民主测评-测评任务
import DemocracyTask from "./democracy/DemocracyTask";
// 个人小结
import MySummary from "./summary/MySummary";
// 考核管理
import ExamineManage from "./system/ExamineManage";

// 领导评鉴
import CriticismTask from "./criticism/CriticismTask"; // 评鉴任务
import CriticismLedger from "./criticism/CriticismLedger"; // 评鉴台账

// 个人奖惩
import IndividualReward from "./reward/IndividualReward";

// 质效考核
import MostlyLeadership from "./quality/MostlyLeadership"; // 主管领导
import BranchLeadership from "./quality/BranchLeadership"; // 分管领导
import SectionPrincipal from "./quality/SectionPrincipal"; // 科室负责人
import SectionClerk from "./quality/SectionClerk"; // 科员
import DirectlyUnit from "./quality/DirectlyUnit"; // 直属单位

// 日常考核
import DailyTask from "./dailyInspection/DailyTask";  // 日常任务
import CheckingAccount from "./dailyInspection/CheckingAccount";  // 考核台账

const components = [
  JDemo,
  SvgViewer,
  JComponents,
  MenuManage,
  RoleManage,
  UserManage,
  OrganManage,
  DictionaryManage,
  NoticeManage,
  BacklogTask,
  DemocracyLedger,
  DemocracyTask,
  MySummary,
  ExamineManage,
  MostlyLeadership,
  BranchLeadership,
  SectionPrincipal,
  SectionClerk,
  DirectlyUnit,
  DailyTask,
  CheckingAccount,
  DirectlyUnit,
  CriticismTask,
  CriticismLedger,
  IndividualReward
];

export default {
  install(Vue) {
    components.forEach((component) => Vue.component(component.name, component));
  },
};
