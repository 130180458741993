const href = location.href
export const title = '宜昌城管委'
export let api // 后端服务地址
export let web // 前端部署地址，用于访问本站的静态资源
export let fls // 文件服务地址
export let gis = 'development' // gis环境变量
export let home = 'menu-manage' // 首次登录加载的页面

export let system = 'yc-cgw' // 当前系统标识，用于文件服务资源标识
export let bucketName = 'grid' // 文件服务资源bucket
export let flsPathSufix = 'static-file' // 当前bucketName如果包含旧文件服务的资源，需配置static-file

export let encryptForNetwork = false // 启用传输体body加密，false时不应用加密逻辑
export let encryptMode = 'SM4' // ASE\SM4 AES：对称加密  SM4：国密

if (href.includes('yc-cgw.btzh.net')) {
  api = 'https://yc-cgw.btzh.net'
  web = 'https://yc-cgw.btzh.net'
  fls = 'https://oss-manage.btzh.net'
} else if (href.includes('localhost')) {
  api = 'http://localhost:8080'
  web = 'http://localhost:8080'
  fls = 'https://oss-manage.btzh.net:4443'
} else {
  web = location.origin
  api = 'http://10.55.68.2:22321/bsApis'
  fls = 'http://10.55.68.2:22321/fsApis'
  encryptForNetwork = true
}
