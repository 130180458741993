/**
 * @Author: fengjunjie <fengjunjie@btzh.cn>
 * @Description: jobrating_pc
 * @Date: 2022/04/18 11:14
 */
import Vue from 'vue'

const state = {
    orgTree: []
}

const mutations = {
    orgTree (state, payload) {
        state.orgTree = payload
    }
}

const actions = {
    async getOrgTree (content) {
        const { data } = await Vue.http.get(`/api/orgs`)
        if (data.status != 'yes') return Vue.prototype.$message.error(`获取机构树异常`)
        content.commit('orgTree', data.data)
    }
}

// 模块方式注册
export const organ = {
    namespaced: true,
    state,
    mutations,
    actions
}
