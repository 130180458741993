import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "../views/home/Home";
import Login from "../views/login/Login";

Vue.use(VueRouter)

const routes = [
  { path: '/login', component: Login, meta: { login: true } },
  { path: '/', name: 'Home', component: Home },
  // svg demo页面
  { path: '/svg', name: 'SvgViewer', component: () => import('../views/demo/SvgViewer') },
  // 组件使用合集demo
  { path: '/demo', name: 'JDemo', component: () => import('../views/demo/JDemo') },
]

const router = new VueRouter({
  routes
})

export default router
