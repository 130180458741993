/* <el-dialog v-if="showDgmx" :title="dgmxTypeMap[dgmxType]" :visible.sync="showDgmx" width="500px" top="5%" :close-on-click-modal="false">
    <el-form ref="dgmxDom" :model="dgmxForm" :rules="dgmxRule" :label-width="dgmxFormLabelWidth">
        <el-form-item label="输入框" prop="input">
            <el-input v-model.trim="dgmxForm.input" placeholder="请输入" maxlength="10" show-word-limit clearable />
        </el-form-item>
        <el-form-item label="描述" prop="description">
            <el-input v-model.trim="dgmxForm.description" type="textarea" :rows="3" placeholder="请输入描述" maxlength="200" show-word-limit clearable />
        </el-form-item>
        <el-form-item label="选择" prop="select">
            <el-select v-model="dgmxForm.select" placeholder="请选择" style="width: 100%" clearable filterable>
                <el-option v-for="(item, i) in ['选项1', '选项2']" :key="i" :label="item" :value="i" />
            </el-select>
        </el-form-item>
    </el-form>
    <template slot="footer">
        <el-button type="primary" @click="checkDgmxEvent('saveDgmxData')" :loading="submitLoad">保 存</el-button>
        <el-button @click="showDgmx = false">取 消</el-button>
    </template>
</el-dialog> */

// 详情弹窗
/* <el-dialog v-if="showDgmxDetail" :title="dgmxTypeMap[dgmxType]" :visible.sync="showDgmxDetail" width="500px" top="5%" :close-on-click-modal="false">
 </el-dialog> */

export default {
  data () {
    return {
      curItem: {}, // 当前选中项
      dgmxChangeProxy: {},
      submitLoad: false,
      dgmxFormLabelWidth: '100px',
      showDgmx: false,
      showDgmxDetail: false,
      dgmxType: 'ADD',
      dgmxTypeMap: {
        'ADD': '新增',
        'EDIT': '编辑',
        'DET': '详情'
      },
      dgmxForm: {
        // input: '',
        // select: ''
      },
      dgmxRule: {
        // input: [{ required: true, message: '请输入', trigger: 'blur' }],
        // select: [{ required: true, message: '请选择', trigger: 'change' }]
      }
    }
  },
  methods: {
    detailDgmxEvent (row) {
      this.dgmxType = 'DET'
      this.curItem = { ...row }
      this.showDgmxDetail = true
    },
    editDgmxEvent (row) {
      this.dgmxType = 'EDIT'
      this.curItem = { ...row }
      this.dgmxForm = this.proxyDgmxData({ ...this.dgmxForm, ...row })
      this.showDgmx = true
    },
    addDgmxEvent () {
      this.dgmxType = 'ADD'
      this.showDgmx = true
    },
    checkDgmxProxy () {
      if (this.dgmxType !== 'EDIT') return true
      let flag = false
      for (let key in this.dgmxChangeProxy) {
        if (this.curItem[key] !== this.dgmxChangeProxy[key]) {
          flag = true
          break
        }
      }
      return flag
    },
    proxyDgmxData (obj) {
      const that = this
      if (!obj) return {}
      return new Proxy(obj, {
        set (target, p, value, receiver) {
          that.dgmxChangeProxy[p] = value
          return Reflect.set(target, p, value, receiver)
        }
      })
    },
    checkDgmxEvent (funName) {
      if (!this.checkDgmxProxy()) return this.$message.info('数据未变动，无需重复保存')
      this.$refs['dgmxDom'].validate((valid) => {
        if (valid) {
          this[funName]()
        }
      })
    },
    async saveDgmxData () {
      if (this.submitLoad) return
      this.submitLoad = true
      let method = 'post'
      let url = `/api/personalDocs`
      if (this.dgmxType === 'EDIT') {
        url = `/api/personalDocs/${this.curItem.id}`
        method = 'put'
      }
      const { data } = await this.$http[method](url, this.dgmxForm)
          .catch(({response}) => { this.$message.error(response.data.msg) })
          .finally(() => { this.submitLoad = false })
      if (data.status !== 'yes') return this.$message.error(data.msg)
      this.showDgmx = false
      this.$message.success('提交成功')
      this.load()
    }
  },
  watch: {
    showDgmx (nv) {
      if (!nv) {
        this.dgmxForm = { ...this.$options.data().dgmxForm }
        this.dgmxChangeProxy = { ...this.$options.data().dgmxChangeProxy }
      }
    }
  }
}
