<template>
    <j-page>
        <div slot="search">
            <j-page-search title="考核月份">
                <el-date-picker v-model="query.yearMonths" type="month"  placeholder="选择考核月份" value-format="yyyy/MM" />
            </j-page-search>
            <el-button type="primary" @click="searchEvent">查询</el-button>
            <el-button @click="resetEvent">重置</el-button>
        </div>
        <div slot="table">
            <el-table v-loading="tableLoading" :data="tableData" height="100%">
                <el-table-column prop="yearMonths" label="考核月份" align="center" show-overflow-tooltip />
                <el-table-column prop="status" label="状态" align="center" show-overflow-tooltip>
                    <template slot-scope="{row}">{{!row.status ? '进行中' : '结束'}}</template>
                </el-table-column>
                <el-table-column prop="previewTime" label="预览时间" align="center" show-overflow-tooltip />
                <el-table-column prop="endTime" label="结束时间" align="center" show-overflow-tooltip />
                <el-table-column label="操作" width="90">
                    <template slot-scope="scope">
                        <el-button v-if="!scope.row.status" @click.stop="previewEvent(scope.row)" type="primary" class="el-button el-button--text font-red" >预览</el-button>
                        <el-button v-if="!scope.row.status" @click.stop="endEvent(scope.row)" type="primary" class="el-button el-button--text font-red" >结束</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div slot="pagination" v-show="query.total > query.limit">
            <el-pagination
                    :current-page="query.page"
                    :page-size="query.limit"
                    :total="query.total"
                    :background="pmxBackground"
                    :pager-count="pmxCount"
                    :layout="pmxLayout"
                    :pageSizes="pmxSizes"
                    @current-change="changePage"
                    @size-change="sizeChange"
            />
        </div>
    </j-page>
</template>

<script>
    /**
     * @Author: fengjunjie <fengjunjie@btzh.cn>
     * @Description: jobrating_pc
     * @Date: 2022/04/15 10:02
     */
    import PageMixins from "../../components/JComponents/JPage/mixins/PageMixins";
    import PaginationMixins from "../../components/JComponents/JPage/mixins/PaginationMixins";
    export default {
        name: "ExamineManage",
        mixins: [PageMixins, PaginationMixins],
        data () {
            return {
                query: {
                    yearMonths: ''
                }
            }
        },
        created () {
          this.load()
        },
        methods: {
            async load () {
                this.tableLoading = true
                const { data } = await this.$http.get(`/api/evalues`, {
                    params: this.query
                })
                this.tableData = data.data.data
                this.query.total = data.data.total
                this.tableLoading = false
            },
            async previewEvent (row) {
              const { data } = await this.$http.post(`/api/evalues/endPreview/${row.id}`)
              if (data.status != 'yes') return this.$message.warning(data.msg || data.message)
              this.$message.success('预览成功')
              this.load()
            },
            endEvent (row) {
                this.$confirm('您确认要结束当前考核吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http
                        .request({
                            method: 'POST',
                            url: `/api/evalues/end/${row.id}`
                        })
                        .then((res) => {
                            if (res.data.status !== 'yes') return this.$message.error(res.data.msg)
                            this.$message.success('结束成功')
                            this.load && this.load()
                        })
                })
            }
        }
    }
</script>
