<template>
    <div class="j-components-layout">
        <div>
            <el-card shadow="hover">
                <label slot="header">时间范围 {{`${starttime} - ${endtime}`}}</label>
                <el-divider content-position="left">:start.sync="starttime" :end.sync="endtime"</el-divider>
                <j-date-range :start.sync="starttime" :end.sync="endtime"/>
                <el-divider content-position="left">时分秒 value-format="yyyy-MM-dd HH:mm:ss"</el-divider>
                <j-date-range :start.sync="starttime" :end.sync="endtime" value-format="yyyy-MM-dd HH:mm:ss" />
                <el-divider content-position="left">可选参数before,after，禁用今天之前/之后的日期 disabledRange="after"</el-divider>
                <j-date-range :start.sync="starttime" :end.sync="endtime" disabledRange="after" />
                <el-divider content-position="left">快速选择过去几天，快捷按钮数组，内部数字代表天数 :before-shortcuts="[3,5,7]"</el-divider>
                <j-date-range :start.sync="starttime" :end.sync="endtime" :before-shortcuts="[3,5,7]" />
                <el-divider content-position="left">快速选择未来几天，快捷按钮数组，内部数字代表天数 :after-shortcuts="[7,30,90]"</el-divider>
                <j-date-range :start.sync="starttime" :end.sync="endtime" :after-shortcuts="[7,30,90]" />
            </el-card>

            <el-card shadow="hover">
                <label slot="header">范围输入 {{`${startlink} - ${endlink}`}}</label>
                <el-divider content-position="left">:start.sync="startlink" :end.sync="endlink"</el-divider>
                <j-link-input :start.sync="startlink" :end.sync="endlink"/>
                <el-divider content-position="left">限定数字 type="number" placeholderStrat="初始数字" placeholderEnd="结束数字" clearable</el-divider>
                <j-link-input :start.sync="startlink" :end.sync="endlink" type="number" placeholderStrat="初始数字" placeholderEnd="结束数字" clearable/>
                <el-divider content-position="left">带单位 unit="单位" range-separator="至" </el-divider>
                <j-link-input :start.sync="startlink" :end.sync="endlink" unit="单位" range-separator="至"/>
            </el-card>

<!--            <el-card shadow="hover">-->
<!--                <label slot="header">所属区域选框 {{`${gridCode}`}}</label>-->
<!--                需要往store.js注入JGridCtrl模块-->
<!--                import { JGridCtrl } from './components/JGridCtrl/store'-->
<!--                <el-divider content-position="left">type="text" :value="gridCode" textSeparator="/"， 默认分隔符textSeparator="-"</el-divider>-->
<!--                <j-grid-ctrl style="color: #409EFF;" type="text" :value="gridCode" textSeparator="/" />-->
<!--                <el-divider content-position="left">type="select" placeholder="请选择区域" v-model="gridCode"</el-divider>-->
<!--                <j-grid-ctrl type="select" placeholder="请选择区域" v-model="gridCode" />-->
<!--                <el-divider content-position="left">type="text" value="12011000101004" :rule="[9, 12, 14]" 默认规则是[6, 9, 12, 14]，可根据后端返回的层级解析</el-divider>-->
<!--                <div style="color: #F56C6C;margin-bottom: 10px;">-->
<!--                    rule[6, 9, 12, 14] : <j-grid-ctrl type="text" value="12011000101004" />-->
<!--                </div>-->
<!--                <div style="color: #409EFF">-->
<!--                    rule[9, 12, 14] : <j-grid-ctrl type="text" value="12011000101004" :rule="[9, 12, 14]" />-->
<!--                </div>-->
<!--            </el-card>-->

            <el-card shadow="hover">
                <label slot="header">脱敏组件</label>
                <el-divider content-position="left">默认保留第一位 v-model="sensitive"</el-divider>
                <j-sensitive v-model="sensitive.default" />
                <el-divider content-position="left">名字脱敏 type="name" eye</el-divider>
                <j-sensitive type="name" v-model="sensitive.phone" eye />
                <el-divider content-position="left">手机号脱敏 type="phone" eye</el-divider>
                <j-sensitive type="phone" v-model="sensitive.phone" eye />
                <el-divider content-position="left">身份证号脱敏 type="id" eye</el-divider>
                <j-sensitive type="phone" v-model="sensitive.id" eye />
                <el-divider content-position="left">定制规则脱敏 :rule="[2, 6]" 第一位为正数表示正向保留多少位， 第二位为负数表示倒向保留多少位</el-divider>
                <el-input style="width: 120px;" v-model="sensitive.custom" placeholder="脱敏测试" />
                <j-sensitive :rule="[2, 6]" v-model="sensitive.custom" />
            </el-card>

            <el-card shadow="hover">
                <label slot="header">ip地址输入框 {{ip}}</label>
                <el-divider content-position="left">v-model="ip"</el-divider>
                <j-ip-input style="width: 400px;" v-model="ip" />
                <el-divider content-position="left">只读:value="ip" read-only</el-divider>
                <j-ip-input style="width: 400px;" :value="ip" read-only />
            </el-card>

            <el-card shadow="hover">
                <label slot="header">富文本</label>
                <el-divider content-position="left">v-model="editorVal"</el-divider>
                <j-editor style="width: 800px;" v-model="editorVal" />
                <el-divider content-position="left">:height="200" </el-divider>
                <j-editor style="width: 800px;" v-model="editorVal" :height="200" />
            </el-card>

            <el-card shadow="hover">
                <label slot="header">上传组件 (附件默认点击行为：图片文件打开文件预览、其他类型文件发起下载)</label>
                <el-divider content-position="left">v-model="file" list-type="text"</el-divider>
                <j-upload v-model="file" list-type="text">
                    <span style="cursor: pointer;color: #2D76D5;">上传</span>
                </j-upload>
                {{file}}
                <el-divider content-position="left">返回全路径 v-model="file1" type="Array" list-type="picture-card" need-fully</el-divider>
                <j-upload v-model="file1" type="Array" list-type="picture-card" need-fully>
                    <i class="el-icon-plus" style="font-size: 18px;"></i>
                </j-upload>
                {{file1}}
                <el-divider content-position="left">隐藏元素，间接触发调用使用事件监听方式获取状态</el-divider>
                <div style="color: #409EFF;margin-bottom: 20px;">
                    j-upload v-show="false" ref="JuploadRef"  @upload-progress="uploadProgress" @upload-complete="uploadComplete" @upload-error="uploadError"
                </div>
                <div style="color: #67C23A;">
                    调用引用实例触发选择文件：this.$refs.JuploadRef.UploadTrigger
                </div>
                <el-divider content-position="left">props</el-divider>
                <el-table border :data="JUploadProps" height="300">
                    <el-table-column prop="name" label="属性" width="200px" />
                    <el-table-column prop="desc" label="描述" />
                </el-table>
            </el-card>

<!--            <el-card shadow="hover">-->
<!--                <label slot="header">lottie动画</label>-->
<!--                需要在项目静态目录中创建lottie文件夹，将ui提供的lottie文件夹更名后复制到其中-->

<!--                只要确保文件夹下有images文件夹和data.json文件即可-->
<!--                <el-divider content-position="left">name="login-center" 名称需要lottie下文件夹命名相同</el-divider>-->
<!--                <div style="width: 500px; height: 500px;background-color: #000">-->
<!--                    <j-lottie name="login-center"/>-->
<!--                </div>-->
<!--            </el-card>-->

            <el-card shadow="hover">
                <label slot="header">色块统计</label>
                <el-divider content-position="left">shade="#90EE90" desc="颜色范围，无数字" :value="88" :color="['#9370DB', '#87CEFA']"</el-divider>
                <!--      无图标，背景渐变自动计算-->
                <j-colour shade="#75d7d5" desc="无图标，背景渐变自动计算" :value="88" />
                <!--      颜色范围，无数字,使用图片作为插槽内容-->
                <j-colour :color="['#9370DB', '#87CEFA']" desc="颜色范围，无数字11111112222222">
                    <img slot="icon" src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" alt="test">
                </j-colour>
                <j-colour shade="#90EE90" desc="颜色范围，无数字" :value="88" >
                    <img slot="icon" src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" alt="test">
                </j-colour>
                <j-colour shade="#90EE90" desc="svg图标" :value="88" >
                    <j-svg-icon slot="icon" name="xtwh"/>
                </j-colour>
            </el-card>

            <el-card shadow="hover">
                <label slot="header">字典解析</label>
                包含缓存和store两种本地持久化方式 （建议往store注入，使用更方便）
                使用store方式，可直接使用this.$store.dispatch('JDictionary/getDictionaryByKey', 'CODE')获取字典
                <el-divider content-position="left">根据提供的字典key，获取对应值的文本code="GENDER" value="1"</el-divider>
                <j-dictionary code="GENDER" value="1"/>
            </el-card>

            <el-card shadow="hover">
                <label slot="header">字典选择框</label>
                字典选择框需要配合j-dictionary组件使用
                <el-divider content-position="left">单选 v-model="gender" code="GENDER" placeholder="请选择性别"</el-divider>
                <j-dic-select style="width: 200px;" v-model="gender" code="GENDER" placeholder="请选择性别" />
                <el-divider content-position="left">多选 multiple</el-divider>
                <j-dic-select style="width: 300px;" v-model="genderArr" code="GENDER" placeholder="请选择性别" multiple />
            </el-card>

            <el-card shadow="hover">
                <label slot="header">label样式的呈现组件</label>
                <el-divider content-position="left">title="内容文字"</el-divider>
                <j-label title="内容文字">
                    包含内容，可以是文本，或块级元素
                </j-label>
                <el-divider content-position="left">内容为空</el-divider>
                <j-label title="内容为空"></j-label>
                <el-divider content-position="left">内容为图片</el-divider>
                <j-label title="内容图片">
                    <img height="100px" src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" alt="test">
                </j-label>
                <el-divider content-position="left">内容为超长文字</el-divider>
                <div style="width: 400px">
                    <j-label title="标题超长文字">
                        超长文字超长文字超长文字超长文字超长文字超长文字超长文字超长文字超长文字超长文字超长文字超长文字超长文字超长文字超长文字超长文字超长文字超长文字
                    </j-label>
                </div>
                <el-divider content-position="left">inline 行内模式，按多个label宽度平均分多余部分自动换行</el-divider>
                <div style="width: 800px;">
                    <j-label title="行内模式1" inline>行内模式行内模式行内模式行内模式</j-label><j-label title="行内模式2" inline>行内模式行内模式行内模式行内模式</j-label>
                    <j-label title="行内模式1" inline>行内模式行内模式行内模式行内模式</j-label><j-label title="行内模式2" inline>行内模式行内模式行内模式行内模式</j-label>
                </div>
                <el-divider content-position="left">label对其方式 align(left, center, right) :labelWidth="200"</el-divider>
                <div style="width: 400px;">
                    <j-label title="左对齐" :labelWidth="200" align="left">left</j-label>
                    <j-label title="中间对齐" :labelWidth="200" align="center">center</j-label>
                    <j-label title="右对齐" :labelWidth="200" align="right">right</j-label>
                </div>
            </el-card>

            <el-card shadow="hover">
                <label slot="header">头像-重置密码-退出登录</label>
                <el-divider content-position="left">j-avatar</el-divider>
                <div style="width: 150px;">
                    <j-avatar />
                </div>
            </el-card>

            <el-card shadow="hover">
                <label slot="header">菜单</label>
                需要往store.js注入JMenu模块
                import { JMenu } from './components/JMenu/store'
                <el-divider content-position="left">j-menu mode默认vertical  自动压缩屏幕尺寸collapseWidth：1280</el-divider>
                <div style="max-width: 200px;">
                    <j-menu />
                </div>
                <el-divider content-position="left">mode="horizontal"</el-divider>
                <j-menu mode="horizontal" />
            </el-card>

            <el-card shadow="hover">
                <label slot="header">快速切换栏</label>
                依赖store.js中的JMenu模块
                <el-divider content-position="left">j-quick-tab</el-divider>
                <j-quick-tab/>
            </el-card>

            <el-card shadow="hover" style="height: 300px;">
                <label slot="header">侧边栏-侧边栏标题-侧边栏文本</label>
                <el-divider content-position="left">j-drawer title="侧边栏" :close.sync="showDrmx"</el-divider>
                <el-divider content-position="left">j-drawer-label type="title" title="基本信息"</el-divider>
                <el-divider content-position="left">j-drawer-label align="right" title="文本"</el-divider>
                <el-button @click="showDrmx = true;DrmxBtn = '1'">单按钮</el-button>
                <el-button @click="showDrmx = true;DrmxBtn = 'n'">多按钮</el-button>
                <j-drawer title="侧边栏" :close.sync="showDrmx">
                    <div slot="header" style="padding: 10px;">
                        <el-input value="头部插槽" />
                    </div>
                    <j-drawer-label type="title" title="基本信息" />
                    <div style="padding-right: 30px;">
                        <j-drawer-label align="right" title="文本">文本文本</j-drawer-label>
                        <j-drawer-label align="right" title="空样式"></j-drawer-label>
                    </div>
                    <div v-if="DrmxBtn === 'n'" slot="footer" style="display: flex; width: 100%;">
                        <el-button @click="showDrmx = false">保存</el-button>
                        <el-button @click="showDrmx = false">支持多个按钮</el-button>
                        <el-button @click="showDrmx = false">支持多个按钮</el-button>
                    </div>
                    <el-button v-else slot="footer" @click="showDrmx = false">单按钮</el-button>
                </j-drawer>
            </el-card>

            <el-card shadow="hover">
                <label slot="header">通用台账布局</label>
                <el-divider content-position="left">j-page slot="header"色块统计 / slot="search"搜索区域 / slot="table"表格 / slot="pagination"分页布局</el-divider>
                <el-divider content-position="left">j-page-search title="搜索项目"</el-divider>
                <j-page>
                    <div slot="header" style="background-color: rgb(250, 236, 216);text-align: center">色块统计区域</div>
                    <div slot="search" style="background-color: rgb(225, 243, 216);text-align: center">
                        <j-page-search title="搜索项目">
                            <j-date-range :start.sync="starttime" :end.sync="endtime"/>
                        </j-page-search>
                    </div>
                    <div slot="table" style="background-color:rgb(140, 197, 255);text-align: center;">表格区域自动撑开</div>
                    <div slot="pagination" style="background-color: rgb(253, 226, 226);text-align: center">分页区域</div>
                </j-page>
            </el-card>

            <el-card shadow="hover">
                <label slot="header">通用台账混入</label>
                <el-divider content-position="left">src/components/JComponents/JPage/mixins/PageMixins.js</el-divider>
                台账页面基本混入，主要包含<span style="color: #409EFF;">基本组件引入、导出、搜索、重置、获取数据列表、更新分页、删除等</span>方法模板
                <el-divider content-position="left">src/components/JComponents/JPage/mixins/validator.js</el-divider>
                台账页面基本校验混入，包含<span style="color: #409EFF;">电话、邮箱、年龄、密码</span>校验规则
                <el-divider content-position="left">src/components/JComponents/JPage/mixins/PaginationMixins.js</el-divider>
                页面分页混入，主要包含<span style="color: #409EFF;">改变分页、切换分页数量限制</span>的方法
                <el-divider content-position="left">src/components/JComponents/JPage/mixins/DrawerMixins.js</el-divider>
                侧边抽屉混入，主要包含<span style="color: #409EFF;">通过侧边栏操作的添加、编辑、详情、保存、重置、提交校验等</span>方法
                <el-divider content-position="left">src/components/JComponents/JPage/mixins/DialogMixins.js</el-divider>
                弹窗混入，主要包含<span style="color: #409EFF;">通过弹窗操作的添加、编辑、详情、保存、重置、提交校验等</span>方法
            </el-card>
        </div>
    </div>
</template>

<script>
    import JDateRange from "../../components/JComponents/JDateRange/JDateRange";
    import JLinkInput from "../../components/JComponents/JLinkInput/JLinkInput";
    import JSensitive from "../../components/JComponents/JSensitive/JSensitive";
    import JIpInput from "../../components/JComponents/JIpInput/JIpInput";
    import JEditor from "../../components/JComponents/JEditor/JEditor";
    import JUpload from "../../components/JComponents/JUpload/components/JUpload";
    // import JLottie from "../../components/JComponents/JLottie/JLottie";
    import JColour from "../../components/JComponents/JColour/JColour";
    import JDictionary from "../../components/JComponents/JDictionary/JDictionary";
    import JAvatar from "../../components/JComponents/JAvatar/JAvatar";
    import JMenu from "../../components/JComponents/JMenu/JMenu";
    import JQuickTab from "../../components/JComponents/JQuickTab/components/JQuickTab";
    import JLabel from "../../components/JComponents/JLabel/JLabel";
    import JDrawer from "../../components/JComponents/JDrawer/JDrawer";
    import JDrawerLabel from "../../components/JComponents/JDrawer/JDrawerLabel";
    import JPage from "../../components/JComponents/JPage/JPage";
    import JPageSearch from "../../components/JComponents/JPage/JPageSearch";
    // import JGridCtrl from "../../components/JComponents/JGridCtrl/JGridCtrl";
    import JDicSelect from "../../components/JComponents/JDicSelect/JDicSelect";
    export default {
        name: "JComponents",
        components: {
            JLinkInput,
            JDateRange,
            JSensitive,
            JIpInput,
            JEditor,
            JUpload,
            // JLottie,
            JColour,
            JDictionary,
            JAvatar,
            JMenu,
            JQuickTab,
            JLabel,
            JDrawer,
            JDrawerLabel,
            JPage,
            JPageSearch,
            // JGridCtrl,
            JDicSelect
        },
        data () {
            return {
                showDrmx: false,
                DrmxBtn: '1',
                gridCode: '',
                starttime: '',
                endtime: '',
                startlink: '',
                endlink: '',
                ip: '192.168.1.0',
                editorVal: '',
                file: '',
                file1: [],
                genderArr: [],
                gender: '',
                JUploadProps: [
                    { name: 'el-upload所有属性', desc: '基于el-upload封装，可使用其所有属性' },
                    { name: 'countLimit', desc: '文件上传限制,默认上传30个文件' },
                    { name: 'mimeTypeLimit', desc: 'mimeType[]数组限制,限制文件无法上传' },
                    { name: 'sizeLimit', desc: '上传文件大小限制，数字类型以KB单位' },
                    { name: 'readOnly', desc: '只读模式下 不可以删除、上传可以文件下载和预览' },
                    { name: 'type', desc: '决定了双向绑定时组件返回的数据类型，包含String 和 Array两种模式，存的时候用什么模式，回显的时候必须用相同的模式和数据格式' },
                    { name: 'action', desc: '可配置的文件上传接口地址' },
                    { name: 'module', desc: '可配置的模块名称，决定了文件上传的path路径模块名，推荐每个模块使用不同的名称，文件服务中存储文件夹更加清晰' },
                    { name: 'value', desc: '双向绑定值，包含两种类型' },
                    { name: 'needFully', desc: 'Boolean类型，默认false，返回全路径' },
                    { name: 'Separator', desc: '返回文本时使用分隔符，只有String模式有效' }
                ],
                sensitive: {
                    default: '默认脱敏文字测试',
                    phone: '13113120861',
                    id: '120110199802094688',
                    name: '张思锐',
                    custom: '123456789'
                }
            }
        }
    }
</script>

<style lang="less">
    .j-components-layout {
        position: relative;
        height: 100%;
        box-sizing: border-box;
        padding: 15px;
        overflow-y: auto;
        .el-card {
            position: relative;
            margin-bottom: 50px;
        }
    }
</style>
