<template>
  <div class="info-container">
    <j-drawer-label type="title" title="考核明细" />
    <div class="label-container">
      <div @click="() => goCheckedDetail()">
        <j-label :labelWidth="200" title="日常任务数量">
          <div class="content">{{ checkedInfo.countDaily }}</div>
        </j-label>
      </div>
      <div @click="() => goCheckedDetail(2)">
        <j-label :labelWidth="200" title="累计得分-重点工作">
          <div class="content">
            {{ checkedInfo.scoreTotalEmphasiswork }}
          </div>
        </j-label>
      </div>
      <div @click="() => goCheckedDetail(1)">
        <j-label :labelWidth="200" title="累计得分-基础工作">
          <div class="content">
            {{ checkedInfo.scoreTotalBasework }}
          </div>
        </j-label>
      </div>
      <div @click="() => goCheckedDetail(9)">
        <j-label :labelWidth="200" title="累计得分-其他">
          <div class="content">
            {{ checkedInfo.scoreTotalOthwork }}
          </div>
        </j-label>
      </div>
      <div @click="() => goCheckedDetail()">
        <j-label :labelWidth="200" title="总分">
          <div class="content text-status">{{ checkedInfo.scoreTotalFinal2 }}</div>
        </j-label>
      </div>
    </div>
  </div>
</template>

<script>
import JLabel from "@/components/JComponents/JLabel/JLabel";
import JDrawerLabel from "@/components/JComponents/JDrawer/JDrawerLabel";

export default {
  name: "CheckedInfo",
  components: {
    JLabel,
    JDrawerLabel,
  },
  props: {
    checkedInfo: {
      type: Object,
    },
    cd: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    goCheckedDetail(itemId) {
      this.cd(this.checkedInfo, itemId);
    },
  },
};
</script>

<style lang="less" scoped>
.info-container {
  margin-top: 20px;
  .label-container {
    .j-label-wrap:hover {
      background: #efefef;
    }
    padding: 0 15px;
    margin-bottom: 15px;
    .content {
      display: flex;
    }
    .text-status {
      font-size: 18px;
      font-family: TeXGyreAdventor-Bold, TeXGyreAdventor;
      font-weight: bold;
      color: #fa8f31;
    }
  }
}
</style>