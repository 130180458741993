/*
 * @Description:
 * @Author: tengmingxin
 * @Date: 2022-04-14 20:15:35
 * @LastEditors: tengmingxin
 * @LastEditTime: 2022-04-28 10:50:52
 */

/**
 * @msg: 处理饼状图数据
 * @param {Object} data
 * @param {Object} map
 * @param {String} title
 * @return {*} option
 */
const countPie = (data, map, title) => {
  const yAxis = [];
  let total = 0;
  let seriesData = [];
  for (const key in map) {
    yAxis.push(data[key]);
    total += Number(data[key] || 0);
    seriesData.push({ name: map[key], value: data[key] || 0 });
  }
  const option = {
    // title: {
    //   text: title,
    //   left: "left",
    // },
    tooltip: {
      trigger: "item",
    },
    color: ["#fa8f31", "#4b83ff", "#00b9dc", "#00e9c2", "#ffd733", "#00e0f0"],
    legend: {
      icon: "roundRect",
      orient: "vertical",
      bottom: "middle",
      type: "scroll",
      right: 20,
      height: "100%",
      itemWidth: 12,
      itemHeight: 12,
      textStyle: {
        rich: {
          a: {
            width: 60,
            height: 12,
            fontSize: 13,
            color: "rgba(29, 31, 34, 0.75)",
          },
          b: {
            height: 12,
            width: 60,
            fontWeight: 600,
            fontSize: 13,
            color: "#1D1F22",
            align: "right",
          },
        },
      },
      formatter: function (name) {
        const value = seriesData.find((e) => e.name === name)?.value;
        return `{a|${name}}{b|${
          Math.round((value / total) * 1000) / 10 || 0
        }%}`;
      },
    },
    graphic: {
      //图形中间图片
      elements: [
        {
          type: "ring",
          style: {
            fill: "#f1f5f6",
          },
          shape: {
            r: 60,
            r0: 50,
          },
          transition: "shape",
          left: "40",
          top: "center",
        },
      ],
    },
    series: [
      {
        type: "pie",
        radius: [70, 85],
        itemStyle: {
          borderRadius: 2,
          borderColor: "#fff",
          borderWidth: 3,
        },
        data: seriesData,
        label: { show: false },
        center: [100, "50%"],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
  return option;
};
/**
 * @msg: 处理柱状图数据
 * @param {Array} xData
 * @param {Array} yData
 * @param {Array} title
 * @return {String} option
 */
const countBar = (xData, yData, title) => {
  const data = yData.map((item, i) => {
    return {
      value: item,
      itemStyle: {
        color: item > 0 ? "#fa8f31" : "#00b9dc",
      },
      label: {
        position: item > 0 ? "top" : "bottom",
        color: item > 0 ? "#fa8f31" : "#00b9dc",
      },
    };
  });
  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      axisTick: {
        show: false,
      },
      data: xData,
    },
    yAxis: [
      {
        type: "value",
        max: function (value) {
          return value.max + Math.floor(value.max / 5);
        },
        min: function (value) {
          return value.min + Math.floor(value.min / 5);
        },
      },
    ],
    series: [
      {
        type: "bar",
        colorBy: "data",
        barMaxWidth: "20",
        label: {
          show: true,
          fontWeight: "bold",
        },
        emphasis: { focus: "series" },
        itemStyle: { borderRadius: 4 },
        data,
      },
    ],
  };
  return option;
};
export default { countPie, countBar };
