import CryptoJS from 'crypto-js'
import { nanoid } from 'nanoid'
import { v4 as uuidv4 } from 'uuid';
const sm4 = require('sm-crypto').sm4
import { encryptForNetwork, encryptMode } from "@/config"; // encryptForNetwor：加密开关，encryptMode：加密方式

export default {
    /**
     * 判断是否为Json格式
     */
    isJson (str){
        if (typeof str == 'string') {
            try {
                let obj=JSON.parse(str);
                if(typeof obj == 'object' && obj ){
                    return true;
                }else{
                    return false;
                }

            } catch(e) {
                return false;
            }
        }
    },
    /**
     * 获取sm4密文
     */
    getEncryptSM4 (data) {
        const id = uuidv4().toString().replaceAll('-', '')
        const encryptCode = this.encryptSM4(data, id)
        return `${id}${encryptCode}`
    },
    /**
     * sm4加密
     */
    encryptSM4 (data, key) {
        return sm4.encrypt(data, key)
    },
    /**
     * sm4解密
     */
    decryptSM4 (data, key) {
        return sm4.decrypt(data, key)
    },
    /**
     * 获取aes对称加密密文
     */
    getEncryptAES (data) {
        const id = nanoid(32)
        const encryptCode = this.encryptAES(data, CryptoJS.MD5(id))
        return `${id}${encryptCode}`
    },
    /**
     * aes对称加密
     */
    encryptAES (data, keyStr) {
        let key = CryptoJS.enc.Utf8.parse(keyStr)
        let encryptedData = CryptoJS.AES.encrypt(data, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        })
        encryptedData = encryptedData.ciphertext.toString()
        let encryptedHexStr = CryptoJS.enc.Hex.parse(encryptedData)
        return CryptoJS.enc.Base64.stringify(encryptedHexStr)
    },
    /**
     * aes对称解密
     */
    decryptAES (data, keyStr) {
        let key = CryptoJS.enc.Utf8.parse(keyStr)
        let decryptedData = CryptoJS.AES.decrypt(data, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        })
        return decryptedData.toString(CryptoJS.enc.Utf8)
    },
    /**
     * 解密字符串
     */
    decryptString (str, mode = encryptMode) {
        let result
        if (mode === 'SM4') {
            result = this.decryptSM4(str.substr(32), str.substr(0, 32))
        } else {
            result = this.decryptAES(str.substr(32), CryptoJS.MD5(str.substr(0, 32)))
        }
        !result && console.error(`字符串解密异常：${str}`)
        return result
    },
    /**
     * 解密请求体
     */
    decryptResponse (response, mode = encryptMode) {
        // console.log(response)
        if (!encryptForNetwork) return
        const { encoded, encodedType, data } = response.data
        if (encoded) {
            switch (encodedType) {
                case 1: {
                    if (mode === 'SM4') {
                        response.data.data = JSON.parse(this.decryptSM4(data.substr(32), data.substr(0, 32))); break
                    } else {
                        response.data.data = JSON.parse(this.decryptAES(data.substr(32), CryptoJS.MD5(data.substr(0, 32)))); break
                    }
                }
                case 2: { break }
                case 3: {
                    if (mode === 'SM4') {
                        response.data.data = JSON.parse(this.decryptSM4(data.substr(32), data.substr(0, 32))); break
                    } else {
                        response.data.data = JSON.parse(this.decryptAES(data.substr(32), CryptoJS.MD5(data.substr(0, 32)))); break
                    }
                }
            }
        }
    },
    /**
     * 加密请求体
     */
    encryptRequest(request, mode = encryptMode) {
        // console.log(request)
        if (!encryptForNetwork) return
        const { data } = request
        if (data) {
            let id
            let encryptCode
            if (mode === 'SM4') {
                id = uuidv4().toString().replaceAll('-', '')
                encryptCode = this.encryptSM4(this.isJson(data) ? data : JSON.stringify(data), id)
            } else {
                id = nanoid(32)
                encryptCode = this.encryptAES(this.isJson(data) ? data : JSON.stringify(data), CryptoJS.MD5(id))
            }
            let md5body = CryptoJS.MD5(JSON.stringify(data))
            request.data = `${id}${encryptCode}`
            request.headers.sign = md5body
        }
    }
}
