/**
 * @Author: fengjunjie <fengjunjie@btzh.cn>
 * @Description: jobrating_pc
 * @Date: 2022/04/21 11:41
 */
import sectionItem from "../../components/sectionItem";
export default {
    components: {
        sectionItem
    },
    data () {
        return {
            query: {
                limit: 5,
                mobile: '',
                orgId: '',
                scoreFinalEnd: '',
                scoreFinalStart: '',
                userName: '',
                yearMonths: ''
            },
            showIndividual: false,
            individualQuery: {
                page: 1,
                limit: 10,
                total: 0
            },
            individualData: [],
            showMember: false,
            memberQuery: {
                page: 1,
                limit: 10,
                total: 0
            },
            memberData: [],
            showDemocracy: false,
            democracyQuery: {
                page: 1,
                limit: 10,
                total: 0
            },
            democracyData: [],
            showLeader: false,
            leaderQuery: {
                page: 1,
                limit: 10,
                total: 0
            },
            leaderData: [],
            showDaily: false,
            dailyQuery: {
                page: 1,
                limit: 10,
                total: 0
            },
            dailyData: []
        }
    },
    methods: {
        individualEvent (row, query) {
            this.curItem = { ...row }
            this.getIndividualData(query)
        },
        changeIndividualPage (page) {
            this.individualQuery.page = page
            this.loadIndividualData()
        },
        async getIndividualData (query = {}) {
            this.individualQuery = { ...this.individualQuery, ...query }
            const { data } = await this.$http.get(`/api/rewardpunishment/getPersonalRewardPunishmentAssessment`, {
                params: this.individualQuery
            })
            this.individualData = data.data.detailResVOList
            // this.individualQuery.total = data.data.total
            this.showIndividual = true
        },
        memberEvent (row, query) {
            this.curItem = { ...row }
            this.getMemberData(query)
        },
        changeMemberPage (page) {
            this.memberQuery.page = page
            this.getMemberData()
        },
        async getMemberData (query = {}) {
            this.memberQuery = { ...this.memberQuery, ...query }
            const { data } = await this.$http.get(`/api/bisMassEffectEvalue22`, {
                params: this.memberQuery
            })
            this.memberData = data.data.data
            this.memberQuery.total = data.data.total
            this.showMember = true
        },
        democracyEvent (row, query) {
            this.curItem = { ...row }
            this.getDemocracyData(query)
        },
        changeDemocracyPage (page) {
            this.leaderQuery.page = page
            this.getDemocracyData()
        },
        async getDemocracyData (query = {}) {
            this.democracyQuery = { ...this.democracyQuery, ...query }
            const { data } = await this.$http.get(`/api/democraticevaluation/getDemocraticEvaluationAssessment`, {
                params: this.democracyQuery
            })
            this.democracyData = data.data
            // this.democracyQuery.total = data.data.total
            this.showDemocracy = true
        },
        leadershipEvent (row, query) {
            this.curItem = { ...row }
            this.getLeadershipData(query)
        },
        changeLeadershipPage (page) {
            this.leaderQuery.page = page
            this.getLeadershipData()
        },
        async getLeadershipData (query = {}) {
            this.leaderQuery = { ...this.leaderQuery, ...query }
            const { data } = await this.$http.get(`/api/leaderAppraise/getLeaderAppraiseAssessment`, {
                params: this.leaderQuery
            })
            this.leaderData = data.data
            // this.leaderQuery.total = data.data.total
            this.showLeader = true
        },
        dailyEvent (row, query) {
            this.curItem = { ...row }
            this.getDailyData(query)
        },
        changeDailyPage (page) {
            this.dailyQuery.page = page
            this.getDailyData()
        },
        async getDailyData (query = {}) {
            this.dailyQuery = { ...this.dailyQuery, ...query }
            const { data } = await this.$http.get(`/api/bisDailyLedgers`, {
                params: this.dailyQuery
            })
            this.dailyData = data.data.data
            this.dailyQuery.total = data.data.total
            this.showDaily = true
        }
    },
    watch: {
        showDaily (nv) {
            if (!nv) {
                this.dailyQuery = { ...this.$options.data().dailyQuery }
            }
        },
        showLeader (nv) {
            if (!nv) {
                this.leaderQuery = { ...this.$options.data().leaderQuery }
            }
        },
        showDemocracy (nv) {
            if (!nv) {
                this.democracyQuery = { ...this.$options.data().democracyQuery }
            }
        },
        showMember (nv) {
            if (!nv) {
                this.memberQuery = { ...this.$options.data().memberQuery }
            }
        },
        showIndividual (nv) {
            if (!nv) {
                this.individualQuery = { ...this.$options.data().individualQuery }
            }
        }
    }
}
