<template>
  <div class="j-drawer-label-wrap">
    <div v-if="type === 'title'" class="view-drawer-title">{{title}}</div>
    <div v-else-if="type === 'block'" class="view-drawer-section-block">
      <label :title="title">{{title}}</label><div><slot></slot></div>
    </div>
    <div v-else class="view-drawer-section">
      <label :title="title">{{title}}</label><div :style="`justify-content: ${align === 'right' ? 'flex-end' : 'flex-start'}`"><slot></slot></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'JDrawerLabel',
  props: {
    title: { // 左侧标题文本
      type: String,
      default: ''
    },
    type: { // 包含title、block、inline三种模式，title用于小标题样式，block用于块级显示，inline普通文本换行模式
      type: String,
      default: 'inline'
    },
    align: {
      type: String,
      default: 'left'
    }
  }
}
</script>

<style scoped lang="less">
  .j-drawer-label-wrap {
    // 抽屉正文小标题
    .view-drawer-title {
      display: flex;
      justify-content: space-between;
      padding: 6px 6px 6px 16px;
      background-color: #F6F7F9;
      box-sizing: border-box;
      border-left: 2px solid;
      border-color: #2D76D5;
      margin-bottom: 15px;
      font-family: Alibaba-PuHuiTi-M;
      font-size: 12px;
      color: #2D76D5;
      background-image: linear-gradient(270deg, rgba(45,118,213,0) 20%, rgba(81,160,255, 0.2) 80%);
    }
    .view-drawer-section-block {
      box-sizing: border-box;
      padding: 0 10px 12px;
      font-size: 14px;
      > label {
        color: #606266;
        width: 100%;
        word-break: break-all;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      > div {
        padding-top: 8px;
        width: 100%;
        color: #606266;
        font-weight: 500;
        overflow-x: auto;
        overflow-y: hidden;
        &:empty {
          width: 100%;
          text-align: center;
          &::before {
            content: '暂无数据';
            font-weight: lighter;
            color: #C0C4CC;
          }
        }
      }
    }
    // 抽屉正文容器-行内样式
    .view-drawer-section {
      display: flex;
      box-sizing: border-box;
      padding: 0 10px 12px;
      font-size: 14px;
      &:last-child {
        margin-bottom: 8px;
      }
      > label {
        max-height: 60px;
        text-align: right;
        margin-right: 12px;
        color: #606266;
        color: rgba(51,51,51,.65);
        width: 74px;
        word-break: break-all;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }
      > div {
        display: flex;
        flex: 1;
        min-width: 100px;
        color: #606266;
        color: #333;
        font-weight: 500;
        overflow-x: auto;
        overflow-y: hidden;
        align-items: center;
        &:empty {
          &::before {
            content: '暂无数据';
            font-weight: lighter;
            color: #C0C4CC;
          }
        }
      }
    }
  }
</style>
