/* <j-page>
        <div slot="search">
            <j-page-search title="时间">
                <j-date-range :start.sync="query.starttime" :end.sync="query.endtime"/>
            </j-page-search>
            <j-page-search title="标题">
                <el-input v-model.trim="query.title" placeholder="请输入" clearable />
            </j-page-search>
            <j-page-search title="角色">
                <el-select v-model="query.xxx" placeholder="请选择" clearable filterable>
                    <el-option v-for="item in []" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
            </j-page-search>
            <el-button type="primary" plain @click="searchEvent">查询</el-button>
        </div>
        <div slot="table">
            <el-table v-loading="tableLoading" :data="tableData" @selection-change="tableSelectionChange" height="100%">
                <el-table-column type="selection" align="center" width="50" />
                <el-table-column type="index" label="序号" align="center" width="50" />
                <el-table-column prop="title" label="所属区域" align="center" show-overflow-tooltip />
                <el-table-column label="操作" width="160">
                    <template slot-scope="scope">
                        <el-button @click.stop="detialEvent(scope.row)" type="primary" class="el-button el-button--text">详情</el-button>
                        <el-button @click.stop="editEvent(scope.row)" type="primary" class="el-button el-button--text">编辑</el-button>
                        <el-button @click.stop="delEvent(scope.row)" type="primary" class="el-button el-button--text font-red" >删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div slot="pagination" v-show="query.total > query.limit">分页需要使用分页混入<el-pagination/></div>
    </j-page> */

import JPage from "../JPage";
import JPageSearch from "../JPageSearch";
import JDateRange from "../../JDateRange/JDateRange";
import JDictionary from "../../JDictionary/JDictionary";
import JColour from "../../JColour/JColour";
import JDicSelect from "../../JDicSelect/JDicSelect";
import JSensitive from "../../JSensitive/JSensitive";
import JLinkInput from "../../JLinkInput/JLinkInput";
import JUpload from "../../JUpload/components/JUpload";
import JLabel from "@/components/JComponents/JLabel/JLabel";
export default {
  components: {
    JPage,
    JPageSearch,
    JDateRange,
    JDictionary,
    JColour,
    JDicSelect,
    JSensitive,
    JUpload,
    JLinkInput,
    JLabel
  },
  data () {
    return {
      submitLoad: false,
      tableLoading: false,
      tableData: [],
      tableSelection: [],
      query: {
        page: 1,
        limit: 10,
        total: 0
      }
    }
  },
  methods: {
    tableSelectionChange (selection) {
      this.tableSelection = selection
    },
    async exportEvent () {
      const { data } = await this.$http.request({
        method: 'get',
        url: `/api/sdlx/task-stat/export`,
        params: { ...this.query, taskType: this.type },
        responseType: 'arraybuffer'
      })
      let url = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }))
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', '结果导出')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link) // 下载完成移除元素
      window.URL.revokeObjectURL(url) // 释放掉blob对象
    },
    async load () {
      this.tableLoading = true
      const { data } = await this.$http.get(`/api/list`, {
        params: this.query
      })
      this.tableData = data.data.data
      this.query.total = data.data.total
      this.tableLoading = false
    },
    searchEvent () {
      this.query.page = 1
      this.load && this.load()
    },
    resetEvent () {
      // 重置query对象
      this.query = { ...this.$options.data().query }
      this.load && this.load()
    },
    delEvent (row) {
      this.$confirm('您确认要删除当前记录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http
          .request({
            method: 'delete',
            url: `/api/categorys/${row.id}`
          })
          .then((res) => {
            if (res.data.status !== 'yes') return this.$message.error(res.data.msg)
            this.pageUpdate(this.query)
            this.$message.success('删除成功')
            this.load && this.load()
          })
      })
    },
    pageUpdate (queryParams) { // 当页面仅有最后一条记录时，删除该记录，需要请求上一页数据
      const query = queryParams || this.query || { page: 1, limit: 10, total: 0 }
      let curPage = Math.ceil((query.total - 1) / query.limit)
      if (query.page > curPage) query.page = curPage
    }
  }
}
