<!--
 * @Description: 民主测评台账
 * @Author: tengmingxin
 * @Date: 2022-04-15 09:37:06
 * @LastEditors: tengmingxin
 * @LastEditTime: 2022-05-05 09:35:35
-->
<template>
  <j-page>
    <!-- 顶部搜索 -->
    <div slot="search">
      <j-page-search title="所属机构">
        <el-cascader
          v-model="query.orgId"
          :options="$store.state.orgTree"
          :props="{
            children: 'childrens',
            label: 'name',
            value: 'id',
            emitPath: false,
            checkStrictly: true,
          }"
          placeholder="请选择"
          clearable
        />
      </j-page-search>
      <j-page-search title="姓名">
        <el-input v-model="query.userName" placeholder="请输入" clearable />
      </j-page-search>
      <j-page-search title="联系电话">
        <el-input v-model="query.userMobile" placeholder="请输入" clearable />
      </j-page-search>
      <j-page-search title="身份证号">
        <el-input v-model="query.cardCode" placeholder="请输入" clearable />
      </j-page-search>
      <j-page-search title="角色">
        <el-select v-model="query.roleId" placeholder="请选择" clearable>
          <el-option
            v-for="item in $store.state.roles"
            :key="item.roleId"
            :label="item.name"
            :value="item.roleId"
          />
        </el-select>
      </j-page-search>
      <j-page-search title="状态">
        <j-dic-select v-model="query.status" code="STATUS_TYPE" />
      </j-page-search>
      <j-page-search title="考核月份">
        <el-date-picker
          v-model="query.yearMonth"
          type="month"
          placeholder="请选择"
          value-format="yyyy/MM"
          clearable
        />
      </j-page-search>
<!--      <j-page-search title="民主测评总分">-->
<!--        <j-link-input-->
<!--          :start.sync="query.evaluateTotalScoreGreaterThan"-->
<!--          :end.sync="query.evaluateTotalScoreLessThan"-->
<!--        />-->
<!--      </j-page-search>-->
      <j-page-search title="评价进度">
        <j-dic-select
          v-model="query.completeProportionId"
          code="COMPLETE_STATUS"
        />
      </j-page-search>
      <el-button type="primary" @click="searchEvent">搜索</el-button>
      <el-button @click="resetEvent">重置</el-button>
      <el-button type="primary" @click="exportEvent">导出</el-button>
    </div>

    <!-- 表格 -->
    <div slot="table">
      <t-table
        :table="tableData"
        :columns="columns"
        :handle-btns="handleBtns"
        @onHandle="onHandle"
        @pageChange="(e) => load({ page: e })"
      >
        <template #status="{row}">
         <span :class="`color-status-style color-status${row.status}`" />
        </template>
        <template #completeProportion="scope">
          <el-link
            :underline="false"
            style="color: #2789ff"
            @click.stop="
              getProgressList({
                userId: scope.row.userId,
                yearMonth: scope.row.yearMonth,
              })
            "
          >
            {{ scope.row.completeProportion }}
          </el-link>
        </template>
      </t-table>
    </div>

    <!-- 侧弹窗 -->
    <j-drawer title="查看" :close.sync="drawerShow" class="ledger-drawer">
      <div v-for="(item, index) in detailData" :key="index">
        <j-drawer-label type="title" :title="item.label" align="right" />
        <el-empty v-if="item.isNull" :image-size="100" description="暂无数据" />
        <div v-else>
          <j-drawer-label
            v-for="(it, i) in item.children"
            :key="i"
            :title="it.label"
            align="left"
          >
            <j-sensitive
              v-if="it.type"
              :type="item.type"
              v-model="it.value"
              eye
            />
            <el-link
              v-else-if="i === 'completeProportion'"
              :underline="false"
              style="color: #2789ff"
              @click.native="getProgressList()"
            >
              {{ it.value }}
            </el-link>
            <span v-else>{{ it.value }}</span>
          </j-drawer-label>
        </div>
      </div>
    </j-drawer>

    <!-- 居中弹窗 -->
    <el-dialog
      title="测评进度"
      :visible.sync="progressDialogShow"
      width="1000px"
      :close-on-click-modal="false"
    >
      <t-table
        :table="progressTableData"
        :columns="progressColumns"
        @pageChange="(e) => getProgressList({ page: e })"
      >
        <template #expand="scope">
          <j-drawer-label title="测评说明">
            {{ scope.row.evaluateDiscripotion }}
          </j-drawer-label>
        </template>
      </t-table>
    </el-dialog>
  </j-page>
</template>

<script>
import TTable from "../../components/TTable.vue";
import PageMixins from "@/components/JComponents/JPage/mixins/PageMixins";
import DrawerMixins from "@/components/JComponents/JPage/mixins/DrawerMixins.js";

import {
  getLedgerList,
  getLedgerDetail,
  getProgressList,
} from "@/api/democracy";

export default {
  name: "DemocracyLedger",
  components: { TTable },
  mixins: [PageMixins, DrawerMixins],
  data() {
    return {
      tableData: {
        currentPage: 1,
        limit: 10,
        total: 0,
        data: [],
        loading: false,
      },
      columns: [
        { prop: "status", label: "状态" },
        { prop: "yearMonth", label: "考核月份" },
        { prop: "orgName", label: "所属机构" },
        { prop: "roleName", label: "角色" },
        { prop: "userName", label: "姓名" },
        { prop: "userMobile", label: "联系电话" },
        { prop: "completeProportion", label: "评价进度" },
        { prop: "evaluateTotalScore", label: "民主测评总分" },
      ],
      handleBtns: [{ key: "view", name: "查看" }],
      query: {
        limit: 10,
        page: 1,
        cardCode: "",
        orgId: "",
        personalSummaryStatus: "",
        roleId: "",
        status: "",
        userMobile: "",
        userName: "",
        yearMonth: "",
        completeProportionId: "",
        evaluateTotalScoreGreaterThan: "",
        evaluateTotalScoreLessThan: "",
      },
      progressTableData: {
        currentPage: 1,
        limit: 10,
        total: 0,
        data: [],
        expand: true,
      },
      progressColumns: [
        { prop: "orgName", label: "所属机构" },
        { prop: "roleName", label: "角色", sortable: true },
        { prop: "userName", label: "姓名" },
        { prop: "personalSummaryStatusName", label: "状态", sortable: true },
        { prop: "evaluateLevelName", label: "等级", sortable: true },
        { prop: "evaluateTime", label: "评价日期", sortable: true },
      ],
      progressQuery: {
        limit: 10,
        page: 1,
        userId: "",
        yearMonth: "",
      },
      type: "view",
      drawerShow: false,
      progressDialogShow: false,
      detailData: {
        userBasicInfo: {
          label: "基本信息",
          children: {
            orgName: { label: "所属机构", value: "" },
            roleName: { label: "角色", value: "" },
            name: { label: "姓名", value: "" },
            cardCode: { label: "身份证号", type: "id", value: "" },
            sexName: { label: "性别", value: "" },
            mobile: { label: "联系电话", value: "" },
            statusName: { label: "状态", value: "" },
          },
        },
        democraticEvaluation: {
          label: "民主测评",
          children: {
            yearMonth: { label: "考核月份", value: "" },
            completeProportion: { label: "评价进度", value: "" },
            evaluateTotalScore: { label: "民主测评总分", value: "" },
          },
        },
      },
    };
  },
  created() {
    this.load();
  },
  methods: {
    async load(params = {}) {
      this.tableData.loading = true;
      const { data } = await getLedgerList({ ...this.query, ...params });
      this.tableData.data = data.data.data || [];
      this.tableData.loading = false;
      this.tableData.total = data.data.total;
      this.query.page = data.data.page;
    },
    onHandle(data) {
      this.type = data.type;
      const { userId, yearMonth } = data.scope.row;
      this.getLedgerDetail(userId, yearMonth);
    },
    async getLedgerDetail(userId, yearMonth) {
      let { data } = await getLedgerDetail({ userId, yearMonth });
      this.drawerShow = true;
      this.progressQuery.userId = userId;
      this.progressQuery.yearMonth = yearMonth;
      for (const key in this.detailData) {
        if (!data.data[key]) {
          this.detailData[key].isNull = true;
          return;
        }
        for (const subKey in this.detailData[key].children) {
          this.detailData[key].children[subKey].value =
            data.data[key][subKey] || "";
        }
      }
    },
    async getProgressList(params = {}) {
      const { data } = await getProgressList({
        ...this.progressQuery,
        ...params,
      });
      this.progressTableData.data = data.data.data;
      this.progressTableData.total = data.data.total;
      this.progressDialogShow = true;
    },
    exportEvent() {
      this.$tool.exportEvent(
        "/api/democraticevaluation/export",
        "民主测评结果",
        this.query
      );
    },
  },
};
</script>

<style lang="less">
.ledger-drawer .view-drawer-section > label {
  width: 100px !important;
  text-align: right !important;
}
</style>
