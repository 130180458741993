<template>
    <j-page>
        <div slot="search">
            <j-page-search title="考核月份">
                <el-date-picker v-model="query.yearMonths" type="month"  placeholder="选择考核月份" value-format="yyyy/MM" />
            </j-page-search>
            <el-button type="primary" @click="searchEvent">查询</el-button>
            <el-button @click="resetEvent">重置</el-button>
        </div>
        <div slot="table">
            <el-table v-loading="tableLoading" :data="tableData" height="100%">
                <el-table-column prop="yearMonths" label="考核月份" align="center" show-overflow-tooltip />
                <el-table-column prop="userName" label="名称" align="center" show-overflow-tooltip />
                <el-table-column prop="orgName" label="所属机构" align="center" show-overflow-tooltip />
                <el-table-column prop="roleName" label="角色" align="center" show-overflow-tooltip />
                <el-table-column prop="scoreFinal" label="质效总分" align="center" show-overflow-tooltip>
                    <span class="common-pointer-style" slot-scope="{row}" @click.stop="leaderEvent(row)">{{row.scoreFinal}}</span>
                </el-table-column>
            </el-table>
        </div>
        <div slot="pagination" v-show="query.total > query.limit">
            <el-pagination
                    :current-page="query.page"
                    :page-size="query.limit"
                    :total="query.total"
                    :background="pmxBackground"
                    :pager-count="pmxCount"
                    :layout="pmxLayout"
                    :pageSizes="pmxSizes"
                    @current-change="changePage"
                    @size-change="sizeChange"
            />
        </div>
        <el-dialog v-if="showDgmxDetail" :visible.sync="showDgmxDetail" width="800px" top="10%" :close-on-click-modal="false">
            <span slot="title" class="common-title-style">分管科室<span>({{curItem && curItem.scoreFinal}}分)</span></span>
            <div class="common-tip-style">
                <div>主管领导质效总分 = 分管领导效质总分的均值</div>
            </div>
            <el-table :data="leaderData" style="width: 100%" height="500px">
                <el-table-column type="index" label="序号" align="center" width="50" />
                <el-table-column prop="yearMonths" label="考核月份" align="center" show-overflow-tooltip />
                <el-table-column prop="orgName" label="分管机构" align="center" show-overflow-tooltip />
                <el-table-column prop="userName" label="姓名" align="center" show-overflow-tooltip />
                <el-table-column prop="roleName" label="角色" align="center" show-overflow-tooltip />
                <el-table-column sortable prop="title" label="总分" align="center" show-overflow-tooltip>
                    <span class="common-pointer-style" slot-scope="{row}">{{row.scoreFinal}}</span>
                </el-table-column>
            </el-table>
            <div slot="pagination" v-show="leaderQuery.total > leaderQuery.limit">
                <el-pagination
                        :current-page="leaderQuery.page"
                        :page-size="leaderQuery.limit"
                        :total="leaderQuery.total"
                        :pager-count="pmxCount"
                        :layout="pmxLayout"
                        @current-change="changeLeaderPage"
                />
            </div>
        </el-dialog>
    </j-page>
</template>

<script>
    /**
     * @Author: fengjunjie <fengjunjie@btzh.cn>
     * @Description: jobrating_pc
     * @Date: 2022/04/15 10:02
     */
    import PageMixins from "../../components/JComponents/JPage/mixins/PageMixins";
    import PaginationMixins from "../../components/JComponents/JPage/mixins/PaginationMixins";
    import DialogMixins from "../../components/JComponents/JPage/mixins/DialogMixins";
    export default {
        name: "MostlyLeadership",
        mixins: [PageMixins, PaginationMixins, DialogMixins],
        data () {
            return {
                query: {
                    yearMonths: ''
                },
                tableData: [
                    { title: 'test' }
                ],
                leaderQuery: {
                  page: 1,
                  limit: 10,
                  total: 0
                },
                leaderData: []
            }
        },
        created () {
            this.load()
        },
        methods: {
            async load () {
                this.tableLoading = true
                const { data } = await this.$http.get(`/api/bisMassEffectEvalue11`, {
                    params: this.query
                })
                this.tableData = data.data.data
                this.query.total = data.data.total
                this.tableLoading = false
            },
            leaderEvent (row) {
                this.curItem = row
                this.loadLeaderData()
            },
            changeLeaderPage (page) {
                this.leaderQuery.page = page
                this.loadLeaderData()
            },
            async loadLeaderData () {
                const { data } = await this.$http.get(`/api/bisMassEffectEvalue12`, {
                    params: { ...this.leaderQuery, orgId: this.curItem.orgId }
                })
                this.leaderData = data.data.data
                this.leaderQuery.total = data.data.total
                this.showDgmxDetail = true
            }
        }
    }
</script>
