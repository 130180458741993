/* <el-pagination
    :current-page="query.page"
    :page-size="query.limit"
    :total="query.total"
    :background="pmxBackground"
    :pager-count="pmxCount"
    :layout="pmxLayout"
    :pageSizes="pmxSizes"
    @current-change="changePage"
    @size-change="sizeChange"
   /> */

export default {
  data () {
    return {
      query: {
        page: 1,
        limit: 10,
        total: 0
      },
      pmxCount: 11,
      pmxLayout: 'total, prev, pager, next',
      pmxSizes: [10, 20, 30, 50, 100, 200, 500],
      pmxBackground: true
    }
  },
  methods: {
    changePage (page) {
      this.query.page = page
      this.load && this.load()
    },
    sizeChange (limit) {
      this.query.limit = limit
      this.load && this.load()
    }
  }
}
